<div class="card-body">
  <h3 translate>RESULTS</h3>

  <p class="card-description" translate>SELECT-CATEGORY</p>
  <div class="row">
    <div class="col-md-6">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary" ngbDropdownToggle>
          <span *ngIf="selectedCompetition; else elseTemplate"
            >{{ selectedCompetition.category.name | translateCategory | async }}
            -
            {{
              selectedCompetition.date | date: "EEEE dd/MM" | titlecase
            }}</span
          >

          <ng-template #elseTemplate translate>
            {{ !noResult ? "CATEGORIES" : "NO-CATEGORY-FOUND" }}
          </ng-template>
        </button>
        <div ngbDropdownMenu>
          <button
            (click)="getTakeOffs(item._id)"
            *ngFor="let item of resultCompetitions"
            ngbDropdownItem
          >
            {{ item.category.name | translateCategory | async }} -
            {{ item.date | date: "EEEE dd/MM" | titlecase }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
      <button
        class="btn btn-outline-primary"
        (click)="openChooseModal(content)"
        translate
      >
        GENERATE-RESULT-LIST
      </button>
    </div>
  </div>
  <div class="row pt-3">
    <div class="table-responsive" *ngIf="takeoffs">
      <table class="table table-striped">
        <thead>
          <tr>
            <th *ngIf="!tkHasPass" translate>TAKEOFF</th>
            <th translate>RANK</th>
            <th translate>COMPETITOR.SINGLE</th>
            <th translate>ANIMAL.SINGLE</th>
            <th translate>SCORE</th>
            <th *ngIf="takeoffs[0]?.oldScore" translate>SOLOSCORE</th>
            <th translate>AWARD</th>
            <th translate>OWNER</th>
            <th translate>GROUPSCORE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="takeoffsLoading">
            <td colspan="6">
              <div
                class="
                  lds-dual-ring lds-dual-ring-black
                  d-flex
                  justify-content-center
                  w-100
                "
              ></div>
            </td>
          </tr>
          <ng-container *ngFor="let takeOff of takeoffs">
            <tr>
              <td *ngIf="!tkHasPass">
                {{ takeOff.pass }}ª {{ "TAKEOFF" | translate }}
              </td>
              <td *ngIf="selectedCompetition.resultType != 'DIVISION'">
                {{ takeOff.classification ? takeOff.classification + "º " : ""
                }}<span *ngIf="takeOff.classification" translate>PLACE</span>
              </td>
              <td *ngIf="selectedCompetition.resultType == 'DIVISION'">
                <span *ngIf="takeOff.classification">{{
                  takeOff.classification
                }}</span
                ><span *ngIf="takeOff.classification" translate>PLACEOF</span
                ><span *ngIf="takeOff.classification">{{
                  takeOff.division + "D"
                }}</span>
              </td>
              <td>{{ takeOff.competitor.name }}</td>

              <td>{{ takeOff.horse.name }}</td>

              <td>{{ takeOff.score }}</td>
              <td *ngIf="takeOff?.oldScore">{{ takeOff.oldScore }}</td>

              <td>
                <span *ngIf="takeOff.award">{{
                  eventParam.currency | currencyPipe
                }}</span>
                {{ takeOff.award ? takeOff.award : "" }}
              </td>
              <td>{{ takeOff.horse.owner }}</td>
              <td>{{takeOff.rankTypegroupScore}}</td>
            </tr>
            <tr
              *ngFor="let item of getEqualsTakeoffs(takeOff.horse._id)"
              class="subtable"
            >
              <td>
                <i
                  class="mdi mdi-subdirectory-arrow-right"
                  style="font-size: 22px"
                ></i>
                <span></span>
              </td>

              <td>{{ item.competitor.name }}</td>

              <td>{{ item.horse.name }}</td>

              <td>{{ item.score }}</td>
              <td *ngIf="item?.oldScore">{{ item.oldScore }}</td>

              <td></td>
              <td>{{ item.horse.owner }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" translate>CHOOSELIST</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body"></div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('landscape')"
        translate
      >
        HORIZONTAL
      </button>
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('portrait')"
        translate
      >
        VERTICAL
      </button>
    </div>
  </ng-template>
</div>
