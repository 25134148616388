<div class="modal-header">
  <h4 class="modal-title">
    {{ competition.category.name | translateCategory | async }} -
    {{ competition.date | date }}
    <span *ngIf="competition.subscriptionCount"
      >{{ competition.subscriptionCount
      }}{{
        competition.subscriptionLimit > 0
          ? "/ " + competition.subscriptionLimit
          : ""
      }}
      {{ "SUBSCRIPTIONS" | translate }}</span
    >
    <span> - {{ event.currency | currencyPipe }}{{ competition.price }}</span>
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card-body">
    <h4 class="card-title" translate>SUBSCRIPTIONS</h4>
    <p class="card-description" *ngIf="!eventFinished" translate>
      ADD-NEW-SUBSCRIPTIONS
    </p>
    <div class="row" *ngIf="!eventFinished">
      <div class="col-lg-4 mb-2">
        <app-searcher
          (searchedValue)="setCustomer($event)"
          [type]="'CUSTOMER'"
        ></app-searcher>
        <small class="form-text text-muted translate" translate
          >CUSTOMER.INSERT</small
        >
      </div>
      <div class="col-lg-4 mb-2">
        <app-searcher
          (searchedValue)="setCompetitor($event)"
          [type]="'COMPETITOR'"
        ></app-searcher>
        <small class="form-text text-muted" translate>CUSTOMER.SINGLE</small>
      </div>
      <div class="col-lg-4 mb-2">
        <app-searcher
          (searchedValue)="setHorse($event)"
          [type]="'HORSE'"
        ></app-searcher>
        <small class="form-text text-muted" translate>ANIMAL.SINGLE</small>
      </div>
      <div class="col-lg-4 mb-2">
        <select class="form-control" [(ngModel)]="pass">
          <option value="1">1 {{ "TAKEOFF" | translate }}</option>
          <option value="2" *ngIf="pass > 1">
            2 {{ "TAKEOFFS" | translate }}
          </option>
          <option value="3" *ngIf="pass > 2">
            3 {{ "TAKEOFFS" | translate }}
          </option>
        </select>
        <small class="form-text text-muted" translate>
          EVENT.CATEGORIES.SELECT-QTY
        </small>
      </div>

      <div class="mb-2" [ngClass]="col - lg - 4">
        <input class="form-control" [(ngModel)]="code" />
        <small class="form-text text-muted" translate>COUPON.ENTER</small>
      </div>
      <div class="col-lg-4">
        <button
          type="submit"
          class="btn btn-block btn-sm btn-gradient-primary"
          (click)="submitSubscription()"
          [disabled]="
            !horse ||
            !competitor ||
            isCouponLoading ||
            isLoading ||
            isReadAbqmCompetitorLoading ||
            isReadAbqmAnimalLoading ||
            isAddTakeOffLoading
          "
        >
          <span
            *ngIf="
              !isReadAbqmCompetitorLoading &&
              !isReadAbqmAnimalLoading &&
              !isAddTakeOffLoading
            "
            translate
            >ADD</span
          >
          <span *ngIf="isReadAbqmCompetitorLoading" translate
            >LOADING-ABQM-COMPETITOR</span
          >
          <span *ngIf="isReadAbqmAnimalLoading" translate
            >LOADING-ABQM-ANIMAL</span
          >
          <span *ngIf="isAddTakeOffLoading" translate>LOADING-ADD-TAKEOFF</span>
        </button>
      </div>
    </div>
    <div
      class="row pl-3"
      *ngIf="
        competition.subscriptionCount >= competition.subscriptionLimit &&
        competition.subscriptionLimit != 0
      "
    >
      <div class="alert alert-danger" translate>
        EVENT.CATEGORIES.SUBSCRIPTION-LIMIT-REACHED
      </div>
    </div>

    <div class="row" *ngIf="competition.pass > 1">
      <div class="col-md-12">
        <ul class="nav nav-tabs justify-content-start">
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="changeCompetitionPass(1)"
              [ngClass]="{ active: competitionPass == 1 }"
              >1ª {{ "TAKEOFF" | translate }}</a
            >
          </li>
          <li class="nav-item" *ngIf="competition.pass > 1">
            <a
              class="nav-link"
              (click)="changeCompetitionPass(2)"
              [ngClass]="{ active: competitionPass == 2 }"
              >2ª {{ "TAKEOFF" | translate }}</a
            >
          </li>
          <li class="nav-item" *ngIf="competition.pass > 2">
            <a
              class="nav-link"
              (click)="changeCompetitionPass(3)"
              [ngClass]="{ active: competitionPass == 3 }"
              >3ª {{ "TAKEOFF" | translate }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md-12">
        <h3>{{ subscriptions?.length }} {{ "SUBSCRIPTIONS" | translate }}</h3>
      </div>
    </div>
    <div class="row mt-4" *ngIf="subscriptions">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th translate>CUSTOMER.SINGLE</th>
                <th translate>COMPETITOR.SINGLE</th>
                <th translate>ANIMAL.SINGLE</th>
                <!-- <th>Tempo</th> -->
                <th translate>VALUE</th>
                <th *ngIf="competition.pass > 1" translate>TAKEOFFS</th>
                <th></th>
              </tr>
            </thead>
            <tbody *ngIf="isLoading">
              <tr>
                <td colspan="4" style="text-align: center">
                  <app-loading></app-loading>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!isLoading && subscriptions.length == 0">
              <tr>
                <td colspan="4" style="text-align: center" translate>
                  EVENT.CATEGORIES.NO-COMPETITORS
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!isLoading">
              <tr *ngFor="let sub of subscriptions; let index = index">
                <td>
                  <span
                    class="mr-3"
                    *ngIf="toggleIsChangeCustomer != index && sub.owner"
                    >{{ sub.order }}{{ sub.owner.name }} {{ sub._id }}
                    <br />
                    {{ getTime(sub.createdAt) < 1632711600000 }}
                  </span>
                  <button
                    *ngIf="toggleIsChangeCustomer != index && sub.owner"
                    (click)="toggleIsChangeCustomer = index"
                    class="btn btn-sm bg-transparent"
                  >
                    <i class="mdi mdi-for-btn mdi-tooltip-edit"></i>
                  </button>

                  <div
                    class="d-flex"
                    *ngIf="toggleIsChangeCustomer == index && sub.owner"
                  >
                    <div>
                      <app-searcher
                        (searchedValue)="setSearchedCustomer($event)"
                        [type]="'CUSTOMER'"
                      ></app-searcher>
                    </div>
                    <div>
                      <button
                        class="btn btn-sm btn-outline-info"
                        (click)="toggleIsChangeCustomer = -1"
                        [disabled]="isChangeCustomerLoading"
                        translate
                      >
                        CANCEL
                      </button>
                    </div>
                    <div>
                      <button
                        class="btn btn-sm btn-outline-primary"
                        (click)="submitChangeUser(sub._id)"
                        [disabled]="isChangeCustomerLoading"
                        translate
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <span class="mr-3" *ngIf="toggleIsChangeCompetitor != index"
                    >{{ sub.competitor.name }} -
                    {{ sub.competitor.age | hippicalYearPipeCompetitor }}
                    {{ "HIPPICALYEARS" | translate }} -
                    {{ sub.competitor.age | date: "dd/MM/yyyy" }}</span
                  >
                  <button
                    *ngIf="toggleIsChangeCompetitor != index"
                    (click)="toggleIsChangeCompetitor = index"
                    class="btn btn-sm bg-transparent"
                  >
                    <i class="mdi mdi-for-btn mdi-tooltip-edit"></i>
                  </button>

                  <div class="d-flex" *ngIf="toggleIsChangeCompetitor == index">
                    <div>
                      <app-searcher
                        (searchedValue)="setSearchedCompetitor($event)"
                        [type]="'COMPETITOR'"
                      ></app-searcher>
                    </div>
                    <div>
                      <button
                        class="btn btn-sm btn-outline-info"
                        (click)="toggleIsChangeCompetitor = -1"
                        [disabled]="isChangeCompetitorLoading"
                        translate
                      >
                        CANCEL
                      </button>
                    </div>
                    <div>
                      <button
                        class="btn btn-sm btn-outline-primary"
                        (click)="submitChangeCompetitor(sub._id)"
                        [disabled]="isChangeCompetitorLoading"
                        translate
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                </td>
                <td
                  [ngClass]="
                    getDuplicates(sub.horse._id) > 1 ? 'bg-danger' : ''
                  "
                >
                  <span class="mr-3" *ngIf="toggleIsChangeHorse != index"
                    >{{ getDuplicates(sub.horse._id) }} ||
                    {{ sub.horse.name }} -
                    {{ sub.horse.birth | hippicalYearPipeAnimal }}
                    {{ "HIPPICALYEARS" | translate }} -
                    {{ sub.horse.birth | date: "dd/MM/yyyy"
                    }}{{ sub.horse.gender }}</span
                  >
                  <button
                    *ngIf="toggleIsChangeHorse != index"
                    (click)="toggleIsChangeHorse = index"
                    class="btn btn-sm bg-transparent"
                  >
                    <i class="mdi mdi-for-btn mdi-tooltip-edit"></i>
                  </button>

                  <div class="d-flex" *ngIf="toggleIsChangeHorse == index">
                    <div>
                      <app-searcher
                        (searchedValue)="setSearchedHorse($event)"
                        [type]="'HORSE'"
                      ></app-searcher>
                    </div>
                    <div>
                      <button
                        class="btn btn-sm btn-outline-info"
                        (click)="toggleIsChangeHorse = -1"
                        [disabled]="isChangeHorseLoading"
                        translate
                      >
                        CANCEL
                      </button>
                    </div>
                    <div>
                      <button
                        class="btn btn-sm btn-outline-primary"
                        (click)="submitChangeHorse(sub._id)"
                        [disabled]="isChangeHorseLoading"
                        translate
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                </td>
                <!-- <td class="d-flex">
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="animalChip[index]"
                  />
                  <div
                    class="btn btn-gradient-primary btn-sm"
                    (click)="editScore(sub._id, tkScore[index])"
                  >
                    Salvar
                  </div>
                </td> -->
                <td>{{ event.currency | currencyPipe }}{{ sub.price }}</td>
                <td *ngIf="competition.pass > 1">
                  <span *ngIf="!sub.hasPass" [translate]="{ pass: sub.pass }"
                    >ONLYSOMEPASS</span
                  >
                </td>
                <td *ngIf="!event.isDoneRanking">
                  <button
                    class="
                      btn btn-outline-primary
                      mdi mdi-for-btn mdi-delete-forever
                    "
                    (click)="deleteSubscription(sub._id)"
                  ></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="activeModal.dismiss('button click')"
        translate
      >
        CLOSE
      </button>
    </div>
  </div>
</div>
