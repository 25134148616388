<div class="page-header">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/']" translate>HOME</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>
        CREATE-GROUP
      </li>
    </ol>
  </nav>
</div>
<div class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body card-body-fill">
        <h4 class="card-title" translate>CREATE-GROUP</h4>

        <form
          class="forms-event"
          [formGroup]="typeGroupForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label for="name" translate>NAME</label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="{{ 'NAME' | translate }}"
              formControlName="name"
            />
            <div
              *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)"
              class="alert alert-danger"
            >
              <small
                *ngIf="f.name.errors.required"
                class="form-text text-danger"
                translate
                >NAME-NEEDED</small
              >
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-sm-block btn-gradient-primary mr-2 float-right"
            translate
          >
            CREATE-GROUP
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
