import { Component, OnInit, Input } from "@angular/core";

import * as dateFns from "date-fns";
import { EventService } from "src/app/services/event.service";
import { Observable, of } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
  switchMap,
} from "rxjs/operators";
import { AddCategory } from "src/app/dto/AddCategory";
import { AlertTranslateService } from "src/app/services/alert.service";
import ErrorHandler from "../../util/errorHandler";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventCategoriesSubscriptionsComponent } from "./event-categories-subscriptions/event-categories-subscriptions.component";
import { Socket } from "ngx-socket-io";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-event-categories",
  templateUrl: "./event-categories.component.html",
  styleUrls: ["./event-categories.component.scss"],
})
export class EventCategoriesComponent implements OnInit {
  date;
  category: any;
  pass = 1;
  passPrice = 0;
  price;
  searching = false;
  searchFailed = false;
  hasSearched = false;
  days = [];
  searchedCategory: string;
  hasAllPrices = false;
  isAdmin = false;
  competitions: any = [];

  @Input() eventParam: any;
  @Input() permission: any;

  constructor(
    private _event: EventService,
    private alertService: AlertTranslateService,
    private modalService: NgbModal,
    private socket: Socket,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.loadCompetitions();
    this.loadCategoryFields();
    this.getToken();
  }

  async loadCompetitions() {
    this.competitions = await this._event.getEventCompetitions(
      this.eventParam._id
    );
    this.competitions = this.competitions.sort((a, b) =>
      a.category.name > b.category.name ? 1 : -1
    );
  }

  getToken() {
    this.isAdmin =
      environment.adminId.filter(
        (adm) => adm == this.authService.decodeToken().id
      ).length > 0;
  }

  async openModal(competition, i) {
    const modalRef = await this.modalService.open(
      EventCategoriesSubscriptionsComponent,
      {
        size: "lg",
      }
    );
    modalRef.componentInstance.competition = competition;
    modalRef.componentInstance.eventFinished = this.eventParam.isFinished;
  }

  async loadCategoryFields() {
    this.eventParam.eventDays.forEach(async (day, index) => {
      const d = await this._event.getCompetitionByEventAndDate(
        this.eventParam._id,
        day
      );

      this.days[index] = d;
    });
  }

  async submitCategory() {
    const newCategory = new AddCategory();
    newCategory.competitionId = this.category._id;
    newCategory.date = this.date;
    newCategory.price = this.price.toString().replace(" ", "").split(",");
    newCategory.pass = !this.category.isRank ? this.pass : 1;
    if (this.category == null || this.date == null) {
      this.alertService.danger("CATEGORY-DATE-NOT-FOUND");
    } else {
      try {
        await this._event.addCompetitionToEvent(
          this.eventParam._id,
          newCategory
        );
        this.emitCompetitionChanges();
        this.alertService.success("ADDED-CATEGORY");
        await this.loadCategoryFields();
        await this.loadCompetitions();
      } catch (ex) {
        this.alertService.danger(ErrorHandler(ex.error.message));
      }
    }
  }

  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this._event.searchCategory(term).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => ((this.searching = false), (this.hasSearched = true)))
    );

  emitCompetitionChanges() {
    this.socket.emit("get:event/competition", this.eventParam._id);
  }

  async deleteCategory(id: String) {
    if (this.eventParam.hasPass) {
      this.alertService.warning("ERROR-DELETE-CATEGORY");
      return;
    }
    if (
      !this.isAdmin &&
      dateFns.isBefore(this.eventParam.startDate, new Date())
    ) {
      this.alertService.warning("ERROR-DELETE-CATEGORY-EVENT");
      return;
    }
    if (await this.alertService.confirm("CONFIRM-REMOVE-CATEGORY")) {
      await this._event.deleteCompetitionCategory(this.eventParam._id, id);
      this.emitCompetitionChanges();
      this.loadCategoryFields();
    }
  }

  setNotSearched() {
    this.hasSearched = false;
    this.category = "";
    this.searchedCategory = null;
  }

  selectedCategoryItem(data) {
    this.searchedCategory = data.item.name;
    this.setAllPrices(data.item);
  }

  setAllPrices(category = null) {
    if (category?.isRank) {
      this.hasAllPrices = true;
      this.price = [0];
      return true;
    }
    if (this.price && this.price !== "") {
      let newPrice = this.price.toString().replace(" ", "").split(",");

      this.hasAllPrices =
        newPrice.length == this.pass &&
        newPrice[newPrice.length - 1].replace(" ", "") != "";
    } else this.hasAllPrices = false;
  }
}
