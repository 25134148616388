/// <reference types="@types/googlemaps" />
declare var google: any;
import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { AlertTranslateService } from "src/app/services/alert.service";
import { Router } from "@angular/router";
import { RegisterUser } from "src/app/dto/RegisterUser";
import ErrorHandler from "../../util/errorHandler";
import { MapsAPILoader } from "@agm/core";
import { LocateService } from "src/app/services/locate.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  submitted: boolean = false;
  countries;

  @ViewChild("search", { static: true }) public searchElement: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private alertService: AlertTranslateService,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private locate: LocateService
  ) {}

  ngOnInit() {
    // this.getCountries();
    this.registerForm = this.formBuilder.group(
      {
        name: ["", [Validators.required]],
        email: ["", [Validators.required]],
        password: ["", [Validators.required, Validators.minLength(8)]],
        confirmPassword: ["", [Validators.required]],
        cpforcnpj: ["", [Validators.required]],
        phone: ["", [Validators.required, Validators.maxLength(11)]],
        address: ["", [Validators.required]],
        // country: ["", [Validators.required]]
      },
      {
        validator: this.checkIfMatchingPasswords("password", "confirmPassword"),
      }
    );
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElement.nativeElement,
        { types: ["geocode"] }
      );

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
        });
      });
    });
  }
  // async getCountries() {
  //   this.countries = await this.locate.getAllCountries();
  // }
  checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  get f() {
    return this.registerForm.controls;
  }

  async onSubmit() {
    if (!this.registerForm.valid) return;
    this.submitted = true;
    const {
      email,
      password,
      name,
      cpforcnpj,
      phone,
      address,
      //country
    } = this.registerForm.controls;
    const user = new RegisterUser();
    user.email = email.value;
    user.password = password.value;
    user.name = name.value;
    user.cpforcnpj = cpforcnpj.value;
    user.phone = phone.value;
    user.address = address.value;
    // user.country = country.value;

    try {
      const register = await this.auth.Register(user);
      this.alertService.success("SUCCESS-REGISTERED");
      this.submitted = false;

      this.router.navigate(["/login"]);
    } catch (ex) {
      this.alertService.danger(ErrorHandler(ex.error.message));
      this.submitted = false;
    }
  }
}
