import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "angular-intl";

@Pipe({ name: "translateCategory" })
export class TranslateCategoryPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  async transform(value: any, exponent?: number) {
    const translated: any = await new Promise((resolve, reject) => {
      this.translate
        .get(value.replaceAll(" ", "_"))
        .subscribe((res) => resolve(res));
    });
    return translated;
  }
}
