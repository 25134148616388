import { Component, Input, OnInit } from "@angular/core";
import { AlertTranslateService } from "src/app/services/alert.service";
import { AbqmService } from "src/app/services/abqm.service";
import { EventService } from "src/app/services/event.service";

@Component({
  selector: "app-event-categories-abqm",
  templateUrl: "./event-categories-abqm.component.html",
  styleUrls: ["./event-categories-abqm.component.scss"],
})
export class EventCategoriesAbqmComponent implements OnInit {
  @Input() event: any;
  competitions: any;
  allCompetitions: any;
  abqmId = [];
  abqmCompetitions: any = [];

  constructor(
    private _event: EventService,
    private _abqm: AbqmService,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {
    this.loadCompetitions();
    console.log(this.event);
    if (this.event.abqmId) this.getAbqmCompetitions();
  }

  async loadCompetitions() {
    this.allCompetitions = await this._event.getEventAllCompetitions(
      this.event._id
    );
    this.competitions = this.allCompetitions
      .filter(
        (x) =>
          !x.hasPass && !x.category.name.toLowerCase().includes("test horse")
      )
      .sort((a, b) => (a.category.name > b.category.name ? 1 : -1));
    for (let i = 0; i < this.competitions.length; i++) {
      this.abqmId.push(
        this.competitions[i].abqmId ? this.competitions[i].abqmId : ""
      );
    }
  }

  async saveSettings(i) {
    try {
      await this._event.setCompetitionAbqm(
        this.event._id,
        this.competitions[i]._id,
        this.abqmId[i]
      );
      this.alertService.success(
        "UPDATED-COMPETITION-SETTINGS",
        this.competitions[i].category.name
      );
    } catch (e) {
      console.log(e);
      this.alertService.danger("ERROR");
    }
  }

  async getAbqmCompetitions() {
    try {
      this.abqmCompetitions = await this._abqm.getAbqmCompetitions(
        this.event.abqmId
      );
      this.abqmCompetitions = this.abqmCompetitions.sort((a, b) =>
        a.cdsModalidade > b.cdsModalidade ? 1 : -1
      );
    } catch (e) {
      console.log(e);
      this.alertService.danger("ERROR");
    }
  }
}
