<h4 class="card-title" translate>SUBSCRIPTION-PAYMENT</h4>
<p class="card-description" translate>ADD-SUBSCRIPTION-PAYMENT</p>
<div class="row">
  <div class="col-md-2">
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        placeholder="00.00"
        [(ngModel)]="value"
      />
    </div>
    <small
      ><span class="badge badge-danger pr-2"
        >{{ "FINANCIAL-GENERAL.SELECTED" | translate
        }}{{ selectedPrice() }}</span
      >
      <span class="badge badge-warning"
        >{{ "REMAINING" | translate }}{{ totalPrice() }}</span
      ></small
    >
  </div>
  <div class="col-md-2">
    <select class="form-control" [(ngModel)]="payment">
      <option selected="selected" value="null" disabled translate>
        PAYMENT-METHOD
      </option>
      <option value="PIX" translate>PIXVALUE</option>
      <option value="MONEY" translate>MONEY</option>
      <option value="DEPOSIT" translate>BANK-DEPOSIT</option>
      <option value="CHECK" translate>CHECK</option>
      <option value="CREDITCARD" translate>CREDITCARD</option>
      <option value="DISCOUNT" translate>DISCOUNT</option>
      <option value="AWARD" translate>CONVERTED-AWARD</option>
      <option value="ORDER" translate>PAYMENT-ORDER</option>
      <option value="CASHBACK" translate>CASH-BACK</option>
    </select>
  </div>
  <div class="col-md-2">
    <div class="input-group">
      <input
        class="form-control"
        placeholder="dd/mm/yyyy"
        name="dp"
        [(ngModel)]="paymentDate"
        ngbDatepicker
        #d="ngbDatepicker"
        [minDate]="{ year: 2019, month: 12, day: 1 }"
        (click)="d.toggle()"
        readonly
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary mdi mdi-for-btn mdi-calendar-check"
          (click)="d.toggle()"
          type="button"
        ></button>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <textarea
        style="resize: vertical"
        class="form-control"
        rows="3"
        [(ngModel)]="comments"
      ></textarea>
    </div>
  </div>
  <div class="col-md-2">
    <button
      type="submit"
      class="btn btn-block btn-sm btn-gradient-primary"
      (click)="submitPayment()"
      [disabled]="!value || !payment || !paymentDate"
      translate
    >
      ADD
    </button>
  </div>
</div>
<div class="row mt-4">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th translate>CUSTOMER.SINGLE</th>
            <th translate>PAYMENT-METHOD</th>
            <th translate>DATE</th>
            <th translate>VALUE</th>
            <th translate>SUBSCRIPTIONS</th>
            <th translate>RESERVATIONS</th>
            <th translate>COMMENTS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of paymentList">
            <td>{{ item.customer.name }}</td>
            <td>
              {{ filterPayment(item.payment) }}
            </td>
            <td>{{ item.paymentDate | date: "shortDate" }}</td>
            <td>{{ event.currency | currencyPipe }}{{ item.value }}</td>
            <td>
              <div *ngFor="let tk of item.takeoffs">
                <s *ngIf="!tkExists(tk); else tk_exists">
                  {{ tk.competitor.name }} - {{ tk.horse.name }},
                  {{
                    tk.competition.category.name | translateCategory | async
                  }}
                  - {{ tk.pass }} {{ "TAKEOFF" | translate
                  }}{{ tk.pass > 1 ? "s" : "" }}
                </s>
                <ng-template #tk_exists>
                  {{ tk.competitor.name }} - {{ tk.horse.name }},
                  {{
                    tk.competition.category.name | translateCategory | async
                  }}
                  - {{ tk.pass }} {{ "TAKEOFF" | translate
                  }}{{ tk.pass > 1 ? "s" : "" }}
                </ng-template>
              </div>
            </td>
            <td>
              <div *ngFor="let item of item.stalls">
                {{ item.stall.stallType
                }}{{ item.horse ? " - " + item.horse.name : "" }}
              </div>
            </td>
            <td>{{ item.comments }}</td>
            <td>
              <button
                class="
                  btn btn-outline-primary
                  mdi mdi-printer mdi-for-btn
                  horse-gradient
                "
                (click)="openChooseModal(content, item)"
                type="button"
              ></button>
            </td>
            <td>
              <div
                class="btn btn-outline-info mdi mdi-for-btn mdi-delete"
                (click)="deletePayment(item._id)"
              ></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" translate>CHOOSELIST</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body"></div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('landscape')"
        translate
      >
        HORIZONTAL
      </button>
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('portrait')"
        translate
      >
        VERTICAL
      </button>
    </div>
  </ng-template>
</div>
