import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { EventService } from "src/app/services/event.service";

@Component({
  selector: "app-searcher",
  templateUrl: "./searcher.component.html",
  styleUrls: ["./searcher.component.scss"],
})
export class SearcherComponent implements OnInit {
  searchingChangeItem = false;
  searchChangeItemFailed = false;
  hasSearchedChangeItem = false;
  isChangeItemLoading = false;
  changeItem;
  searchedItem;
  searchedChangeItem;

  @Input() public type = "";

  @Output() searchedValue = new EventEmitter<any>();

  constructor(private _event: EventService) {}

  ngOnInit(): void {}

  formatterChangeItem = (x: { name: string }) => x.name;

  searchChangeItem = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searchingChangeItem = true)),
      switchMap((term) => {
        if (term.length < 3) return of([]);
        return this.searchByType(term).pipe(
          tap(() => (this.searchChangeItemFailed = false)),
          catchError(() => {
            this.searchChangeItemFailed = true;
            return of([]);
          })
        );
      }),
      tap(
        () => (
          (this.searchingChangeItem = false),
          (this.hasSearchedChangeItem = true)
        )
      )
    );

  searchByType(term) {
    switch (this.type) {
      case "COMPETITOR":
        return this._event.searchCompetitor(term);
      case "HORSE":
        return this._event.searchHorse(term);
      case "CUSTOMER":
        return this._event.searchCustomer(term);
      default:
        return this._event.searchCustomer(term);
    }
  }

  setChangeItemNotSearched() {
    this.hasSearchedChangeItem = false;
    this.changeItem = "";
    this.searchedChangeItem = null;
    this.searchedValue.emit(null);
  }

  selectedChangeItem(data) {
    this.searchedChangeItem = data.item.name;
    this.searchedValue.emit(data.item);
  }

  setPlaceholder() {
    const char =
      this.type === "CUSTOMER"
        ? "Usuário"
        : this.type === "COMPETITOR"
        ? "Competidor"
        : "Animal";
    return `Digite o nome do ${char}`;
  }
}
