import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertTranslateService } from "src/app/services/alert.service";
import { FinancialService } from "src/app/services/financial.service";

@Component({
  selector: "app-financial-accountings-items",
  templateUrl: "./financial-accountings-items.component.html",
  styleUrls: ["./financial-accountings-items.component.scss"],
})
export class FinancialAccountingsItemsComponent implements OnInit {
  @Input() event;
  @Input() accounting;
  @Output() loadAccountings: EventEmitter<any> = new EventEmitter();
  inputValue: number;
  selectType: string = "CASHIER";
  date;
  items: any = [];
  itemsLoading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private financial: FinancialService,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {
    this.listAccountingItems();
  }

  ngOnChanges(): void {}

  async listAccountingItems() {
    this.itemsLoading = true;
    this.items = await this.financial.getEventAccountingItems(
      this.event,
      this.accounting
    );

    this.itemsLoading = false;
  }

  async submitItem() {
    await this.financial.setEventAccountingItem(
      this.event,
      this.accounting,
      this.inputValue,
      this.selectType,
      new Date(this.date.year, this.date.month - 1, this.date.day).getTime()
    );
    this.listAccountingItems();
    this.loadAccountings.emit();
  }

  async deleteItem(item) {
    if (confirm("Você realmente deseja deletar este item?")) {
      this.itemsLoading = true;
      await this.financial.deleteEventAccountingItem(this.event, item);
      this.listAccountingItems();
      this.loadAccountings.emit();
    }
  }
}
