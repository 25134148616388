import { Component, OnInit } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { FinancialService } from "../services/financial.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["../app.component.scss", "./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  events = [];
  adminEvents = [];
  statistics: any = [];

  constructor(
    private auth: AuthService,
    private financial: FinancialService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.getDashboard();
    const infos = await this.auth.getInfos();
    const data: any = await this.auth.getUserEvents(infos.id);
    this.adminEvents = data.admin.map((el) => el.event);

    this.events = data.owner;
  }

  openEvent(slug, tab) {
    this.router.navigate([`event/${slug}/${tab}`]);
  }

  async getDashboard() {
    this.statistics = await this.financial.getDashboardStatistics();
  }
}
