import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertTranslateService } from "src/app/services/alert.service";
import { EventService } from "src/app/services/event.service";
import ErrorHandler from "../../util/errorHandler";
import { EventStallsDetailsComponent } from "./event-stalls-details/event-stalls-details.component";

@Component({
  selector: "app-event-stalls",
  templateUrl: "./event-stalls.component.html",
  styleUrls: ["./event-stalls.component.scss"],
})
export class EventStallsComponent implements OnInit {
  @Input() eventParam: any;
  stallType;
  price: number;
  limitStalls = 0;
  limitStallsGlobal = 0;
  stalls: any = [];
  limitToCancel;
  constructor(
    private _event: EventService,
    private _alertService: AlertTranslateService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.loadStalls();
  }

  async loadStalls() {
    try {
      this.stalls = await this._event.getEventStalls(this.eventParam._id);
    } catch (ex) {
      this._alertService.danger(ErrorHandler(ex.error.message));
    }
  }

  async deleteStall(stallId: string) {
    if (this._alertService.confirm("CONFIRM-REMOVE-STALL")) {
      try {
        await this._event.deleteEventStall(this.eventParam._id, stallId);
        this._alertService.success("REMOVED-STALL");
        this.loadStalls();
      } catch (ex) {
        this._alertService.danger(ErrorHandler(ex.error.message));
      }
    }
  }

  async submitStall() {
    if (!this.price || !this.stallType) return;
    try {
      const limitToCancelToDate = new Date(
        "" +
          this.limitToCancel.year +
          "-" +
          this.limitToCancel.month +
          "-" +
          this.limitToCancel.day
      ).getTime();
      await this._event.addEventStall(
        this.stallType,
        this.price,
        this.limitStalls,
        this.eventParam._id,
        this.limitStallsGlobal,
        limitToCancelToDate
      );
      this._alertService.success("ADDED-STALL");
      this.loadStalls();
    } catch (ex) {
      console.log(ex);
      if (ex.error) this._alertService.danger(ErrorHandler(ex.error));
    }
  }

  openStallsDetailModal(stallId: string) {
    const modalRef = this.modalService.open(EventStallsDetailsComponent, {
      size: "lg",
    });
    modalRef.componentInstance.stallId = stallId;
    modalRef.componentInstance.eventId = this.eventParam._id;
  }

  async stallAutomaticCheck(event, stallId) {
    await this._event.setStallActive(
      this.eventParam._id,
      stallId,
      event.target.checked
    );
    this.loadStalls();
  }
}
