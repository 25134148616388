import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "angular-intl";
import { environment } from "src/environments/environment";

@Component({
  selector: "body",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Horsecs Admin";

  constructor(
    private router: Router,
    public translationService: TranslateService
  ) {
    const defaultPrefix = "default";
    let browserLanguage = this.translationService.getBrowserLanguage();
    if (localStorage.getItem("lang")) {
      this.translationService.setDefault(
        `${defaultPrefix}-${localStorage.getItem("lang")}`
      );
    } else {
      if (
        environment.langs.filter((x) => x.type == browserLanguage).length == 0
      )
        browserLanguage = "pt";
      this.translationService.setDefault(`${defaultPrefix}-${browserLanguage}`);
    }
  }

  isOutRouter() {
    return (
      this.router.url == "/login" ||
      this.router.url == "/register" ||
      this.router.url == "/forgot"
    );
  }
}
