<div class="row">
  <div class="col-md-4 stretch-card grid-margin">
    <div class="card bg-gradient-danger card-img-holder text-white">
      <div class="card-body">
        <img
          src="assets/images/dashboard/circle.svg"
          class="card-img-absolute"
          alt="circle-image"
        />
        <h4 class="font-weight-normal mb-3">
          {{ "SUBSCRIPTIONS" | translate }}
          <i class="mdi mdi-chart-line mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">
          {{ statistics ? statistics.takeoffs : "-" }}
        </h2>
        <!-- <h6 class="card-text">
          Apenas Administrados:{{ statistics.eventsAdmin }}
        </h6> -->
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin">
    <div class="card bg-gradient-info card-img-holder text-white">
      <div class="card-body">
        <img
          src="assets/images/dashboard/circle.svg"
          class="card-img-absolute"
          alt="circle-image"
        />
        <h4 class="font-weight-normal mb-3">
          {{ "STALLS" | translate }}
          <i class="mdi mdi-ticket mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">
          {{ statistics ? statistics.stalls : "-" }}
        </h2>
        <!-- <h6 class="card-text">Decreased by 10%</h6> -->
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin">
    <div class="card bg-gradient-success card-img-holder text-white">
      <div class="card-body">
        <img
          src="assets/images/dashboard/circle.svg"
          class="card-img-absolute"
          alt="circle-image"
        />
        <h4 class="font-weight-normal mb-3">
          {{ "USER-WITH-STALLS" | translate }}
          <i class="mdi mdi-account mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">
          {{ statistics ? statistics.takeoffCustomers : "-" }}
        </h2>
        <!-- <h6 class="card-text">Increased by 5%</h6> -->
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <div class="card-title" translate>FINANCIAL-GENERAL.TITLE</div>
    <div class="card-description">
      <div class="form-group">
        <label for="selectedEvent" translate>EVENT.SELECT-EVENT</label>
        <select
          class="form-control form-control-lg"
          id="selectedEvent"
          [(ngModel)]="selectedEvent"
          [disabled]="isEventsLoading"
          (change)="onSelectedChange()"
        >
          <option *ngFor="let event of events" [ngValue]="event">
            {{ event.name }}
          </option>
        </select>
      </div>
      <p *ngIf="events.length == 0 && !isEventsLoading" translate>
        NO-EVENT-MANAGER
      </p>
    </div>

    <nav ngbNav #nav="ngbNav" class="nav-tabs">
      <ng-container ngbNavItem>
        <a ngbNavLink translate>USERS</a>
        <ng-template ngbNavContent>
          <app-financial-users
            *ngIf="selectedEvent"
            [event]="selectedEvent"
          ></app-financial-users>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a ngbNavLink translate>COMPETITOR.PLURAL</a>
        <ng-template ngbNavContent>
          <app-financial-competitors
            [event]="selectedEvent"
          ></app-financial-competitors>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a ngbNavLink translate>ANIMAL.PLURAL</a>
        <ng-template ngbNavContent>
          <app-financial-animals
            [event]="selectedEvent"
          ></app-financial-animals>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a ngbNavLink translate>CATEGORIES</a>
        <ng-template ngbNavContent>
          <app-financial-categories
            [event]="selectedEvent"
          ></app-financial-categories>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a ngbNavLink translate>STALLS</a>
        <ng-template ngbNavContent>
          <app-financial-stalls [event]="selectedEvent"></app-financial-stalls>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a ngbNavLink translate>ACCOUNTING</a>
        <ng-template ngbNavContent>
          <app-financial-accountings
            [event]="selectedEvent"
          ></app-financial-accountings>
        </ng-template>
      </ng-container>
    </nav>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
