import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { Event } from "src/app/dto/Event";
import { TypeGroupService } from "src/app/services/typeGroup.service";
import * as dateFns from "date-fns";

@Component({
  selector: "app-event-list",
  templateUrl: "./event-list.component.html",
  styleUrls: ["./event-list.component.scss"],
})
export class EventListComponent implements OnInit {
  events = [];
  typeGroups = [];
  adminEvents = [];
  years: any = [];
  currentYear = dateFns.getYear(new Date());
  constructor(
    private auth: AuthService,
    private router: Router,
    private _typeGroupService: TypeGroupService
  ) {}

  ngOnInit() {
    this.getYearsArray();
    this.loadEvents();
  }

  async loadEvents() {
    this.getTypeGroups();
    const infos = await this.auth.getInfos();
    const userEvents: any = await this.auth.getUserEventsByYear(
      this.currentYear
    );

    this.adminEvents = userEvents.admin.map((el) => el.event);

    this.events = userEvents.owner;
  }

  getYearsArray() {
    this.years = dateFns
      .eachYearOfInterval({
        start: new Date(2020, 0, 1),
        end: new Date(),
      })
      .map((x) => dateFns.getYear(x));
  }

  openEvent(slug, tab) {
    this.router.navigate([`event/${slug}/${tab}`]);
  }

  async getTypeGroups() {
    const typeGroups: any = await this._typeGroupService.getTypeGroups();
    this.typeGroups = typeGroups;
  }
}
