<div class="card">
  <div class="card-body">
    <div class="card-title" translate>ACTIVITY-REGISTRY.TITLE</div>
    <div class="card-description">
      <div class="form-group">
        <label for="selectedEvent" translate>EVENT.SELECT-EVENT</label>
        <select
          class="form-control form-control-lg"
          id="selectedEvent"
          [(ngModel)]="selectedEvent"
          [disabled]="isEventsLoading"
          (change)="onChangeEvent()"
        >
          <option *ngFor="let event of events" [ngValue]="event">
            {{ event.name }}
          </option>
        </select>
      </div>
      <p *ngIf="events.length == 0 && !isEventsLoading" translate>
        EVENT.NO-EVENT-MANAGER
      </p>
    </div>
  </div>
</div>
<div class="row pt-3">
  <div class="col-md-4">
    <div class="form-group">
      <label for="action" translate>SELECT-ONE</label>
      <select
        class="form-control form-control-lg"
        id="action"
        [(ngModel)]="action"
        [disabled]="isEventsLoading"
        (change)="onChangeAction()"
      >
        <option *ngFor="let action of actions" [ngValue]="action">
          <span *ngIf="action == ''" translate>ALL</span>
          <span *ngIf="action != ''" translate
            >ACTIVITY-REGISTRY.ACTIONS.{{ action }}</span
          >
        </option>
      </select>
    </div>
  </div>
</div>
<div class="card mt-3" *ngFor="let item of activityRegistry">
  <div class="card-body">
    <div class="mr-2 d-flex justify-content-between">
      <div>
        <span class="text-primary">{{ item.user.name }}</span>
        {{ "ACTIVITY-REGISTRY." + item.action | translate }}:
      </div>
      <small class="text-gray">{{
        item.createdAt | date: "d/M/yy, H:mm"
      }}</small>
    </div>
    <div *ngIf="item.action == 'COUPONSTATUS'">
      <span
        class="text-success"
        *ngIf="item.enabled"
        [translate]="{ coupon: item.coupon.code }"
        >ACTIVITY-REGISTRY.ENABLED-COUPON</span
      >
      <span
        class="text-danger"
        *ngIf="!item.enabled"
        [translate]="{ coupon: item.coupon.code }"
        >ACTIVITY-REGISTRY.DISABLED-COUPON</span
      >
    </div>
    <div *ngIf="item.action == 'STALLSTATUS'">
      <span
        class="text-success"
        *ngIf="item.enabled"
        [translate]="{ stall: item.stall.stallType }"
        >ACTIVITY-REGISTRY.ENABLED-STALL</span
      >
      <span
        class="text-danger"
        *ngIf="!item.enabled"
        [translate]="{ stall: item.stall.stallType }"
        >ACTIVITY-REGISTRY.DISABLED-STALL</span
      >
    </div>
    <div *ngIf="item.action == 'UPDATEACTIVESUBSCRIPTION'">
      <span
        class="text-success"
        *ngIf="item.enabled"
        [translate]="{ competition: item.competition.id.category.name }"
        >ACTIVITY-REGISTRY.ACTIVE-SUBSCRIPTION</span
      >
      <span
        class="text-danger"
        *ngIf="!item.enabled"
        [translate]="{ competition: item.competition.id.category.name }"
        >ACTIVITY-REGISTRY.INACTIVE-SUBSCRIPTION</span
      >
    </div>
    <div *ngIf="item.action == 'UPDATEACTIVESUBSCRIPTIONALL'">
      <span class="text-success" *ngIf="item.enabled" translate
        >ACTIVITY-REGISTRY.ACTIVE-SUBSCRIPTION-ALL</span
      >
      <span class="text-danger" *ngIf="!item.enabled" translate
        >ACTIVITY-REGISTRY.INACTIVE-SUBSCRIPTION-ALL</span
      >
    </div>
    <div *ngIf="item.action == 'RANKINGSTATUS'">
      <label class="text-warning"
        >{{ "ACTIVITY-REGISTRY.UPDATED-COMPETITION" | translate }}
        {{
          item.competition.id.category.name | translateCategory | async
        }}</label
      >
      <div *ngFor="let ranking of item.competition.ranking | keyvalue">
        <div *ngIf="ranking.value">
          {{ "ACTIVITY-REGISTRY.VARIABLES." + ranking.key | translate }}
          {{ "ACTIVITY-REGISTRY.NEW-VALUE" | translate }}:
          {{ ranking.value }}
        </div>
      </div>
    </div>
    <div *ngIf="item.action == 'AWARDSTATUS'">
      <label class="text-warning"
        >{{ "ACTIVITY-REGISTRY.UPDATED-COMPETITION" | translate }}
        {{
          item.competition.id.category.name | translateCategory | async
        }}</label
      >
      <div *ngFor="let award of item.competition.award | keyvalue">
        <div *ngIf="award.value">
          {{ "ACTIVITY-REGISTRY.VARIABLES." + award.key | translate }}
          {{ "ACTIVITY-REGISTRY.NEW-VALUE" | translate }}:
          {{ award.value | safeTranslate | async }}
        </div>
      </div>
    </div>
    <div *ngIf="item.action == 'SETTINGSTATUS'">
      <label class="text-warning"
        >{{ "ACTIVITY-REGISTRY.UPDATED-COMPETITION" | translate }}
        {{
          item.competition.id.category.name | translateCategory | async
        }}</label
      >
      <div *ngFor="let setting of item.competition.setting | keyvalue">
        <div *ngIf="setting.value">
          {{ "ACTIVITY-REGISTRY.VARIABLES." + setting.key | translate }}
          {{ "ACTIVITY-REGISTRY.NEW-VALUE" | translate }}:
          {{ setting.value }}
        </div>
      </div>
    </div>
    <div *ngIf="item.action == 'RULESTATUS'">
      <label class="text-warning"
        >{{ "ACTIVITY-REGISTRY.UPDATED-COMPETITION" | translate }}
        {{
          item.competition.id.category.name | translateCategory | async
        }}</label
      >
      <div *ngFor="let rule of item.competition.rule | keyvalue">
        <div *ngIf="rule.value">
          {{ "ACTIVITY-REGISTRY.VARIABLES." + rule.key | translate }}
          {{ "ACTIVITY-REGISTRY.NEW-VALUE" | translate }}:
          {{ rule.value }}
        </div>
      </div>
    </div>
    <div *ngIf="item.changedOwner">
      {{ "ACTIVITY-REGISTRY.USER" | translate }}
      <span class="text-danger" *ngIf="item.changedOwner.oldOwner">{{
        item.changedOwner.oldOwner.name
      }}</span>
      {{ "ACTIVITY-REGISTRY.UPDATEDTO" | translate }}
      <span class="text-success" *ngIf="item.changedOwner.newOwner">{{
        item.changedOwner.newOwner.name
      }}</span>
    </div>
    <div *ngIf="item.changedHorse">
      {{ "ACTIVITY-REGISTRY.ANIMAL" | translate }}
      <span class="text-danger" *ngIf="item.changedHorse.oldHorse">{{
        item.changedHorse.oldHorse.name
      }}</span>
      {{ "ACTIVITY-REGISTRY.UPDATEDTO" | translate }}
      <span class="text-success" *ngIf="item.changedHorse.newHorse">{{
        item.changedHorse.newHorse.name
      }}</span>
    </div>
    <div *ngIf="item.changedCompetitor">
      {{ "ACTIVITY-REGISTRY.COMPETITOR" | translate }}
      <span class="text-danger" *ngIf="item.changedCompetitor.oldCompetitor">{{
        item.changedCompetitor.oldCompetitor.name
      }}</span>
      {{ "ACTIVITY-REGISTRY.UPDATEDTO" | translate }}
      <span class="text-success" *ngIf="item.changedCompetitor.newCompetitor">{{
        item.changedCompetitor.newCompetitor.name
      }}</span>
    </div>
    <div *ngIf="item.takeoff">
      {{ "ACTIVITY-REGISTRY.TAKEOFF" | translate }}
      <span *ngIf="item.takeoff.competition">{{
        item.takeoff.competition.category.name | translateCategory | async
      }}</span>
      {{ item.takeoff.pass }}ª Passada -
      {{ item.takeoff.competitor ? item.takeoff.competitor.name : "" }},
      {{ item.takeoff.horse ? item.takeoff.horse.name : "" }}
      {{ "OF" | translate }}
      <span class="text-warning">{{
        item.takeoff ? item.takeoff.owner.name : ""
      }}</span>
    </div>
    <div *ngIf="item.customerStall">
      {{ "STALL" | translate }} {{ item.customerStall.stall.stallType }}
      {{
        item.customerStall.horse ? " - " + item.customerStall.horse.name : ""
      }}
      {{ "OF" | translate }}
      <span class="text-warning">{{
        item.customerStall ? item.customerStall.customer.name : ""
      }}</span>
    </div>
  </div>
</div>
<div
  class="d-flex justify-content-center mt-3"
  *ngIf="activityRegistryCount > activityRegistry.length && !endReached"
>
  <button class="button btn btn-primary" (click)="loadMore()" translate>
    LOAD-MORE
  </button>
</div>
