import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  NgbActiveModal,
  NgbCalendar,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { FinancialService } from "src/app/services/financial.service";
import * as dateFns from "date-fns";
import { PdfService } from "src/app/services/pdf.service";
import { Filters } from "src/app/util/filters";
import { TranslateService } from "angular-intl";
import { AlertTranslateService } from "src/app/services/alert.service";

@Component({
  selector: "app-financial-users-subscriptions",
  templateUrl: "./financial-users-subscriptions.component.html",
  styleUrls: ["./financial-users-subscriptions.component.scss"],
})
export class FinancialUsersSubscriptionsComponent implements OnInit {
  @Input() user;
  @Input() event;
  @Input() selectedTakeoffs;
  @Input() selectedStalls;
  @Input() userTakeoffs;

  @Output() eventUsersFinancial: EventEmitter<any> = new EventEmitter();
  @Output() payedTakeoffs = new EventEmitter<Array<any>>();
  @Output() payedStalls = new EventEmitter<Array<any>>();
  @Output() paymentValue = new EventEmitter<Array<any>>();
  payment = null;
  paymentDate = this.calendar.getToday();
  value;
  paymentList;
  comments;

  constructor(
    public modal: NgbActiveModal,
    private calendar: NgbCalendar,
    private financialService: FinancialService,
    private pdfService: PdfService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {
    this.listPayments();
  }

  async listPayments() {
    this.paymentList = await this.financialService.listCustomerTakeoffPayments(
      this.event._id,
      this.user._id
    );
    this.payedTakeoffs.emit(this.paymentList.map((e) => e.takeoffs));
    this.payedStalls.emit(this.paymentList.map((e) => e.stalls));
    this.paymentValue.emit(
      this.paymentList.map((x) => x.value).reduce((a, b) => a + b, 0)
    );
  }

  filterPayment(payment) {
    return new Filters(this.translateService).filterPayment(payment);
  }

  async submitPayment() {
    await this.financialService.setFinancialTakeOffPayment(
      this.event._id,
      this.user._id,
      this.payment,
      dateFns.getTime(
        new Date(
          this.paymentDate.year,
          this.paymentDate.month - 1,
          this.paymentDate.day
        )
      ),
      this.value,
      this.selectedTakeoffs,
      this.selectedStalls,
      this.comments
    );
    this.listPayments();
    this.payment = null;
    this.paymentDate = this.calendar.getToday();
    this.value = null;
    this.eventUsersFinancial.emit();
  }

  tkExists(tk: any) {
    return (
      this.userTakeoffs.filter(
        (x) =>
          x.competitor.name == tk.competitor.name &&
          x.horse.name == tk.horse.name &&
          tk.competition.category.name == x.competition.category.name &&
          tk.pass == x.pass
      ).length > 0
    );
  }

  async deletePayment(paymentId: string) {
    if (await this.alertService.confirm("CONFIRM-REMOVE")) {
      await this.financialService.deleteFinancialTakeoffPayment(
        this.event._id,
        paymentId
      );
      this.listPayments();
      this.eventUsersFinancial.emit();
    }
  }

  selectedPrice() {
    const tks = this.selectedTakeoffs
      .map((x) => x.price)
      .reduce((a, b) => a + b, 0);
    const stalls = this.selectedStalls
      .map((x) => x.stall.price)
      .reduce((a, b) => a + b, 0);
    return parseInt(tks) + parseInt(stalls);
  }

  totalPrice() {
    let price = this.selectedPrice() - parseInt(this.value);
    return price ? price : 0;
  }

  async openChooseModal(content, item) {
    const orientation = await this.modalService.open(content, { size: "sm" })
      .result;
    this.pdfService.genReceipt(this.event, item, orientation);
  }
}
