import { Injectable } from "@angular/core";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import { EventService } from "./event.service";
import { format } from "date-fns";
import { Filters } from "../util/filters";
import { TranslateService } from "angular-intl";
import * as dateFns from "date-fns";

@Injectable({
  providedIn: "root",
})
export class PdfService {
  filters: Filters = new Filters(this.translateService);
  num;
  competitor;
  animal;
  owner;
  register;
  chip;
  score;
  takeoff;
  arenaRepair;
  threeBarrel;
  customer;
  coach;
  takeoffList;
  judgeList;
  gateList;
  currency;
  page;
  confirmReceived;
  subscriptionList;
  total;
  sub;
  cat;
  comp;
  paytype;
  value;
  userSubscriptions;
  releasedForGTA;
  enterOf;
  resultList;
  rank;
  place;
  placeOf;
  stall;
  type;
  award;
  gta;
  reservedStalls;
  subscriptionByUser;
  requester;
  receivedPayment;
  compOrCoach;
  catOrStall;
  generalTotal;
  constructor(private translateService: TranslateService) {
    this.translateService
      .get([
        "NUMBER-SHORT",
        "COMPETITOR.SINGLE",
        "ANIMAL.SINGLE",
        "OWNER",
        "REGISTER",
        "SCORE",
        "TAKEOFF",
        "LIVE.MENU.ARENA-REPAIR",
        "THREE-BARREL",
        "CUSTOMER.SINGLE",
        "COACH",
        "TAKEOFF-LIST",
        "JUDGE-LIST",
        "GATE-LIST",
        "CURRENCY",
        "PAGE",
        "CONFIRM-RECEIVED",
        "SUBSCRIPTION-LIST.INFO",
        "TOTAL",
        "SUB",
        "CAT",
        "COMP",
        "PAYTYPE",
        "VALUE",
        "CUSTOMER.SUBSCRIPTIONS",
        "RELEASED-FOR-GTA",
        "OF",
        "RESULT-LIST",
        "RANK",
        "PLACE",
        "PLACE-OF",
        "STALL",
        "TYPE",
        "AWARD",
        "GTA",
        "RESERVED-STALLS",
        "SUBSCRIPTION-BY-USER",
        "REQUESTER",
        "RECEIVED-PAYMENT",
        "COMP-OR-COACH",
        "CAT-OR-STALL",
        "GENERAL-TOTAL",
      ])
      .subscribe((translations) => {
        this.num = translations["NUMBER-SHORT"];
        this.competitor = translations["COMPETITOR.SINGLE"];
        this.animal = translations["ANIMAL.SINGLE"];
        this.owner = translations["OWNER"];
        this.register = translations["REGISTER"];
        this.chip = translations[this.chip];
        this.score = translations["SCORE"];
        this.takeoff = translations["TAKEOFF"];
        this.arenaRepair = translations["LIVE.MENU.ARENA-REPAIR"];
        this.threeBarrel = translations["THREE-BARREL"];
        this.customer = translations["CUSTOMER.SINGLE"];
        this.coach = translations["COACH"];
        this.takeoffList = translations["TAKEOFF-LIST"];
        this.judgeList = translations["JUDGE-LIST"];
        this.gateList = translations["GATE-LIST"];
        this.currency = translations["CURRENCY"];
        this.page = translations["PAGE"];
        this.confirmReceived = translations["CONFIRM-RECEIVED"];
        this.subscriptionList = translations["SUBSCRIPTION-LIST.INFO"];
        this.total = translations["TOTAL"];
        this.sub = translations["SUB"];
        this.cat = translations["CAT"];
        this.comp = translations["COMP"];
        this.paytype = translations["PAYTYPE"];
        this.value = translations["VALUE"];
        this.userSubscriptions = translations["CUSTOMER.SUBSCRIPTIONS"];
        this.releasedForGTA = translations["RELEASED-FOR-GTA"];
        this.enterOf = translations["OF"];
        this.resultList = translations["RESULT-LIST"];
        this.rank = translations["RANK"];
        this.place = translations["PLACE"];
        this.placeOf = translations["PLACE-OF"];
        this.stall = translations["STALL"];
        this.type = translations["TYPE"];
        this.award = translations["AWARD"];
        this.gta = translations["GTA"];
        this.reservedStalls = translations["RESERVED-STALLS"];
        this.subscriptionByUser = translations["SUBSCRIPTION-BY-USER"];
        this.requester = translations["REQUESTER"];
        this.receivedPayment = translations["RECEIVED-PAYMENT"];
        this.compOrCoach = translations["COMP-OR-COACH"];
        this.catOrStall = translations["CAT-OR-STALL"];
        this.generalTotal = translations["GENERAL-TOTAL"];
      });
  }

  private getHeader(type) {
    switch (type) {
      case "COMPETITOR":
        return {
          num: this.num,
          competitor: this.competitor,
          horse: this.animal,
          owner: this.owner,
        };
      case "JUDGE":
        return {
          num: this.num,
          competitor: this.competitor,
          horse: this.animal,
          owner: this.owner,
          register: this.register,
          chip: this.chip ? this.chip : "-",
          score: this.score,
        };
      case "GATE":
        return {
          num: this.num,
          competitor: this.competitor,
          horse: this.animal,
          owner: this.owner,
        };
    }
  }

  private getBody(type, competition, i) {
    switch (type) {
      case "COMPETITOR":
        return {
          num: i,
          competitor: competition.competitor.name,
          horse: competition.horse.name,
          owner: competition.horse.owner,
        };
      case "JUDGE":
        return {
          num: i,
          competitor: competition.competitor.name,
          horse: competition.horse.name,
          owner: competition.horse.owner,
          register: competition.horse.registerId,
          chip: competition.horse.chip ? competition.horse.chip : "-",
          score: "                                                ",
        };
      case "GATE":
        return {
          num: i,
          competitor: competition.competitor.name,
          horse: competition.horse.name,
          owner: competition.horse.owner,
        };
    }
  }

  private bodyRows(object: any, type: string, event: any) {
    let body = [];
    object = object.filter((x) => x.length > 0);
    let counter = 0;
    for (let i = 0; i < object.length; i++) {
      let pass = [];
      if (type == "COMPETITOR")
        pass.sort((a, b) =>
          a.competitor > b.competitor ? 1 : b.competitor > a.competitor ? -1 : 0
        );
      for (var j = 0; j < object[i].length; j++) {
        counter++;
        if (j == 0) pass.push({ competitor: `` });
        if (j == 0) pass.push({ competitor: `${i + 1}ª PASSADA` });

        if (
          type == "SPEAKER" &&
          (counter - 1) % event.arenaRepairInterval == 0 &&
          counter - 1 > 0
        ) {
          pass.push({ competitor: `REPARO DE PISTA` });
        }
        if (j == 0) pass.push({ competitor: `` });
        pass.push(this.getBody(type, object[i][j], j + 1));
      }

      body = [...body, ...pass];
    }
    return body;
  }

  async genCompetitors(
    type: string,
    object: any,
    event: any,
    competition: any,
    orientation: string
  ) {
    var doc = new jsPDF({
      orientation,
    });
    doc.setProperties({
      title: event.name + " - " + competition.category.name,
    });
    doc.viewerPreferences({ DisplayDocTitle: true });
    var totalPagesExp = "{total_pages_count_string}";
    const threeBarrel = this.threeBarrel;
    const page = this.page;
    const enterOf = this.enterOf;
    doc.autoTable({
      theme: "grid",
      columnStyles: {
        num: { cellWidth: 12 },
        register: { cellWidth: 20 },
      },
      bodyStyles: { lineColor: [0, 0, 0] },
      head: [this.getHeader(type)],
      body: this.bodyRows(object, type, event),
      didDrawPage: function (data) {
        // Header
        doc.setFont("Courier");
        doc.setFontStyle("bold");
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.setFontStyle("normal");
        doc.text(event.name, 15, 20);
        doc.setFontSize(15);
        doc.text(`${threeBarrel} - ${competition.category.name}`, 15, 30);
        let text = () => {
          switch (type) {
            case "COMPETITOR":
              return this.takeOffList;
            case "JUDGE":
              return this.judgeList;
            case "GATE":
              return this.gateList;
          }
        };

        doc.text(text, 15, 40);

        // Footer
        var str = `${page} ` + doc.internal.getNumberOfPages();
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === "function") {
          str = str + ` ${enterOf} ` + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
      margin: { top: 50 },
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }
    doc.output("dataurlnewwindow");
  }

  async genStalls(
    event: any,
    eventStall: any,
    customerStalls: any,
    orientation: string,
    type: string
  ) {
    if (type == "coach") {
      customerStalls = customerStalls.sort((a, b) => {
        var x = a.coach ? a.coach.toLowerCase() : "Z";
        var y = b.coach ? b.coach.toLowerCase() : "Z";
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }
    if (type == "customer") {
      customerStalls = customerStalls.sort((a, b) => {
        var x = a.customer ? a.customer.name.toLowerCase() : "Z";
        var y = b.customer ? b.customer.name.toLowerCase() : "Z";
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }
    var doc = new jsPDF({
      orientation,
    });
    doc.setProperties({
      title: event.name + " - " + eventStall.name,
    });
    doc.viewerPreferences({ DisplayDocTitle: true });
    var totalPagesExp = "{total_pages_count_string}";
    const page = this.page;
    const reservedStalls = this.reservedStalls;
    const enterOf = this.enterOf;
    doc.autoTable({
      theme: "grid",
      bodyStyles: { lineColor: [0, 0, 0] },
      head: [
        {
          user: this.customer,
          horse: this.animal,
          coach: this.coach,
        },
      ],
      body: customerStalls.map((e) => ({
        user: e.customer ? e.customer.name : "-",
        horse: e.horse ? e.horse.name : "-",
        coach: e.coach ? e.coach : "-",
      })),
      didDrawPage: function (data) {
        // Header
        doc.setFont("Courier");
        doc.setFontStyle("bold");
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.setFontStyle("normal");
        doc.text(event.name, 15, 20);
        doc.setFontSize(15);
        doc.text(reservedStalls, 15, 40);

        // Footer
        var str = `${page} ` + doc.internal.getNumberOfPages();
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === "function") {
          str = str + ` ${enterOf} ` + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
      margin: { top: 50 },
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }
    doc.output("dataurlnewwindow");
  }

  async genReceipt(event: any, items: any, orientation: string) {
    let item = null;
    if (items.comments) {
      item = {
        type: items.comments,
        category: ``,
        stall: "-",
        horse: "-",
        competitor: "-",
        owner: "-",
        coach: "-",
        value: items.value,
      };
    }
    const parsedItems = [
      ...items.stalls.map((x) => ({
        type: this.stall,
        category: "-",
        stall: x.stall.stallType,
        horse: x.horse ? x.horse.name : "-",
        competitor: "-",
        owner: "-",
        coach: x.coach,
        value: x.stall.price,
      })),
      ...items.takeoffs.map((x) => ({
        type: "Insc.",
        category: `${x.competition.category.name} - ${x.pass} Passada${
          x.pass > 1 ? "s" : ""
        }`,
        stall: "-",
        horse: x.horse.name,
        competitor: x.competitor.name,
        owner: x.owner.name,
        coach: "-",
        value: x.price,
      })),
      item,
    ];
    var doc = new jsPDF({ orientation });
    doc.setProperties({
      title: "Recibo de Pagamento",
    });
    doc.viewerPreferences({ DisplayDocTitle: true });
    var totalPagesExp = "{total_pages_count_string}";
    const page = this.page;
    const enterOf = this.enterOf;
    const receivedPayment = this.receivedPayment;
    const currency = this.currency;
    const filters = this.filters;
    doc.autoTable({
      theme: "grid",
      bodyStyles: { lineColor: [0, 0, 0] },
      head: [
        {
          type: this.type,
          category: this.cat,
          stall: this.stall,
          horse: this.animal,
          competitor: `${this.comp}.`,
          owner: this.owner,
          coach: this.coach,
          value: this.value,
        },
      ],
      body: parsedItems.map((x) => ({
        type: x.type,
        category: x.category,
        stall: x.stall,
        horse: x.horse,
        competitor: x.competitor,
        owner: x.owner,
        coach: x.coach,
        value: `${this.currency} ${x.value}`,
      })),
      didDrawPage: function (data) {
        // Header
        doc.setFont("Courier");
        doc.setFontStyle("bold");
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.setFontStyle("normal");
        doc.text(event.name, 15, 20);
        doc.setFontSize(12);

        doc.text(
          receivedPayment
            .replace("[1]", items.customer.name)
            .replace("[2]", currency)
            .replace("[3]", items.value)
            .replace("[4]", format(new Date(items.updatedAt), "dd/MM/yyyy"))
            .replace("[5]", filters.filterPayment(items.payment)),
          15,
          30
        );

        // Footer
        var str = ` ${page} ` + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === "function") {
          str = str + ` ${enterOf} ` + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
      margin: { top: 50 },
    });
    let finalY = (doc as any).lastAutoTable.finalY;
    doc.setFontSize(12);
    doc.text(this.confirmReceived, 15, finalY + 20);
    doc.text("__________________________________________", 15, finalY + 35);
    doc.text(event.org, 15, finalY + 40);

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }
    doc.output("dataurlnewwindow");
  }

  async genTakeoffs(
    event: any,
    customer: any,
    takeoffs: any,
    stalls: any,
    orientation: string
  ) {
    var doc = new jsPDF({ orientation });
    doc.setProperties({
      title: this.subscriptionList,
    });
    doc.viewerPreferences({ DisplayDocTitle: true });
    var totalPagesExp = "{total_pages_count_string}";
    let generalTotal = 0;
    let generalTotalAward = 0;
    if (takeoffs.length > 0) {
      generalTotal += takeoffs.map((x) => x.price).reduce((a, b) => a + b, 0);
      generalTotalAward += takeoffs
        .map((x) => (x.award ? x.award : 0))
        .reduce((a, b) => a + b, 0);
      takeoffs.push({
        competition: "-",
        competitor: "-",
        horse: "-",
        payment: `${this.total}: `,
        price: takeoffs.map((x) => x.price).reduce((a, b) => a + b, 0),
        award: takeoffs
          .map((x) => (x.award ? x.award : 0))
          .reduce((a, b) => a + b, 0),
        createdAt: "-",
      });
    }

    if (stalls) {
      generalTotal += stalls
        .map((x) => x.stall.price)
        .reduce((a, b) => a + b, 0);
      takeoffs = [
        ...takeoffs,
        ...stalls.map((x) => ({
          competition: { cat: x.stall.stallType },
          competitor: { name: x.coach },
          horse: x.horse ? x.horse : { name: "-" },
          payment: "-",
          price: x.stall.price,
          award: "-",
          createdAt: x.createdAt,
        })),
        {
          competition: { cat: "-" },
          competitor: "-",
          horse: "-",
          payment: `${this.total}: `,
          price: stalls.map((x) => x.stall.price).reduce((a, b) => a + b, 0),
          award: "-",
          createdAt: "-",
        },
      ];
    }
    takeoffs.push({
      competition: "-",
      competitor: "-",
      horse: "-",
      payment: `${this.generalTotal}: `,
      price: generalTotal,
      award: generalTotalAward,
      createdAt: "-",
    });

    const page = this.page;
    const enterOf = this.enterOf;
    const subscriptionByUser = this.subscriptionByUser;
    console.log({ takeoffs });
    doc.autoTable({
      theme: "grid",
      bodyStyles: { lineColor: [0, 0, 0] },
      head: [
        {
          category: this.catOrStall,
          competitor: this.compOrCoach,
          horse: this.animal,
          payment: this.paytype,
          price: this.value,
          award: this.award,
        },
      ],
      body: takeoffs.map((x) => {
        let category = "-";
        if (x.competition?.cat) category = x.competition?.cat;
        else if (x.competition?.pass) {
          category = `${x.competition.category?.name} - ${x.competition.pass} ${
            this.takeoff
          }${x.competition.pass > 1 ? "s" : ""}`;
          if (x.competition.date)
            category += ` - ${dateFns.format(
              new Date(x.competition.date),
              "dd/MM"
            )}`;
        }
        return {
          category,
          competitor: x.competitor.name,
          horse: x.horse.name,
          payment: x.payment,
          price: `${this.currency} ${x.price}`,
          award: `${this.currency} ${x.award}`,
        };
      }),
      didDrawPage: function (data) {
        // Header
        doc.setFont("Courier");
        doc.setFontStyle("bold");
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.setFontStyle("normal");
        doc.text(event.name, 15, 20);
        doc.setFontSize(12);
        doc.text(`${subscriptionByUser} ${customer.name}`, 15, 30);

        // Footer
        var str = ` ${page} ` + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === "function") {
          str = str + ` ${enterOf} ` + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(event.org, data.settings.margin.left, pageHeight - 20);
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
      margin: { top: 50 },
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }
    doc.output("dataurlnewwindow");
  }

  async genGTA(event: any, customer: any, takeoffs: any, orientation: string) {
    var doc = new jsPDF({ orientation });
    doc.setProperties({
      title: this.gta,
    });
    doc.viewerPreferences({ DisplayDocTitle: true });
    var totalPagesExp = "{total_pages_count_string}";
    const page = this.page;
    const enterOf = this.enterOf;
    const requester = this.requester;
    const releasedForGTA = this.releasedForGTA;
    const confirmReceived = this.confirmReceived;
    const filters = this.filters;
    doc.autoTable({
      theme: "grid",
      bodyStyles: { lineColor: [0, 0, 0] },
      head: [
        {
          category: this.cat,
          competitor: this.comp,
          horse: this.animal,
          payment: this.paytype,
        },
      ],
      body: takeoffs.map((x) => ({
        category: x.competition.category.name,
        competitor: x.competitor.name,
        horse: x.horse.name,
        payment: filters.filterPayment(x.payment),
      })),
      didDrawPage: function (data) {
        // Header
        doc.setFont("Courier");
        doc.setFontStyle("bold");
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.setFontStyle("normal");
        doc.text(event.name, 15, 20);
        doc.setFontSize(12);
        doc.text(`${requester} ${customer.name}`, 15, 30);
        doc.text(releasedForGTA, 15, 35);

        // Footer
        var str = `${page} ` + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === "function") {
          str = str + ` ${enterOf} ` + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
      margin: { top: 50, bottom: 50 },
    });

    let finalY = (doc as any).lastAutoTable.finalY;
    doc.setFontSize(12);
    doc.text(confirmReceived, 15, finalY + 20);
    doc.text("__________________________________________", 15, finalY + 35);
    doc.text(event.org, 15, finalY + 40);

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }
    doc.output("dataurlnewwindow");
  }

  async submitGenResult(
    event: any,
    takeoffs: any,
    competition: any,
    orientation: string
  ) {
    var doc = new jsPDF({ orientation });
    doc.setProperties({
      title: this.resultList,
    });

    doc.viewerPreferences({ DisplayDocTitle: true });
    var totalPagesExp = "{total_pages_count_string}";
    takeoffs.push({
      competitor: "-",
      horse: "-",
      score: "-",
      award: "-",
      owner: "-",
    });
    const page = this.page;
    const enterOf = this.enterOf;
    doc.autoTable({
      theme: "grid",
      columnStyles: {
        sign: { cellWidth: 35 },
        score: { cellWidth: 20 },
        award: { cellWidth: 20 },
      },
      styles: {
        overflow: "linebreak",
        overflowColumns: "linebreak",
      },
      bodyStyles: { lineColor: [0, 0, 0] },
      head: [
        {
          classification: this.rank,
          competitor: this.comp,
          horse: this.animal,
          score: this.score,
          award: this.award,
          owner: this.owner,
          sign: "",
        },
      ],
      body: takeoffs
        .filter((x) => x.classification)
        .map((x) => ({
          classification:
            competition.resultType == "DIVISION"
              ? x.classification
                ? x.classification + `º ${this.placeOf} ` + x.division + "D"
                : ""
              : x.classification
              ? x.classification + `º ${this.place}`
              : "",
          competitor: x.competitor.name,
          horse: x.horse.name,
          score: x.score,
          award: x.award ? `${this.currency}${x.award}` : "-",
          owner: x.horse.owner,
          sign: "",
        })),
      didDrawPage: function (data) {
        // Header
        doc.setFont("Courier");
        doc.setFontStyle("bold");
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.setFontStyle("normal");
        doc.text(event.name, 15, 20);
        doc.text(competition.category.name, 15, 30);
        // Footer
        var str = `${page}` + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === "function") {
          str = str + ` ${enterOf} ` + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(event.org, data.settings.margin.left, pageHeight - 20);

        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
      margin: { top: 50 },
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }
    doc.output("dataurlnewwindow");
  }
}
