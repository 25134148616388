<div class="page-header">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/']" translate>HOME</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>
        ACCOUNT
      </li>
    </ol>
  </nav>
</div>

<app-info></app-info>
<!-- <app-delete></app-delete> -->
