import * as dateFns from "date-fns";

export class ValidateSubscription {
  static hippicalYearCompetitor(age) {
    const birthYear =
      new Date(parseInt(age, 10)).getMonth() >= 7
        ? new Date(new Date(parseInt(age, 10)).getFullYear(), 7, 1)
        : new Date(new Date(parseInt(age, 10)).getFullYear() - 1, 7, 1);

    const currentYear =
      new Date().getMonth() >= 7
        ? new Date(new Date().getFullYear(), 6, 30)
        : new Date(new Date().getFullYear() - 1, 6, 30);

    const year = dateFns.differenceInYears(currentYear, birthYear);

    return year;
  }

  static hippicalYearAnimal(age) {
    const birthYear =
      new Date().getMonth() >= 7
        ? new Date(parseInt(age, 10))
        : new Date(new Date(parseInt(age, 10)).getFullYear(), 7, 1);

    const currentYear = new Date();

    const year = dateFns.differenceInYears(currentYear, birthYear);

    return year;
  }

  static isCompetitionAvailable(
    comp,
    competitor,
    horse,
    horseEventTakeoffsData,
    customerTakeoffsDataByEvent,
    competitorLineage
  ) {
    if (
      comp.subscriptionLimit != 0 &&
      comp.subscriptionCount >= comp.subscriptionLimit
    )
      return false;
    if (horseEventTakeoffsData) {
      let tks = horseEventTakeoffsData.filter(
        (tk) => tk.competition == comp._id
      );
      if (tks[0]) return false;
    }
    if (comp.isFinished) return false;

    if (!comp.isActiveSubscriptions) return false;

    const a =
      comp.maxAgeCompetitor >= this.hippicalYearCompetitor(competitor.age) ||
      comp.maxAgeCompetitor == 0;
    const b =
      this.hippicalYearCompetitor(competitor.age) >= comp.minAgeCompetitor ||
      comp.minAgeCompetitor == 0;
    const c =
      comp.maxAgeAnimal >= this.hippicalYearAnimal(horse.birth) ||
      comp.maxAgeAnimal == 0;
    const d =
      this.hippicalYearAnimal(horse.birth) >= comp.minAgeAnimal ||
      comp.minAgeAnimal == 0;
    const e =
      comp.skillLevel == "PROFESSIONAL" ? competitor.professional : true;
    const f = comp.skillLevel == "AMATEUR" ? !competitor.professional : true;
    const g =
      comp.genderRule == "NONE" ? true : comp.genderRule == competitor.gender;
    const customerTks = customerTakeoffsDataByEvent.filter(
      (tk) => comp._id == tk.competition._id
    );
    const stallionsAndFemales = customerTks.filter(
      (tk) => tk.horse.gender == "STALLION" || tk.horse.gender == "FEMALE"
    );

    const h =
      customerTks.length < comp.subscriptionPerCompetitorLimit ||
      comp.subscriptionPerCompetitorLimit == 0;
    const i = horse.gender != "STALLION" || comp.allowStallion == true;
    const j =
      stallionsAndFemales.length <
        comp.stallionAndFemalePerCompetitorSubscriptionLimit ||
      comp.stallionAndFemalePerCompetitorSubscriptionLimit == 0 ||
      horse.gender == "GELDING";
    const k =
      competitor.abqmScore &&
      (comp.maxCompetitorScore >=
        parseFloat(competitor.abqmScore.replace(",", ".")) ||
        comp.maxCompetitorScore == 0);
    const l =
      competitor.abqmScore &&
      (parseFloat(competitor.abqmScore.replace(",", ".")) >=
        comp.minCompetitorScore ||
        comp.minCompetitorScore == 0);
    const m = comp.isLineage
      ? competitorLineage.status_aprovacao != "NEGADO" ||
        competitor.professional
      : true;
    const n = comp.onlyOneSubscriptionPerCompetitor
      ? customerTakeoffsDataByEvent.filter((x) => x.competition._id == comp._id)
          .length == 0
      : true;
    return a && b && c && d && e && f && g && h && i && j && k && l && m && n;
  }
  static defineCompRestrictions(
    comp,
    competitor,
    horse,
    horseEventTakeoffsData,
    customerTakeoffsDataByEvent,
    competitorLineage
  ) {
    if (horseEventTakeoffsData) {
      let tks = horseEventTakeoffsData.filter(
        (tk) => tk.competition == comp._id
      );
      if (tks.length > 0) return ["Animal já inscrito"];
    }
    // if (comp.isFinished) return ["Categoria Finalizada"];
    // if (!comp.isActiveSubscriptions) return ["Inscrições Inativas"];
    const restrictions = [];
    if (comp.maxAgeCompetitor && comp.maxAgeCompetitor != 0)
      if (this.hippicalYearCompetitor(competitor.age) > comp.maxAgeCompetitor)
        restrictions.push(
          `Comp. possui mais de ${comp.maxAgeCompetitor} anos hípicos.`
        );
    if (comp.minAgeCompetitor && comp.minAgeCompetitor != 0)
      if (this.hippicalYearCompetitor(competitor.age) < comp.minAgeCompetitor)
        restrictions.push(
          `Comp. possui menos de ${comp.minAgeCompetitor} anos hípicos.`
        );
    if (comp.maxAgeAnimal && comp.maxAgeAnimal != 0)
      if (this.hippicalYearAnimal(horse.birth) > comp.maxAgeAnimal)
        restrictions.push(
          `Animal possui mais de ${comp.maxAgeAnimal} anos hípicos.`
        );
    if (comp.minAgeAnimal && comp.minAgeAnimal != 0)
      if (this.hippicalYearAnimal(horse.birth) < comp.minAgeAnimal)
        restrictions.push(
          `Animal possui menos de ${comp.minAgeAnimal} anos hípicos.`
        );
    if (comp.skillLevel == "PROFESSIONAL")
      if (!competitor.professional)
        restrictions.push(`Comp. não é profissional`);
    if (comp.skillLevel == "AMATEUR")
      if (competitor.professional) restrictions.push(`Comp. não é amador`);
    if (comp.genderRule != "NONE")
      comp.genderRule == "MALE"
        ? competitor.gender == "FEMALE"
          ? restrictions.push(`Comp. não é do sexo Masculino`)
          : null
        : competitor.gender == "MALE"
        ? restrictions.push(`Comp. não é do sexo Feminino`)
        : null;
    const customerTks = customerTakeoffsDataByEvent.filter(
      (tk) => comp._id == tk.competition._id
    );
    const stallionsAndFemales = customerTks.filter(
      (tk) => tk.horse.gender == "STALLION" || tk.horse.gender == "FEMALE"
    );
    if (
      customerTks.length >= comp.subscriptionPerCompetitorLimit &&
      comp.subscriptionPerCompetitorLimit != 0
    )
      restrictions.push(
        `Limite de ${comp.subscriptionPerCompetitorLimit} Inscrições por usuário atingido`
      );
    if (horse.gender == "STALLION" && !comp.allowStallion) {
      restrictions.push(`Esta categoria não permite garanhões`);
    }
    if (
      stallionsAndFemales.length >=
        comp.stallionAndFemalePerCompetitorSubscriptionLimit &&
      comp.stallionAndFemalePerCompetitorSubscriptionLimit != 0 &&
      horse.gender != "GELDING"
    ) {
      restrictions.push(
        `Limite de ${comp.stallionAndFemalePerCompetitorSubscriptionLimit} Garanhões/Fêmeas atingido por usuário`
      );
    }
    if (competitor.abqmScore) {
      if (comp.maxCompetitorScore && comp.maxCompetitorScore != 0)
        if (
          parseFloat(competitor.abqmScore.replace(",", ".")) >
          comp.maxCompetitorScore
        )
          restrictions.push(
            `Comp. possui mais de ${comp.maxCompetitorScore} pontos na abqm.`
          );
      if (comp.minCompetitorScore && comp.minCompetitorScore != 0)
        if (
          parseFloat(competitor.abqmScore.replace(",", ".")) <
          comp.minCompetitorScore
        )
          restrictions.push(
            `Comp. possui menos de ${comp.minCompetitorScore} pontos na abqm.`
          );
    }
    if (
      comp.isLineage &&
      competitorLineage.status_aprovacao == "NEGADO" &&
      !competitor.professional
    )
      restrictions.push(`Comp. não é parente do proprietário.`);
    if (
      comp.onlyOneSubscriptionPerCompetitor &&
      customerTakeoffsDataByEvent.filter((x) => x.competition._id == comp._id)
        .length > 0
    )
      restrictions.push(`Já existe uma inscrição deste competidor`);
    return restrictions;
  }
  static defineCompRules(
    comp,
    competitorLineage,
    customerTakeoffsDataByEvent,
    competitor
  ) {
    let rules = [];
    if (comp.maxAgeCompetitor != 0 && comp.minAgeCompetitor != 0) {
      rules.push(
        `Comp. de ${comp.minAgeCompetitor} até  ${comp.maxAgeCompetitor} anos hípicos`
      );
    } else {
      if (comp.maxAgeCompetitor != 0)
        rules.push(`Comp. até ${comp.maxAgeCompetitor} anos hípicos`);
      if (comp.minAgeCompetitor != 0)
        rules.push(
          `Comp. possui no mínimo ${comp.minAgeCompetitor} anos hípicos`
        );
    }

    if (comp.maxAgeAnimal != 0 && comp.minAgeAnimal != 0) {
      rules.push(
        `Animal de ${comp.minAgeAnimal} até  ${comp.maxAgeAnimal} anos hípicos`
      );
    } else {
      if (comp.maxAgeAnimal != 0)
        rules.push(`Animal possui no mínimo ${comp.maxAgeAnimal} anos hípicos`);
      if (comp.minAgeAnimal != 0)
        rules.push(`Animal possui no mínimo ${comp.minAgeAnimal} anos hípicos`);
    }

    if (comp.skillLevel == "PROFESSIONAL") rules.push(`Apenas Profissionais`);
    if (comp.skillLevel == "AMATEUR") rules.push(`Apenas Amadores`);
    if (comp.genderRule != "NONE")
      comp.genderRule == "MALE"
        ? rules.push(`Comp. deverá ser do sexo Masculino`)
        : rules.push(`Comp. deverá ser do sexo Feminino`);

    if (comp.maxCompetitorScore != 0 && comp.minCompetitorScore != 0) {
      rules.push(
        `Comp. de ${comp.minCompetitorScore} até  ${comp.maxCompetitorScore} pontos abqm`
      );
    } else {
      if (comp.maxCompetitorScore != 0)
        rules.push(`Comp. até ${comp.maxCompetitorScore} pontos abqm`);
      if (comp.minCompetitorScore != 0)
        rules.push(
          `Comp. possui no mínimo ${comp.minCompetitorScore} pontos abqm`
        );
    }
    if (
      comp.isLineage &&
      competitorLineage.status_aprovacao == "NEGADO" &&
      !competitor.professional
    )
      rules.push(`Comp. não é parente do proprietário.`);
    if (
      comp.onlyOneSubscriptionPerCompetitor &&
      customerTakeoffsDataByEvent.filter((x) => x.competition._id == comp._id)
        .length > 0
    )
      rules.push(`Apenas 1 inscrição por Comp.`);
    if (rules.length == 0) rules.push("Livre");
    return rules;
  }
  static setAbqmCompetitorRestrictions(competitor) {
    const abqmSituation = [];
    let competitorAbqmSituation = "";
    if (competitor.returnedAbqm) {
      if (competitor.dt_obito != "") abqmSituation.push("Competidor em óbito");
      if (competitor.inadimplente == "1")
        abqmSituation.push("Competidor Inadimplente");
      if (competitor.inadimplente == "2")
        abqmSituation.push("Competidor não é sócio");
      if (competitor.suspenso == "1") abqmSituation.push("Competidor Suspenso");
      competitorAbqmSituation = abqmSituation.join(", ");
    }

    return competitorAbqmSituation;
  }
  static setAbqmHorseRestrictions(horse) {
    const abqmSituation = [];
    let horseAbqmSituation = "";
    if (horse.returnedAbqm) {
      if (
        horse.situacao_animal != "Ativo" &&
        horse.situacao_animal_esporte != "Ativo" &&
        horse.motivo_suspensao_animal != "Ativo"
      )
        abqmSituation.push(
          "O Animal selecionado não consta como ativo na abqm"
        );
      if (horse.dt_obito != "" && horse.dt_obito != null)
        abqmSituation.push("Animal em óbito");
      horseAbqmSituation = abqmSituation.join(", ");
    }
    return horseAbqmSituation;
  }
}
