import { Component, Input, OnInit } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";

@Component({
  selector: "app-financial-stalls",
  templateUrl: "./financial-stalls.component.html",
  styleUrls: ["./financial-stalls.component.scss"],
})
export class FinancialStallsComponent implements OnInit {
  @Input() event;
  eventStalls: any = [];
  isStallsLoading: boolean = true;

  constructor(private financial: FinancialService) {}

  ngOnInit() {}

  ngOnChanges() {
    this.listStalls();
  }

  async listStalls() {
    this.isStallsLoading = true;
    this.eventStalls = await this.financial.getEventStalls(this.event._id);
    this.isStallsLoading = false;
  }
}
