import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Competitor } from "src/app/dto/Competitor";
import { EventService } from "src/app/services/event.service";
import { CompetitorAddComponent } from "../competitor-add/competitor-add.component";

@Component({
  selector: "app-competitor-list",
  templateUrl: "./competitor-list.component.html",
  styleUrls: ["./competitor-list.component.scss"],
})
export class CompetitorListComponent implements OnInit {
  competitors: Array<Competitor> = [];
  competitorQuery: string = "";
  count = null;
  page = 0;

  constructor(private _event: EventService, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.getAllCompetitors();
  }

  async getAllCompetitors() {
    try {
      const query: any = await this._event.getAllCompetitors(this.page);
      this.competitors = [...this.competitors, ...query.resCompetitor];
      this.count = query.count;
      this.page += 1;
    } catch (error) {}
  }

  async searchCompetitor() {
    if (this.competitorQuery !== "") {
      this.competitors = Object.assign(
        await this._event.searchCompetitor(this.competitorQuery).toPromise()
      );
    }
  }

  clearCompetitor() {
    this.competitorQuery = "";
    this.getAllCompetitors();
  }

  openCompetitorModal(competitor: Competitor) {
    const modalRef = this.modalService.open(CompetitorAddComponent, {
      size: "lg",
    });
    modalRef.componentInstance.competitor = competitor;
    modalRef.result.then(
      (close) => {
        this.getAllCompetitors();
      },
      (dismiss) => {}
    );
  }
}
