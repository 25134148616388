<div class="input-group">
  <div class="chip form-control-chip" *ngIf="searchedChangeItem">
    <div class="badge badge-secondary">
      {{ this.searchedChangeItem }}
      <span class="closebtn" (click)="setChangeItemNotSearched()">&times;</span>
    </div>
  </div>
  <ng-template #rtChangeItem let-r="result" let-t="term">
    <div>
      <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
      -
      {{
        type === "CUSTOMER"
          ? r.cpforcnpj
          : type === "COMPETITOR"
          ? r.cpf
          : r.registerId
      }}
    </div>
    <img
      *ngIf="r.returnedAbqm"
      class="search-logo"
      src="../../../assets/images/abqm.png"
      alt="abqm logo"
    />
  </ng-template>
  <div class="inputcontainer" *ngIf="!searchedChangeItem">
    <input
      type="text"
      class="form-control"
      [class.is-invalid]="searchChangeItemFailed"
      [(ngModel)]="changeItem"
      [ngbTypeahead]="searchChangeItem"
      [placeholder]="setPlaceholder()"
      [inputFormatter]="formatterChangeItem"
      [resultTemplate]="rtChangeItem"
      (selectItem)="selectedChangeItem($event)"
    />
    <div class="icon-container" *ngIf="searchingChangeItem">
      <i class="loader"></i>
    </div>
  </div>
</div>
