<nav ngbNav #nav="ngbNav" class="nav-tabs">
  <ng-container ngbNavItem>
    <a ngbNavLink translate>EVENT.CATEGORIES.ADD</a>
    <ng-template ngbNavContent>
      <div class="card-body">
        <h3 *ngIf="!eventParam.isFinished" translate>CATEGORIES</h3>
        <p *ngIf="!eventParam.isFinished" class="card-description" translate>
          EVENT.CATEGORIES.MANAGE
        </p>
        <div class="row" *ngIf="!eventParam.isFinished">
          <div class="col-md-2 mb-2">
            <div class="input-group">
              <ng-template #rt let-r="result" let-t="term">
                <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
              </ng-template>
              <div class="inputcontainer">
                <div class="chip form-control-chip" *ngIf="searchedCategory">
                  <div class="badge badge-secondary">
                    {{ this.searchedCategory | translateCategory | async }}
                    <span class="closebtn" (click)="setNotSearched()"
                      >&times;</span
                    >
                  </div>
                </div>
                <input
                  *ngIf="!searchedCategory"
                  (selectItem)="selectedCategoryItem($event)"
                  type="text"
                  class="form-control"
                  [class.is-invalid]="searchFailed"
                  [(ngModel)]="category"
                  [ngbTypeahead]="search"
                  placeholder="{{ 'EVENT.CATEGORIES.ENTER-NAME' | translate }}"
                  [inputFormatter]="formatter"
                  [resultTemplate]="rt"
                />
                <div class="icon-container" *ngIf="searching">
                  <i class="loader"></i>
                </div>
              </div>
            </div>
            <small class="form-text text-muted" translate>
              EVENT.CATEGORIES.NAME-REQUIRED
            </small>
          </div>
          <div class="col-md-2 mb-2" *ngIf="!category?.isRank">
            <input
              type="text"
              class="form-control"
              [placeholder]="
                pass == 1 ? '100' : pass == 2 ? '100, 200' : '100, 200, 300'
              "
              [(ngModel)]="price"
              (ngModelChange)="setAllPrices()"
            />
            <small class="form-text text-muted" *ngIf="pass == 1" translate>
              EVENT.CATEGORIES.PRICE-REQUIRED
            </small>
            <small class="form-text text-muted" *ngIf="pass > 1" translate>
              EVENT.CATEGORIES.TK-PRICE-REQUIRED
            </small>
          </div>
          <div class="col-md-2 mb-2" *ngIf="eventParam != null">
            <select required class="form-control" [(ngModel)]="date">
              <option selected="selected" disabled>Selecione o dia</option>
              <option
                *ngFor="let eventDay of eventParam.eventDays"
                [ngValue]="eventDay"
              >
                {{ eventDay | date: "EEEE dd/MM" | titlecase }}
              </option>
            </select>
            <small class="form-text text-muted" translate
              >EVENT.CATEGORIES.SELECT-DATE</small
            >
          </div>
          <div class="col-md-2 mb-2" *ngIf="eventParam != null">
            <select
              required
              class="form-control"
              [(ngModel)]="pass"
              (ngModelChange)="setAllPrices(category)"
            >
              <option selected="selected" value="1">1 Passada</option>
              <option selected="selected" value="2" *ngIf="!category?.isRank">
                2 {{ "TAKEOFFS" | translate }}
              </option>
              <option selected="selected" value="3" *ngIf="!category?.isRank">
                3 {{ "TAKEOFFS" | translate }}
              </option>
            </select>
            <small class="form-text text-muted" translate>
              EVENT.CATEGORIES.SELECT-QTY
            </small>
          </div>

          <div class="col-md-2">
            <button
              type="submit"
              class="btn btn-block btn-sm btn-gradient-primary"
              (click)="submitCategory()"
              [disabled]="!date || !hasAllPrices || !searchedCategory"
              translate
            >
              ADD
            </button>
          </div>
        </div>
        <div class="row">
          <div *ngIf="eventParam" class="mt-5">
            <h4 class="card-title" translate>EVENT.CATEGORIES.LIST</h4>
            <div
              *ngFor="let day of eventParam.eventDays; let i = index"
              class="mt-3"
            >
              <p class="card-description">
                {{ day | date: "EEEE dd/MM" | titlecase }}
              </p>
              <div class="row">
                <ng-container *ngFor="let day of days[i]; first as isFirst">
                  <div class="col-md-4 pt-3" *ngIf="!day.hasPass">
                    <div ngbDropdown class="h-100">
                      <div class="btn-group">
                        <button
                          class="btn btn-outline-primary w-100 h-100"
                          (click)="openModal(day, i)"
                        >
                          {{ day.category.name | translateCategory | async }}

                          <div class="text-dark">
                            <small translate>EVENT.CATEGORIES.EDIT-SUB</small>
                          </div>
                        </button>
                        <button
                          class="btn btn-secondary p-3"
                          type="button"
                          ngbDropdownToggle
                        ></button>
                        <div ngbDropdownMenu>
                          <h6 class="dropdown-header" translate>SETTINGS</h6>
                          <div class="dropdown-divider"></div>
                          <a
                            class="dropdown-item"
                            (click)="deleteCategory(day._id)"
                            translate
                            >DELETE</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>MANAGE-RANK</a>
    <ng-template ngbNavContent>
      <app-event-ranking
        [event]="eventParam"
        [competitions]="competitions"
        [isAdmin]="isAdmin"
      ></app-event-ranking>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>MANAGE-AWARD</a>
    <ng-template ngbNavContent>
      <app-event-awards
        [event]="eventParam"
        [competitions]="competitions"
        [isAdmin]="isAdmin"
      ></app-event-awards>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>MANAGE-RULE</a>
    <ng-template ngbNavContent>
      <app-event-categories-rules
        [event]="eventParam"
        [competitions]="competitions"
        [isAdmin]="isAdmin"
      ></app-event-categories-rules>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>MANAGE-SETTINGS</a>
    <ng-template ngbNavContent>
      <app-event-categories-settings
        [event]="eventParam"
        [competitions]="competitions"
        [isAdmin]="isAdmin"
      ></app-event-categories-settings>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem *ngIf="permission.xis">
    <a ngbNavLink translate>MANAGE-ABQM</a>
    <ng-template ngbNavContent>
      <app-event-categories-abqm
        [event]="eventParam"
        [competitions]="competitions"
        [isAdmin]="isAdmin"
      ></app-event-categories-abqm>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem *ngIf="permission.xis">
    <a ngbNavLink translate>MANAGE-ABQM-RESULT</a>
    <ng-template ngbNavContent>
      <app-event-categories-abqm-result
        [event]="eventParam"
        [competitions]="competitions"
        [isAdmin]="isAdmin"
      ></app-event-categories-abqm-result>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem *ngIf="permission.xis">
    <a ngbNavLink translate>RANK</a>
    <ng-template ngbNavContent>
      <app-event-categories-rank
        [event]="eventParam"
        [competitions]="competitions"
        [isAdmin]="isAdmin"
      ></app-event-categories-rank>
    </ng-template>
  </ng-container>
</nav>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
