<div class="card-body">
  <h4 class="card-title" translate>PROMOTIONS</h4>
  <div class="row">
    <div class="col-md-3 mb-2">
      <input
        class="form-control"
        [(ngModel)]="startDate"
        mask="00/00/0000"
        [dropSpecialCharacters]="false"
        placeholder="01/01/0001"
      />
      <small class="form-text text-muted" translate>INITIALDATE</small>
    </div>
    <div class="col-md-3 mb-2">
      <input
        class="form-control"
        [(ngModel)]="startHour"
        mask="00:00"
        [dropSpecialCharacters]="false"
        placeholder="00:00"
      />
      <small class="form-text text-muted" translate>INITIALTIME</small>
    </div>
    <div class="col-md-3 mb-2">
      <input
        class="form-control"
        [(ngModel)]="finalDate"
        mask="00/00/0000"
        placeholder="01/01/0001"
        [dropSpecialCharacters]="false"
      />
      <small class="form-text text-muted" translate>FINALDATE</small>
    </div>
    <div class="col-md-3 mb-2">
      <input
        class="form-control"
        [(ngModel)]="finalHour"
        mask="00:00"
        placeholder="00:00"
        [dropSpecialCharacters]="false"
      />
      <small class="form-text text-muted" translate>FINALTIME</small>
    </div>
    <div class="col-md-3 mb-2">
      <input class="form-control" [(ngModel)]="code" />
      <small class="form-text text-muted" translate
        >PROMOTION.ENTER-COUPON</small
      >
    </div>
    <div class="col-md-3 mb-2">
      <input class="form-control" mask="00000000" [(ngModel)]="value" />
      <small class="form-text text-muted" translate
        >PROMOTION.ENTER-COUPON-VALUE</small
      >
    </div>
    <div class="col-md-3 mb-2">
      <select class="form-control" [(ngModel)]="isPercentage">
        <option [value]="true" translate>IS-PERCENTAGE</option>
        <option [value]="false" translate>IS-NOT-PERCENTAGE</option>
      </select>
      <small class="form-text text-muted" translate>SELECT-IS-PERCENTAGE</small>
    </div>

    <div class="col-md-3 mb-2">
      <button
        type="submit"
        class="btn btn-block btn-sm btn-gradient-primary"
        (click)="submitCoupon()"
        [disabled]="
          !code ||
          !startDate ||
          !startHour ||
          !finalDate ||
          !finalHour ||
          !value ||
          !isPercentage ||
          filteredSelectedCompetitions().length === 0
        "
        translate
      >
        ADD
      </button>
    </div>
  </div>

  <div class="row pt-3" *ngIf="searchedCoupons">
    <div class="col-md-12">
      <h3 translate>COUPON.PLURAL</h3>
    </div>
  </div>
  <div class="row mt-4" *ngIf="searchedCoupons">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th translate>NAME</th>
              <th translate>INITIALDATE</th>
              <th translate>FINALDATE</th>
              <th translate>VALUE</th>
              <th translate>CATEGORIES</th>
              <th translate>AUTO-ACTIVATION</th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="isCouponsLoading">
            <tr>
              <td colspan="5" style="text-align: center">
                <app-loading></app-loading>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isCouponsLoading && searchedCoupons.length === 0">
            <tr>
              <td colspan="5" style="text-align: center" translate>
                PROMOTION.NOT-FOUND
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isCouponsLoading">
            <tr *ngFor="let coupom of searchedCoupons; let index = index">
              <td>{{ coupom.code }}</td>
              <td>{{ coupom.startDate | date: "medium" }}</td>
              <td>{{ coupom.finalDate | date: "medium" }}</td>
              <td>
                <span *ngIf="!coupom.isPercentage" translate>CURRENCY</span
                >{{ coupom.value }}<span *ngIf="coupom.isPercentage">%</span>
              </td>
              <td>
                <div *ngFor="let comp of coupom.competitions">
                  {{ comp.category.name | translateCategory | async }} -
                  {{ comp.pass }} {{ "TAKEOFF" | translate
                  }}{{ comp.pass > 1 ? "s" : "" }}
                </div>
              </td>
              <td>
                <label class="switch">
                  <input
                    type="checkbox"
                    [checked]="isCouponActive(coupom._id)"
                    (change)="couponAutomaticCheck($event, coupom._id)"
                  />
                  <span class="slider round"></span>
                </label>
              </td>
              <td>
                <button
                  class="
                    btn btn-outline-primary
                    mdi-for-btn mdi mdi-delete-forever
                  "
                  (click)="deleteCoupon(coupom._id)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row pt-3">
    <div class="col-md-12">
      <h3 translate>CATEGORIES</h3>
    </div>
  </div>
  <div class="row mt-4" *ngIf="competitions">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th (click)="toggleSelectAllCompetitions()">
                <i
                  class="mdi"
                  [ngClass]="
                    this.filteredSelectedCompetitions().length ===
                    this.competitions.length
                      ? 'mdi-checkbox-marked-circle-outline'
                      : 'mdi-checkbox-blank-circle-outline'
                  "
                ></i>
              </th>
              <th translate>CATEGORY</th>
              <th translate>PRICE</th>
            </tr>
          </thead>
          <tbody *ngIf="isCompetitionsLoading">
            <tr>
              <td colspan="4" style="text-align: center">
                <app-loading></app-loading>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isCompetitionsLoading && competitions.length == 0">
            <tr>
              <td colspan="4" style="text-align: center" translate>
                NO-CATEGORY-FOUND
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isCompetitionsLoading">
            <tr
              *ngFor="let comp of competitions; let index = index"
              (click)="selectCompetition(index)"
              [ngClass]="{ 'table-success': selectedCompetitions[index] }"
              style="cursor: pointer"
            >
              <td>
                <i
                  class="mdi"
                  [ngClass]="
                    selectedCompetitions[index]
                      ? 'mdi-checkbox-marked-circle-outline'
                      : 'mdi-checkbox-blank-circle-outline'
                  "
                ></i>
              </td>
              <td>
                {{ comp.category.name | translateCategory | async }} -
                {{ comp.pass }}ª
                {{ "TAKEOFF" | translate }}
              </td>
              <td>{{ comp.price }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
