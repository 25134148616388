<div class="modal-header">
  <h4 class="modal-title" translate>SUBSCRIPTION-LIST.RELEASE</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row mb-2">
    <div class="col-auto">
      <label for="date" translate>SUBSCRIPTION-LIST.SCHEDULE-DATE</label>
      <input
        type="text"
        class="form-control"
        id="date"
        placeholder="XX/XX/XXXX"
        mask="00/00/0000"
        [(ngModel)]="date"
        [disabled]="isLoadingSchedule"
      />
    </div>
    <div class="col-auto">
      <label for="date" translate>SUBSCRIPTION-LIST.SCHEDULE-HOUR</label>
      <input
        type="text"
        [(ngModel)]="hour"
        class="form-control"
        id="hour"
        placeholder="XX:XX"
        mask="00:XX"
        [disabled]="isLoadingSchedule"
      />
    </div>
    <div class="col-auto d-flex" style="align-items: flex-end">
      <button
        class="btn btn-outline-primary btn-sm"
        [disabled]="!hour || !date || isLoadingSchedule"
        (click)="submitScheduleSubscriptions()"
        translate
      >
        SCHEDULE
      </button>
    </div>
  </div>
  <p>
    <ngb-alert [dismissible]="false">
      <strong translate>ATENTION!</strong
      ><span translate>ONLY-PUBLISHED-WARNING</span
      ><b translate>PROGRESS-PUBLISHED</b>.
    </ngb-alert>
  </p>
  <div class="row" *ngIf="isLoading">
    <div class="col">
      <div class="d-flex justify-content-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <div class="row mb-2" *ngIf="competitions">
    <div class="col-auto d-flex">
      <button
        class="btn btn-outline-primary btn-sm"
        (click)="setActiveSubscriptionAll(true)"
        translate
      >
        RELEASE-ALL
      </button>
    </div>
    <div class="col-auto d-flex">
      <button
        class="btn btn-outline-primary btn-sm"
        (click)="setActiveSubscriptionAll(false)"
        translate
      >
        CLOSE-ALL
      </button>
    </div>
  </div>
  <div class="row" *ngIf="competitions">
    <div class="col">
      <div class="list-group">
        <p
          class="d-flex justify-content-center"
          *ngIf="competitions.length == 0"
          translate
        >
          EVENT.CATEGORY.EMPTY
        </p>
        <ng-container *ngIf="!isLoading">
          <button
            type="button"
            class="list-group-item list-group-item-action"
            [ngClass]="{
              'list-group-item-success': comp.isActiveSubscriptions
            }"
            *ngFor="let comp of competitions"
            (click)="
              setActiveSubscription(comp._id, !comp.isActiveSubscriptions)
            "
          >
            <i
              class="mdi"
              [ngClass]="
                comp.isActiveSubscriptions
                  ? 'mdi-checkbox-marked-circle-outline'
                  : 'mdi-checkbox-blank-circle-outline'
              "
            ></i>
            {{ comp.category.name | translateCategory | async }} -
            {{ comp.date | date: "EEEE dd/MM" }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
    translate
  >
    CLOSE
  </button>
</div>
