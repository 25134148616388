<h5 translate>FILTERS</h5>
<div class="row">
  <div class="col-md-4">
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        placeholder="{{ 'FINANCIAL-GENERAL.CUSTOMER.FILTER' | translate }}"
        [(ngModel)]="userFilter"
        (input)="filterSearch()"
      />
      <button
        *ngIf="userFilter != ''"
        class="btn bg-transparent"
        style="margin-left: -40px; z-index: 100; padding: 0"
        (click)="clearUserFilter()"
      >
        <i class="mdi mdi-for-btn mdi-close"></i>
      </button>
    </div>
  </div>
  <div class="col-md-3">
    <select
      class="form-control"
      (change)="onListFilterChange($event.target.value)"
    >
      <option value="all" selected translate>ALL</option>
      <option value="debtors" translate>LIST-DEBTORS</option>
      <option value="remaining" translate>LIST-REMAINING</option>
      <option value="clients" translate>LIST-CUSTOMER</option>
    </select>
  </div>
</div>
<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th></th>
        <th translate>CUSTOMER.SINGLE</th>
        <th translate>SUBS</th>
        <th translate>STALL</th>
        <th translate>STALLS-VALUE</th>
        <th translate>SUBS-VALUE</th>
        <th translate>PAID</th>
        <th translate>BALANCE</th>
        <th translate>AWARD</th>
        <th translate>AWARD-PAID</th>
        <!-- <th>Saldo Prem.</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!usersPerm && !users && !isUsersLoading">
        <td colspan="9" class="text-center" translate>EVENT.NO-CUSTOMERS</td>
      </tr>
      <tr
        *ngIf="usersPerm?.length > 0 && users?.length == 0 && !isUsersLoading"
      >
        <td colspan="9" class="text-center" translate>CUSTOMER.NOT-FOUND</td>
      </tr>
      <tr *ngIf="isUsersLoading">
        <td colspan="9">
          <div
            class="
              lds-dual-ring lds-dual-ring-black
              d-flex
              justify-content-center
              w-100
            "
          ></div>
        </td>
      </tr>
      <tr
        *ngFor="let user of users"
        [ngClass]="{
          'table-success': user.balance > 0,
          'table-danger': user.balance < 0
        }"
      >
        <td>
          <div
            (click)="open(user)"
            class="btn btn-sm btn-outline-primary"
            translate
          >
            DETAILS
          </div>
        </td>
        <td>{{ user.customer.name }}</td>
        <td>{{ user.subscriptions ? user.subscriptions : "-" }}</td>
        <td>{{ user.stalls ? user.stalls : "-" }}</td>
        <td>{{ event.currency | currencyPipe }}{{ user.totalStall }}</td>
        <td>
          {{ event.currency | currencyPipe }}{{ user.total ? user.total : "0" }}
        </td>
        <td>
          {{ event.currency | currencyPipe }}{{ user.paid ? user.paid : "0" }}
        </td>
        <td
          [ngClass]="{
            'text-success': user.balance > 0,
            'text-danger': user.balance < 0
          }"
        >
          <b
            >{{ "CURRENCY" | translate
            }}{{ user.balance ? user.balance : "0" }}</b
          >
        </td>
        <td>
          {{ "CURRENCY" | translate
          }}{{ user.totalAward ? user.totalAward : "0" }}
        </td>
        <td>
          {{ "CURRENCY" | translate
          }}{{ user.paidAward ? user.paidAward : "0" }}
        </td>
        <!-- <td
          [ngClass]="{
            'text-success': user.balanceAward > 0,
            'text-danger': user.balanceAward < 0
          }"
        >
          <b>R${{ user.balanceAward ? user.balanceAward : "0" }}</b>
        </td> -->
      </tr>
      <tr *ngIf="usersPerm && users?.length > 0 && !isUsersLoading">
        <td translate>TOTAL</td>
        <td></td>
        <td>{{ usersPerm.subscriptionsCount }}</td>
        <td>{{ usersPerm.stallsCount }}</td>
        <td>{{ usersPerm.stallsTotal }}</td>
        <td>
          {{ event.currency | currencyPipe }}{{ usersPerm.subscriptionsTotal }}
        </td>
        <td>
          {{ event.currency | currencyPipe
          }}{{ usersPerm.subscriptionsPaidTotal }}
        </td>
        <td>
          {{ event.currency | currencyPipe
          }}{{ usersPerm.balanceSubscriptionsTotal }}
        </td>

        <td>{{ event.currency | currencyPipe }}{{ usersPerm.awardsTotal }}</td>
        <td>
          {{ event.currency | currencyPipe }}{{ usersPerm.awardsPaidTotal }}
        </td>
        <!-- <td>R${{ users.balanceAwardTotal }}</td> -->
      </tr>
    </tbody>
  </table>
</div>
