<p class="card-description" *ngIf="event.isFinished" translate>
  EVENT.ALREADY-FINISHED
</p>

<div class="card-body" *ngIf="!event.isFinished">
  <h3 translate>EVENT.GENERAL-SETTINGS</h3>
  <div class="row mt-5">
    <div class="col-md-4">
      <div class="form-group">
        <label for="name" translate>EVENT.IMPORT-EXPORT</label>
        <div class="row">
          <button
            class="btn btn-outline-secondary"
            type="button"
            (click)="downloadEventConfig()"
            translate
          >
            EVENT.DOWNLOAD-SETTINGS
          </button>
          <label
            for="file-upload"
            class="btn btn-outline-primary"
            type="button"
            translate
          >
            EVENT.UPLOAD-SETTINGS
          </label>
          <input
            id="file-upload"
            type="file"
            (change)="importEventConfig($event.target, content)"
            accept=".csv"
            name="csvFile"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-4">
      <div class="form-group">
        <label for="name" translate>EVENT.ARENA-REPAIR-INTERVAL</label>
        <div class="input-group">
          <input
            type="text"
            id="name"
            class="form-control"
            [(ngModel)]="arenaRepairInterval"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary pb-0 pt-0"
              type="button"
              (click)="updateEventGeneral()"
              translate
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-4">
      <div class="form-group">
        <label for="name" translate>EVENT.GENERAL-REPAIR-INTERVAL</label>
        <div class="input-group">
          <input
            type="text"
            id="name"
            class="form-control"
            [(ngModel)]="generalRepairInterval"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary pb-0 pt-0"
              type="button"
              (click)="updateEventGeneral()"
              translate
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="card-description" *ngIf="permissions.hasCategory" translate>
    EVENT.CHANGE-CATEGORY-ORDER
  </p>
  <div class="row" *ngIf="permissions.hasCategory">
    <div class="col-md-12">
      <div class="btn-group-vertical">
        <div
          class="btn-group mb-3"
          *ngFor="let competition of competitions; let i = index"
        >
          <div class="btn-group-vertical">
            <div ngbDropdown class="d-inline-block h-100">
              <button
                ngbDropdownToggle
                type="button"
                [ngClass]="{
                  'btn-outline-secondary': i % 2 == 0,
                  'btn-outline-primary': i % 2 != 0,
                  btn: true,
                  'btn-order': true,
                  'h-100': true
                }"
              >
                {{ i + 1 }}
              </button>
              <div
                class="dropdown-order-menu"
                ngbDropdownMenu
                aria-labelledby="dropdownOrder"
              >
                <button
                  *ngFor="let competition of competitions; let j = index"
                  ngbDropdownItem
                  (click)="changeOrder(i, j)"
                >
                  {{ j + 1 }}
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            [ngClass]="{
              'btn-outline-secondary': i % 2 == 0,
              'btn-outline-primary': i % 2 != 0,
              btn: true,
              'btn-order': true
            }"
          >
            {{ competition.category.name | translateCategory | async }}
            {{ competition.date | date: "EEEE dd/MM" | titlecase }}
            <br />
            {{ event.currency | currencyPipe }}{{ competition.price }}
          </button>
          <div
            *ngIf="event.progress != 'published' || isAdmin"
            class="btn-group-vertical arrows"
            role="group"
          >
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm btn-order"
              (click)="goUp(i)"
            >
              <i class="mdi mdi-format-vertical-align-top" *ngIf="i > 0"></i>
              <span *ngIf="i == 0">-</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm btn-order"
              (click)="goDown(i)"
            >
              <i
                class="mdi mdi-format-vertical-align-bottom"
                *ngIf="i < competitions.length - 1"
              ></i>
              <span *ngIf="i == competitions.length - 1">-</span>
            </button>
          </div>
        </div>
        <small *ngIf="event.progress == 'published'" translate
          >EVENT.DENIED-ALREADY-PUBLISHED-CHANGE-ORDER</small
        >
        <div class="btn-group" *ngIf="event.progress != 'published' || isAdmin">
          <button
            class="btn btn-gradient-secondary"
            (click)="getEventCompetitions()"
            translate
          >
            EVENT.RESET-ORDER
          </button>
          <div
            class="btn btn-gradient-primary"
            (click)="saveTakeOffList()"
            translate
          >
            EVENT.SAVE-ORDER
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" translate>CHOOSELIST</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th translate>EVENT.ATUAL-CATEGORY</th>
              <th translate>EVENT.IMPORTED-CATEGORY</th>
              <th translate>DIFFERENCE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="form-group">
                  <label for="selectedCompetition" translate
                    >CHOOSE-CATEGORY</label
                  >
                  <select
                    class="form-control form-control-lg"
                    id="selectedCompetition"
                    [(ngModel)]="selectedCompetition"
                  >
                    <option
                      *ngFor="let comp of mappedCompetitons"
                      [ngValue]="comp"
                    >
                      {{ comp.categoria }} -
                      {{ comp.data | date: "EEEE dd/MM" | titlecase }}
                    </option>
                  </select>
                </div>
              </td>
              <td>
                <span *ngFor="let comp of loadedCsv">
                  {{ comp.categoria }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>
</div>
