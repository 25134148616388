<div class="card">
  <div class="card-body">
    <div
      class="card-title"
      [translate]="{
        count: count ? ' -' + count + ' Animais Registrados' : ''
      }"
    >
      ANIMAL.MANAGE
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="input-group">
          <input
            type="text"
            class="form-control h-100"
            placeholder="{{ 'ANIMAL.SEARCH' | translate }}"
            [(ngModel)]="animalQuery"
            (keyup.enter)="searchAnimal()"
          />
          <button
            *ngIf="animalQuery !== ''"
            (click)="clearAnimal()"
            class="btn bg-transparent"
            style="margin-left: -40px; z-index: 100; padding: 0"
          >
            <i class="mdi mdi-for-btn mdi-close"></i>
          </button>
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="searchAnimal()"
              translate
            >
              SEARCH
            </button>
            <button
              class="btn btn-outline-info"
              type="button"
              (click)="openAddAnimal({})"
              translate
            >
              ANIMAL.ADD
            </button>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" translate>ABQMID</th>
          <th scope="col" translate>REGISTER</th>
          <th scope="col" translate>NAME</th>
          <th scope="col" translate>GENDER</th>
          <th scope="col" translate>CHIP</th>
          <th scope="col" translate>BREED</th>
          <th scope="col" translate>OWNER</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let animal of animals; index as i">
          <td>{{ animal.abqmId }}</td>
          <th scope="row">{{ animal.registerId }}</th>
          <td>
            {{ animal.name }}
          </td>
          <td translate>
            {{ animal.gender }}
          </td>
          <td>
            {{ animal.chip }}
          </td>

          <td translate>{{ animal.breed }}</td>
          <td>{{ animal.owner }}</td>
          <td>
            <button
              class="btn btn-outline-info mdi mdi-for-btn mdi-table-edit"
              type="button"
              (click)="openAddAnimal(animal)"
            ></button>
          </td>
        </tr>
        <tr>
          <td colspan="8" style="vertical-align: middle; text-align: center">
            <div
              class="btn btn-outline-primary"
              (click)="getAllAnimals()"
              *ngIf="count != animals.length"
              translate
            >
              LOAD-MORE
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
