import { Component, OnInit, Input } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FinancialUsersDetailComponent } from "./financial-users-detail/financial-users-detail.component";
import { Utils } from "src/app/util/utils";

@Component({
  selector: "app-financial-users",
  templateUrl: "./financial-users.component.html",
  styleUrls: ["./financial-users.component.scss"],
})
export class FinancialUsersComponent implements OnInit {
  @Input() event: any;
  users: any = [];
  usersPerm: any = null;
  userFilter: string = "";
  isUsersLoading: boolean = true;

  constructor(
    private financial: FinancialService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    if (Object.keys(this.event).length !== 0) {
      // console.log(this.event);
      this.getEventUsersFinancial(this.event._id);
    }
  }

  async getEventUsersFinancial(event: string) {
    this.isUsersLoading = true;
    this.usersPerm = await this.financial.getEventUsersFinancial(event);

    this.users = this.usersPerm.items;
    this.isUsersLoading = false;
  }

  open(user) {
    const modalRef = this.modalService.open(FinancialUsersDetailComponent, {
      size: "lg",
    });
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.event = this.event;
    modalRef.componentInstance.getEventUsersFinancial.subscribe(() => {
      this.getEventUsersFinancial(this.event._id);
    });
  }

  clearUserFilter() {
    this.userFilter = "";
    this.users = this.usersPerm.items;
  }

  filterSearch() {
    this.userFilter = this.userFilter.toUpperCase();
    this.users = this.usersPerm.items.filter((x) => {
      if (this.userFilter.length == 0) return true;
      if (x.customer) {
        return Utils.removeAccent(x.customer.name.toUpperCase()).includes(
          this.userFilter
        );
      }

      return false;
    });
  }

  onListFilterChange(el) {
    switch (el) {
      case "all":
        this.users = this.usersPerm.items;
        break;
      case "debtors":
        this.users = this.usersPerm.items.filter((x) => x.balance < 0);
        break;
      case "remaining":
        this.users = this.usersPerm.items.filter((x) => x.balance > 0);
        break;
      case "clients":
        this.users = this.usersPerm.items.filter((x) => x.customer);
        break;
    }
  }
}
