<div class="modal-header">
  <h4 class="modal-title" translate>STALL-DETAILS</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row pl-3">
    <h5 translate>SCHEDULE-STALL-DATE</h5>
  </div>
  <div class="row mb-2">
    <div class="col-auto">
      <label for="date" translate>SCHEDULE-DATE</label>
      <input
        type="text"
        class="form-control"
        id="date"
        placeholder="XX/XX/XXXX"
        mask="00/00/0000"
        [(ngModel)]="date"
        [disabled]="isLoadingSchedule"
      />
    </div>
    <div class="col-auto">
      <label for="date" translate>SCHEDULE-HOUR</label>
      <input
        type="text"
        [(ngModel)]="hour"
        class="form-control"
        id="hour"
        placeholder="XX:XX"
        mask="00:XX"
        [disabled]="isLoadingSchedule"
      />
    </div>
    <div class="col-auto d-flex" style="align-items: flex-end">
      <button
        class="btn btn-outline-primary btn-sm"
        [disabled]="!hour || !date || isLoadingSchedule"
        (click)="submitScheduleStall()"
        translate
      >
        SCHEDULE
      </button>
    </div>
  </div>
  <div class="row pl-3">
    <h5 translate>ADD-BOOKING</h5>
  </div>
  <div class="row">
    <div class="col-md-4 mb-2">
      <div class="input-group">
        <div class="chip form-control-chip" *ngIf="searchedCustomer">
          <div class="badge badge-secondary">
            {{ this.searchedCustomer }}
            <span class="closebtn" (click)="setCustomerNotSearched()"
              >&times;</span
            >
          </div>
        </div>
        <ng-template #rtCustomer let-r="result" let-t="term">
          <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
          - {{ r.cpforcnpj }}
        </ng-template>
        <div class="inputcontainer" *ngIf="!searchedCustomer">
          <input
            type="text"
            class="form-control"
            [class.is-invalid]="searchCustomerFailed"
            [(ngModel)]="customer"
            [ngbTypeahead]="searchCustomer"
            placeholder="{{ 'CUSTOMER.INSERT' | translate }}"
            [inputFormatter]="formatterCustomer"
            [resultTemplate]="rtCustomer"
            (selectItem)="selectedCustomerItem($event)"
          />
          <div class="icon-container" *ngIf="searchingCustomer">
            <i class="loader"></i>
          </div>
        </div>
      </div>
      <small class="form-text text-muted" translate>CUSTOMER.INSERT</small>
    </div>

    <div class="col-md-3 mb-2">
      <div class="input-group">
        <div class="chip form-control-chip" *ngIf="searchedAnimal">
          <div class="badge badge-secondary">
            {{ this.searchedAnimal }}
            <span class="closebtn" (click)="setAnimalNotSearched()"
              >&times;</span
            >
          </div>
        </div>
        <ng-template #rtHorse let-r="result" let-t="term">
          <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
          - {{ r.registerId }}
        </ng-template>

        <div class="inputcontainer" *ngIf="!searchedAnimal">
          <input
            type="text"
            class="form-control"
            [class.is-invalid]="searchHorseFailed"
            [(ngModel)]="horse"
            [ngbTypeahead]="searchHorse"
            placeholder="{{ 'ANIMAL.INSERT' | translate }}"
            [inputFormatter]="formatterHorse"
            [resultTemplate]="rtHorse"
            (selectItem)="selectedAnimalItem($event)"
          />
          <div class="icon-container" *ngIf="searchingHorse">
            <i class="loader"></i>
          </div>
        </div>
      </div>
      <small class="form-text text-muted" translate>ANIMAL.INSERT</small>
    </div>

    <div class="col-md-3">
      <div class="input-group">
        <input
          type="text"
          [(ngModel)]="coach"
          id="coach"
          class="form-control"
        />
      </div>
      <small class="form-text text-muted" translate>COACH-INSERT</small>
    </div>
    <div class="col-md-2">
      <button
        type="submit"
        class="btn btn-block btn-sm btn-gradient-primary"
        (click)="submitAddStall()"
        [disabled]="!customer || !coach"
        translate
      >
        ADD
      </button>
    </div>
  </div>
  <h5 translate>FILTERS</h5>
  <div class="row">
    <div class="col-md-4">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="Filtrar Baia"
          [(ngModel)]="stallFilter"
          (input)="filterSearch()"
        />
        <button
          *ngIf="stallFilter != ''"
          class="btn bg-transparent"
          style="margin-left: -40px; z-index: 100; padding: 0"
          (click)="clearStallFilter()"
        >
          <i class="mdi mdi-for-btn mdi-close"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row pl-3 d-flex justify-content-between pr-5">
    <h5>{{ "BOOKING-TOTAL" | translate }} {{ customerStalls.length }}</h5>
    <button
      class="btn btn-outline-primary mdi mdi-for-btn mdi-printer"
      (click)="openPrintType(printType)"
    ></button>
  </div>
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th translate>CUSTOMER.SINGLE</th>
          <th translate>ANIMAL.SINGLE</th>
          <th translate>COACH</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngIf="isLoading">
        <tr>
          <td colspan="4" style="text-align: center">
            <app-loading></app-loading>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isLoading && customerStalls.length == 0">
        <tr>
          <td colspan="5" style="text-align: center" translate>
            STALL-RESERVATION.EMPTY
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isLoading">
        <tr *ngFor="let item of customerStalls; let index = index">
          <td>{{ item.customer ? item.customer.name : "-" }}</td>
          <td>{{ item.horse ? item.horse.name : "-" }}</td>
          <td>{{ item.coach ? item.coach : "-" }}</td>
          <td>
            <button
              class="btn btn-outline-primary mdi mdi-for-btn mdi-delete-forever"
              (click)="deleteCustomerStall(item._id)"
            ></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" translate>CHOOSELIST</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body"></div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('landscape')"
      >
        HORIZONTAL
      </button>
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('portrait')"
        translate
      >
        VERTICAL
      </button>
    </div>
  </ng-template>
  <ng-template #printType let-modal>
    <div class="modal-header">
      <h4 class="modal-title" translate>PRINT-TYPE</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body"></div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="openChooseModal(modal, content, 'coach')"
        translate
      >
        ORDER-BY-COACH
      </button>
      <button
        type="button"
        class="btn btn-light"
        (click)="openChooseModal(modal, content, 'customer')"
        translate
      >
        ORDER-BY-CUSTOMER
      </button>
    </div>
  </ng-template>
</div>
