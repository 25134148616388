import { TranslateService } from "angular-intl";

export class Filters {
  constructor(private translateService: TranslateService) {}

  filterPayment(payment) {
    let none;
    let pix;
    let check;
    let bankDeposit;
    let cashBack;
    let money;
    let award;
    let paymentOrder;
    let convertedAward;
    this.translateService
      .get([
        "NONE",
        "PIXVALUE",
        "CHECK",
        "BANK-DEPOSIT",
        "CASH-BACK",
        "MONEY",
        "AWARD",
        "PAYMENT-ORDER",
        "CONVERTED-AWARD",
      ])
      .subscribe((translations) => {
        none = translations["NONE"];
        pix = translations["PIXVALUE"];
        check = translations["CHECK"];
        bankDeposit = translations["BANK-DEPOSIT"];
        cashBack = translations["CASH-BACK"];
        money = translations["MONEY"];
        award = translations["AWARD"];
        paymentOrder = translations["PAYMENT-ORDER"];
        convertedAward = translations["CONVERTED-AWARD"];
      });
    switch (payment) {
      case "NONE":
        return none;
      case "PIX":
        return pix;
      case "NONE":
        return none;
      case "CHECK":
        return check;
      case "DEPOSIT":
        return bankDeposit;
      case "MONEYBACK":
        return cashBack;
      case "MONEY":
        return money;
      case "ORDER":
        return paymentOrder;
      case "AWARD":
        return convertedAward;
      default:
        return none;
    }
  }
}
