<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper content-auth d-flex align-items-center auth">
      <div class="row w-100">
        <div class="col-lg-4 mx-auto">
          <div class="card">
            <div class="card-body">
              <h1 class="text-center">
                <img
                  src="../../../assets/images/hcs-horizontal.png"
                  style="width: 20rem;max-width: 100%;"
                  alt="logo"
                />
              </h1>
              <h1 class="font-weight-light" translate>LOGIN.TITLE</h1>
              <form
                class="pt-3"
                [formGroup]="loginForm"
                (ngSubmit)="onSubmit()"
              >
                <div class="form-group">
                  <label for="login" translate>LOGIN.LOGIN</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    formControlName="login"
                    id="login"
                    placeholder="Login"
                  />

                  <div
                    *ngIf="
                      f.login.invalid && (f.login.dirty || f.login.touched)
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="f.login.errors.required"
                      class="form-text text-danger"
                      translate
                      >LOGIN.LOGIN-NEEDED</small
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="password" translate>PASS</label>
                  <input
                    type="password"
                    id="password"
                    class="form-control form-control-lg"
                    formControlName="password"
                    placeholder="{{ 'PASS' | translate }}"
                  />
                  <div
                    *ngIf="
                      f.password.invalid &&
                      (f.password.dirty || f.password.touched)
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="f.password.errors.required"
                      class="form-text text-danger"
                      translate
                      >PASS-NEEDED
                    </small>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    type="submit"
                    [disabled]="!loginForm.valid || submitted"
                    class="
                      btn btn-block btn-gradient-primary btn-lg
                      font-weight-medium
                      auth-form-btn
                    "
                    translate
                  >
                    {{ submitted ? "LOGIN.VERIFYING" : "LOGIN.SUBMIT" }}
                  </button>
                </div>
                <div class="my-2 d-flex justify-content-end align-items-center">
                  <!-- <div class="form-check">
                    <label class="form-check-label text-muted">
                      <input type="checkbox" class="form-check-input"/>
                      Continuar Logado<i class="input-helper"></i
                    ></label>
                  </div> -->
                  <a
                    [routerLink]="['/forgot']"
                    class="auth-link text-black"
                    translate
                    >LOGIN.FORGOT-PASS</a
                  >
                </div>
                <!-- <div class="mb-2">
                  <button
                    type="button"
                    class="btn btn-block btn-facebook auth-form-btn"
                  >
                    <i class="mdi mdi-facebook mr-2"></i>Connect using facebook
                  </button>
                </div> -->
                <!-- <div class="text-center mt-4 font-weight-light">
                  É administrador e não tem conta?
                  <a [routerLink]="['/register']" class="text-primary"
                    >Registre-se</a
                  >
                </div> -->
              </form>
            </div>
          </div>
          <div class="card mt-5">
            <div class="card-body">
              <span
                class="w-100 text-center d-flex justify-content-center"
                translate
                >LOGIN.NOT-ADMIN</span
              >
              <a
                class="btn btn-primary w-100 mt-3"
                href="https://www.horsecs.com.br"
                translate
                >LOGIN.SITE</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>
