import { Component, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { FinancialService } from "../services/financial.service";

@Component({
  selector: "app-financial",
  templateUrl: "./financial.component.html",
  styleUrls: ["./financial.component.scss"],
})
export class FinancialComponent implements OnInit {
  events = [];
  selectedEvent = null;
  isEventsLoading: boolean = true;
  statistics = null;

  constructor(private auth: AuthService, private financial: FinancialService) {}

  async ngOnInit() {
    await this.loadEvent();
    await this.loadStatistics();
  }

  async loadEvent() {
    this.isEventsLoading = true;
    const infos = await this.auth.getInfos();
    const userEvents: any = await this.auth.getUserEvents(infos.id);
    this.events = [
      ...userEvents.owner,
      ...userEvents.admin.map((x) => x.event),
    ].reverse();
    this.selectedEvent = this.events[0];
    if (this.getSelectedEvent()) {
      let found = this.events.find((x) => x._id == this.getSelectedEvent()._id);
      this.selectedEvent = found ? found : this.selectedEvent;
    }

    this.isEventsLoading = false;
  }

  async loadStatistics() {
    localStorage.setItem("selectedEvent", JSON.stringify(this.selectedEvent));
    this.statistics = await this.financial.getEventStatistics(
      this.selectedEvent._id
    );
  }
  onSelectedChange() {
    this.loadStatistics();
  }

  getSelectedEvent() {
    return JSON.parse(localStorage.getItem("selectedEvent"));
  }
}
