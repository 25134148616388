<ng-container *ngFor="let cDay of competitionDays; let i = index">
  <div class="card mb-3">
    <div class="card-body">
      <h3 translate *ngIf="i == 0">CATEGORIES</h3>
      <h3 translate *ngIf="i == 1">FINISHED-COMPETITIONS</h3>
      <div class="row pt-3 mx-2" *ngFor="let comp of cDay">
        <div class="col-md-12" *ngIf="comp.competitions.length > 0">
          <h3>{{ comp.day | date: "EEEE dd/MM" | titlecase }}</h3>
        </div>

        <div class="col-md-3 pt-2" *ngFor="let item of comp.competitions">
          <div class="btn-group d-flex h-100">
            <button
              type="button"
              (click)="openCategory(item._id)"
              class="btn w-100 d-flex flex-column align-items-center justify-content-center"
              [ngClass]="
                item.event.activeCompetition == item._id
                  ? 'btn-success'
                  : item.category.isRank
                  ? 'btn-outline-danger'
                  : 'btn-outline-info'
              "
            >
              <span>{{ item.category.name | translateCategory | async }}</span>
              <small class="pt-2">
                <i
                  class="mdi mdi-shuffle"
                  [ngClass]="{ 'text-success': item.isShuffled }"
                ></i>
                <i
                  class="mdi mdi-ticket-account"
                  [ngClass]="{ 'text-success': item.isActiveSubscriptions }"
                ></i>
                <i
                  class="mdi mdi-format-list-bulleted"
                  [ngClass]="{ 'text-success': item.isActiveTakeOffsList }"
                ></i>
                <!-- <i
                class="mdi mdi-radio-tower"
                [ngClass]="{
                  'text-success': item.event.activeCompetition == item._id
                }"
              ></i> -->
                <i
                  class="mdi mdi-close-box"
                  [ngClass]="{ 'text-success': item.isFinished }"
                ></i>
              </small>
              <!-- <small>{{ item.date | date: "EEEE - dd/MM" | titlecase }}</small> -->
            </button>
            <!-- <div class="btn-group" ngbDropdown role="group">
            <button
              class="btn dropdown-toggle-split"
              [ngClass]="
                item.event.activeCompetition == item._id
                  ? 'btn-outline-success'
                  : item.category.isRank
                  ? 'btn-outline-danger'
                  : 'btn-outline-info'
              "
              ngbDropdownToggle
            ></button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button
                (click)="activeCategory(item._id, item.event._id)"
                *ngIf="item.event.activeCompetition != item._id"
                ngbDropdownItem
              >
                Ativar
              </button>
              <button
                (click)="disableCategory(item._id, item.event._id)"
                *ngIf="item.event.activeCompetition == item._id"
                ngbDropdownItem
              >
                Desativar
              </button>
              <button ngbDropdownItem (click)="openCategory(item._id)">
                Editar
              </button>
            </div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
