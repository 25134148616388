import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertTranslateService } from "src/app/services/alert.service";
import { Socket } from "ngx-socket-io";
import { Observable, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { TakeOff } from "src/app/dto/TakeOff";
import { AbqmService } from "src/app/services/abqm.service";
import { EventService } from "src/app/services/event.service";
import { PdfService } from "src/app/services/pdf.service";
import { ValidateSubscription } from "src/app/util/validateSubscription";
import ErrorHandler from "../../util/errorHandler";

@Component({
  selector: "app-event-subscriptions",
  templateUrl: "./event-subscriptions.component.html",
  styleUrls: ["./event-subscriptions.component.scss"],
})
export class EventSubscriptionsComponent implements OnInit {
  @Input() eventParam;
  competitions: any = [];
  insertedCoupon;
  isCouponLoading;
  loadedCompetitions: any = [];
  isCompetitionsLoading = false;
  isCustomerTakeoffsLoading = false;

  searchingCompetitor = false;
  searchCompetitorFailed = false;
  hasSearchedCompetitor = false;
  searchingHorse = false;
  searchingCustomer = false;
  searchCustomerFailed = false;
  hasSearchedCustomer = false;
  searchHorseFailed = false;
  hasSearchedHorse = false;
  competitor;
  horse;
  abqmHorse;
  abqmCompetitor;
  customer;
  searchedCompetitor;
  searchedAnimal;
  searchedCustomer;

  selectedCompetitions = [];
  selectedCustomerTakeoffs = [];
  // selectedPasses = [];
  customerTakeoffs: any = [];
  loadedCustomerTakeoffs: any = [];

  searchingChangeCustomer = false;
  searchChangeCustomerFailed = false;
  hasSearchedChangeCustomer = false;
  changeCustomer;
  isChangeCustomerLoading = false;
  searchedChangeCustomer;
  toggleIsChangeCustomer = -1;
  listedCompetitions = false;
  competitorLineage: any = {};
  horseAbqmSituation: "";
  competitorAbqmSituation: "";
  competitorRestrictions = "";
  horseRestrictions = "";
  isCategoriesLoading = false;
  isCompetitorCore = false;
  isHorseCore = false;
  baseCompetitor;
  baseHorse;

  code = "";
  constructor(
    private _event: EventService,
    private _abqmService: AbqmService,
    private alertService: AlertTranslateService,
    private socket: Socket,
    private pdfService: PdfService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getAutomaticCoupon();
  }

  async listCompetitions() {
    if (this.isCategoriesLoading) return;
    this.competitorRestrictions = "";
    this.horseRestrictions = "";
    this.isCategoriesLoading = true;
    this.competitorLineage = { status_aprovacao: "APROVADO" };
    this.baseHorse = this.horse;
    this.baseCompetitor = this.competitor;

    if (this.competitor.returnedAbqm) {
      if (!this.isCompetitorCore)
        this.competitorRestrictions =
          ValidateSubscription.setAbqmCompetitorRestrictions(this.competitor);
      this.competitor = await this._abqmService.addAbqmCompetitor(
        this.competitor.cpf
      );
      this.baseCompetitor._id = this.competitor._id;
    }
    if (this.horse.returnedAbqm) {
      if (!this.isHorseCore)
        this.horseRestrictions = ValidateSubscription.setAbqmHorseRestrictions(
          this.horse
        );
      this.horse = await this._abqmService.addAbqmAnimal(this.horse.abqmId);
      this.baseHorse._id = this.horse._id;
    }
    if (
      !this.isCompetitorCore &&
      !this.isHorseCore &&
      this.horse.returnedAbqm &&
      this.competitor.returnedAbqm
    ) {
      this.competitorLineage = await this._abqmService.verifyCompetitorLineage(
        this.horse.registerId,
        this.competitor.abqmId
      );
    }
    if (this.competitorRestrictions == "" && this.horseRestrictions == "") {
      await this.loadCompetitions();
      this.listedCompetitions = true;
    }

    this.isCategoriesLoading = false;
    this.reset();
  }

  formatterCompetitor = (x: { name: string }) => x.name;

  searchCompetitor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searchingCompetitor = true)),
      switchMap((term) => {
        if (term.length < 3) return of([]);
        return this._event.searchCompetitor(term).pipe(
          tap(() => (this.searchCompetitorFailed = false)),
          catchError(() => {
            this.searchCompetitorFailed = true;
            return of([]);
          })
        );
      }),
      tap(
        () => (
          (this.searchingCompetitor = false),
          (this.hasSearchedCompetitor = true)
        )
      )
    );

  formatterHorse = (x: { name: string }) => x.name;

  searchHorse = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searchingHorse = true)),
      switchMap((term) => {
        if (term.length < 3) return of([]);
        return this._event.searchHorse(term).pipe(
          tap(() => (this.searchHorseFailed = false)),
          catchError(() => {
            this.searchHorseFailed = true;
            return of([]);
          })
        );
      }),
      tap(() => ((this.searchingHorse = false), (this.hasSearchedHorse = true)))
    );

  formatterCustomer = (x: { name: string }) => x.name;

  searchCustomer = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searchingCustomer = true)),
      switchMap((term) => {
        if (term.length < 3) return of([]);
        return this._event.searchCustomer(term).pipe(
          tap(() => (this.searchCustomerFailed = false)),
          catchError(() => {
            this.searchCustomerFailed = true;
            return of([]);
          })
        );
      }),
      tap(
        () => (
          (this.searchingCustomer = false), (this.hasSearchedCustomer = true)
        )
      )
    );

  formatterChangeCustomer = (x: { name: string }) => x.name;

  searchChangeCustomer = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searchingChangeCustomer = true)),
      switchMap((term) => {
        if (term.length < 3) return of([]);
        return this._event.searchCustomer(term).pipe(
          tap(() => (this.searchChangeCustomerFailed = false)),
          catchError(() => {
            this.searchChangeCustomerFailed = true;
            return of([]);
          })
        );
      }),
      tap(
        () => (
          (this.searchingChangeCustomer = false),
          (this.hasSearchedChangeCustomer = true)
        )
      )
    );

  setChangeCustomerNotSearched() {
    this.hasSearchedChangeCustomer = false;
    this.changeCustomer = "";
    this.searchedChangeCustomer = null;
  }

  selectedChangeCustomerItem(data) {
    this.searchedChangeCustomer = data.item.name;
  }

  setCompetitorNotSearched() {
    this.hasSearchedCompetitor = false;
    this.competitor = "";
    this.searchedCompetitor = null;
    this.isCompetitorCore = false;
    this.baseCompetitor = "";
  }

  selectedCompetitorItem(data) {
    this.searchedCompetitor = data.item.name;
    this.listedCompetitions = false;
  }

  setAnimalNotSearched() {
    this.hasSearchedHorse = false;
    this.horse = "";
    this.searchedAnimal = null;
    this.isHorseCore = false;
    this.baseHorse = "";
  }

  selectedAnimalItem(data) {
    this.searchedAnimal = data.item.name;
    this.listedCompetitions = false;
  }

  setCustomerNotSearched() {
    this.hasSearchedCustomer = false;
    this.customer = "";
    this.searchedCustomer = null;
  }
  selectedCustomerItem(data) {
    this.listedCompetitions = false;
    this.searchedCustomer = data.item.name;
    this.getCustomerTakeoffs(data.item._id);
  }

  async getAutomaticCoupon() {
    this.isCouponLoading = true;
    const data: any = await this._event.getEventCoupons(this.eventParam._id);

    const coupon = data.filter(
      (x) =>
        this.eventParam.automaticCoupon.filter((ac) => ac === x._id).length > 0
    );
    if (coupon.length > 0) {
      this.code = coupon.map((x) => x.code).join(",");
    }
    this.isCouponLoading = false;
  }

  async getCustomerTakeoffs(customerId: string) {
    this.isCustomerTakeoffsLoading = true;
    this.loadedCustomerTakeoffs = await this._event.getCustomerTakeoffs(
      this.eventParam._id,
      customerId
    );
    this.customerTakeoffs = this.loadedCustomerTakeoffs
      // .map((tk, i) => {
      //   if(tk.pass == 2){

      //   }
      //   if (!tk.hasPass && tk.pass > 1)
      //     tk.competition.price +=
      //       this.loadedCustomerTakeoffs[i - 1].competition.price;
      //   if (!tk.hasPass && tk.pass > 2)
      //     tk.competition.price +=
      //       this.loadedCustomerTakeoffs[i - 2].competition.price;
      //   return tk;
      // })
      .filter((x) => !x.hasPass);
    this.selectedCustomerTakeoffs = this.customerTakeoffs.map((x) => false);
    this.isCustomerTakeoffsLoading = false;
    this.competitions = this.loadedCompetitions;
    this.selectedCompetitions = this.competitions.map((x) => false);
  }

  async loadCompetitions() {
    this.isCompetitionsLoading = true;
    const horseEventTakeoffsData = await this._event.getEventHorseTakeoffs(
      this.eventParam._id,
      this.horse._id
    );
    this.loadedCompetitions = await this._event.getEventCompetitionsPass(
      this.eventParam._id
    );
    this.loadedCompetitions = this.loadedCompetitions.map((comp) => {
      return {
        ...comp,
        rule: ValidateSubscription.defineCompRestrictions(
          comp,
          this.competitor,
          this.horse,
          horseEventTakeoffsData,
          this.customerTakeoffs,
          this.competitorLineage
        ),
      };
    });

    this.competitions = this.loadedCompetitions;
    this.isCompetitionsLoading = false;
    this.selectedCompetitions = this.competitions.map((x) => false);
  }

  selectCompetition(index: number) {
    if (
      this.searchedCustomer == null ||
      this.loadedCompetitions[index].rule.length > 0
    )
      return;
    this.selectedCompetitions[index] = this.selectedCompetitions[index]
      ? false
      : this.competitions[index]._id;
    if (this.selectedCompetitions[index]) {
      if (this.competitions[index].initialCompetition) {
        const initial = this.competitions.findIndex(
          (x) => x._id == this.competitions[index].initialCompetition
        );

        if (initial != -1)
          this.selectedCompetitions[initial] = this.competitions[initial]._id;
      }
      if (this.competitions[index].secondCompetition) {
        const second = this.competitions.findIndex(
          (x) => x._id == this.competitions[index].secondCompetition
        );
        if (second != -1)
          this.selectedCompetitions[second] = this.competitions[second]._id;
      }
    } else {
      if (this.competitions[index].hasPass) {
        if (
          this.competitions[index + 1].initialCompetition ==
            this.competitions[index]._id ||
          this.competitions[index + 1].secondCompetition ==
            this.competitions[index]._id
        )
          this.selectedCompetitions[index + 1] = false;
        if (
          this.competitions[index + 2].initialCompetition ==
          this.competitions[index]._id
        )
          this.selectedCompetitions[index + 2] = false;
      }
    }
  }
  selectCustomerTakeoffs(index: number) {
    this.selectedCustomerTakeoffs[index] = this.selectedCustomerTakeoffs[index]
      ? false
      : this.customerTakeoffs[index]._id;
  }

  async submitSubscription() {
    this.isCustomerTakeoffsLoading = true;
    const comps = this.competitions.filter((x) => x);
    const selectedComps = this.selectedCompetitions.filter((x) => x);

    // Validate Action
    if (!this.validateSubmit(comps)) return;

    for (let i = 0; i < comps.length; i++) {
      const selected = comps.filter((x) => selectedComps[i] == x._id)[0];
      if (selected) await this.submitCompetition(selected);
    }

    this.alertService.success("ADDED-SUBSCRIPTION");

    this.getCustomerTakeoffs(this.customer._id);
  }

  reset() {
    this.horse = this.baseHorse;
    this.competitor = this.baseCompetitor;
  }

  validateSubmit(comps) {
    let selectedIsFinished = false;
    let selectedIsNotActive = false;
    comps.forEach((x) => {
      selectedIsFinished = selectedIsFinished
        ? selectedIsFinished
        : x.isFinished;
      selectedIsNotActive = selectedIsNotActive
        ? selectedIsNotActive
        : !x.isActiveSubscriptions;
    });
    let validateAction = true;
    if (selectedIsFinished || selectedIsNotActive) {
      validateAction = confirm(
        `Deseja confirmar inscrição? uma ou mais categorias constam como: ${
          selectedIsFinished ? "Finalizadas" : ""
        } ${selectedIsFinished && selectedIsNotActive ? "e" : ""} ${
          selectedIsNotActive ? "Inativas" : ""
        } `
      );
    }
    return validateAction;
  }

  async submitCompetition(competition) {
    const takeOff = new TakeOff();
    takeOff.competition = competition._id;

    takeOff.competitor = this.competitor._id;
    takeOff.horse = this.horse._id;
    takeOff.pass = competition.pass;
    takeOff.owner = this.customer._id;
    takeOff.coupon = this.code;
    takeOff.linkedCompetition = null;
    takeOff.isCompetitorCore = this.isCompetitorCore;
    takeOff.isHorseCore = this.isHorseCore;

    try {
      await this._event.addTakeOff(takeOff, this.eventParam._id);
      this.emitTakeOffChange(competition._id);
    } catch (ex) {
      this.alertService.danger(ErrorHandler(ex.error.message));
    }
  }

  async submitDeleteSubscription() {
    if (!this.HasSelectedCustomerTakeoffs) return;
    if (await this.alertService.confirm("CONFIRM-REMOVE-SUBSCRIPTION")) {
      this.isCustomerTakeoffsLoading = true;
      const takeoffs = this.loadedCustomerTakeoffs.filter((x) => x);

      const selectedTakeoffs = this.selectedCustomerTakeoffs.filter((x) => x);
      for (let i = 0; i < takeoffs.length; i++) {
        const selected = takeoffs.filter(
          (x) => selectedTakeoffs[i] == x._id && !x.hasPass
        )[0];
        console.log(selected);
        if (selected) await this.deleteSubscription(selected);
      }
      this.isCustomerTakeoffsLoading = false;
      this.alertService.success("REMOVED-SUBSCRIPTION");
      this.getCustomerTakeoffs(this.customer._id);
    }
  }

  async deleteSubscription(subscription) {
    await this._event.deleteTakeOff(
      subscription.competition.event,
      subscription._id,
      subscription.competition._id
    );
    this.emitTakeOffChange(subscription.competition._id);
  }

  HasSelectedCompetitions() {
    return this.selectedCompetitions.filter((x) => x).length > 0;
  }

  HasSelectedCustomerTakeoffs() {
    return this.selectedCustomerTakeoffs.filter((x) => x).length > 0;
  }

  filteredSelectedCustomerTakeoffs() {
    return this.selectedCustomerTakeoffs.filter((x) => x);
  }

  emitTakeOffChange(competitionId: string) {
    this.socket.emit("get:takeoff", {
      eventId: this.eventParam._id,
      competitionForTakeOff: competitionId,
    });
  }

  async submitChangeCustomer() {
    if (!this.HasSelectedCustomerTakeoffs) return;
    this.isChangeCustomerLoading = true;
    const takeoffs = this.loadedCustomerTakeoffs.filter((x) => x);
    const selectedTakeoffs = this.selectedCustomerTakeoffs.filter((x) => x);
    for (let i = 0; i < takeoffs.length; i++) {
      const selected = takeoffs.filter(
        (x) => selectedTakeoffs[i] == x._id && !x.competition.hasPass
      )[0];

      if (selected) await this.submitChangeUser(selected);
    }
    this.alertService.success("UPDATED-SUBSCRIPTION");
    this.getCustomerTakeoffs(this.customer._id);
    this.toggleIsChangeCustomer = -1;
    this.hasSearchedChangeCustomer = false;
    this.isChangeCustomerLoading = false;
  }

  async submitChangeUser(takeoff) {
    try {
      await this._event.changeTakeoff(
        this.eventParam._id,
        takeoff._id,
        this.changeCustomer._id,
        null,
        null
      );
    } catch (ex) {}
  }

  toggleSelectAllCustomerTakeoffs() {
    if (
      this.filteredSelectedCustomerTakeoffs().filter((x) => x).length ===
      this.customerTakeoffs.length
    ) {
      this.selectedCustomerTakeoffs = this.customerTakeoffs.map((x) => false);
    } else {
      this.selectedCustomerTakeoffs = this.customerTakeoffs.map((x) => x._id);
    }
  }

  submitGenPdfTakeoffs(orientation: string) {
    this.pdfService.genTakeoffs(
      this.eventParam,
      this.customer,
      this.customerTakeoffs.filter(
        (x) =>
          this.filteredSelectedCustomerTakeoffs().filter((y) => y === x._id)
            .length > 0
      ),
      null,
      orientation
    );
  }

  submitGenPdfGTA(orientation: string) {
    this.pdfService.genGTA(
      this.eventParam,
      this.customer,
      this.customerTakeoffs.filter(
        (x) =>
          this.filteredSelectedCustomerTakeoffs().filter((y) => y === x._id)
            .length > 0
      ),
      orientation
    );
  }

  async openChooseModal(content, type) {
    const result = await this.modalService.open(content, { size: "sm" }).result;
    if (type == "GTA") {
      this.submitGenPdfGTA(result);
    } else {
      this.submitGenPdfTakeoffs(result);
    }
  }
}
