<ngb-alert
  type="dark"
  *ngIf="event.progress == 'published' && !isAdmin && !disabledNotify"
  (close)="disabledNotify = true"
  translate
  >EVENT.CATEGORIES.RULES-CHANGE-DENIED</ngb-alert
>
<ngb-accordion>
  <ngb-panel>
    <ng-template ngbPanelTitle>
      <span translate>SEE-SUMMARY</span>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th translate>CATEGORY</th>
              <th translate>EVENT.CATEGORIES.COMPETITOR-MIN-AGE</th>
              <th translate>EVENT.CATEGORIES.COMPETITOR-MAX-AGE</th>
              <th translate>EVENT.CATEGORIES.ANIMAL-MIN-AGE</th>
              <th translate>EVENT.CATEGORIES.ANIMAL-MAX-AGE</th>
              <th translate>EVENT.CATEGORIES.GENDER-RESTRICTION</th>
              <th translate>EVENT.CATEGORIES.LIMIT-LEVEL</th>
              <th translate>EVENT.CATEGORIES.LIMIT-SUBS</th>
              <th translate>EVENT.CATEGORIES.LIMIT-SUBS-PER-USER</th>
              <th translate>EVENT.CATEGORIES.LIMIT-SUBS-STALLION-FEMALE</th>
              <th translate>EVENT.CATEGORIES.ALLOW-STALLION</th>
              <th translate>EVENT.CATEGORIES.CLOSED-DIVISION</th>
              <th translate>EVENT.CATEGORIES.COMPETITOR-MAX-POINTS</th>
              <th translate>>EVENT.CATEGORIES.COMPETITOR-MIN-POINTS</th>
              <th translate>EVENT.CATEGORIES.ALLOW-ONLY-LINEAGE</th>
              <th translate>
                EVENT.CATEGORIES.ALLOW-ONLY-ONE-SUB-PER-COMPETITOR
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let comp of competitions; let i = index">
              <td>
                {{ comp.category.name | translateCategory | async }} -
                {{ comp.date | date: "EEEE dd/MM" | titlecase }}
              </td>
              <td>{{ comp.minAgeCompetitor }}</td>
              <td>{{ comp.maxAgeCompetitor }}</td>
              <td>{{ comp.minAgeAnimal }}</td>
              <td>{{ comp.maxAgeAnimal }}</td>
              <td>{{ comp.minAgeAnimal }}</td>
              <td translate>{{ comp.genderRule }}</td>
              <td translate>{{ comp.skillLevel }}</td>
              <td>{{ comp.subscriptionLimit }}</td>
              <td>{{ comp.subscriptionPerCompetitorLimit }}</td>
              <td>
                {{ comp.stallionAndFemalePerCompetitorSubscriptionLimit }}
              </td>
              <td translate>{{ comp.allowStallion }}</td>
              <td translate>{{ comp.closedDivision }}</td>
              <td translate>{{ comp.maxCompetitorScore }}</td>
              <td>{{ comp.minCompetitorScore }}</td>
              <td translate>{{ comp.isLineage }}</td>
              <td translate>{{ comp.onlyOneSubscriptionPerCompetitor }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th translate>CATEGORY</th>
        <th translate>RULES</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let comp of competitions; let i = index">
        <tr *ngIf="!comp.hasPass">
          <td>
            {{ comp.category.name | translateCategory | async }} <br />
            {{ comp.date | date: "EEEE dd/MM" | titlecase }}
          </td>
          <td>
            <div class="row">
              <div class="col-md-3">
                <label for="min-age-competitor" translate
                  >EVENT.CATEGORIES.COMPETITOR-MIN-AGE</label
                >
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="min-age-competitor"
                    [(ngModel)]="minAgeCompetitor[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <label for="max-age-competitor" translate
                  >EVENT.CATEGORIES.COMPETITOR-MAX-AGE</label
                >
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="max-age-competitor"
                    [(ngModel)]="maxAgeCompetitor[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <label for="min-age-animal" translate
                  >EVENT.CATEGORIES.ANIMAL-MIN-AGE</label
                >
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="min-age-animal"
                    [(ngModel)]="minAgeAnimal[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <label for="max-age-animal" translate
                  >EVENT.CATEGORIES.ANIMAL-MAX-AGE</label
                >
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="max-age-animal"
                    [(ngModel)]="maxAgeAnimal[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label translate>EVENT.CATEGORIES.GENDER-RESTRICTION</label>
                  <select
                    class="form-control form-control-lg"
                    [(ngModel)]="genderRules[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option value="NONE" translate>NONE</option>
                    <option value="MALE" translate>MALE</option>
                    <option value="FEMALE" translate>FEMALEGENDER</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="min-age-animal" translate
                    >EVENT.CATEGORIES.LIMIT-LEVEL</label
                  >
                  <select
                    class="form-control form-control-lg"
                    [(ngModel)]="skillLevel[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option value="NONE" translate>NONE</option>
                    <option value="AMATEUR" translate>AMATEUR</option>
                    <option value="PROFESSIONAL" translate>PROFESSIONAL</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <label for="subscription-limit" translate
                  >EVENT.CATEGORIES.LIMIT-SUBS</label
                >
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="subscription-limit"
                    [(ngModel)]="subscriptionLimit[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <label for="subscription-pcl" translate
                  >EVENT.CATEGORIES.LIMIT-SUBS-PER-USER</label
                >
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="subscription-pcl"
                    [(ngModel)]="subscriptionPerCompetitorLimit[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  />
                </div>
              </div>

              <div class="col-md-3">
                <label for="stallion-female-pcsl" translate
                  >EVENT.CATEGORIES.LIMIT-SUBS-STALLION-FEMALE</label
                >
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="stallion-female-pcsl"
                    [(ngModel)]="
                      stallionAndFemalePerCompetitorSubscriptionLimit[i]
                    "
                    [disabled]="event.progress == 'published' && !isAdmin"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="min-age-animal" translate
                    >EVENT.CATEGORIES.ALLOW-STALLION</label
                  >
                  <select
                    class="form-control form-control-lg"
                    [(ngModel)]="allowStallion[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option value="true" translate>YES</option>
                    <option value="false" translate>NO</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="min-age-animal" translate
                    >EVENT.CATEGORIES.CLOSED-DIVISION</label
                  >
                  <select
                    class="form-control form-control-lg"
                    [(ngModel)]="closedDivision[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option value="true" translate>YES</option>
                    <option value="false" translate>NO</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <label for="max-competition-score" translate
                  >EVENT.CATEGORIES.COMPETITOR-MAX-POINTS</label
                >
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="max-competition-score"
                    [(ngModel)]="maxCompetitorScore[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <label for="min-competition-score" translate
                  >EVENT.CATEGORIES.COMPETITOR-MIN-POINTS</label
                >
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="min-competition-score"
                    [(ngModel)]="minCompetitorScore[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  />
                </div>
              </div>
              <div class="col-md-4" *ngIf="!comp.isRank">
                <div class="form-group">
                  <label translate>EVENT.CATEGORIES.ALLOW-ONLY-LINEAGE</label>
                  <select
                    required
                    class="form-control"
                    [(ngModel)]="isLineage[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option value="true" translate>YES</option>
                    <option value="false" translate>NO</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4" *ngIf="!comp.isRank">
                <div class="form-group">
                  <label translate
                    >EVENT.CATEGORIES.ALLOW-ONLY-ONE-SUB-PER-COMPETITOR</label
                  >
                  <select
                    required
                    class="form-control"
                    [(ngModel)]="onlyOneSubscriptionPerCompetitor[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option value="true" translate>YES</option>
                    <option value="false" translate>NO</option>
                  </select>
                </div>
              </div>
            </div>
          </td>

          <td>
            <button
              class="btn btn-outline-primary"
              (click)="saveRules(i)"
              translate
            >
              SAVE
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
