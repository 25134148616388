<div class="card">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" translate>COMPETITOR.SINGLE</th>
            <th scope="col" translate>ANIMAL.SINGLE</th>
            <th scope="col" translate>TIME-SCORE</th><th scope="col" >Grupo</th>
          </tr>
        </thead>
        <ng-container *ngFor="let pass of takeOffs; let j = index">
          <tr>
            <td colspan="5">
              <b>{{ pass[0] }}ª {{ "TAKEOFF" | translate }}</b>
            </td>
          </tr>
          <tbody
            app-pass
            [competition]="competition"
            [event]="event"
            [selectedCompetitionIndex]="selectedCompetitionIndex"
            [takeOffs]="pass[1]"
            colspan="5"
            [isInDate]="isInDate"
            [isPassedDate]="isPassedDate"
          ></tbody>
        </ng-container>
      </table>
    </div>
  </div>
</div>
