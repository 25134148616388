import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FinancialService } from "src/app/services/financial.service";
import { Utils } from "src/app/util/utils";
import { FinancialAnimalsDetailsComponent } from "../financial-animals/financial-animals-details/financial-animals-details.component";

@Component({
  selector: "app-financial-animals",
  templateUrl: "./financial-animals.component.html",
  styleUrls: ["./financial-animals.component.scss"],
})
export class FinancialAnimalsComponent implements OnInit {
  @Input() event;
  animals: any = [];
  animalsPerm: any = [];
  isAnimalsLoading = true;
  animalFilter: string = "";

  constructor(
    private financial: FinancialService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    if (Object.keys(this.event).length !== 0) {
      // console.log(this.event);
      this.getEventAnimalsFinancial(this.event._id);
    }
  }

  async getEventAnimalsFinancial(event: string) {
    this.isAnimalsLoading = true;
    this.animalsPerm = await this.financial.getEventAnimalsFinancial(event);
    this.animals = this.animalsPerm;
    this.isAnimalsLoading = false;
  }

  clearAnimalFilter() {
    this.animalFilter = "";
    this.animals = this.animalsPerm;
  }

  filterSearch() {
    this.animalFilter = this.animalFilter.toUpperCase();
    this.animals = this.animalsPerm.filter((x) => {
      if (this.animalFilter.length == 0) return true;
      return Utils.removeAccent(x.horse.name.toUpperCase()).includes(
        this.animalFilter
      );
    });
  }

  open(animal) {
    const modalRef = this.modalService.open(FinancialAnimalsDetailsComponent, {
      size: "lg",
    });
    modalRef.componentInstance.animal = animal;
    modalRef.componentInstance.event = this.event;
  }
}
