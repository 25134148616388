<div class="modal-header">
  <h4 class="modal-title" translate>ANIMAL.ADD</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="addAnimalForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="name" translate>NAME</label>
          <input
            type="text"
            formControlName="name"
            id="name"
            class="form-control"
          />
          <div *ngIf="submitted && f.name.invalid" class="alert alert-danger">
            <small
              *ngIf="f.name.errors.required"
              class="form-text text-danger"
              translate
              >NAME-NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="registerId" translate>REGISTER</label>
          <input
            type="text"
            formControlName="registerId"
            id="registerId"
            class="form-control"
          />
          <div
            *ngIf="submitted && f.registerId.invalid"
            class="alert alert-danger"
          >
            <small
              *ngIf="f.registerId.errors.required"
              class="form-text text-danger"
              translate
              >REGISTER-NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="birth" translate>BIRTH</label>
          <div class="input-group">
            <!-- <input
              class="form-control"
              name="dp"
              id="birth"
              formControlName="birth"
              ngbDatepicker
              #d="ngbDatepicker"
              [minDate]="{ year: 1940, month: 1, day: 1 }"
            /> -->
            <!-- prettier-ignore -->
            <input
              class="form-control"
              name="dp"
              id="birth"
              formControlName="birth"
              mask="00/00/0000"
              [dropSpecialCharacters]="false"
            />
            <!-- <div class="input-group-append">
              <button
                class="btn btn-outline-primary mdi mdi-calendar mdi-for-btn horse-gradient"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div> -->
            <div
              *ngIf="submitted && f.birth.invalid"
              class="alert alert-danger"
            >
              <small
                *ngIf="f.birth.errors.required"
                class="form-text text-danger"
                translate
                >BIRTH-NEEDED</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="gender" translate>GENDER</label>
          <select class="form-control" formControlName="gender" id="gender">
            <option value="" selected disabled translate>SELECT-ONE</option>
            <option value="STALLION" translate>STALLION</option>
            <option value="GELDING" translate>GELDING</option>
            <option value="FEMALE" translate>FEMALE</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="breed" translate>BREED</label>
          <select class="form-control" formControlName="breed" id="breed">
            <option value="" selected disabled translate>SELECT-ONE</option>
            <option value="QUARTER" translate>QUARTER</option>
            <option value="PAINT" translate>PAINT</option>
            <option value="APPALOOSA" translate>APPALOOSA</option>
            <option value="MANGALARGA" translate>MANGALARGA</option>
            <option value="THOROUGHBRED" translate>THOROUGHBRED</option>
            <option value="CRIOLLO" translate>CRIOLLO</option>
            <option value="MIXGELDING" translate>MIXGELDING</option>
            <option value="ARABIAN" translate>ARABIAN</option>
            <option value="MUARES" translate>MUARES</option>
            <option value="UNKNOWN" translate>UNKNOWN</option>
          </select>
          <div *ngIf="submitted && f.breed.invalid" class="alert alert-danger">
            <small
              *ngIf="f.breed.errors.required"
              class="form-text text-danger"
              translate
              >BREED-NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="owner" translate>OWNER</label>
          <input
            formControlName="owner"
            id="owner"
            type="text"
            class="form-control"
          />
          <div *ngIf="submitted && f.owner.invalid" class="alert alert-danger">
            <small
              *ngIf="f.owner.errors.required"
              class="form-text text-danger"
              translate
              >OWNER-NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="city" translate>CITY</label>
          <input
            formControlName="city"
            id="city"
            type="text"
            class="form-control"
          />
          <div *ngIf="submitted && f.city.invalid" class="alert alert-danger">
            <small
              *ngIf="f.city.errors.required"
              class="form-text text-danger"
              translate
              >CITY-NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="state" translate>STATE</label>
          <select class="form-control" formControlName="state" id="state">
            <option value="" selected disabled translate>SELECT-ONE</option>
            <option *ngFor="let uf of ufs" [value]="uf.Sigla">
              {{ uf.Sigla }}
            </option>
          </select>
          <div *ngIf="submitted && f.state.invalid" class="alert alert-danger">
            <small
              *ngIf="f.state.errors.required"
              class="form-text text-danger"
              translate
              >STATE-NEEDED</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-gradient-primary"
      [translate]="{ type: animal.name ? 'Editar' : 'Adicionar' }"
    >
      TOGGLE-ANIMAL
    </button>
  </div>
</form>
