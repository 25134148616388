import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Socket } from "ngx-socket-io";
import { PdfService } from "src/app/services/pdf.service";
import { EventService } from "src/app/services/event.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertTranslateService } from "src/app/services/alert.service";
import { isWithinInterval, isPast, subDays } from "date-fns";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  @Input() public event;
  @Input() private competition;
  @Input() public selectedCompetitionIndex;
  selectedCompetition;

  isActiveSubscriptions: boolean = false;
  isActiveTakeOffsList: boolean = false;
  isActiveCompetition: boolean = false;

  isInDate = false;
  isPassedDate = false;
  isInDateShuffle = false;

  constructor(
    public socket: Socket,
    public _pdf: PdfService,
    public _event: EventService,
    private modalService: NgbModal,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {
    this.getEventInfo();
    this.getCompetition();
  }

  getEventInfo() {
    this.isInDate = isWithinInterval(new Date(), {
      start: this.event.startDate,
      end: this.event.finalDate,
    });
    this.isInDateShuffle = isWithinInterval(new Date(), {
      start: subDays(this.event.startDate, 1),
      end: this.event.finalDate,
    });
    this.isPassedDate = isPast(this.event.finalDate);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedCompetitionIndex) this.getCompetition();
  }

  getCompetition() {
    this.selectedCompetition = this.competition.filter(
      (cat) => cat._id == this.selectedCompetitionIndex
    )[0];
    this.socket.emit("get:event/competition", {
      eventId: this.event._id,
    });
    this.socket.on("competition", (data) => {
      this.competition = data;
      this.selectedCompetition = this.competition.filter(
        (cat) => cat._id == this.selectedCompetitionIndex
      )[0];

      this.isActiveSubscriptions =
        this.selectedCompetition.isActiveSubscriptions;
      this.isActiveTakeOffsList = this.selectedCompetition.isActiveTakeOffsList;
    });
    this.socket.on("event", (data) => {
      this.event = data;
      if (this.event.activeCompetition) {
        if (
          this.event.activeCompetition._id == this.selectedCompetition._id ||
          this.event.activeCompetition.initialCompetition ==
            this.selectedCompetition._id ||
          this.event.activeCompetition.secondCompetition ==
            this.selectedCompetition._id
        ) {
          this.isActiveCompetition = true;
        } else {
          this.isActiveCompetition = false;
        }
      } else {
        this.isActiveCompetition = false;
      }
    });
  }

  async openChooseModal(content, type) {
    const result = await this.modalService.open(content, { size: "sm" }).result;
    this.genList(type, result);
  }

  setActiveSubscriptions() {
    this.socket.emit("post:event/competition/activesubscription", {
      eventId: this.event._id,
      competitionId: this.selectedCompetition._id,
      isActiveSubscriptions: !this.isActiveSubscriptions,
    });
  }

  setActiveTakeOffsList() {
    this.socket.emit("post:event/competition/activetakeoffs", {
      eventId: this.event._id,
      competitionId: this.selectedCompetition._id,
      isActiveTakeOffsList: !this.isActiveTakeOffsList,
    });
  }

  async setActiveCompetition() {
    if (!this.isActiveCompetition) {
      const confirmText = this.event.activeCompetition
        ? `CONFIRM-ACTIVE-DESACTIVE-CATEGORY`
        : "CONFIRM-ACTIVE-CATEGORY";
      if (
        await this.alertService.confirm(
          confirmText,
          this.event.activeCompetition
            ? `${this.event.activeCompetition.category.name} ?`
            : ""
        )
      ) {
        this.socket.emit("post:event/competition/active", {
          eventId: this.event._id,
          competitionId: this.selectedCompetition._id,
        });
      }
    } else {
      if (await this.alertService.confirm("CONFIRM-DEACTIVATE-CATEGORY"))
        this.socket.emit("post:event/competition/disable", {
          eventId: this.event._id,
          competitionId: this.selectedCompetition._id,
        });
    }
  }

  setCompetitionResult() {
    this.socket.emit("post:event/competition/setResult", {
      eventId: this.event._id,
      competitionId: this.selectedCompetition._id,
    });
  }

  async toggleAlert(alert) {
    if (await this.alertService.confirm("CONFIRM-ALERT")) {
      this.socket.emit("post:takeoff/alert", {
        eventId: this.event._id,
        alert,
      });
    }
  }

  doShuffle() {
    this.socket.emit("post:takeoff/shuffle", {
      eventId: this.event._id,
      competitionForTakeOff: this.selectedCompetition._id,
    });
  }

  async genList(type: string, screen) {
    const takeOffs = await this._event.getEveryTakeOffs(
      this.event._id,
      this.selectedCompetition._id
    );
    this._pdf.genCompetitors(
      type,
      takeOffs,
      this.event,
      this.selectedCompetition,
      screen
    );
  }
}
