import { Component, OnInit, Input } from "@angular/core";
import { EventService } from "src/app/services/event.service";
import { AlertTranslateService } from "src/app/services/alert.service";

@Component({
  selector: "app-event-awards",
  templateUrl: "./event-categories-awards.component.html",
  styleUrls: ["./event-categories-awards.component.scss"],
})
export class EventAwardsComponent implements OnInit {
  @Input() event: any;
  awardType: [];
  disabledNotify = false;
  @Input() isAdmin: boolean;
  @Input() competitions: any;

  constructor(
    private _event: EventService,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {
    this.loadCompetitions();
  }

  loadCompetitions() {
    this.awardType = this.competitions.map((comp) =>
      comp.awardType ? comp.awardType : "APPORT"
    );
    for (let i = 0; i < this.competitions.length; i++) {
      if (this.competitions[i].resultType === "CLASSIC") {
        if (!this.competitions[i].awardPerClassified)
          this.competitions[i].awardPerClassified = [];
      }
      if (this.competitions[i].resultType === "DIVISION") {
        if (!this.competitions[i].awardPerClassifiedDivision)
          this.competitions[i].awardPerClassifiedDivision = [];
        for (
          let ii = 0;
          ii < this.competitions[i].classifiedAmountByDivision.length;
          ii++
        ) {
          if (this.competitions[i].awardPerClassifiedDivision[ii] == null)
            this.competitions[i].awardPerClassifiedDivision[ii] = [];

          for (
            let j = 0;
            j < this.competitions[i].classifiedAmountByDivision[ii];
            j++
          ) {
            if (!this.competitions[i].awardPerClassifiedDivision[ii])
              this.competitions[i].awardPerClassifiedDivision[ii].push("");
          }
        }
      }
    }
  }

  async saveAwards(i) {
    try {
      if (this.competitions[i].resultType === "CLASSIC") {
        await this._event.setCompetitionAwards(
          this.event._id,
          this.competitions[i]._id,
          this.competitions[i].awardPercentage,
          this.competitions[i].awardPerClassified,
          null,
          this.awardType[i]
        );
      } else if (this.competitions[i].resultType === "DIVISION") {
        await this._event.setCompetitionAwards(
          this.event._id,
          this.competitions[i]._id,
          this.competitions[i].awardPercentage,
          null,
          this.competitions[i].awardPerClassifiedDivision,
          this.awardType[i]
        );
      }
      this.alertService.success(
        "UPDATED-AWARDS-SETTINGS",
        this.competitions[i].category.name
      );
    } catch (e) {
      this.alertService.danger("ERROR");
    }
  }
}
