<ngb-alert
  type="dark"
  *ngIf="event.progress == 'published' && !isAdmin && !disabledNotify"
  (close)="disabledNotify = true"
  translate
  >EVENT.CATEGORIES.AWARD-CHANGE-DENIED</ngb-alert
>
<ngb-accordion>
  <ngb-panel>
    <ng-template ngbPanelTitle>
      <span translate>SEE-SUMMARY</span>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th translate>CATEGORY</th>
              <th translate>AWARD-TYPE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let comp of competitions; let i = index">
              <td>
                {{ comp.category.name | translateCategory | async }} -
                {{ comp.date | date: "EEEE dd/MM" | titlecase }}
              </td>
              <td>
                <span *ngIf="comp.resultType == 'NONE'" translate>
                  EVENT.CATEGORIES.NO-RANK
                </span>
                <span *ngIf="comp.resultType != 'NONE'" translate>
                  {{ comp.awardType }}
                </span>
              </td>
              <td>
                <span
                  *ngIf="
                    comp.resultType != 'NONE' && comp.awardType == 'APPORT'
                  "
                >
                  {{ comp.awardPercentage }}%
                </span>
                <div *ngIf="comp.resultType != 'NONE'">
                  <div *ngIf="comp.resultType == 'CLASSIC'">
                    {{ comp.awardPerClassified }}
                  </div>
                  <div *ngIf="comp.resultType == 'DIVISION'">
                    <div
                      *ngFor="
                        let item of comp.classifiedAmountByDivision;
                        let place = index
                      "
                    >
                      <span>
                        {{ place + 1 }}ª {{ "DIVISION" | translate }}
                      </span>
                      {{ comp.awardPerClassifiedDivision[i] }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th translate>CATEGORY</th>
        <th translate>AWARD</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let comp of competitions; let i = index">
        <tr *ngIf="!comp.hasPass">
          <td class="d-flex align-items-start">
            {{ comp.category.name | translateCategory | async }} <br />
            {{ comp.date | date: "EEEE dd/MM" | titlecase }}
          </td>

          <td *ngIf="comp.resultType == 'NONE'" translate>
            EVENT.CATEGORIES.NO-RANK
          </td>
          <td *ngIf="comp.resultType != 'NONE'">
            <div class="row" style="min-width: 15rem">
              <div class="col-md-12">
                <select
                  class="form-control"
                  [(ngModel)]="awardType[i]"
                  [disabled]="event.progress == 'published' && !isAdmin"
                >
                  <option value="APPORT" translate>APPORT</option>
                  <option value="FIXED" translate>FIXED</option>
                </select>
              </div>
              <div class="col-md-4 mt-3" *ngIf="awardType[i] == 'APPORT'">
                <div class="form-group">
                  <label for="percentAPPORT + i" translate>
                    EVENT.CATEGORIES.TOTAL-APPORT-PERCENTAGE</label
                  >
                  <div class="input-group">
                    <input
                      id="percentAPPORT + i"
                      type="text"
                      class="form-control"
                      [(ngModel)]="competitions[i].awardPercentage"
                      [disabled]="event.progress == 'published' && !isAdmin"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="competitions[i].resultType == 'CLASSIC'">
              <div class="row mt-3">
                <div
                  class="col-md-3"
                  *ngFor="
                    let item of ' '
                      .repeat(competitions[i].classifiedAmount)
                      .split('');
                    let place = index
                  "
                >
                  <div class="form-group">
                    <label for="{{ place }}"
                      >{{ place + 1 }}º {{ "PLACE" | translate }}</label
                    >
                    <div class="input-group">
                      <div
                        class="input-group-prepend"
                        *ngIf="awardType[i] == 'FIXED'"
                      >
                        <div class="input-group-text">
                          {{ event.currency | currencyPipe }}
                        </div>
                      </div>
                      <input
                        id="{{ place }}"
                        [(ngModel)]="
                          (this.competitions[i]?.awardPerClassified)[place]
                        "
                        [disabled]="event.progress == 'published' && !isAdmin"
                        type="text"
                        class="form-control"
                      />
                      <div
                        class="input-group-append"
                        *ngIf="awardType[i] == 'APPORT'"
                      >
                        <div class="input-group-text">%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="competitions[i].resultType == 'DIVISION'">
              <div
                class="mt-3"
                *ngFor="
                  let division of competitions[i].classifiedAmountByDivision;
                  let ind = index
                "
              >
                <p>{{ ind + 1 }}ª {{ "DIVISION" | translate }}</p>
                <hr />
                <div class="row">
                  <div
                    class="col-md-3"
                    *ngFor="
                      let item of ' '
                        .repeat(competitions[i].classifiedAmountByDivision[ind])
                        .split('');
                      let place = index
                    "
                  >
                    <div class="form-group">
                      <label for="{{ (place + 1) * ind * i }}"
                        >{{ place + 1 }}º {{ "PLACE" | translate }}</label
                      >
                      <div class="input-group">
                        <div
                          class="input-group-prepend"
                          *ngIf="awardType[i] == 'FIXED'"
                        >
                          <div class="input-group-text">
                            {{ event.currency | currencyPipe }}
                          </div>
                        </div>
                        <input
                          id="{{ (place + 1) * ind * i }}"
                          type="text"
                          class="form-control"
                          [(ngModel)]="
                            (this.competitions[i]?.awardPerClassifiedDivision)[
                              ind
                            ][place]
                          "
                          [disabled]="event.progress == 'published' && !isAdmin"
                        />
                        <div
                          class="input-group-append"
                          *ngIf="awardType[i] == 'APPORT'"
                        >
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>

          <td
            *ngIf="
              (event.progress != 'published' || isAdmin) &&
              comp.resultType != 'NONE'
            "
            class="d-flex align-items-end"
          >
            <div
              class="btn btn-outline-primary"
              (click)="saveAwards(i)"
              translate
            >
              SAVE
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
