export class TakeOff {
  competition: String;
  competitor: String;
  horse: String;
  pass: Number;
  owner: Number;
  coupon: String;
  linkedCompetition: String;
  isCompetitorCore: boolean;
  isHorseCore: boolean;
}
