<div class="modal-header">
  <h4 class="modal-title">{{ animal.name }} - Passadas</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Categoria</th>
          <th>Competidor</th>
          <th>Cavalo</th>
          <th>Usuário</th>
          <th>Valor</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isTakeoffsLoading">
          <td colspan="9">
            <div
              class="
                lds-dual-ring lds-dual-ring-black
                d-flex
                justify-content-center
                w-100
              "
            ></div>
          </td>
        </tr>
        <tr *ngFor="let item of animalTakeoffs">
          <td>
            {{ item.competition.category.name | translateCategory | async }} -
            {{ item.competition.pass }}ª Passada -
            {{ item.competition.date | date: "EEEE dd/MM" | titlecase }}
          </td>
          <td>{{ item.competitor.name }}</td>
          <td>{{ item.horse.name }}</td>
          <td *ngIf="item.owner">{{ item.owner.name }}</td>
          <td *ngIf="item.user">{{ item.user.name }}</td>
          <td>{{ item.price }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
