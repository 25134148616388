import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AlertTranslateService } from "src/app/services/alert.service";
import { Router } from "@angular/router";
import ErrorHandler from "../../util/errorHandler";
import { AuthService } from "src/app/services/auth.service";
import { CodeInfo } from "src/app/dto/CodeInfo";

@Component({
  selector: "app-forgot-pass",
  templateUrl: "./forgot-pass.component.html",
  styleUrls: ["./forgot-pass.component.scss"],
})
export class ForgotPassComponent implements OnInit {
  forgotForm: FormGroup;
  resetForm: FormGroup;
  submitted: boolean = false;
  emailSent: boolean = false;
  forgotEmail: String;
  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertTranslateService,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.minLength(10)]],
    });
    this.resetForm = this.formBuilder.group(
      {
        code: ["", [Validators.required]],
        password: ["", [Validators.required, Validators.minLength(8)]],
        confirmPassword: ["", [Validators.required]],
      },
      {
        validator: this.checkIfMatchingPasswords("password", "confirmPassword"),
      }
    );
  }

  checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  get f() {
    return this.forgotForm.controls;
  }

  get ff() {
    return this.resetForm.controls;
  }

  async onSubmit() {
    if (!this.forgotForm.valid) return;
    this.submitted = true;
    const { email } = this.forgotForm.controls;

    try {
      const sendEmail = await this.auth.sendEmail(email.value);
      this.alertService.success("EMAIL-SENT");
      this.submitted = false;
      this.emailSent = true;
      this.forgotForm.controls.email.disable();
      this.forgotEmail = email.value;
    } catch (ex) {
      this.alertService.danger(ErrorHandler(ex.error.message));
      this.submitted = false;
    }
  }

  async onReset() {
    if (!this.resetForm.valid) return;
    this.submitted = true;
    const { code, password } = this.resetForm.controls;
    try {
      const codeInfo = new CodeInfo();
      codeInfo.email = this.forgotEmail;
      codeInfo.code = code.value;
      codeInfo.password = password.value;
      const sendEmail = await this.auth.confirmCode(codeInfo);
      this.alertService.success("RESET-PASSWORD");
      this.submitted = false;
      this.router.navigate(["/login"]);
    } catch (ex) {
      this.alertService.danger(ErrorHandler(ex.error.message));
      this.submitted = false;
    }
  }
}
