<div class="modal-header">
  <h4 class="modal-title">
    {{ competitor.name }} - {{ "TAKEOFFS" | translate }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th translate>CATEGORY</th>
          <th translate>COMPETITOR.SINGLE</th>
          <th translate>ANIMAL.SINGLE</th>
          <th translate>CUSTOMER.SINGLE</th>
          <th translate>VALUE</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isTakeoffsLoading">
          <td colspan="9">
            <div
              class="
                lds-dual-ring lds-dual-ring-black
                d-flex
                justify-content-center
                w-100
              "
            ></div>
          </td>
        </tr>
        <tr *ngFor="let item of competitorTakeoffs">
          <td>
            {{ item.category.name | translateCategory | async }} -
            {{ item.competition.pass }}ª Passada -
            {{ item.competition.date | date: "EEEE dd/MM" | titlecase }}
          </td>
          <td>{{ item.competitor.name }}</td>
          <td>{{ item.horse.name }}</td>
          <td *ngIf="item.customer">{{ item.customer.name }}</td>
          <td *ngIf="item.user">{{ item.user.name }}</td>
          <td>{{ event.currency | currencyPipe }}{{ item.price }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
