<div class="card-body card-body-fill" *ngIf="eventParam">
  <div class="alert alert-info">
    <i class="mdi mdi-alert-circle"
      >{{ "EVENT.INFO.CHANGE" | translate }} <b>11978732213</b></i
    >
  </div>
  <h4 class="card-title" translate>EVENT.INFO.GENERAL</h4>
  <div class="card-description">
    {{ "EVENT.INFO.EMAIL" | translate }}{{ eventParam.email }} <br />
    {{ "EVENT.INFO.PHONE" | translate }}{{ eventParam.phone }} <br />
    {{ "EVENT.INFO.ADDRESS" | translate }}{{ eventParam.address }} <br />
    {{ "EVENT.INFO.DATE" | translate }}
    <span>{{ eventParam.startDate | date: "longDate" }}</span>
    <span *ngIf="eventParam.finalDate !== eventParam.startDate">
      {{ "UNTIL" | translate }}
      {{ eventParam.finalDate | date: "longDate" }}</span
    >
    <div class="row pt-2">
      <div class="col-12" *ngIf="eventParam.ruleUrl">
        <a
          href="{{ eventParam.ruleUrl }}"
          target="_blank"
          class="btn btn-outline-primary mb-3"
          translate
        >
          EVENT.INFO.RULE
        </a>
      </div>
      <div class="col-12" *ngIf="eventParam.folderUrl">
        <a
          href="{{ eventParam.folderUrl }}"
          target="_blank"
          class="btn btn-outline-primary mb-3"
          translate
        >
          EVENT.INFO.FOLDER
        </a>
      </div>
    </div>
  </div>

  <h4 class="card-title">
    {{ "DESCRIPTION" | translate }}
    <small *ngIf="!isDescriptionEditing"
      ><a
        [routerLink]=""
        (click)="showDescription()"
        *ngIf="permission.hasInfo"
        translate
        >EDIT</a
      ></small
    >
  </h4>
  <div *ngIf="isDescriptionEditing">
    <ckeditor
      [editor]="Editor"
      [(ngModel)]="description"
      [config]="editorConfig"
      (ready)="onReady($event)"
    ></ckeditor>
    <button
      class="btn btn-sm-block btn-gradient-primary float-right mt-3"
      (click)="saveDescription()"
      translate
    >
      SAVE
    </button>
  </div>
  <div [innerHTML]="eventParam.description" *ngIf="!isDescriptionEditing"></div>
  <h4 class="card-title mt-5">
    {{ "EVENT.INFO.EXTRA" | translate }}
    <small *ngIf="!isInfosEditing"
      ><a
        [routerLink]=""
        (click)="showInfos()"
        *ngIf="!isDescriptionEditing && permission.hasInfo"
        translate
        >EDIT</a
      ></small
    >
  </h4>
  <div *ngIf="isInfosEditing">
    <ckeditor
      [editor]="Editor"
      [(ngModel)]="infos"
      [config]="editorConfig"
      (ready)="onReady($event)"
    ></ckeditor>
    <button
      class="btn btn-sm-block btn-gradient-primary float-right mt-3"
      (click)="saveInfos()"
      translate
    >
      SAVE
    </button>
  </div>
  <div [innerHTML]="eventParam.infos" *ngIf="!isInfosEditing"></div>
</div>
