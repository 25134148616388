import { Component, OnInit } from "@angular/core";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "angular-intl";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  providers: [NgbDropdownConfig],
})
export class NavbarComponent implements OnInit {
  public sidebarOpened = false;
  user = {};

  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector(".sidebar-offcanvas").classList.add("active");
    } else {
      document.querySelector(".sidebar-offcanvas").classList.remove("active");
    }
  }
  constructor(
    config: NgbDropdownConfig,
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService
  ) {
    config.placement = "bottom-right";
  }
  async ngOnInit() {
    this.user = this.authService.getInfos();
  }
  changeLanguage(lang: string) {
    localStorage.setItem("lang", lang);
    this.translateService.setDefault(`default-${lang}`);
  }
  languages() {
    return environment.langs;
  }

  Logout() {
    if (this.authService.Logout()) this.router.navigate(["/login"]);
  }
}
