import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class FinancialService {
  constructor(private http: HttpClient) {}

  getEventUsersFinancial(event: string) {
    return this.http
      .get(`${environment.apiUrl}/financials/event/${event}/users`)
      .toPromise();
  }

  getEventCompetitorsFinancial(event: string) {
    return this.http
      .get(`${environment.apiUrl}/financials/event/${event}/competitors`)
      .toPromise();
  }

  getEventAnimalsFinancial(event: string) {
    return this.http
      .get(`${environment.apiUrl}/financials/event/${event}/animals`)
      .toPromise();
  }

  setFinancialTakeOffPayment(
    event: string,
    user: string,
    payment: string,
    paymentDate: number,
    value: number,
    takeoffs: Array<any>,
    stalls: Array<any>,
    comments: string
  ) {
    return this.http
      .post(
        `${environment.apiUrl}/financials/event/${event}/user/${user}/payment`,
        {
          payment,
          paymentDate,
          value,
          stalls,
          takeoffs,
          comments,
        }
      )
      .toPromise();
  }

  deleteFinancialTakeoffPayment(eventId: string, paymentId: string) {
    return this.http
      .delete(
        `${environment.apiUrl}/financials/event/${eventId}/user/payment/${paymentId}`
      )
      .toPromise();
  }

  listCustomerTakeoffPayments(event: string, user: string) {
    return this.http
      .get(
        `${environment.apiUrl}/financials/event/${event}/user/${user}/payment`
      )
      .toPromise();
  }

  setFinancialTakeOffPaymentAward(
    event: string,
    user: string,
    payment: string,
    paymentDate: number,
    value: number,
    comments: string
  ) {
    return this.http
      .post(
        `${environment.apiUrl}/financials/event/${event}/user/${user}/paymentaward`,
        {
          payment,
          paymentDate,
          value,
          comments,
        }
      )
      .toPromise();
  }

  deleteFinancialTakeoffPaymentAward(eventId: string, paymentId: string) {
    return this.http
      .delete(
        `${environment.apiUrl}/financials/event/${eventId}/user/paymentaward/${paymentId}`
      )
      .toPromise();
  }

  listCustomerTakeoffPaymentAwards(event: string, user: string) {
    return this.http
      .get(
        `${environment.apiUrl}/financials/event/${event}/user/${user}/paymentaward`
      )
      .toPromise();
  }

  getCompetitorTakeoffs(event: string, competitor: string) {
    return this.http
      .get(
        `${environment.apiUrl}/financials/event/${event}/competitor/${competitor}/takeoffs`
      )
      .toPromise();
  }

  getAnimalTakeoffs(event: string, horse: string) {
    return this.http
      .get(
        `${environment.apiUrl}/financials/event/${event}/horse/${horse}/takeoffs`
      )
      .toPromise();
  }

  getCompetitorTakeoffsByUser(event: string, customer: string) {
    return this.http
      .get(
        `${environment.apiUrl}/financials/event/${event}/user/${customer}/takeoffs`
      )
      .toPromise();
  }

  getUserStalls(event: string, customer: string) {
    return this.http
      .get(
        `${environment.apiUrl}/financials/event/${event}/user/${customer}/stalls`
      )
      .toPromise();
  }

  getEventCategories(event: string) {
    return this.http
      .get(`${environment.apiUrl}/financials/event/${event}/competitions`)
      .toPromise();
  }

  getEventStalls(event: string) {
    return this.http
      .get(`${environment.apiUrl}/financials/event/${event}/stalls`)
      .toPromise();
  }

  setEventAccounting(
    event: string,
    description: string,
    type: string,
    value: number
  ) {
    return this.http
      .post(`${environment.apiUrl}/financials/event/${event}/accounting`, {
        description,
        type,
        value,
      })
      .toPromise();
  }

  setEventAccountingItem(
    event: string,
    accounting: string,
    value: number,
    type: string,
    date: number
  ) {
    return this.http
      .post(
        `${environment.apiUrl}/financials/event/${event}/accountingitem/${accounting}`,
        {
          value,
          type,
          date,
        }
      )
      .toPromise();
  }

  getEventAccountings(event: string) {
    return this.http
      .get(`${environment.apiUrl}/financials/event/${event}/accountings`)
      .toPromise();
  }

  getEventAccountingItems(event: string, accounting: string) {
    return this.http
      .get(
        `${environment.apiUrl}/financials/event/${event}/accountingitems/${accounting}`
      )
      .toPromise();
  }

  deleteEventAccounting(event: string, accountingId: string) {
    return this.http
      .delete(
        `${environment.apiUrl}/financials/event/${event}/accounting/${accountingId}`
      )
      .toPromise();
  }

  deleteEventAccountingItem(event: string, itemId: string) {
    return this.http
      .delete(
        `${environment.apiUrl}/financials/event/${event}/accountingitem/${itemId}`
      )
      .toPromise();
  }

  getDashboardStatistics() {
    return this.http
      .get(`${environment.apiUrl}/financials/dashboard`)
      .toPromise();
  }

  getEventStatistics(event: string) {
    return this.http
      .get(`${environment.apiUrl}/financials/event/${event}/statistics`)
      .toPromise();
  }
}
