import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { LoginUser } from "src/app/dto/LoginUser";
import { AlertTranslateService } from "src/app/services/alert.service";

import ErrorHandler from "../../util/errorHandler";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private alertService: AlertTranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      login: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  async onSubmit() {
    if (!this.loginForm.valid) return;
    this.submitted = true;
    const { login, password } = this.loginForm.controls;
    const user = new LoginUser();
    user.login = login.value;
    user.password = password.value;

    try {
      const login = await this.auth.Login(user);
      this.alertService.success("SUCCESS-LOGGED");
      this.submitted = false;

      localStorage.setItem("currentUser", JSON.stringify(login));
      window.location.href = "/#/dashboard";
    } catch (ex) {
      this.alertService.danger(ErrorHandler(ex.error.message));
      this.submitted = false;
    }
  }
}
