import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "angular-intl";

@Pipe({ name: "safeTranslate" })
export class SafeTranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  async transform(value: any, exponent?: number) {
    const translated: any = await new Promise((resolve, reject) => {
      if (typeof value !== "string") resolve(value);
      if (value.includes(" ")) resolve(value);
      this.translate.get(value).subscribe(
        (res) => resolve(res),
        (err) => resolve(value)
      );
    });
    return translated;
  }
}
