<div class="modal-header">
  <h4 class="modal-title">{{ event.typeGroup.name }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="isLoading">
  <div class="row">
    <div class="col">
      <div class="d-flex justify-content-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
</div>
<div
  class="modal-body"
  *ngIf="
    !isLoading && (!competitions || (competitions && competitions.length == 0))
  "
>
  <div class="row">
    <div class="col">
      <div class="d-flex justify-content-center">
        <span translate>NO-RESULT-AVAILABLE</span>
      </div>
    </div>
  </div>
</div>

<div class="modal-body">
  <div class="form-group">
    <select class="form-control" (change)="onChangeEvent($event)">
      <option
        *ngFor="let event of events; let index = index"
        [value]="index"
        [selected]="index == selectedEventIndex"
      >
        {{ event.stage }}ª {{ "STAGE" | translate }} - {{ event.name }}
      </option>
      <option value="result" translate>RESULTS</option>
    </select>
  </div>
  <div class="row">
    <div class="col-md-6 mt-3" *ngFor="let competition of competitions">
      <h4>{{ (competition | keyvalue)[0].key | translateCategory | async }}</h4>
      <div class="table-responsive" *ngIf="competitions">
        <table class="table">
          <thead>
            <tr>
              <th translate>POSITION</th>
              <th translate>COMPETITOR.SINGLE</th>
              <th translate>ANIMAL.SINGLE</th>
              <th translate>SCORE</th>
              <th translate>GROUPSCORE</th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="
                let takeoff of getObjectEntries(competition);
                let index = index
              "
            >
              <th>{{ index + 1 }}º {{ "PLACE" | translate }}</th>
              <td>{{ takeoff.competitor.name }}</td>
              <td>{{ takeoff.horse.name }}</td>
              <td>{{ takeoff.score }}</td>
              <td>{{ takeoff.rankTypegroupScore }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
    translate
  >
    CLOSE
  </button>
</div>
