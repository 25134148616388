<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Categoria</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let comp of competitions; let i = index">
        <tr *ngIf="!comp.hasPass">
          <td>
            {{ comp.category.name | translateCategory | async }} <br />
            {{ comp.date | date: "EEEE dd/MM" | titlecase }}
          </td>

          <td>
            <button
              class="btn btn-outline-primary"
              (click)="insertParticipation(i)"
            >
              Inserir Participação
            </button>
          </td>
          <td>
            <button
              class="btn btn-outline-primary"
              (click)="finishCompetition(i)"
            >
              Finalizar Competição
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
