import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AlertTranslateService } from "src/app/services/alert.service";
import { AddAnimal } from "src/app/dto/AddAnimal";
import { EventService } from "src/app/services/event.service";
import { AddCompetitor } from "src/app/dto/AddCompetitor";
import errorHandler from "src/app/util/errorHandler";
import {
  NgbActiveModal,
  NgbCalendar,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { Competitor } from "src/app/dto/Competitor";
import * as dateFns from "date-fns";

@Component({
  selector: "app-competitor-add",
  templateUrl: "./competitor-add.component.html",
  styleUrls: ["./competitor-add.component.scss"],
})
export class CompetitorAddComponent implements OnInit {
  private birth;
  private selectedUf;
  addCompetitorForm: FormGroup;
  submitted: boolean = false;

  @Input() competitor: Competitor;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertTranslateService,
    private _event: EventService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.addCompetitorForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      age: ["", [Validators.required]],
      cpf: ["", [Validators.required]],
      gender: ["MALE", [Validators.required]],
      professional: ["false", [Validators.required]],
      email: [""],
    });

    if (this.competitor) {
      // const age = new Date(parseInt(this.competitor.age));

      this.addCompetitorForm.patchValue({
        ...this.competitor,
        // age: {
        //   year: age.getFullYear(),
        //   month: age.getMonth() + 1,
        //   day: age.getDate(),
        // },
        age: dateFns.format(
          new Date(parseInt(this.competitor.age)),
          "dd/MM/yyyy"
        ),
        professional: this.competitor.professional
          ? this.competitor.professional.toString()
          : false,
      });
    }
  }

  async onSubmit() {
    this.submitted = true;
    if (!this.addCompetitorForm.valid) return;

    const { name, age, cpf, gender, professional, email } =
      this.addCompetitorForm.controls;
    // const convertedAge = new Date(
    //   "" + age.value.year + "-" + age.value.month + "-" + age.value.day
    // ).getTime();
    const convertedAge = new Date(
      `${age.value.split("/")[1]}-${age.value.split("/")[0]}-${
        age.value.split("/")[2]
      }`
    ).getTime();
    try {
      let competitor: Competitor = new Competitor();
      competitor = {
        name: name.value,
        age: convertedAge,
        cpf: cpf.value,
        gender: gender.value,
        professional: professional.value == "true" ? true : false,
        email: email.value,
      };

      if (this.competitor.name) {
        competitor._id = this.competitor._id;
        await this._event.editCompetitor(competitor);
        this.alertService.warning("UPDATED-COMPETITOR");
      } else {
        await this._event.addCompetitor(competitor);
        this.alertService.success("ADDED-COMPETITOR");
      }

      this.submitted = false;
      this.addCompetitorForm.reset();
      this.activeModal.close();
    } catch (ex) {
      this.alertService.danger(errorHandler(ex.error.message));
      this.submitted = false;
    }
  }

  get f() {
    return this.addCompetitorForm.controls;
  }
}
