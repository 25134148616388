<div class="card-body">
  <h3>Adicionar Usuários</h3>
  <p class="card-description">
    Adicione usuários com privilégios ao seu evento
  </p>
  <div class="row">
    <div class="col-md-9 mb-2">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">@</div>
        </div>
        <input
          type="email"
          class="form-control"
          placeholder="Email"
          [(ngModel)]="username"
        />

        <div class="input-group-append">
          <button
            (click)="setUpdateFalse()"
            *ngIf="isUpdate"
            class="btn btn-outline-primary mdi mdi-for-btn mdi-close"
          ></button>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <button
        type="submit"
        class="btn btn-block btn-sm"
        [ngClass]="isUpdate ? 'btn-outline-secondary' : 'btn-gradient-primary'"
        (click)="submitUser()"
      >
        {{ isUpdate ? "Atualizar" : "Adicionar" }}
      </button>
    </div>
  </div>
  <div class="h4">Selecionar Permissões</div>
  <div class="row">
    <div class="col-md-3 mb-2">
      <div class="custom-control custom-checkbox">
        <input
          class="custom-control-input"
          type="checkbox"
          id="ckbInfo"
          [(ngModel)]="hasInfo"
        /><label class="custom-control-label" for="ckbInfo"
          >Editar Informações e Finanças</label
        >
      </div>
    </div>
    <div class="col-md-3 mb-2">
      <div class="custom-control custom-checkbox">
        <input
          class="custom-control-input"
          type="checkbox"
          id="ckbCategory"
          [(ngModel)]="hasCategory"
        /><label class="custom-control-label" for="ckbCategory"
          >Editar Categorias e Reservas de baias</label
        >
      </div>
    </div>
    <div class="col-md-3 mb-2">
      <div class="custom-control custom-checkbox">
        <input
          class="custom-control-input"
          type="checkbox"
          id="ckbAdmin"
          [(ngModel)]="hasAdmin"
        /><label class="custom-control-label" for="ckbAdmin"
          >Editar Administradores</label
        >
      </div>
    </div>
    <div class="col-md-3 mb-2">
      <div class="custom-control custom-checkbox">
        <input
          class="custom-control-input"
          type="checkbox"
          id="ckbLive"
          [(ngModel)]="hasLive"
        /><label class="custom-control-label" for="ckbLive"
          >Editar Ao Vivo</label
        >
      </div>
    </div>
  </div>
  <hr />
</div>

<div class="card-body">
  <div class="table-responsive">
    <h4 class="card-title">Usuários</h4>
    <p class="card-description">Criador</p>

    <table class="table table-hover mb-4">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Email</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="owner">
          <td>
            {{ owner.name }}
          </td>
          <td>
            {{ owner.email }}
          </td>
          <td class="text-center">Criador</td>
        </tr>
      </tbody>
    </table>
    <p class="card-description">Administradores</p>
    <table class="table table-hover mb-4">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Email</th>
          <th>Info/Finanças</th>
          <th>Categorias/Baias</th>
          <th>Admin</th>
          <th>Ao Vivo</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let event of admin"
          class="form-clickable"
          (click)="editUser(event)"
          [ngClass]="{
            'bg-light': event.user.email == username,
            'text-dark': event.user.email == username
          }"
        >
          <td>
            {{ event.user.name }}
          </td>
          <td>
            {{ event.user.email }}
          </td>
          <td>
            <div
              class="badge badge-pill"
              [ngClass]="event.hasInfo ? 'badge-success' : 'badge-danger'"
            >
              <i
                class="mdi"
                [ngClass]="event.hasInfo ? 'mdi-check' : 'mdi-close'"
              ></i>
            </div>
          </td>
          <td>
            <div
              class="badge badge-pill"
              [ngClass]="event.hasCategory ? 'badge-success' : 'badge-danger'"
            >
              <i
                class="mdi"
                [ngClass]="event.hasCategory ? 'mdi-check' : 'mdi-close'"
              ></i>
            </div>
          </td>
          <td>
            <div
              class="badge badge-pill"
              [ngClass]="event.hasAdmin ? 'badge-success' : 'badge-danger'"
            >
              <i
                class="mdi"
                [ngClass]="event.hasAdmin ? 'mdi-check' : 'mdi-close'"
              ></i>
            </div>
          </td>
          <td>
            <div
              class="badge badge-pill"
              [ngClass]="event.hasLive ? 'badge-success' : 'badge-danger'"
            >
              <i
                class="mdi"
                [ngClass]="event.hasLive ? 'mdi-check' : 'mdi-close'"
              ></i>
            </div>
          </td>
          <td>
            <button
              class="btn btn-outline-primary mdi-for-btn mdi mdi-delete-forever"
              (click)="deleteUser(event.user._id, event.user.email)"
            ></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
