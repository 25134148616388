import { Component, OnInit, Input } from "@angular/core";
import { EventService } from "src/app/services/event.service";
import { AlertTranslateService } from "src/app/services/alert.service";

@Component({
  selector: "app-event-categories-rules",
  templateUrl: "./event-categories-rules.component.html",
  styleUrls: ["./event-categories-rules.component.scss"],
})
export class EventCategoriesRulesComponent implements OnInit {
  @Input() event: any;
  @Input() isAdmin: boolean;
  @Input() competitions: any;
  disabledNotify = false;
  minAgeAnimal = [];
  maxAgeAnimal = [];
  minAgeCompetitor = [];
  maxAgeCompetitor = [];
  skillLevel = [];
  genderRules = [];
  subscriptionLimit = [];
  closedDivision = [];
  subscriptionPerCompetitorLimit = [];
  allowStallion = [];
  stallionAndFemalePerCompetitorSubscriptionLimit = [];
  maxCompetitorScore = [];
  minCompetitorScore = [];
  isLineage = [];
  onlyOneSubscriptionPerCompetitor = [];

  constructor(
    private _event: EventService,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {
    this.loadCompetitions();
  }

  async loadCompetitions() {
    for (let i = 0; i < this.competitions.length; i++) {
      this.minAgeAnimal.push(
        this.competitions[i].minAgeAnimal
          ? this.competitions[i].minAgeAnimal
          : 0
      );
      this.maxAgeAnimal.push(
        this.competitions[i].maxAgeAnimal
          ? this.competitions[i].maxAgeAnimal
          : 0
      );
      this.minAgeCompetitor.push(
        this.competitions[i].minAgeCompetitor
          ? this.competitions[i].minAgeCompetitor
          : 0
      );
      this.maxAgeCompetitor.push(
        this.competitions[i].maxAgeCompetitor
          ? this.competitions[i].maxAgeCompetitor
          : 0
      );
      this.skillLevel.push(
        this.competitions[i].skillLevel
          ? this.competitions[i].skillLevel
          : false
      );
      this.genderRules.push(
        this.competitions[i].genderRule
          ? this.competitions[i].genderRule
          : "NONE"
      );
      this.subscriptionLimit.push(
        this.competitions[i].subscriptionLimit
          ? this.competitions[i].subscriptionLimit
          : 0
      );
      this.subscriptionPerCompetitorLimit.push(
        this.competitions[i].subscriptionPerCompetitorLimit
          ? this.competitions[i].subscriptionPerCompetitorLimit
          : 0
      );
      this.allowStallion.push(
        this.competitions[i].allowStallion
          ? this.competitions[i].allowStallion
          : 0
      );
      this.stallionAndFemalePerCompetitorSubscriptionLimit.push(
        this.competitions[i].stallionAndFemalePerCompetitorSubscriptionLimit
          ? this.competitions[i].stallionAndFemalePerCompetitorSubscriptionLimit
          : 0
      );
      this.closedDivision.push(this.competitions[i].closedDivision);
      this.maxCompetitorScore.push(
        this.competitions[i].maxCompetitorScore
          ? this.competitions[i].maxCompetitorScore
          : 0
      );
      this.minCompetitorScore.push(
        this.competitions[i].minCompetitorScore
          ? this.competitions[i].minCompetitorScore
          : 0
      );
      this.isLineage.push(
        this.competitions[i].isLineage ? this.competitions[i].isLineage : false
      );
      this.onlyOneSubscriptionPerCompetitor.push(
        this.competitions[i].onlyOneSubscriptionPerCompetitor
          ? this.competitions[i].onlyOneSubscriptionPerCompetitor
          : false
      );
    }
  }

  async saveRules(i) {
    try {
      await this._event.setCompetitionRules(
        this.event._id,
        this.competitions[i]._id,
        parseInt(this.maxAgeAnimal[i]),
        parseInt(this.minAgeAnimal[i]),
        parseInt(this.maxAgeCompetitor[i]),
        parseInt(this.minAgeCompetitor[i]),
        parseInt(this.subscriptionLimit[i]),
        this.genderRules[i],
        this.skillLevel[i],
        this.closedDivision[i],
        parseInt(this.subscriptionPerCompetitorLimit[i]),
        parseInt(this.stallionAndFemalePerCompetitorSubscriptionLimit[i]),
        this.allowStallion[i],
        parseInt(this.maxCompetitorScore[i]),
        parseInt(this.minCompetitorScore[i]),
        this.isLineage[i],
        this.onlyOneSubscriptionPerCompetitor[i]
      );
      this.alertService.success(
        "UPDATED-RULES-SETTINGS",
        this.competitions[i].category.name
      );
    } catch (e) {
      this.alertService.danger("ERROR");
    }
  }
}
