import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "currencyPipe" })
export class CurrencyPipe implements PipeTransform {
  transform(value: any, exponent?: number): String {
    switch (value ? value : "BRL") {
      case "BRL":
        return "R$";
      case "USD":
        return "$";
      case "PYG":
        return "₲";
    }
  }
}
