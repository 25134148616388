import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService();

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    if (localStorage.getItem("currentUser")) {
      if (helper.isTokenExpired(localStorage.getItem("currentUser"))) {
        this.router.navigate(["/login"]);
        return false;
      } else {
        return true;
      }
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
