<ngb-alert
  type="dark"
  *ngIf="event.progress == 'published' && !isAdmin && !disabledNotify"
  (close)="disabledNotify = true"
  translate
  >EVENT.CATEGORIES.RANK-CHANGE-DENIED</ngb-alert
>
<ngb-accordion>
  <ngb-panel>
    <ng-template ngbPanelTitle>
      <span translate>SEE-SUMMARY</span>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th translate>CATEGORY</th>
              <th translate>AWARD-TYPE</th>
              <th translate>EVENT.CATEGORIES.DIVISION-TIME</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let comp of competitions; let i = index">
              <td>
                {{ comp.category.name | translateCategory | async }} -
                {{ comp.date | date: "EEEE dd/MM" | titlecase }}
              </td>
              <td>
                <span *ngIf="comp.resultType == 'NONE'" translate>NO-RANK</span>
                <span *ngIf="comp.resultType == 'CLASSIC'" translate
                  >BEST-TIME</span
                >
                <span *ngIf="comp.resultType == 'DIVISION'" translate
                  >DIVISION</span
                >
              </td>
              <td>
                {{ comp.divisionRange }}
              </td>
              <td>
                <div *ngIf="comp.resultType != 'NONE'">
                  <div *ngIf="comp.resultType == 'CLASSIC'">
                    {{ comp.classifiedAmount }}
                  </div>
                  <div *ngIf="comp.resultType == 'DIVISION'">
                    {{ comp.classifiedAmountByDivision }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th translate>CATEGORY</th>
        <th translate>RANK</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let comp of competitions; let i = index">
        <tr *ngIf="!comp.hasPass">
          <td>
            {{ comp.category.name | translateCategory | async }} <br />
            {{ comp.date | date: "EEEE dd/MM" | titlecase }}
          </td>
          <td>
            <div class="row" style="min-width: 15rem">
              <div class="col-md-12">
                <select
                  class="form-control"
                  [(ngModel)]="rankType[i]"
                  [disabled]="event.progress == 'published' && !isAdmin"
                >
                  <option value="NONE" translate>NO-RANK</option>
                  <option value="CLASSIC" translate>BEST-TIME</option>
                  <option value="DIVISION" translate>DIVISION</option>
                </select>
              </div>
              <div class="col-md-12" *ngIf="rankType[i] == 'DIVISION'">
                <select
                  class="form-control"
                  [(ngModel)]="division[i].d"
                  [disabled]="event.progress == 'published' && !isAdmin"
                >
                  <option [value]="n" *ngFor="let n of [1, 2, 3, 4, 5]">
                    {{ "UNTIL" | translate }} {{ n }}D
                  </option>
                </select>
              </div>

              <div
                class="col-md-12 d-flex pt-2"
                *ngIf="rankType[i] == 'CLASSIC'"
              >
                <select
                  class="form-control"
                  [(ngModel)]="classicPlaces[i]"
                  [disabled]="event.progress == 'published' && !isAdmin"
                >
                  <option
                    [value]="n"
                    *ngFor="
                      let n of [
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17, 18, 19, 20
                      ]
                    "
                  >
                    1º {{ "PLACE" | translate }}
                    <span *ngIf="n == 1" translate>ONLY</span
                    ><span *ngIf="n != 1"
                      >{{ "TO" | translate }} {{ n }}º
                      {{ "PLACE" | translate }}</span
                    >
                  </option>
                </select>
              </div>
              <div *ngIf="rankType[i] == 'DIVISION'">
                <div
                  class="col-md-12 d-flex pt-2"
                  *ngFor="
                    let items of ' '.repeat(division[i].d).split('');
                    let in = index
                  "
                >
                  <select
                    class="form-control"
                    [(ngModel)]="division[i].places[in]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option
                      [value]="n"
                      *ngFor="
                        let n of [
                          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                          17, 18, 19, 20
                        ]
                      "
                    >
                      1º {{ "PLACE" | translate }}
                      <span *ngIf="n == 1">{{ "ONLY" | translate }}</span
                      ><span *ngIf="n != 1"
                        >ao {{ n }}º {{ "PLACE" | translate }}</span
                      >
                      {{ "FROMFIRST" | translate }} {{ in + 1 }}D
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-12 mt-3" *ngIf="rankType[i] == 'DIVISION'">
                <div class="form-group">
                  <label for="divisionRange" translate>
                    EVENT.CATEGORIES.DIVISION-TIME</label
                  >
                  <div class="input-group">
                    <input
                      id="divisionRange"
                      type="text"
                      class="form-control"
                      [(ngModel)]="divisionRange[i]"
                      [disabled]="event.progress == 'published' && !isAdmin"
                    />
                  </div>
                </div>
              </div>
            </div>
          </td>

          <td *ngIf="event.progress != 'published' || isAdmin">
            <button
              class="btn btn-outline-primary"
              (click)="saveRank(i)"
              translate
            >
              SAVE
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
