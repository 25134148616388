import { Injectable } from "@angular/core";
import { Parser } from "json2csv";

@Injectable({
  providedIn: "root",
})
export class CsvService {
  constructor() {}

  downloadCsv(object: Object, filename: string) {
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(object);

    let blob = new Blob(["\ufeff" + csv], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  csvToJson(csv: string) {
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {
      if (!lines[i]) continue;
      const obj = {};
      const currentline = lines[i].split(",");

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j].replace(/\"/g, "");
      }
      result.push(obj);
    }
    return result;
  }
}
