import { Injectable } from "@angular/core";
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import * as dateFns from "date-fns";

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  readonly DT_FORMAT = "dd/MM/yyyy";

  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      let mdt = dateFns.format(parseFloat(value), this.DT_FORMAT);
    }
    return null;
  }
  format(date: NgbDateStruct): string {
    if (!date) return "";
    let mdt = new Date(date.year, date.month - 1, date.day);
    if (!dateFns.isValid(mdt)) return "";
    return dateFns.format(mdt, this.DT_FORMAT);
  }
}
