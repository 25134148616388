import { Component, Input, OnInit } from "@angular/core";
import { AlertTranslateService } from "src/app/services/alert.service";
import { EventService } from "src/app/services/event.service";

@Component({
  selector: "app-event-categories-settings",
  templateUrl: "./event-categories-settings.component.html",
  styleUrls: ["./event-categories-settings.component.scss"],
})
export class EventCategoriesSettingsComponent implements OnInit {
  @Input() event: any;
  @Input() isAdmin: boolean;
  competitions: any;
  allCompetitions: any;
  disabledNotify = false;
  rankOnlyOne = [];
  selectedIsRankCompetition = [];
  rankedToCompetition = [];
  isRankCompetitions: any = [];
  sumCompetition = [];
  rankType = [];
  isNoRankCompetitions: any = [];
  selectedClassificatoryType: any = [];
  averagePrice: any = [];
  paymentPrice: any = [];
  limitScoreSAT: any = [];
  alwaysSubscriptionAllPass: boolean[] = [];

  constructor(
    private _event: EventService,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {
    this.loadCompetitions();
    this.loadAllIsRankCompetitions();
    this.loadAllIsNoRankCompetitions();
  }

  async loadCompetitions() {
    this.allCompetitions = await this._event.getEventAllCompetitions(
      this.event._id
    );
    this.competitions = this.allCompetitions.filter((x) => !x.hasPass);
    for (let i = 0; i < this.competitions.length; i++) {
      this.rankOnlyOne.push(
        this.competitions[i].rankOnlyOne
          ? this.competitions[i].rankOnlyOne
          : false
      );
      this.selectedIsRankCompetition.push(
        this.competitions[i].rankCompetition
          ? this.competitions[i].rankCompetition
          : null
      );
      this.rankedToCompetition.push(
        this.competitions[i].rankedToCompetition
          ? this.competitions[i].rankedToCompetition
          : 0
      );
      this.sumCompetition.push(
        this.competitions[i].sumCompetition
          ? this.competitions[i].sumCompetition
          : null
      );
      this.rankType.push(
        this.competitions[i].rankType
          ? this.competitions[i].rankType
          : "RANK-BEST"
      );
      this.selectedClassificatoryType.push(
        this.competitions[i].selectedClassificatoryType
          ? this.competitions[i].selectedClassificatoryType
          : null
      );
      this.averagePrice.push(
        this.competitions[i].averagePrice
          ? this.competitions[i].averagePrice
          : null
      );
      let initial = this.allCompetitions.find(
        (x) => x._id == this.competitions[i].initialCompetition
      );
      let second = this.allCompetitions.find(
        (x) => x._id == this.competitions[i].secondCompetition
      );
      this.paymentPrice.push(
        this.competitions[i].price
          ? `${initial ? initial.price + "," : ""}${
              second ? second.price + "," : ""
            }${this.competitions[i].price}`
          : null
      );
      this.limitScoreSAT.push(
        this.competitions[i].limitScoreSAT
          ? this.competitions[i].limitScoreSAT
          : "60"
      );
      this.alwaysSubscriptionAllPass.push(
        this.competitions[i].alwaysSubscriptionAllPass
          ? this.competitions[i].alwaysSubscriptionAllPass
          : false
      );
    }
  }

  async loadAllIsRankCompetitions() {
    this.isRankCompetitions = await this._event.getEventIsRankCompetitions(
      this.event._id
    );
  }

  async loadAllIsNoRankCompetitions() {
    this.isNoRankCompetitions = await this._event.getEventIsNoRankCompetitions(
      this.event._id
    );
  }

  async saveSettings(i) {
    try {
      await this._event.setCompetitionSettings(
        this.event._id,
        this.competitions[i]._id,
        this.rankOnlyOne[i],
        this.selectedIsRankCompetition[i],
        this.rankedToCompetition[i],
        this.sumCompetition[i],
        this.rankType[i],
        this.selectedClassificatoryType[i],
        this.averagePrice[i],
        this.paymentPrice[i],
        this.limitScoreSAT[i],
        this.alwaysSubscriptionAllPass[i]
      );
      this.alertService.success(
        "UPDATED-CATEGORY-SETTINGS",
        this.competitions[i].category.name
      );
    } catch (e) {
      console.log(e);
      this.alertService.danger("ERROR");
    }
  }

  findRankCompetition(item) {
    return this.isRankCompetitions.filter((comp) => comp._id === item)[0];
  }
}
