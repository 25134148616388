<div class="table-responsive" *ngIf="abqmCompetitions.length > 0">
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Id</th>
        <th>Nome</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of abqmCompetitions">
        <td>{{ item.nidProvaEvento }}</td>
        <td>{{ item.cdsModalidade }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Categoria</th>
        <th>Configurações</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let comp of competitions; let i = index">
        <tr *ngIf="!comp.hasPass">
          <td>
            {{ comp.category.name | translateCategory | async }} <br />
            {{ comp.date | date: "EEEE dd/MM" | titlecase }}
          </td>
          <td>
            <div class="col">
              <label for="abqmId">ID ABQM</label>
              <select required class="form-control" [(ngModel)]="abqmId[i]">
                <option
                  [value]="competition.nidProvaEvento"
                  *ngFor="let competition of abqmCompetitions"
                >
                  <span>{{ competition.cdsModalidade }}</span>
                </option>
              </select>
            </div>
          </td>

          <td>
            <button class="btn btn-outline-primary" (click)="saveSettings(i)">
              Salvar
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
