<div class="page-header">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/']" translate>HOME</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>
        EVENT.CREATE
      </li>
    </ol>
  </nav>
</div>
<div class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body card-body-fill">
        <h4 class="card-title" translate>EVENT.DETAILS</h4>
        <p class="card-description" translate>EVENT.CREATE-YOUR</p>
        <form
          class="forms-event"
          [formGroup]="eventForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label for="name" translate>NAME</label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="{{ 'NAME' | translate }}"
              formControlName="name"
            />
            <div
              *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)"
              class="alert alert-danger"
            >
              <small
                *ngIf="f.name.errors.required"
                class="form-text text-danger"
                translate
                >NAME-NEEDED</small
              >
            </div>
          </div>
          <div class="form-group">
            <label for="org" translate>ORGNAME</label>
            <input
              type="text"
              class="form-control"
              id="org"
              placeholder="Organização"
              formControlName="org"
            />
            <div
              *ngIf="f.org.invalid && (f.org.dirty || f.org.touched)"
              class="alert alert-danger"
            >
              <small
                *ngIf="f.org.errors.required"
                class="form-text text-danger"
                translate
                >ORGNAME-NEEDED</small
              >
            </div>
          </div>
          <div class="form-group">
            <label translate>EVENT.LOGO</label>
            <div class="img-block">
              <img
                [src]="logoImage"
                class="img-fluid"
                [alt]="logoUrl"
                (click)="logoFile.click()"
              />
              <input
                type="file"
                accept="image/x-png,image/jpeg"
                formControlName="logoFile"
                #logoFile
                id="logoFile"
                class="file-upload-default"
                (change)="onLogoSelected($event)"
              />
              <div class="upload-label">
                <ngb-progressbar
                  *ngIf="loadingLogoUpload"
                  type="success"
                  [value]="logoPercentage"
                  >{{ logoPercentage }}%</ngb-progressbar
                >
              </div>
            </div>
          </div>
          <div class="form-group">
            <label translate>EVENT.COVER</label>
            <input
              type="file"
              formControlName="coverFile"
              #coverFile
              id="coverFile"
              class="file-upload-default"
              (change)="onCoverSelected($event)"
            />
            <div class="input-group">
              <label
                *ngIf="!loadingCoverUpload"
                for="coverFile"
                class="form-control file-upload-info"
              >
                <span *ngIf="coverUrl">coverUrl</span>
                <span *ngIf="!coverUrl" translate
                  >EVENT.COVERUPLOAD</span
                ></label
              >
              <label
                *ngIf="loadingCoverUpload"
                class="form-control file-upload-info"
                translate
              >
                LOADING...</label
              >
              <span class="input-group-append">
                <button
                  class="file-upload-browse btn btn-gradient-primary btn-append"
                  type="button"
                  (click)="openFileCover()"
                  translate
                >
                  UPLOAD
                </button>
              </span>
            </div>
          </div>
          <div class="form-group">
            <label translate>EVENT.FOLDER</label>
            <input
              type="file"
              formControlName="folderFile"
              #folderFile
              id="folderFile"
              class="file-upload-default"
              (change)="onFolderSelected($event)"
            />
            <div class="input-group">
              <label
                *ngIf="!loadingFolderUpload"
                for="folderFile"
                class="form-control file-upload-info"
              >
                <span *ngIf="folderUrl">folderUrl</span>
                <span *ngIf="!folderUrl" translate>EVENT.FOLDERUPLOAD</span>
              </label>
              <label
                *ngIf="loadingFolderUpload"
                class="form-control file-upload-info"
                translate
              >
                LOADING...</label
              >
              <span class="input-group-append">
                <button
                  class="file-upload-browse btn btn-gradient-primary btn-append"
                  type="button"
                  (click)="openFileFolder()"
                  translate
                >
                  UPLOAD
                </button>
              </span>
            </div>
          </div>

          <div class="form-group">
            <label translate>EVENT.RULE</label>
            <input
              type="file"
              formControlName="ruleFile"
              #ruleFile
              id="ruleFile"
              class="file-upload-default"
              (change)="onRuleSelected($event)"
            />
            <div class="input-group">
              <label
                *ngIf="!loadingRuleUpload"
                for="ruleFile"
                class="form-control file-upload-info"
              >
                <span *ngIf="ruleUrl">ruleUrl</span>
                <span *ngIf="!ruleUrl" translate>EVENT.RULEUPLOAD</span>
              </label>
              <label
                *ngIf="loadingRuleUpload"
                class="form-control file-upload-info"
                translate
              >
                LOADING...</label
              >
              <span class="input-group-append">
                <button
                  class="file-upload-browse btn btn-gradient-primary btn-append"
                  type="button"
                  (click)="openRuleFolder()"
                  translate
                >
                  UPLOAD
                </button>
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="phone" translate>PHONE</label>
            <input
              type="text"
              class="form-control"
              id="phone"
              placeholder="{{ 'PHONE' | translate }}"
              formControlName="phone"
            />
            <div
              *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)"
              class="alert alert-danger"
            >
              <small
                *ngIf="f.phone.errors.required"
                class="form-text text-danger"
                translate
                >PHONE-NEEDED</small
              >
            </div>
          </div>
          <div class="form-group">
            <label for="email" translate>EMAIL</label>
            <input
              type="text"
              class="form-control"
              id="email"
              placeholder="{{ 'EMAIL' | translate }}"
              formControlName="email"
            />
            <div
              *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)"
              class="alert alert-danger"
            >
              <small
                *ngIf="f.email.errors.required"
                class="form-text text-danger"
                translate
                >EMAIL-NEEDED</small
              >
            </div>
          </div>

          <div class="form-group">
            <label for="address" translate>ADDRESS</label>
            <input
              type="text"
              class="form-control"
              id="address"
              placeholder="{{ 'ADDRESS' | translate }}"
              formControlName="address"
              #search
            />
            <div
              *ngIf="
                f.address.invalid && (f.address.dirty || f.address.touched)
              "
              class="alert alert-danger"
            >
              <small
                *ngIf="f.address.errors.required"
                class="form-text text-danger"
                translate
                >ADDRESS-NEEDED</small
              >
            </div>
          </div>
          <div class="form-group">
            <label for="currency" translate>CURRENCY-VALUE</label>
            <select
              class="form-control"
              formControlName="currency"
              id="currency"
            >
              <option value="BRL" selected>BRL</option>
              <option value="PYG">PYG</option>
              <option value="USD">USD</option>
            </select>
          </div>
          <div class="form-group">
            <div class="input-group">
              <input
                name="datepicker"
                class="hidden"
                ngbDatepicker
                #datepicker="ngbDatepicker"
                [autoClose]="'outside'"
                (dateSelect)="onDateSelection($event)"
                [displayMonths]="2"
                [dayTemplate]="t"
                outsideDays="hidden"
                [startDate]="today"
                [markDisabled]="isDisabled"
                [minDate]="today"
              />

              <ng-template #t let-date let-focused="focused">
                <span
                  class="custom-day"
                  [class.focused]="focused"
                  [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)"
                  (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null"
                >
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="startDate" translate>INITIALDATE</label>
                <div class="input-group">
                  <input
                    #dpFromDate
                    class="form-control"
                    placeholder="yyyy-mm-dd"
                    name="dpFromDate"
                    formControlName="startDate"
                  />
                  <div class="input-group-append">
                    <button
                      class="
                        btn btn-outline-primary
                        mdi mdi-calendar mdi-for-btn
                        horse-gradient
                      "
                      (click)="datepicker.toggle()"
                      type="button"
                    ></button>
                  </div>
                </div>

                <div
                  *ngIf="
                    f.startDate.invalid &&
                    (f.startDate.dirty || f.startDate.touched)
                  "
                  class="alert alert-danger"
                >
                  <small
                    *ngIf="f.startDate.errors.required"
                    class="form-text text-danger"
                    translate
                    >INITIALDATE-NEEDED</small
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="finalDate" translate>FINALDATE</label>

                <div class="input-group">
                  <input
                    #dpToDate
                    class="form-control"
                    placeholder="yyyy-mm-dd"
                    name="dpToDate"
                    formControlName="finalDate"
                  />
                  <div class="input-group-append">
                    <button
                      class="
                        btn btn-outline-primary
                        mdi mdi-calendar mdi-for-btn
                        horse-gradient
                      "
                      (click)="datepicker.toggle()"
                      type="button"
                    ></button>
                  </div>
                </div>

                <div
                  *ngIf="
                    f.finalDate.invalid &&
                    (f.finalDate.dirty || f.finalDate.touched)
                  "
                  class="alert alert-danger"
                >
                  <small
                    *ngIf="f.finalDate.errors.required"
                    class="form-text text-danger"
                    translate
                    >FINALDATE-NEEDED</small
                  >
                </div>
              </div>
            </div>
          </div>

          <ng-template
            #t
            let-date
            let-focused="focused"
            let-disabled="disabled"
          >
            <span
              class="custom-day"
              [class.text-muted]="disabled"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>

          <div class="form-group">
            <label for="description" translate>BANKDATA</label>

            <ckeditor
              [editor]="Editor"
              formControlName="description"
              [config]="editorConfig"
              (ready)="onReady($event)"
            ></ckeditor>
            <div
              *ngIf="
                f.description.invalid &&
                (f.description.dirty || f.description.touched)
              "
              class="alert alert-danger"
            >
              <small
                *ngIf="f.description.errors.required"
                class="form-text text-danger"
                translate
                >DESCRIPTION-NEEDED</small
              >
              <small
                *ngIf="f.description.errors.minlength"
                class="form-text text-danger"
                translate
                >DESCRIPTION-CHAR</small
              >
            </div>
          </div>
          <div class="form-group">
            <label for="infos" translate>EXTRA-INFO</label>
            <ckeditor
              [editor]="Editor"
              formControlName="infos"
              [config]="editorConfig"
              (ready)="onReady($event)"
            ></ckeditor>
          </div>
          <button
            type="submit"
            class="btn btn-sm-block btn-gradient-primary mr-2 float-right"
            [ngClass]="{ 'btn-disabled': !this.eventForm.valid }"
            translate
          >
            SEND-TO-REVISION
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
