import {
  Component,
  OnInit,
  Input,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from "@angular/core";
import { Socket } from "ngx-socket-io";
import { EventService } from "src/app/services/event.service";
import { isWithinInterval, isPast } from "date-fns";

@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"],
})
export class CategoryComponent implements OnInit {
  @Input() public competition;
  @Input() private event;
  @Input() private selectedCompetitionIndex;
  public takeOffs: any = [];

  isInDate = false;
  isPassedDate = false;

  constructor(
    private socket: Socket,
    private changeDetector: ChangeDetectorRef,
    private eventService: EventService
  ) {}

  ngOnInit() {
    this.getEventInfo();
    this.loadTakeOffs();
  }
  getEventInfo() {
    this.isInDate = isWithinInterval(new Date(), {
      start: this.event.startDate,
      end: this.event.finalDate,
    });
    this.isPassedDate = isPast(this.event.finalDate);
  }
  async loadTakeOffs() {
    // const tempData = await this.eventService.getEventLiveCompetitionTakeOffs(this.competition._id);
    // this.takeOffs = Object.entries(this.groupBy("pass")(tempData));

    this.socket.emit("get:takeoff", {
      eventId: this.event._id,
      competitionForTakeOff: this.competition._id,
    });

    this.socket.on("takeoffs", (data) => {
      const tks: any = Object.entries(this.groupBy("pass")(data));
      console.log(tks);
      if (JSON.stringify(this.takeOffs) != JSON.stringify(tks))
        this.takeOffs = tks;
    });

    this.changeDetector.detectChanges();
  }

  groupBy(key) {
    return (array) =>
      array.reduce((objectsByKeyValue, obj) => {
        let value = obj[key];

        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});
  }
}
