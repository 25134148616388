<tr *ngFor="let takeOff of takeOffs; let i = index">
  <th scope="row">{{ i + 1 }}</th>
  <td>
    {{ takeOff.competitor.name }}
    <span *ngIf="isAdmin">
      <br />
      {{ takeOff.order }} {{ takeOff._id }}
    </span>
  </td>
  <td>{{ takeOff.horse.name }}</td>
  <td *ngIf="competition.isFinished || isPassedDate">
    {{ takeOff.score ? takeOff.score : 0 }}
  </td>
  <td>
    <div *ngIf="!competition.isFinished && isInDate && !isPassedDate">
      <div class="input-group">
        <div class="input-group-append">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="score[i]"
            (keyup.enter)="setScore(takeOff._id, i)"
            (keyup.arrowdown)="setScore(takeOff._id, i)"
            [ngClass]="{ 'border border-success': isNewScore[i] }"
            #scoreInput
          />

          <button
            type="button"
            class="mdi mdi-for-btn btn"
            [ngClass]="
              takeOff.score
                ? isNewScore[i]
                  ? 'btn-outline-success mdi-arrow-right-bold-circle-outline'
                  : 'btn-outline-dark mdi-arrow-right-bold-circle-outline'
                : 'btn-outline-secondary mdi-checkbox-marked-circle-outline'
            "
            (click)="setScore(takeOff._id, i)"
          ></button>
        </div>
      </div>
    </div>
  </td>
  <td>
    {{takeOff.GroupScore}}/{{takeOff.GroupPosition}}
  </td>
</tr>
