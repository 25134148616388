import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class RankService {
  constructor(private http: HttpClient) {}

  setRank(eventId: string, rankCategoryId: string) {
    return this.http
      .post(`${environment.apiUrl}/event/${eventId}/rank/${rankCategoryId}`, {})
      .toPromise();
  }
}
