import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FinancialService } from 'src/app/services/financial.service';

@Component({
  selector: 'app-financial-animals-details',
  templateUrl: './financial-animals-details.component.html',
  styleUrls: ['./financial-animals-details.component.scss']
})
export class FinancialAnimalsDetailsComponent implements OnInit {

   @Input() event;
  @Input() animal;
  animalTakeoffs;
  isTakeoffsLoading: boolean = true;

  constructor(
    private financial: FinancialService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.listCompetitorTakeoffs();
  }

  async listCompetitorTakeoffs() {
    this.isTakeoffsLoading = true;
    this.animalTakeoffs = await this.financial.getAnimalTakeoffs(
      this.event._id,
      this.animal._id
    );
    this.isTakeoffsLoading = false;
  }

}
