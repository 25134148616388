import { Injectable } from "@angular/core";
import { TranslateService } from "angular-intl";
import { AlertService } from "ngx-alerts";

@Injectable({
  providedIn: "root",
})
export class AlertTranslateService {
  constructor(
    private alertService: AlertService,
    private translate: TranslateService
  ) {}

  success(msg: string, msg2: string = "") {
    this.translate.get(msg).subscribe((res: string) => {
      this.alertService.success(res + msg2);
    });
  }

  danger(msg: string, msg2: string = "", useTranslate = true) {
    if (useTranslate)
      this.translate.get(msg).subscribe((res: string) => {
        this.alertService.danger(res + msg2);
      });
    else this.alertService.danger(msg + msg2);
  }

  translateText(msg: string) {
    return new Promise((resolve, reject) => {
      this.translate.get(msg).subscribe((res: string) => {
        return resolve(res);
      });
    });
  }

  warning(msg: string) {
    this.translate.get(msg).subscribe((res: string) => {
      this.alertService.warning(res);
    });
  }

  async confirm(msg: string, msg2: string = "") {
    const translated = new Promise((resolve, reject) => {
      this.translate.get(msg).subscribe((res: string) => {
        return resolve(res);
      });
    });
    const res: any = await translated;
    return new Promise(function (resolve, reject) {
      let confirmed = confirm(res + msg2);

      return confirmed ? resolve(true) : reject(false);
    });
  }
}
