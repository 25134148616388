<div class="card-body">
  <h3 *ngIf="!eventParam.isFinished" translate>STALL-RESERVATION.TITLE</h3>
  <p *ngIf="!eventParam.isFinished" class="card-description" translate>
    STALL-RESERVATION.MANAGE
  </p>
  <div class="row" *ngIf="!eventParam.isFinished">
    <div class="col-md-2 mb-2">
      <input
        type="text"
        class="form-control"
        placeholder="Digite o tipo de baia"
        [(ngModel)]="stallType"
      />
      <small class="form-text text-muted" translate
        >STALL-RESERVATION.ENTER-TYPE</small
      >
    </div>
    <div class="col-md-2 mb-2">
      <input
        type="text"
        class="form-control"
        placeholder="50"
        [(ngModel)]="price"
      />
      <small class="form-text text-muted" translate
        >STALL-RESERVATION.ENTER-PRICE</small
      >
    </div>

    <div class="col-md-2 mb-2" *ngIf="eventParam != null">
      <select required class="form-control" [(ngModel)]="limitStalls">
        <option selected="selected" value="0" translate>UNLIMITED</option>
        <option
          [value]="n"
          *ngFor="
            let n of [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
              20
            ]
          "
        >
          <span>{{ n }} {{ "BOOKINGS" | translate }}</span>
        </option>
      </select>
      <small class="form-text text-muted" translate>
        STALL-RESERVATION.MAX-QTY-PER-USER
      </small>
    </div>

    <div class="col-md-2 mb-2">
      <input
        type="text"
        class="form-control"
        placeholder="50"
        [(ngModel)]="limitStallsGlobal"
      />
      <small class="form-text text-muted" translate
        >STALL-RESERVATION.ENTER-EVENT-STALL-LIMIT</small
      >
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <div class="input-group">
          <input
            class="form-control"
            name="dp"
            [(ngModel)]="limitToCancel"
            ngbDatepicker
            #d="ngbDatepicker"
            [minDate]="{ year: 2021, month: 1, day: 1 }"
            (click)="d.toggle()"
            readonly
          />
          <div class="input-group-append">
            <button
              class="
                btn btn-outline-primary
                mdi mdi-calendar mdi-for-btn
                horse-gradient
              "
              (click)="d.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </div>
      <small class="form-text text-muted" translate
        >STALL-RESERVATION.ENTER-LIMIT-CANCEL-DATE</small
      >
    </div>

    <div class="col-md-2">
      <button
        type="submit"
        class="btn btn-block btn-sm btn-gradient-primary"
        (click)="submitStall()"
        [disabled]="!price || !stallType"
        translate
      >
        ADD
      </button>
    </div>
  </div>
  <div class="row">
    <div *ngIf="eventParam" class="mt-5 w-100">
      <h4 class="card-title" translate>STALL-RESERVATION.LIST</h4>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th></th>
                <th translate>ACTIVE</th>
                <th translate>STALL-RESERVATION.TYPE</th>
                <th translate>PRICE</th>
                <th translate>STALL-RESERVATION.BOOKINGS-LIMIT</th>
                <th translate>STALL-RESERVATION.EVENT-BOOKINGS-LIMIT</th>
                <th translate>STALL-RESERVATION.CANCEL-LIMIT-DATE</th>

                <th></th>
              </tr>
            </thead>
            <tbody *ngIf="isLoading">
              <tr>
                <td colspan="4" style="text-align: center">
                  <app-loading></app-loading>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!isLoading && stalls.length == 0">
              <tr>
                <td colspan="8" style="text-align: center" translate>
                  STALL-RESERVATION.EMPTY
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!isLoading">
              <tr *ngFor="let item of stalls; let index = index">
                <td>
                  <button
                    class="
                      btn btn-outline-primary
                      mdi mdi-for-btn mdi-table-edit
                    "
                    (click)="openStallsDetailModal(item._id)"
                  ></button>
                </td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="item.isActive"
                      (change)="stallAutomaticCheck($event, item._id)"
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
                <td>
                  {{ item.stallType }}
                </td>
                <td>{{ item.price }}</td>
                <td>
                  <span *ngIf="item.limitStalls > 0"
                    >{{ item.limitStalls }} {{ "BOOKINGS" | translate }}</span
                  >

                  <span *ngIf="item.limitStalls == 0" translate>UNLIMITED</span>
                </td>
                <td>
                  <span *ngIf="item.limitStallsGlobal == 0" translate
                    >UNLIMITED</span
                  >
                  <span *ngIf="item.limitStallsGlobal > 0">{{
                    item.limitStallsGlobal
                  }}</span>
                </td>
                <td>{{ item.limitToCancel | date: "dd/MM/yyyy" }}</td>
                <td>
                  <button
                    class="
                      btn btn-outline-primary
                      mdi mdi-for-btn mdi-delete-forever
                    "
                    (click)="deleteStall(item._id)"
                  ></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
