<a [routerLink]="['/event', params.value.slug]" *ngIf="params"
  ><i class="mdi mdi-arrow-left"></i>{{ params.value.slug }}</a
>
<h1 translate>LIVE.MANAGE</h1>
<div class="row mt-5">
  <div class="col-md-2 mt-3 side-category">
    <nav class="nav d-flex flex-column">
      <a
        *ngIf="event && !event.activeCompetition"
        class="nav-link btn mt-2 btn-outline-dark text-dark"
        translate
        >LIVE.NO-ACTIVE</a
      >
      <a
        *ngIf="event && event.activeCompetition"
        [ngClass]="{
          active:
            tab == 1 &&
            (selectedCompetition._id == event.activeCompetition._id ||
              selectedCompetition._id ==
                event.activeCompetition.initialCompetition ||
              selectedCompetition._id ==
                event.activeCompetition.secondCompetition)
        }"
        class="nav-link btn mt-2 btn-outline-success text-success"
        (click)="openCategory(event.activeCompetition._id)"
      >
        <span *ngIf="event.activeCompetition.category">{{
          event.activeCompetition.category.name | translateCategory | async
        }}</span>

        <br />
        <small *ngIf="event.activeCompetition.category">{{
          event.activeCompetition.date | date: "EEEE - dd/MM" | titlecase
        }}</small>
        <br /><small>{{
          event.activeCompetition.category ? "(Ao Vivo Agora)" : null
        }}</small></a
      >
      <div *ngIf="tab == 1">
        <a
          *ngIf="
            !event.activeCompetition ||
            (selectedCompetition._id != event.activeCompetition._id &&
              selectedCompetition._id !=
                event.activeCompetition.initialCompetition &&
              selectedCompetition._id !=
                event.activeCompetition.secondCompetition)
          "
          class="nav-link btn btn-outline-primary mt-2"
          [ngClass]="{ active: tab == 1 }"
          ><span *ngIf="selectedCompetition.category">{{
            selectedCompetition.category.name | translateCategory | async
          }}</span>

          <br />
          <small *ngIf="selectedCompetition.category">{{
            selectedCompetition.date | date: "EEEE - dd/MM" | titlecase
          }}</small>
          <br /><small>{{
            selectedCompetition.category ? "(Ao Vivo Agora)" : null
          }}</small></a
        >
      </div>
      <a
        class="nav-link btn btn-outline-primary mt-2"
        [ngClass]="{ active: tab == 2 }"
        (click)="tabToggle(2)"
        translate
        >CATEGORIES</a
      >
      <a
        *ngIf="event"
        class="nav-link btn btn-outline-warning mt-2"
        [ngClass]="{ active: event.alert == 'REPAIR' }"
        (click)="toggleAlert(event.alert == 'REPAIR')"
        >{{ event.alert == "REPAIR" ? "Desativar" : "Ativar" }} Reparo</a
      >
    </nav>
  </div>
  <div class="col-md-10 side-menu">
    <app-menu
      *ngIf="tab == 1"
      [event]="event"
      [competition]="competition"
      [selectedCompetitionIndex]="selectedCompetitionIndex"
    ></app-menu>
    <!-- <app-loading *ngIf="isCategoryLoading" class="d-flex justify-content-center"></app-loading> -->
    <app-category
      *ngIf="tab == 1"
      [competition]="selectedCompetition"
      [event]="event"
      [selectedCompetitionIndex]="selectedCompetitionIndex"
    ></app-category>
    <app-categories
      *ngIf="tab == 2 && event && competition"
      [event]="event"
      [competition]="competition"
      (tab)="openCategory($event)"
    ></app-categories>
    <button
      class="btn btn-outline-warning w-100 btn-block"
      (click)="confirmFinishEvent(finishContent)"
      *ngIf="event && !event.isFinished"
      translate
    >
      EVENT.FINISH
    </button>
  </div>
</div>
<ng-template #finishContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" translate>EVENT.FINISH</h4>
    <button type="button" class="close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="dateOfBirth"
        >{{ "EVENT.FINISH-CONFIRM" | translate }}
        <br />
        "{{ event.name }}"
      </label>
      <div class="input-group">
        <input class="form-control" [(ngModel)]="confirmModal" />
      </div>
      <small class="text-danger" *ngIf="wrongName" translate>NAME-WRONG</small>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close(true)"
      translate
    >
      EVENT.FINISH
    </button>
  </div>
</ng-template>
