<h4 class="card-title" translate>AWARD-PAYMENT</h4>
<p class="card-description" translate>ADD-AWARD-PAYMENT</p>
<div class="row">
  <div class="col-md-2">
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        placeholder="00.00"
        [(ngModel)]="value"
      />
    </div>
  </div>
  <div class="col-md-2">
    <select class="form-control" [(ngModel)]="payment">
      <option selected="selected" value="null" disabled translate>
        PAYMENT-METHOD
      </option>
      <option value="PIX" translate>PIXVALUE</option>
      <option value="WITHDRAWN" translate>WITHDRAWN</option>
      <option value="AWARD" translate>CHECK-AWARD</option>
      <option value="DEPOSIT" translate>BANK-DEPOSIT</option>
    </select>
  </div>
  <div class="col-md-2">
    <div class="input-group">
      <input
        class="form-control"
        name="dp"
        [(ngModel)]="paymentDate"
        ngbDatepicker
        #d="ngbDatepicker"
        [minDate]="{ year: 2019, month: 12, day: 1 }"
        (click)="d.toggle()"
        readonly
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary mdi mdi-for-btn mdi-calendar-check"
          (click)="d.toggle()"
          type="button"
        ></button>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <textarea
        style="resize: vertical"
        class="form-control"
        rows="3"
        [(ngModel)]="comments"
      ></textarea>
    </div>
  </div>
  <div class="col-md-2">
    <button
      type="submit"
      class="btn btn-block btn-sm btn-gradient-warning"
      (click)="submitAwardPayment()"
      [disabled]="!value || !payment || !paymentDate"
      translate
    >
      ADD
    </button>
  </div>
</div>
<div class="row mt-4">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th translate>CUSTOMER.SINGLE</th>
            <th translate>PAYMENT-METHOD</th>
            <th translate>DATE</th>
            <th translate>VALUE</th>
            <th translate>COMMENTS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of paymentList">
            <td>{{ item.customer.name }}</td>
            <td>
              {{ filterPayment(item.payment) }}
            </td>
            <td>{{ item.paymentDate | date: "shortDate" }}</td>
            <td>{{ event.currency | currencyPipe }}{{ item.value }}</td>
            <td>{{ item.comments }}</td>
            <td>
              <div
                class="btn btn-outline-info mdi mdi-for-btn mdi-delete"
                (click)="deleteAwardPayment(item._id)"
              ></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
