<div class="page-header" *ngIf="typeGroup">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/']" translate>HOME</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/events']" translate>MY-EVENTS</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ typeGroup.name }}
      </li>
    </ol>
  </nav>
</div>

<div class="row" *ngIf="typeGroup">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body card-body-fill">
        <h3 class="card-title">{{ typeGroup.name }}</h3>
        <p class="card-description" translate>TYTPEGROUP.MANAGE</p>
        <div class="row">
          <div class="col-md-10">
            <select
              *ngIf="!isEventsLoading && events.length > 0"
              (ngModelChange)="selectEvent($event)"
              class="form-control"
              [(ngModel)]="selectedEvent"
              [disabled]="isEventsLoading"
            >
              <option [ngValue]="event" *ngFor="let event of events">
                {{ event.name }}
              </option>
            </select>
            <div
              class="alert alert-warning"
              *ngIf="!isEventsLoading && events.length === 0"
              translate
            >
              TYTPEGROUP.NO-EVENT
            </div>
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-outline-primary btn-sm"
              [disabled]="isEventsLoading || events.length === 0"
              (click)="addEvent()"
              translate
            >
              ADD
            </button>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th style="width: 10%"></th>
                <th style="width: 70%" translate>NAME</th>
                <th style="width: 10%">STAGE</th>
                <th style="width: 10%"></th>
              </tr>
            </thead>
            <tbody *ngIf="!isEventsLoading && typeGroupEvents.length == 0">
              <tr>
                <td colspan="4">TYPEGROUP.NO-EVENT-ADDED</td>
              </tr>
            </tbody>
            <tbody *ngIf="isEventsLoading">
              <tr>
                <td colspan="4" style="text-align: center">
                  <app-loading></app-loading>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!isEventsLoading">
              <tr
                class="p-3"
                *ngFor="let event of typeGroupEvents; let i = index"
              >
                <td>
                  <button
                    class="btn btn-outline-info btn-sm"
                    (click)="changeStage(i, -1)"
                    *ngIf="i > 0"
                  >
                    <i class="mdi mdi-arrow-up"></i>
                  </button>
                  <button
                    class="btn btn-outline-info btn-sm"
                    (click)="changeStage(i, 1)"
                    *ngIf="i < typeGroupEvents.length - 1"
                  >
                    <i class="mdi mdi-arrow-down"></i>
                  </button>
                </td>
                <td>
                  {{ event.name }}
                </td>
                <td>{{ event.stage }}</td>
                <td>
                  <button
                    class="btn btn-outline-danger"
                    (click)="removeEvent(i)"
                  >
                    X
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4 class="card-title mt-3" translate>RANK</h4>

        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col" translate>POSITION</th>
            </tr>
          </thead>
          <tbody *ngIf="!isEventsLoading">
            <tr *ngFor="let rank of ranking; index as i">
              <td>{{ i + 1 }}º {{ "PLACE" | translate }}</td>
              <td>
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="ranking[i].value"
                  max="1000"
                  min="0"
                />
                <small
                  *ngIf="ranking[i].value > 1000"
                  class="text-danger"
                  translate
                  >MAX-VALUE</small
                >
              </td>

              <td>
                <button
                  class="
                    btn btn-outline-primary
                    mdi mdi-for-btn mdi-playlist-remove
                  "
                  (click)="removeRanking(i)"
                ></button>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <button
                  class="btn btn-sm-block w-100"
                  [ngClass]="
                    ranking.length >= 20 ? 'btn-gray' : 'btn-outline-primary'
                  "
                  [disabled]="ranking.length >= 20"
                  (click)="addRanking()"
                >
                  <i class="mdi mdi-playlist-plus"></i>
                  <span translate>{{
                    ranking.length >= 20 ? "POSITION-LIMIT" : "ADD"
                  }}</span>
                </button>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="isEventsLoading">
            <tr>
              <td colspan="2" style="text-align: center">
                <app-loading></app-loading>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          class="btn btn-sm-block btn-gradient-primary mr-2 float-right mt-5"
          (click)="addTypeGroupEvents()"
          [disabled]="
            typeGroupEvents.length == 0 || isEventsLoading || isRank1000()
          "
          translate
        >
          SAVE
        </button>
      </div>
    </div>
  </div>
</div>
