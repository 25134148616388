export class Animal {
  _id?: string;
  name?: string;
  birth?: any;
  registerId?: string;
  gender?: string;
  breed?: string;
  owner?: string;
  city?: string;
  state?: string;
}
