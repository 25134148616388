export default (err) => {
  if (err == "Authentication Failed") return "EMAIL-PASS-INCORRECT";
  if (err == "Cannot read property 'id' of null")
    return "ERROR-HANDLE.USER-ALREADY-EXISTS";
  if (err.includes("E11000")) return "ALREADY-EXISTS";
  // return `O ${err
  //   .slice(err.indexOf(".$") + 2, err.indexOf("_1"))
  //   .replace("registerId", "Registro")} Já existe`;
  if (err == "This stall has users")
    return "ERROR-HANDLE.STALL-ALREADY-RESERVED";
  if (err == "Exceeded Horse Stalls Limit")
    return "ERROR-HANDLE.HORSE-ALREADY-RESERVED";
  // validate subscription
  if (err == "Animal Already Subscribed")
    return "ERROR-HANDLE.HORSE-ALREADY-RESERVED";
  if (err == "Comp, Has More Than")
    return "ERROR-HANDLE.HIPPICAL-YEAR-COMPETITOR-MAX";
  if (err == "Comp. Has Less Than")
    return "ERROR-HANDLE.HIPPICAL-YEAR-COMPETITOR-MIN";
  if (err == "Animal Has More Than")
    return "ERROR-HANDLE.HIPPICAL-YEAR-HORSE-MAX";
  if (err == "Animal Has Less Than")
    return "ERROR-HANDLE.HIPPICAL-YEAR-HORSE-MIN";
  if (err == "Comp. Is Not Professional")
    return "ERROR-HANDLE.COMPETITOR-NOT-PROFESSIONAL";
  if (err == "Comp. Is Not Amateur")
    return "ERROR-HANDLE.COMPETITOR-NOT-AMATEUR";
  if (err == "Comp. Is Not Male") return "ERROR-HANDLE.COMPETITOR-NOT-MALE";
  if (err == "Comp. Is Not Female") return "ERROR-HANDLE.COMPETITOR-NOT-FEMALE";
  if (err == "Subscription Limit Reached")
    return "ERROR-HANDLE.SUB-LIMIT-REACHED";
  if (err == "Subscription Per User Reached")
    return "ERROR-HANDLE.USER-SUB-LIMIT-REACHED";
  if (err == "No Stallions Allowed By This Category")
    return "ERROR-HANDLE.STALLION-NOT-ALLOWED";
  if (err == "Limit of Stallion/Female Reached")
    return "ERROR-HANDLE.LIMIT-STALLION-FEMALE-REACHED";
  if (err == "Comp. More Than Abqm Points")
    return "ERROR-HANDLE.ABQM-SCORE-COMPETITOR-MAX";
  if (err == "Comp. Less Than Abqm Points")
    return "ERROR-HANDLE.ABQM-SCORE-COMPETITOR-MIN";
  if (err == "Comp. Is Not Parent") return "ERROR-HANDLE.GENEALOGY";
  if (err == "Only One Subscription Allowed Per Competitor")
    return "ERROR-HANDLE.ONLY-ONE-SUB";
  if (err == "Unable to find competitor, competition or horse")
    return "UNABLE-FIND-COMP-HORSE";
  if (err == "diedComp") return "ERROR-HANDLE.DIED-COMP";
  if (err == "overdueComp") return "ERROR-HANDLE.OVERDUE-COMP";
  if (err == "notPartnerComp") return "ERROR-HANDLE.NOT-PARTNER-COMP";
  if (err == "suspendedComp") return "ERROR-HANDLE.SUSPENDED-COMP";
  if (err == "notActiveAnimal") return "ERROR-HANDLE.NOT-ACTIVE-ANIMAL";
  if (err == "diedAnimal") return "ERROR-HANDLE.DIED-ANIMAL";
  return "ERROR-HANDLE.ERROR";
};
