import { Component, OnInit, Input } from "@angular/core";
import { EventService } from "src/app/services/event.service";
import { stringify } from "querystring";
import { AlertTranslateService } from "src/app/services/alert.service";

@Component({
  selector: "app-event-ranking",
  templateUrl: "./event-categories-ranking.component.html",
  styleUrls: ["./event-categories-ranking.component.scss"],
})
export class EventRankingComponent implements OnInit {
  @Input()
  event: any;
  @Input() isAdmin: boolean;
  @Input() competitions: any;
  rankType: [];
  division = [];
  classicPlaces = [];
  disabledNotify = false;
  divisionRange = [];

  constructor(
    private _event: EventService,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {
    this.loadCompetitions();
  }

  async loadCompetitions() {
    this.rankType = this.competitions.map((comp) => comp.resultType);
    for (let i = 0; i < this.competitions.length; i++) {
      this.competitions[i].classifiedAmount
        ? (this.classicPlaces[i] = this.competitions[i].classifiedAmount)
        : (this.classicPlaces[i] = 1);
      this.division.push({
        d: this.competitions[i].classifiedAmountByDivision
          ? this.competitions[i].classifiedAmountByDivision.length
          : 1,
        places: this.competitions[i].classifiedAmountByDivision
          ? this.competitions[i].classifiedAmountByDivision
          : [1, 1, 1, 1, 1],
      });
      this.divisionRange.push(
        this.competitions[i].divisionRange
          ? this.competitions[i].divisionRange.join(",")
          : "500,501,501,501,501"
      );
    }
  }

  async saveRank(i) {
    try {
      if (this.rankType[i] == "CLASSIC") {
        await this._event.setCompetitionResult(
          this.event._id,
          this.competitions[i]._id,
          this.rankType[i],
          this.classicPlaces[i],
          null,
          null
        );
      } else if (this.rankType[i] == "DIVISION") {
        await this._event.setCompetitionResult(
          this.event._id,
          this.competitions[i]._id,
          this.rankType[i],
          null,
          this.division[i].places.slice(0, this.division[i].d),
          this.divisionRange[i].replaceAll(" ", "").split(",")
        );
      } else if (this.rankType[i] == "NONE") {
        await this._event.setCompetitionResult(
          this.event._id,
          this.competitions[i]._id,
          this.rankType[i],
          null,
          null,
          null
        );
      }
      this.alertService.success(
        "UPDATED-RANKING-SETTINGS",
        this.competitions[i].category.name
      );
    } catch (e) {
      console.log(e);
      this.alertService.danger("ERROR");
    }
  }
}
