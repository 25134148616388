import { Component, OnInit, Input } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FinancialAccountingsItemsComponent } from "./financial-accountings-items/financial-accountings-items.component";
import { AlertTranslateService } from "src/app/services/alert.service";

@Component({
  selector: "app-financial-accountings",
  templateUrl: "./financial-accountings.component.html",
  styleUrls: ["./financial-accountings.component.scss"],
})
export class FinancialAccountingsComponent implements OnInit {
  @Input() event;
  inputDescription: string;
  selectType: string = "INCOME";
  inputValue: number;
  accountings: any = [];
  accountingsLoading: boolean = false;

  constructor(
    private financial: FinancialService,
    private modalService: NgbModal,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {}

  async submitAccount() {
    await this.financial.setEventAccounting(
      this.event._id,
      this.inputDescription,
      this.selectType,
      this.inputValue
    );
    this.clearFields();
    this.loadAccountings();
  }

  ngOnChanges(): void {
    this.loadAccountings();
  }

  async loadAccountings() {
    this.accountingsLoading = true;
    this.accountings = await this.financial.getEventAccountings(this.event._id);
    this.accountingsLoading = false;
  }

  clearFields() {
    this.inputDescription = null;
    this.inputValue = null;
  }

  async deleteAccounting(accounting: string) {
    if (await this.alertService.confirm("CONFIRM-REMOVE")) {
      this.accountingsLoading = true;
      await this.financial.deleteEventAccounting(this.event._id, accounting);
      this.loadAccountings();
    }
  }

  open(accounting) {
    const modalRef = this.modalService.open(
      FinancialAccountingsItemsComponent,
      {
        size: "lg",
      }
    );
    modalRef.componentInstance.accounting = accounting;
    modalRef.componentInstance.event = this.event._id;
    modalRef.componentInstance.loadAccountings.subscribe(($e) => {
      this.loadAccountings();
    });
  }
}
