import { Component, OnInit, Input } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-financial-categories",
  templateUrl: "./financial-categories.component.html",
  styleUrls: ["./financial-categories.component.scss"],
})
export class FinancialCategoriesComponent implements OnInit {
  @Input() event;
  eventCategories: any = [];
  isCategoriesLoading: boolean = true;
  testHorseTotal = null;

  constructor(private financial: FinancialService) {}

  ngOnInit() {}

  ngOnChanges() {
    this.listCompetitorTakeoffs();
  }

  async listCompetitorTakeoffs() {
    this.isCategoriesLoading = true;
    this.eventCategories = await this.financial.getEventCategories(
      this.event._id
    );
    this.isCategoriesLoading = false;
    this.setTestHorseTotal(this.eventCategories[0].items);
  }

  setTestHorseTotal(categories) {
    let testHorses = categories.filter(
      (item) => item.categoryName.indexOf("Test Horse") > -1
    );
    if (testHorses.length > 0) {
      this.testHorseTotal = {
        categoryName: "Test Horse Total",
        balance: testHorses.map((x) => x.balance).reduce((a, b) => a + b, 0),
        earned: testHorses.map((x) => x.earned).reduce((a, b) => a + b, 0),
        earnedAward: testHorses
          .map((x) => x.earnedAward)
          .reduce((a, b) => a + b, 0),
        subscriptions: testHorses
          .map((x) => x.subscriptions)
          .reduce((a, b) => a + b, 0),
      };
    }
  }
}
