<div class="modal-header">
  <h4 class="modal-title" translate>COMPETITOR.ADD</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="addCompetitorForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="name" translate>NAME</label>
          <input
            type="text"
            formControlName="name"
            id="name"
            class="form-control"
          />
          <div *ngIf="submitted && f.name.invalid" class="alert alert-danger">
            <small
              *ngIf="f.name.errors.required"
              class="form-text text-danger"
              translate
              >NAME-NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cpf" translate>INSERT-CPF</label>
          <input
            type="text"
            formControlName="cpf"
            id="cpf"
            class="form-control"
          />
          <div *ngIf="submitted && f.cpf.invalid" class="alert alert-danger">
            <small
              *ngIf="f.cpf.errors.required"
              class="form-text text-danger"
              translate
              >CPF-NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="age" translate>BIRTH</label>
          <div class="input-group">
            <!-- <input
              class="form-control"
              name="dp"
              id="age"
              formControlName="age"
              ngbDatepicker
              #d="ngbDatepicker"
              [minDate]="{ year: 1940, month: 1, day: 1 }"
              (click)="d.toggle()"
              readonly
            /> -->
            <input
              class="form-control"
              name="dp"
              id="age"
              formControlName="age"
              mask="00/00/0000"
              [dropSpecialCharacters]="false"
            />
            <!-- <div class="input-group-append">
              <button
                class="btn btn-outline-primary mdi mdi-calendar mdi-for-btn horse-gradient"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div> -->
            <div *ngIf="submitted && f.age.invalid" class="alert alert-danger">
              <small
                *ngIf="f.age.errors.required"
                class="form-text text-danger"
                translate
                >BIRTH-NEEDED</small
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="gender" translate>GENDER</label>
          <select class="form-control" formControlName="gender" id="gender">
            <option value="MALE" selected translate>MALE</option>
            <option value="FEMALE" translate>FEMALEGENDER</option>
          </select>
          <div *ngIf="submitted && f.gender.invalid" class="alert alert-danger">
            <small
              *ngIf="f.gender.errors.required"
              class="form-text text-danger"
              translate
              >GENDER-NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="form-check-label" for="professional" translate
            >PROFESSIONAL</label
          >
          <select
            class="form-control"
            formControlName="professional"
            id="professional"
          >
            <option value="false" selected translate>NO</option>
            <option value="true" translate>YES</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="email" translate>EMAIL</label>
          <input
            type="text"
            formControlName="email"
            id="email"
            class="form-control"
          />
          <div *ngIf="submitted && f.email.invalid" class="alert alert-danger">
            <small
              *ngIf="f.email.errors.required"
              class="form-text text-danger"
              translate
              >EMAIL-NEEDED</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-gradient-primary"
      [translate]="{ type: competitor.name ? 'Editar' : 'Adicionar' }"
    >
      TOGGLE-COMPETITOR
    </button>
  </div>
</form>
