import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { EventService } from "src/app/services/event.service";
import { AuthService } from "src/app/services/auth.service";
import { AlertTranslateService } from "src/app/services/alert.service";
import ErrorHandler from "../../util/errorHandler";
import { CsvService } from "src/app/services/csv.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-event-general",
  templateUrl: "./event-general.component.html",
  styleUrls: ["./event-general.component.scss"],
})
export class EventGeneralComponent implements OnInit {
  private isOwner = false;
  private competitions;
  public permissions;
  arenaRepairInterval;
  generalRepairInterval;
  loadedCsv = [];
  mappedCompetitons = [];
  selectedCompetition;
  selectedCsvCompetition;

  @Input() event;
  isAdmin: boolean = false;
  @Output() initEventInfo: EventEmitter<any> = new EventEmitter();

  constructor(
    private _event: EventService,
    private _auth: AuthService,
    private alertService: AlertTranslateService,
    private csvService: CsvService,
    private modalService: NgbModal
  ) {}

  ngOnChanges(): void {
    this.getToken();
    this.getEvent();
    this.getPermissions();
    this.getEventCompetitions();
  }
  getToken() {
    this.isAdmin =
      environment.adminId.filter((adm) => adm == this._auth.decodeToken().id)
        .length > 0;
  }

  ngOnInit() {
    this.isOwner = this.event.owner._id == this._auth.getInfos().id;
    this.arenaRepairInterval = this.event.arenaRepairInterval
      ? this.event.arenaRepairInterval
      : 5;
    this.generalRepairInterval = this.event.generalRepairInterval
      ? this.event.generalRepairInterval
      : 50;
  }

  async getEvent() {
    this.event = await this._event.getEvent(this.event.slug);
  }

  async getEventCompetitions() {
    const ev: any = await this._event.getEventOrderList(this.event._id);
    this.competitions = ev.orderList.filter(
      (competition) => !competition.hasPass
    );
  }

  changeOrder(i: number, j: number) {
    const selected = this.competitions.splice(i, 1)[0];
    this.competitions.splice(j, 0, selected);
  }

  goUp(i: number) {
    if (i > 0) {
      const selected = this.competitions[i];
      const upper = this.competitions[i - 1];
      this.competitions[i] = upper;
      this.competitions[i - 1] = selected;
    }
  }

  goDown(i: number) {
    if (i < this.competitions.length - 1) {
      const selected = this.competitions[i];
      const down = this.competitions[i + 1];
      this.competitions[i] = down;
      this.competitions[i + 1] = selected;
    }
  }

  async saveTakeOffList() {
    let takeoffs = [];
    for (let i = 0; i < this.competitions.length; i++) {
      takeoffs.push(this.competitions[i]._id);
    }
    await this._event.setEventCompetitionOrder(this.event._id, takeoffs);
    this.alertService.success("UPDATED-LIST");
  }

  async getPermissions() {
    this.permissions = await this._event.getEventUserRoles(this.event._id);
  }

  async updateEventGeneral() {
    try {
      await this._event.updateEventGeneral(
        this.event._id,
        this.arenaRepairInterval,
        this.generalRepairInterval
      );
      this.alertService.success("EVENT-CREATED");
    } catch (ex) {
      this.alertService.danger(ErrorHandler(ex.error.message));
    }
  }

  async downloadEventConfig() {
    await this.mapCompetition();
    this.csvService.downloadCsv(this.mappedCompetitons, "dados_evento");
  }

  async mapCompetition() {
    if (this.mappedCompetitons.length > 0) return this.mappedCompetitons;
    let competitions: any = await this._event.getEventCompetitions(
      this.event._id
    );
    this.mappedCompetitons = competitions
      .filter((x) => !x.hasPass)
      .map((x) => ({
        id_categoria: x.category._id,
        categoria: x.category.name,
        passada: x.pass,
        data: new Date(x.date).getTime(),
        permitir_garanhão: x.allowStallion,
        premiacao_por_classificado: x.awardPerClassified,
        premiacao_por_classificado_divisao: x.awardPerClassifiedDivision,
        percentagem_premiacao: x.awardPercentage,
        tipo_premiacao: x.awardType,
        qtd_classificados: x.classifiedAmount,
        qtd_classificados_divisao: x.classifiedAmountByDivision,
        divisao_fechada: x.closedDivision,
        regra_genero: x.genderRule,
        max_idade_animal: x.maxAgeAnimal,
        max_idade_competidor: x.maxAgeCompetitor,
        min_idade_animal: x.minAgeAnimal,
        min_idade_competidor: x.minAgeCompetitor,
        classificar_apenas_um_cavalo: x.rankOnlyOne,
        classificar_para_competicao: x.rankedToCompetition,
        tipo_resultado: x.resultType,
        tipo_classificatoria: x.selectedClassificatoryType,
        limitar_nivel: x.skillLevel,
        limite_de_inscriçoes_garanhao_femea:
          x.stallionAndFemalePerCompetitorSubscriptionLimit,
        limite_inscricoes_geral: x.subscriptionLimit,
        limite_inscricoes_por_competidor: x.subscriptionPerCompetitorLimit,
      }));
  }

  importEventConfig(input: HTMLInputElement, content) {
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const textFromFileLoaded: any = e.target.result;
        const data = this.csvService.csvToJson(textFromFileLoaded);
        this.loadedCsv = JSON.parse(JSON.stringify(data));
        this.importCsvModal(content);
      };

      fileReader.readAsText(fileToRead, "UTF-8");
    }
  }

  async importCsvModal(content) {
    await this.mapCompetition();
    const result = await this.modalService.open(content, { size: "sm" }).result;
  }
}
