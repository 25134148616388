import { Component, Input, OnInit } from "@angular/core";
import * as ufJson from "../../util/uf-list.json";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AlertTranslateService } from "src/app/services/alert.service";
import { Animal } from "src/app/dto/Animal";
import { EventService } from "src/app/services/event.service";
import errorHandler from "src/app/util/errorHandler";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as dateFns from "date-fns";

@Component({
  selector: "app-animal-add",
  templateUrl: "./animal-add.component.html",
  styleUrls: ["./animal-add.component.scss"],
})
export class AnimalAddComponent implements OnInit {
  private birth;
  ufs: any = ufJson;
  private selectedUf;
  addAnimalForm: FormGroup;
  submitted: boolean = false;

  @Input() animal: Animal;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertTranslateService,
    private _event: EventService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.ufs = this.ufs.default;
    this.addAnimalForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      registerId: [""],
      birth: ["", [Validators.required]],
      gender: ["", [Validators.required]],
      breed: ["", [Validators.required]],
      owner: ["", [Validators.required]],
      city: ["", [Validators.required]],
      state: ["", [Validators.required]],
    });

    if (this.animal) {
      // const birth = new Date(parseInt(this.animal.birth));
      this.addAnimalForm.patchValue({
        ...this.animal,
        // birth: {
        //   year: birth.getFullYear(),
        //   month: birth.getMonth() + 1,
        //   day: birth.getDate(),
        // },
        birth: dateFns.format(new Date(this.animal.birth), "dd/MM/yyyy"),
      });
    }
  }

  async onSubmit() {
    this.submitted = true;
    if (!this.addAnimalForm.valid) return;

    const { name, birth, gender, breed, owner, city, state, registerId } =
      this.addAnimalForm.controls;
    // const convertedBirth = new Date(
    //   "" + birth.value.year + "-" + birth.value.month + "-" + birth.value.day
    // ).getTime();
    const convertedBirth = new Date(
      `${birth.value.split("/")[1]}-${birth.value.split("/")[0]}-${
        birth.value.split("/")[2]
      }`
    ).getTime();
    try {
      let animal: Animal = new Animal();
      animal = {
        name: name.value,
        birth: convertedBirth,
        registerId: registerId.value,
        gender: gender.value,
        breed: breed.value,
        owner: owner.value,
        city: city.value,
        state: state.value,
      };
      if (this.animal.name) {
        animal._id = this.animal._id;
        await this._event.editAnimal(animal);
        this.alertService.success("UPDATED-ANIMAL");
      } else {
        await this._event.addAnimal(animal);
        this.alertService.success("ADDED-ANIMAL");
      }

      this.submitted = false;
      this.addAnimalForm.reset();
      this.activeModal.close();
    } catch (ex) {
      this.alertService.danger(errorHandler(ex.error.message));
      this.submitted = false;
    }
  }

  get f() {
    return this.addAnimalForm.controls;
  }
}
