<ngb-alert
  type="dark"
  *ngIf="event.progress == 'published' && !isAdmin && !disabledNotify"
  (close)="disabledNotify = true"
  translate
  >EVENT.CATEGORIES.SETTINGS-CHANGE-DENIED</ngb-alert
>
<ngb-accordion>
  <ngb-panel>
    <ng-template ngbPanelTitle>
      <span translate>SEE-SUMMARY</span>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th translate>CATEGORY</th>
              <th translate>EVENT.CATEGORIES.RANK-ONLY-ONE-ANIMAL</th>
              <th translate>EVENT.CATEGORIES.LIMIT-SAT-TIME</th>
              <th translate>PRICE-TO-PAY</th>
              <th translate>PRICE-TO-APPORT</th>
              <th translate>EVENT.CATEGORIES.SELECT-RANK-COMPETITION</th>
              <th translate>EVENT.CATEGORIES.RANK-SELECTION-TYPE</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let comp of competitions; let i = index">
              <td>
                {{ comp.category.name | translateCategory | async }} -
                {{ comp.date | date: "EEEE dd/MM" | titlecase }}
              </td>
              <td translate>{{ comp.rankOnlyOne }}</td>
              <td>{{ comp.limitScoreSAT }}</td>
              <td>{{ paymentPrice[i] }}</td>
              <td>
                {{ event.currency | currencyPipe }}{{ comp.averagePrice }}
              </td>
              <td>
                <span *ngIf="!comp.rankCompetition"> -</span>
                <span *ngIf="comp.rankCompetition"
                  >{{
                    findRankCompetition(comp.rankCompetition).category.name
                      | translateCategory
                      | async
                  }}
                  - {{ comp.date | date: "EEEE dd/MM" | titlecase }}</span
                >
              </td>
              <td translate>{{ comp.selectedClassificatoryType }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th translate>CATEGORY</th>
        <th translate>SETTINGS</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let comp of competitions; let i = index">
        <tr *ngIf="!comp.hasPass">
          <td>
            {{ comp.category.name | translateCategory | async }} <br />
            {{ comp.date | date: "EEEE dd/MM" | titlecase }}
          </td>
          <td>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label translate>EVENT.CATEGORIES.RANK-ONLY-ONE-ANIMAL</label>
                  <select
                    class="form-control form-control-lg"
                    [(ngModel)]="rankOnlyOne[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option value="false" translate>NO</option>
                    <option value="true" translate>YES</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6" *ngIf="!comp.isRank">
                <div class="form-group">
                  <label translate
                    >EVENT.CATEGORIES.SELECT-RANK-COMPETITION</label
                  >
                  <select
                    required
                    class="form-control"
                    [(ngModel)]="selectedIsRankCompetition[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option [value]="null" translate>
                      EVENT.CATEGORIES.NONE-SELECTED
                    </option>
                    <option
                      [value]="rank._id"
                      *ngFor="let rank of isRankCompetitions"
                    >
                      {{ rank.category.name | translateCategory | async }} -
                      {{ rank.date | date: "EEEE dd/MM" | titlecase }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="col-md-6"
                *ngIf="!comp.isRank && selectedIsRankCompetition[i]"
              >
                <div class="form-group">
                  <label translate>EVENT.CATEGORIES.RANK-SELECTION-TYPE</label>
                  <select
                    required
                    class="form-control"
                    [(ngModel)]="selectedClassificatoryType[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option value="GELDING" translate>GELDING</option>
                    <option value="CLASSIFICATORY" translate>RANK</option>
                    <option value="ONLYRESULT" translate>ONLY-RESULT</option>
                  </select>
                </div>
              </div>
              <div
                class="col-md-6"
                *ngIf="
                  !comp.isRank &&
                  selectedIsRankCompetition[i] &&
                  selectedClassificatoryType[i] == 'CLASSIFICATORY'
                "
              >
                <div class="form-group">
                  <label translate>EVENT.CATEGORIES.RANK-TYPE</label>
                  <select
                    required
                    class="form-control"
                    [(ngModel)]="rankType[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option [value]="null" translate>NONE-SELECTED</option>
                    <option value="RANK-ALL" translate>
                      EVENT.CATEGORIES.RANK-ALL
                    </option>
                    <option value="RANK-BEST" translate>
                      EVENT.CATEGORIES.RANK-BEST
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="col-md-6"
                *ngIf="
                  !comp.isRank &&
                  selectedIsRankCompetition[i] &&
                  selectedClassificatoryType[i] === 'CLASSIFICATORY'
                "
              >
                <div class="form-group">
                  <label translate
                    >EVENT.CATEGORIES.RANKED-TO-RANKED-COMPETITION</label
                  >
                  <div class="form-group">
                    <input
                      type="number"
                      class="form-control"
                      id="max-age-animal"
                      [(ngModel)]="rankedToCompetition[i]"
                      [disabled]="event.progress == 'published' && !isAdmin"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="comp.isRank">
                <div class="form-group">
                  <label translate>EVENT.CATEGORIES.CATEGORY-TO-SUM</label>
                  <select
                    required
                    class="form-control"
                    [(ngModel)]="sumCompetition[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option [value]="null" translate>NONE-SELECTED</option>
                    <option
                      [value]="comp._id"
                      *ngFor="let comp of isNoRankCompetitions"
                    >
                      {{ comp.category.name | translateCategory | async }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label translate>EVENT.CATEGORIES.LIMIT-SAT-TIME</label>
                  <select
                    required
                    class="form-control"
                    [(ngModel)]="limitScoreSAT[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  >
                    <option value="60" [translate]="{ time: '60' }">
                      X-SECONDS
                    </option>
                    <option value="120" [translate]="{ time: '120' }">
                      X-SECONDS
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <label for="averagePrice" translate>PRICE-TO-PAY</label>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="averagePrice"
                    [(ngModel)]="paymentPrice[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <label for="averagePrice" translate>PRICE-TO-APPORT</label>
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="averagePrice"
                    [(ngModel)]="averagePrice[i]"
                    [disabled]="event.progress == 'published' && !isAdmin"
                  />
                </div>
              </div>
              <div class="col-md-3" *ngIf="!comp.isRank">
                <label for="alwaysSubscriptionAllPass" translate>ALWAYS-SUBSCRIPTION-ALL-PASS</label>
                <div class="form-group">
                <select required class="form-control" [(ngModel)]="alwaysSubscriptionAllPass[i]"
                  [disabled]="event.progress == 'published' && !isAdmin">
                  <option :value="true" [translate]>
                    YES
                  </option>
                  <option :value="false" [translate]>
                   NO
                  </option>
                </select>
                </div>
              </div>
            </div>
          </td>

          <td>
            <button
              class="btn btn-outline-primary"
              [disabled]="event.progress == 'published' && !isAdmin"
              (click)="saveSettings(i)"
              translate
            >
              SAVE
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
