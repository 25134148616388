<nav class="sidebar sidebar-offcanvas horse-sidebar" id="sidebar">
  <ul class="nav">
    <li class="nav-item nav-profile">
      <a [routerLink]="['/']" class="nav-link">
        <!-- <div class="nav-profile-image">
          <img src="assets/images/faces/face1.jpg" alt="profile" />
          <span class="login-status online"></span>
        </div> -->
        <div class="nav-profile-text d-flex flex-column">
          <span class="font-weight-bold mb-2" *ngIf="user">{{
            user.userName
          }}</span>
          <span class="text-secondary text-small" translate
            >DASHBOARD.TITLE</span
          >
        </div>
      </a>
    </li>
    <li class="nav-item">
      <a
        class="btn btn-gradient-primary"
        [routerLink]="['/events/add']"
        translate
        >CREATE-EVENT</a
      >
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">
        <span class="menu-title" translate>DASHBOARD.TITLE</span>
        <i class="mdi mdi-home menu-icon"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/events" routerLinkActive="active">
        <span class="menu-title" translate>EVENTS</span>
        <i class="mdi mdi-trophy-variant menu-icon"></i>
      </a>
      <div
        class="nav-item submenu"
        routerLinkActive="d-block"
        [ngClass]="route.url.startsWith('/events') ? 'd-block' : 'd-none'"
      >
        <a class="nav-link" routerLink="/events/add" routerLinkActive="active">
          <span class="menu-title" translate>CREATE-EVENT</span>
        </a>
        <a
          class="nav-link"
          routerLink="/events/typeGroup"
          routerLinkActive="active"
        >
          <span class="menu-title" translate>CREATE-GROUP</span>
        </a>
        <a
          class="nav-link"
          routerLink="/events"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="menu-title" translate>DASHBOARD.MY-EVENTS</span>
        </a>
      </div>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/financial" routerLinkActive="active">
        <span class="menu-title" translate>FINANCIAL</span>
        <i class="mdi mdi-cash menu-icon"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/user" routerLinkActive="active">
        <span class="menu-title" translate>ACCOUNT</span>
        <i class="mdi mdi-account menu-icon"></i>
      </a>
      <div
        class="nav-item submenu"
        routerLinkActive="d-block"
        [ngClass]="route.url.startsWith('/user') ? 'd-block' : 'd-none'"
      >
        <a
          class="nav-link"
          routerLink="/user/account"
          routerLinkActive="active"
        >
          <span class="menu-title" translate>MY-ACCOUNT</span>
        </a>
      </div>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/animal" routerLinkActive="active">
        <span class="menu-title" [translate]="{ count: '' }"
          >ANIMAL.MANAGE</span
        >
        <i class="mdi mdi-view-dashboard menu-icon"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/competitor" routerLinkActive="active">
        <span class="menu-title" [translate]="{ count: '' }"
          >COMPETITOR.MANAGE</span
        >
        <i class="mdi mdi-account menu-icon"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/customer" routerLinkActive="active">
        <span class="menu-title" translate>CUSTOMER.MANAGE</span>
        <i class="mdi mdi-account menu-icon"></i>
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        routerLink="/activity-register"
        routerLinkActive="active"
      >
        <span class="menu-title" translate>ACTIVITY-REGISTER</span>
        <i class="mdi mdi-history menu-icon"></i>
      </a>
    </li>
  </ul>
</nav>
