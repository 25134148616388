import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { EventService } from "src/app/services/event.service";
import { TakeOff } from "src/app/dto/TakeOff";
import { AlertTranslateService } from "src/app/services/alert.service";
import ErrorHandler from "../../../util/errorHandler";
import { Socket } from "ngx-socket-io";
import { AbqmService } from "src/app/services/abqm.service";

@Component({
  selector: "app-event-categories-subscriptions",
  templateUrl: "./event-categories-subscriptions.component.html",
  styleUrls: ["./event-categories-subscriptions.component.scss"],
})
export class EventCategoriesSubscriptionsComponent implements OnInit {
  competitor;
  horse;
  customer;
  code;
  isCouponLoading = false;
  changedCustomer;
  changedCompetitor;
  changedHorse;
  subscriptions;
  pass = 1;
  isLoading = false;
  toggleIsChangeCustomer = -1;
  toggleIsChangeCompetitor = -1;
  toggleIsChangeHorse = -1;
  @Input() public competition;
  @Input() public eventFinished;
  event;
  tkScore = [];
  isAddTakeOffLoading = false;
  isReadAbqmCompetitorLoading = false;
  isReadAbqmAnimalLoading = false;

  competitionPass: number = 1;

  constructor(
    public activeModal: NgbActiveModal,
    private _event: EventService,
    private alertService: AlertTranslateService,
    private socket: Socket,
    private modalService: NgbModal,
    private _abqmService: AbqmService
  ) {}

  ngOnInit() {
    this.getAutomaticCoupon();
    this.loadSubscriptions();
  }

  getTime(date) {
    return new Date(date).getTime();
  }

  async editScore(_id: string, chip: string) {
    await this._event.editScore(_id, chip);
    this.alertService.success("UPDATED-NUMBER");
  }

  async getAutomaticCoupon() {
    this.isCouponLoading = true;
    this.event = await this._event.getEventById(this.competition.event);
    const data: any = await this._event.getEventCoupons(this.competition.event);
    const coupon = data.filter(
      (x) =>
        this.event.automaticCoupon.filter((ac) => ac === x._id).length > 0 &&
        x.competitions.filter(
          (comp) => comp.category.name == this.competition.category.name
        ).length > 0
    );
    if (coupon.length > 0) {
      this.code = coupon.map((x) => x.code).join(",");
    }
    this.isCouponLoading = false;
  }

  async loadSubscriptions() {
    this.isLoading = true;
    let id;
    if (this.competitionPass == 1)
      id = this.competition.initialCompetition
        ? this.competition.initialCompetition
        : this.competition._id;
    if (this.competitionPass == 2)
      id = this.competition.secondCompetition
        ? this.competition.secondCompetition
        : this.competition._id;
    if (this.competitionPass == 3) id = this.competition._id;
    try {
      this.subscriptions = await this._event.getTakeOffs(
        this.competition.event,
        id
      );
      this.pass = this.competition.pass;
      this.tkScore = this.subscriptions.map((x) => (x.score ? x.score : 0));
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
      this.isAddTakeOffLoading = false;
    }
  }

  async submitSubscription() {
    if (this.isLoading) return;
    this.isLoading = true;
    this.isReadAbqmCompetitorLoading = true;
    if (this.competitor.returnedAbqm)
      this.competitor = await this._event.addCompetitorAbqm(
        this.competitor.cpf
      );
    this.isReadAbqmCompetitorLoading = false;
    this.isReadAbqmAnimalLoading = true;

    if (this.horse.returnedAbqm)
      this.horse = await this._event.addHorseAbqm(this.horse.abqmId);
    this.isReadAbqmAnimalLoading = false;
    this.isAddTakeOffLoading = true;
    const takeOff = new TakeOff();
    if (this.pass == 1) {
      if (this.competition.initialCompetition) {
        takeOff.competition = this.competition.initialCompetition;
      } else {
        takeOff.competition = this.competition._id;
      }
    } else if (this.pass == 2) {
      if (this.competition.secondCompetition) {
        takeOff.competition = this.competition.secondCompetition;
      } else {
        takeOff.competition = this.competition._id;
      }
    } else if (this.pass == 3) {
      takeOff.competition = this.competition._id;
    }

    takeOff.competitor = this.competitor._id;
    takeOff.horse = this.horse._id;
    takeOff.pass = this.pass;
    takeOff.owner = this.customer._id;
    takeOff.coupon = this.code;
    takeOff.linkedCompetition = null;
    try {
      await this._event.addTakeOff(takeOff, this.competition.event);
      this.emitTakeOffChange();
      this.alertService.success("ADDED-SUBSCRIPTION");
      this.loadSubscriptions();
    } catch (ex) {
      this.isLoading = false;
      this.isAddTakeOffLoading = false;
      let msg = ex.error.message;
      if (msg.indexOf("[")) {
        this.alertService.danger(ErrorHandler(ex.error.message));
      } else {
        this.alertService.danger(
          await JSON.parse(ex.error.message).map(async (x) => {
            let message = x.split(":");
            if (message.length > 1) {
              message[0] = await this.alertService.translateText(
                ErrorHandler(message[0])
              );
              return message[0] + (message.length > 1 ? message[1] : "");
            } else {
              const translate = await this.alertService.translateText(
                ErrorHandler(x)
              );
              return translate;
            }
          })[0],
          "",
          false
        );
      }
    }
  }

  emitTakeOffChange() {
    this.socket.emit("get:takeoff", {
      eventId: this.competition.event,
      competitionForTakeOff: this.competition._id,
    });
  }

  async deleteSubscription(subscriptionId) {
    if (await this.alertService.confirm("CONFIRM-REMOVE-CATEGORY")) {
      await this._event.deleteTakeOff(
        this.competition.event,
        subscriptionId,
        this.competition._id
      );
      this.emitTakeOffChange();
      this.loadSubscriptions();
    }
  }

  setCustomer(customer) {
    this.customer = customer;
  }
  setHorse(horse) {
    this.horse = horse;
  }
  setCompetitor(competitor) {
    this.competitor = competitor;
  }

  setSearchedCustomer(customer) {
    this.changedCustomer = customer;
  }
  setSearchedCompetitor(competitor) {
    this.changedCompetitor = competitor;
  }
  setSearchedHorse(horse) {
    this.changedHorse = horse;
  }

  changeCompetitionPass(pass: number) {
    this.competitionPass = pass;
    this.loadSubscriptions();
  }

  async loadCompetition() {
    this.competition = await this._event.getCompetition(this.competition._id);
  }

  async submitChangeUser(takeoffId) {
    try {
      await this._event.changeTakeoff(
        this.competition.event,
        takeoffId,
        this.changedCustomer._id,
        null,
        null
      );
      this.toggleIsChangeCustomer = -1;
      this.loadSubscriptions();
    } catch (ex) {
      this.alertService.danger(ErrorHandler(ex.error.message));
    }
  }
  async submitChangeCompetitor(takeoffId) {
    let competitor: any = this.changedCompetitor.returnedAbqm
      ? await this._abqmService.addAbqmCompetitor(this.changedCompetitor.cpf)
      : this.changedCompetitor;
    try {
      await this._event.changeTakeoff(
        this.competition.event,
        takeoffId,
        null,
        competitor._id,
        null
      );
      this.toggleIsChangeCompetitor = -1;
      this.loadSubscriptions();
    } catch (ex) {
      let message = JSON.parse(ex.error.message);
      if (Array.isArray(message)) {
        message = message
          .map((item) => {
            const splitted = item.split(": ");
            if (splitted.length > 1) {
              return ErrorHandler(splitted[0]) + ": " + splitted[1];
            } else {
              return ErrorHandler(item);
            }
          })
          .join(", ");
        this.alertService.danger(message);
      } else {
        this.alertService.danger(ErrorHandler(message));
      }
    }
  }
  async submitChangeHorse(takeoffId) {
    console.log(this.changedHorse);
    let horse: any = this.changedHorse.returnedAbqm
      ? await this._abqmService.addAbqmAnimal(this.changedHorse.abqmId)
      : this.changedHorse;
    try {
      await this._event.changeTakeoff(
        this.competition.event,
        takeoffId,
        null,
        null,
        horse._id
      );
      this.toggleIsChangeHorse = -1;
      this.loadSubscriptions();
    } catch (ex) {
      let message = JSON.parse(ex.error.message);
      if (Array.isArray(message)) {
        message = message
          .map((item) => {
            const splitted = item.split(": ");
            if (splitted.length > 1) {
              return ErrorHandler(splitted[0]) + ": " + splitted[1];
            } else {
              return ErrorHandler(item);
            }
          })
          .join(", ");
        this.alertService.danger(ErrorHandler(message));
      } else {
        this.alertService.danger(ErrorHandler(message));
      }
    }
  }

  getDuplicates(horse: string) {
    return this.subscriptions.filter((x) => x.horse._id == horse).length;
  }
}
