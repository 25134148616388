import { Pipe, PipeTransform } from "@angular/core";
import * as dateFns from "date-fns";
@Pipe({ name: "hippicalYearPipeAnimal" })
export class HippicalYearPipeAnimal implements PipeTransform {
  transform(value: any, exponent?: number): number {
    // const hy = new Date(new Date().getFullYear(), 6);

    // const current = new Date(parseInt(value));
    // const lastHY = new Date().getMonth() > 6 ? hy : dateFns.subYears(hy, 1);
    // const isAfter =
    //   dateFns.isAfter(new Date(parseInt(value)), lastHY) &&
    //   dateFns.isAfter(
    //     new Date(),
    //     new Date(new Date().getFullYear(), current.getMonth(), current.getDay())
    //   )
    //     ? 0
    //     : 1;
    // const currentHY =
    //   new Date(parseInt(value)).getMonth() >= 6
    //     ? new Date(new Date().getFullYear(), 7, 1)
    //     : new Date(new Date().getFullYear() - 1, 7, 1);
    // console.log(new Date(new Date().getFullYear() - 1, 6, 1));
    const year = dateFns.differenceInYears(
      new Date().getMonth() >= 6
        ? new Date(new Date().getFullYear(), 5, 30)
        : new Date(),
      new Date(parseInt(value)).getMonth() >= 6
        ? new Date(new Date(parseInt(value)).getFullYear(), 5, 30)
        : new Date(new Date(parseInt(value)).getFullYear() - 1, 5, 30)
    );
    return year;
  }
}
