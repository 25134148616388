import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TypeGroup } from "src/app/dto/TypeGroup";
import { EventService } from "src/app/services/event.service";
import { AlertTranslateService } from "src/app/services/alert.service";
import { Router } from "@angular/router";
import ErrorHandler from "../../util/errorHandler";
import { TypeGroupService } from "src/app/services/typeGroup.service";

@Component({
  selector: "app-event-typeGroup",
  templateUrl: "./event-typeGroup.component.html",
  styleUrls: ["./event-typeGroup.component.scss"],
})
export class EventTypeGroupComponent implements OnInit {
  typeGroupForm: FormGroup;
  submitted = false;
  eventList: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private _typeGroupService: TypeGroupService,
    private alertService: AlertTranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.typeGroupForm = this.formBuilder.group({
      name: ["", [Validators.required]],
    });
  }

  get f() {
    return this.typeGroupForm.controls;
  }

  async onSubmit() {
    if (!this.typeGroupForm.valid) return;
    this.submitted = true;
    const { name } = this.typeGroupForm.controls;

    const dtoTypeGroup = new TypeGroup();
    dtoTypeGroup.name = name.value;

    try {
      await this._typeGroupService.addTypeGroup(dtoTypeGroup);
      this.alertService.success("CREATED-GROUP");
      this.submitted = false;
      this.router.navigate(["events"]);
    } catch (ex) {
      this.alertService.danger(ErrorHandler(ex.error.message));
      this.submitted = false;
    }
  }
}
