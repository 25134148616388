<h5 translate>FILTERS</h5>
<div class="row">
  <div class="col-md-4">
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        placeholder="Filtrar Competidor"
        [(ngModel)]="competitorFilter"
        (input)="filterSearch()"
      />
      <button
        *ngIf="competitorFilter != ''"
        class="btn bg-transparent"
        style="margin-left: -40px; z-index: 100; padding: 0"
        (click)="clearCompetitorFilter()"
      >
        <i class="mdi mdi-for-btn mdi-close"></i>
      </button>
    </div>
  </div>
</div>
<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th></th>
        <th translate>COMPETITOR.SINGLE</th>
        <th translate>SUBSCRIPTIONS</th>
        <th translate>TOTAL</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngIf="
          competitorsPerm.length == 0 &&
          competitors.length == 0 &&
          !isCompetitorsLoading
        "
      >
        <td colspan="9" class="text-center" translate>EVENT.NO-COMPETITORS</td>
      </tr>
      <tr
        *ngIf="
          competitorsPerm.length > 0 &&
          competitors.length == 0 &&
          !isCompetitorsLoading
        "
      >
        <td colspan="9" class="text-center" translate>CUSTOMER.NOT-FOUND</td>
      </tr>
      <tr *ngIf="isCompetitorsLoading">
        <td colspan="9">
          <div
            class="
              lds-dual-ring lds-dual-ring-black
              d-flex
              justify-content-center
              w-100
            "
          ></div>
        </td>
      </tr>
      <tr *ngFor="let item of competitors">
        <td>
          <div
            (click)="open(item.competitor)"
            class="btn btn-sm btn-outline-primary"
            translate
          >
            TAKEOFFS
          </div>
        </td>
        <td>
          {{ item.competitor.name }}
        </td>
        <td>
          {{ item.subscriptions }}
        </td>
        <td>{{ event.currency | currencyPipe }}{{ item.total }}</td>
      </tr>
    </tbody>
  </table>
</div>
