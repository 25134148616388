<div class="page-header">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/']" translate>HOME</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" translate>
        MY-EVENTS
      </li>
    </ol>
  </nav>
</div>

<div class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body card-body-fill">
        <h4 class="card-title" translate>MY-GROUPS</h4>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th translate>NAME</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngIf="typeGroups.length == 0"
                [routerLink]="['/events/typeGroup']"
              >
                <td class="py-1 text-center" colspan="6" rowspan="1">
                  <a [routerLink]="" translate>TYPEGROUP.NOT-CREATED</a>
                </td>
              </tr>
              <tr *ngFor="let typeGroup of typeGroups" class="form-clickable">
                <td class="p-3" [routerLink]="['/typeGroup', typeGroup.slug]">
                  {{ typeGroup.name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr class="pb-5" />
        <h4 class="card-title" translate>MY-EVENTS</h4>
        <p class="card-description" translate>EVENT.MANAGE</p>
        <select
          class="form-select form-control"
          aria-label="Default select example"
          [(ngModel)]="currentYear"
          (change)="loadEvents()"
        >
          <option *ngFor="let item of years">{{ item }}</option>
        </select>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th translate>NAME</th>
                <th translate>PROGRESS</th>
                <th></th>
                <th translate>EARNS</th>
                <th translate>DATE</th>
                <th translate>ACCESS</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="events.length == 0" [routerLink]="['/events/add']">
                <td class="py-1 text-center" colspan="6" rowspan="1">
                  <a [routerLink]="" translate>DASHBOARD.NO-EVENTS</a>
                </td>
              </tr>
              <tr *ngFor="let event of events" class="form-clickable">
                <td class="py-1" [routerLink]="['/event', event.slug]">
                  {{ event.name }}
                </td>
                <td [routerLink]="['/event', event.slug]">
                  <div class="progress">
                    <div
                      class="progress-bar bg-gradient-success"
                      role="progressbar"
                      [ngStyle]="{
                        width: event.progress == 'revision' ? '50%' : '100%'
                      }"
                    ></div>
                  </div>
                </td>
                <td class="py-1" [routerLink]="['/event', event.slug]">
                  <label
                    class="badge"
                    [ngClass]="
                      event.progress == 'revision'
                        ? 'badge-gradient-warning'
                        : 'badge-gradient-success'
                    "
                  >
                    <span *ngIf="event.progress == 'revision'" translate
                      >IN-REVISION</span
                    >
                    <span *ngIf="event.progress == 'published'" translate
                      >PUBLISHED</span
                    ></label
                  >
                </td>
                <td [routerLink]="['/event', event.slug]">
                  {{ event.currency | currencyPipe }}{{ event.earnings }}
                </td>
                <td [routerLink]="['/event', event.slug]">
                  <span>{{ event.startDate | date: "shortDate" }}</span
                  ><span *ngIf="event.finalDate !== event.startDate"
                    >- {{ event.finalDate | date: "shortDate" }}</span
                  >
                </td>
                <td translate>OWNER</td>
              </tr>
              <tr *ngFor="let event of adminEvents" class="form-clickable">
                <td class="py-1" [routerLink]="['/event', event.slug]">
                  {{ event.name }}
                </td>
                <td [routerLink]="['/event', event.slug]">
                  <div class="progress">
                    <div
                      class="progress-bar bg-gradient-success"
                      role="progressbar"
                      [ngStyle]="{
                        width: event.progress == 'revision' ? '50%' : '100%'
                      }"
                    ></div>
                  </div>
                </td>
                <td class="py-1" [routerLink]="['/event', event.slug]">
                  <label
                    class="badge"
                    [ngClass]="
                      event.progress == 'revision'
                        ? 'badge-gradient-warning'
                        : 'badge-gradient-success'
                    "
                  >
                    <span *ngIf="event.progress == 'revision'" translate
                      >IN-REVISION</span
                    >
                    <span *ngIf="event.progress == 'published'" translate
                      >PUBLISHED</span
                    >
                  </label>
                </td>
                <td [routerLink]="['/event', event.slug]">
                  {{ event.currency | currencyPipe }}{{ event.earnings }}
                </td>
                <td [routerLink]="['/event', event.slug]">
                  <span>{{ event.startDate | date: "mediumDate" }}</span>
                  <span *ngIf="event.finalDate !== event.startDate"
                    >- {{ event.finalDate | date: "mediumDate" }}</span
                  >
                </td>
                <td translate>ADMIN</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
