import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertTranslateService } from "src/app/services/alert.service";
import { Observable, of } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";
import { EventService } from "src/app/services/event.service";
import { PdfService } from "src/app/services/pdf.service";
import ErrorHandler from "src/app/util/errorHandler";
import * as dateFns from "date-fns";
import { Utils } from "src/app/util/utils";

@Component({
  selector: "app-event-stalls-details",
  templateUrl: "./event-stalls-details.component.html",
  styleUrls: ["./event-stalls-details.component.scss"],
})
export class EventStallsDetailsComponent implements OnInit {
  @Input() stallId;
  @Input() eventId;
  customerStalls: any = [];
  customerStallsPerm: any = [];
  stallFilter: string = "";
  isLoading = true;

  searchingHorse = false;
  searchingCustomer = false;
  searchCustomerFailed = false;
  hasSearchedCustomer = false;
  searchHorseFailed = false;
  hasSearchedHorse = false;

  horse;
  customer;
  coach;
  eventStalls: any = [];

  searchedAnimal;
  searchedCustomer;

  date;
  hour;
  isLoadingSchedule = false;

  constructor(
    public activeModal: NgbActiveModal,
    private _eventService: EventService,
    private alertService: AlertTranslateService,
    private pdfService: PdfService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getScheduleStall();
    this.listAllCustomerStalls();
  }

  async listAllCustomerStalls() {
    this.customerStallsPerm = await this._eventService.getAllCustomerStalls(
      this.eventId,
      this.stallId
    );
    this.customerStalls = this.customerStallsPerm;
    this.isLoading = false;
  }

  formatterHorse = (x: { name: string }) => x.name;

  searchHorse = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searchingHorse = true)),
      switchMap((term) => {
        if (term.length < 3) return of([]);
        return this._eventService.searchHorse(term).pipe(
          tap(() => (this.searchHorseFailed = false)),
          catchError(() => {
            this.searchHorseFailed = true;
            return of([]);
          })
        );
      }),
      tap(() => ((this.searchingHorse = false), (this.hasSearchedHorse = true)))
    );

  formatterCustomer = (x: { name: string }) => x.name;

  searchCustomer = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searchingCustomer = true)),
      switchMap((term) => {
        if (term.length < 3) return of([]);
        return this._eventService.searchCustomer(term).pipe(
          tap(() => (this.searchCustomerFailed = false)),
          catchError(() => {
            this.searchCustomerFailed = true;
            return of([]);
          })
        );
      }),
      tap(
        () => (
          (this.searchingCustomer = false), (this.hasSearchedCustomer = true)
        )
      )
    );

  setAnimalNotSearched() {
    this.hasSearchedHorse = false;
    this.horse = "";
    this.searchedAnimal = null;
  }

  selectedAnimalItem(data) {
    this.searchedAnimal = data.item.name;
  }

  setCustomerNotSearched() {
    this.hasSearchedCustomer = false;
    this.customer = "";
    this.searchedCustomer = null;
  }

  selectedCustomerItem(data) {
    this.searchedCustomer = data.item.name;
  }

  async submitAddStall() {
    try {
      if (this.horse)
        if (this.horse.returnedAbqm)
          this.horse = await this._eventService.addHorseAbqm(this.horse.abqmId);
      await this._eventService.addCustomerStall(
        this.customer._id,
        this.eventId,

        this.stallId,
        this.coach,
        this.horse?._id
      );
      this.alertService.success("RESERVED-STALL");
      this.setCustomerNotSearched();
      this.setAnimalNotSearched();
      this.coach = "";
      this.listAllCustomerStalls();
    } catch (ex) {
      console.log(ex);
      this.alertService.danger(ErrorHandler(ex));
    }
  }

  async generateDocsStall(screen: string, type) {
    const event = await this._eventService.getEventById(this.eventId);
    const eventStall = await this._eventService.getStall(this.stallId);
    this.pdfService.genStalls(
      event,
      eventStall,
      this.customerStalls,
      screen,
      type
    );
  }

  async deleteCustomerStall(stallId: string) {
    if (await this.alertService.confirm("CONFIRM-REMOVE-STALL")) {
      try {
        await this._eventService.deleteEventCustomerStall(
          this.eventId,
          stallId
        );
        this.alertService.success("REMOVED-CUSTOMER-STALL");
        this.listAllCustomerStalls();
      } catch (ex) {
        this.alertService.danger(ErrorHandler(ex.error.message));
      }
    }
  }

  async openChooseModal(modal, content, type) {
    modal.dismiss();
    const result = await this.modalService.open(content, { size: "sm" }).result;
    this.generateDocsStall(result, type);
  }

  async openPrintType(content) {
    const result = await this.modalService.open(content, { size: "sm" }).result;
  }

  submitScheduleStall() {
    let selectedDate = dateFns.addMinutes(
      dateFns.addHours(
        new Date(
          `${this.date[2]}${this.date[3]}-${this.date[0]}${this.date[1]}-${this.date[4]}${this.date[5]}${this.date[6]}${this.date[7]}`
        ),
        parseInt(`${this.hour[0]}${this.hour[1]}`)
      ),
      parseInt(`${this.hour[2]}${this.hour[3]}`)
    );
    if (dateFns.isBefore(selectedDate, new Date())) {
      this.alertService.danger(
        "A data e horário selecionados não podem ser antes da data e horários atuais"
      );
    }

    try {
      this._eventService.setStallIsActiveSchedule(
        this.eventId,
        this.stallId,
        new Date(selectedDate).getTime().toString()
      );
      this.alertService.success("SUCCESS-SCHEDULE");
    } catch (error) {}
  }

  async getScheduleStall() {
    this.isLoadingSchedule = true;
    let scheduled: any = await this._eventService.getScheduleStallActive(
      this.eventId,
      this.stallId
    );
    if (scheduled) {
      this.date = dateFns.format(
        new Date(
          scheduled.nextRunAt ? scheduled.nextRunAt : scheduled.lastRunAt
        ),
        "ddMMyyyy"
      );
      this.hour = dateFns.format(
        new Date(
          scheduled.nextRunAt ? scheduled.nextRunAt : scheduled.lastRunAt
        ),
        "HHmm"
      );
    }
    this.isLoadingSchedule = false;
  }

  clearStallFilter() {
    this.stallFilter = "";
    this.customerStalls = this.customerStallsPerm;
  }

  filterSearch() {
    this.stallFilter = this.stallFilter.toUpperCase();
    this.customerStalls = this.customerStallsPerm.filter((x) => {
      if (this.stallFilter.length == 0) return true;
      return (
        (x.customer
          ? Utils.removeAccent(x.customer.name.toUpperCase()).includes(
              this.stallFilter
            )
          : false) ||
        (x.horse
          ? Utils.removeAccent(x.horse.name.toUpperCase()).includes(
              this.stallFilter
            )
          : false) ||
        (x.coach
          ? Utils.removeAccent(x.coach.toUpperCase()).includes(this.stallFilter)
          : false)
      );
    });
  }
}
