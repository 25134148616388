import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { FinancialService } from "src/app/services/financial.service";
import * as dateFns from "date-fns";
import { Filters } from "src/app/util/filters";
import { TranslateService } from "angular-intl";
import { AlertTranslateService } from "src/app/services/alert.service";

@Component({
  selector: "app-financial-users-awards",
  templateUrl: "./financial-users-awards.component.html",
  styleUrls: ["./financial-users-awards.component.scss"],
})
export class FinancialUsersAwardsComponent implements OnInit {
  @Input() user;
  @Input() event;
  @Output() eventUsersFinancial: EventEmitter<any> = new EventEmitter();
  payment = null;
  paymentDate = this.calendar.getToday();
  value;
  paymentList;
  comments;

  constructor(
    public modal: NgbActiveModal,
    private calendar: NgbCalendar,
    private financialService: FinancialService,
    private translateService: TranslateService,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {
    this.listAwardPayments();
  }

  filterPayment(payment) {
    return new Filters(this.translateService).filterPayment(payment);
  }

  async listAwardPayments() {
    this.paymentList =
      await this.financialService.listCustomerTakeoffPaymentAwards(
        this.event._id,
        this.user._id
      );
  }

  async submitAwardPayment() {
    await this.financialService.setFinancialTakeOffPaymentAward(
      this.event._id,
      this.user._id,
      this.payment,
      dateFns.getTime(
        new Date(
          this.paymentDate.year,
          this.paymentDate.month - 1,
          this.paymentDate.day
        )
      ),
      this.value,
      this.comments
    );
    this.listAwardPayments();
    this.payment = null;
    this.paymentDate = this.calendar.getToday();
    this.value = null;
    this.eventUsersFinancial.emit();
  }

  async deleteAwardPayment(paymentId: string) {
    if (await this.alertService.confirm("CONFIRM-REMOVE")) {
      await this.financialService.deleteFinancialTakeoffPaymentAward(
        this.event._id,
        paymentId
      );
      this.listAwardPayments();
      this.eventUsersFinancial.emit();
    }
  }
}
