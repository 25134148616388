export class Utils {
  static removeAccent(str: string) {
    const withAccent =
      "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    const withoutAccent =
      "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let newStr = "";
    for (let i = 0; i < str.length; i++) {
      let change = false;
      for (let a = 0; a < withAccent.length; a++) {
        if (str.substr(i, 1) == withAccent.substr(a, 1)) {
          newStr += withoutAccent.substr(a, 1);
          change = true;
          break;
        }
      }
      if (change == false) {
        newStr += str.substr(i, 1);
      }
    }
    return newStr;
  }
}
