/// <reference types="@types/googlemaps" />
declare var google: any;
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { AlertTranslateService } from "src/app/services/alert.service";
import { Router } from "@angular/router";
import { Event } from "src/app/dto/Event";
import ErrorHandler from "../../util/errorHandler";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import {
  NgbDate,
  NgbCalendar,
  NgbDatepickerConfig,
  NgbDateStruct,
  NgbDatepicker,
  NgbInputDatepicker,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { MapsAPILoader } from "@agm/core";
import { EventService } from "src/app/services/event.service";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { resolve } from "url";
import { reject } from "q";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import Base64UploaderPlugin from "../../../@ckeditor/Base64Upload";
import "@ckeditor/ckeditor5-build-decoupled-document//build/translations/pt-br";
import * as dateFns from "date-fns";

@Component({
  selector: "app-event-add",
  templateUrl: "./event-add.component.html",
  styleUrls: ["./event-add.component.scss"],
})
export class EventAddComponent implements OnInit {
  eventForm: FormGroup;
  submitted: boolean = false;
  hoveredDate: NgbDate;
  logoUrl: string;
  logoKey: string;
  logoImage: string = "assets/images/img-placeholder.png";
  logoPercentage: number = 0;
  loadingLogoUpload: boolean = false;
  folderUrl: string;
  folderKey: string;
  coverUrl: string;
  coverKey: string;
  ruleUrl: string;
  ruleKey: string;

  loadingFolderUpload: boolean = false;
  loadingCoverUpload: boolean = false;
  loadingRuleUpload: boolean = false;
  Editor = ClassicEditor;
  editorConfig = { language: "pt-br", extraPlugins: [Base64UploaderPlugin] };
  public onReady(editor) {
    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element
    );
  }

  fromDate: NgbDate;
  toDate: NgbDate;
  today: NgbDate;

  @ViewChild("datepicker", { static: true }) NgbDatepicker: NgbInputDatepicker;
  @ViewChild("search", { static: true }) public searchElement: ElementRef;
  @ViewChild("logoFile", { static: true }) logoFile: ElementRef;
  @ViewChild("folderFile", { static: true }) folderFile: ElementRef;
  @ViewChild("coverFile", { static: true }) coverFile: ElementRef;
  @ViewChild("ruleFile", { static: true }) ruleFile: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private eventService: EventService,
    private alertService: AlertTranslateService,
    private router: Router,
    private calendar: NgbCalendar,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public formatter: NgbDateParserFormatter
  ) {}

  ngOnInit() {
    this.today = this.calendar.getToday();
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), "d", 1);
    this.toDate = this.calendar.getNext(this.calendar.getToday(), "d", 2);
    this.eventForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      org: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required]],
      logoFile: ["", [Validators.required]],
      folderFile: ["", [Validators.required]],
      coverFile: ["", [Validators.required]],
      ruleFile: [""],
      startDate: [this.formatter.format(this.fromDate), [Validators.required]],
      finalDate: [this.formatter.format(this.toDate), [Validators.required]],
      description: ["", [Validators.required, Validators.minLength(10)]],
      infos: [""],
      currency: ["BRL", [Validators.required]],
      address: ["", [Validators.required]],
    });

    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElement.nativeElement,
        { types: ["geocode"] }
      );

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
        });
      });
    });
  }

  get f() {
    return this.eventForm.controls;
  }

  async onLogoSelected(event) {
    const target = event.target.files[0];
    const fd = new FormData();
    fd.append("file", target, target.name);

    this.eventService.uploadEventImage(fd).subscribe(
      (event: HttpEvent<any>) => {
        this.loadingLogoUpload = true;
        switch (event.type) {
          case HttpEventType.Sent:
            this.logoUrl = "Contatando o Servidor...";
            break;
          case HttpEventType.ResponseHeader:
            this.logoUrl = "Carregando...";
            break;
          case HttpEventType.UploadProgress:
            const percentDone = Math.round((100 * event.loaded) / event.total);
            this.logoPercentage = percentDone;

            break;
          case HttpEventType.Response:
            this.logoUrl = event.body.imageName;
            this.logoKey = event.body.imageKey;
            this.logoImage = `https://cloud.horsecs.com.br/${this.logoKey}`;
            this.loadingLogoUpload = false;
        }
      },
      (err) => {
        this.loadingLogoUpload = false;
        console.log(err);
        reject(err);
      }
    );
  }

  async onRuleSelected(event) {
    this.loadingRuleUpload = true;
    const target = event.target.files[0];
    const fd = new FormData();
    fd.append("file", target, target.name);
    const uploadedLogo: any = this.eventService.uploadEventImage(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.ruleUrl = "Contatando o Servidor...";
            break;
          case HttpEventType.ResponseHeader:
            this.ruleUrl = "Carregando...";
            break;
          case HttpEventType.UploadProgress:
            const percentDone = Math.round((100 * event.loaded) / event.total);
            this.ruleUrl = `${percentDone}%`;
            break;
          case HttpEventType.Response:
            this.ruleUrl = event.body.imageName;
            this.ruleKey = event.body.imageKey;
        }
      },
      (err) => {
        console.log(err);
        reject(err);
      }
    );

    this.loadingRuleUpload = false;
  }

  async onFolderSelected(event) {
    this.loadingFolderUpload = true;
    const target = event.target.files[0];
    const fd = new FormData();
    fd.append("file", target, target.name);
    const uploadedLogo: any = this.eventService.uploadEventImage(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.folderUrl = "Contatando o Servidor...";
            break;
          case HttpEventType.ResponseHeader:
            this.folderUrl = "Carregando...";
            break;
          case HttpEventType.UploadProgress:
            const percentDone = Math.round((100 * event.loaded) / event.total);
            this.folderUrl = `${percentDone}%`;
            break;
          case HttpEventType.Response:
            this.folderUrl = event.body.imageName;
            this.folderKey = event.body.imageKey;
        }
      },
      (err) => {
        console.log(err);
        reject(err);
      }
    );

    this.loadingFolderUpload = false;
  }

  async onCoverSelected(event) {
    this.loadingCoverUpload = true;
    const target = event.target.files[0];
    const fd = new FormData();
    fd.append("file", target, target.name);
    const uploadedCover: any = this.eventService.uploadEventImage(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.coverUrl = "Contatando o Servidor...";
            break;
          case HttpEventType.ResponseHeader:
            this.coverUrl = "Carregando...";
            break;
          case HttpEventType.UploadProgress:
            const percentDone = Math.round((100 * event.loaded) / event.total);
            this.coverUrl = `${percentDone}%`;
            break;
          case HttpEventType.Response:
            this.coverUrl = event.body.imageName;
            this.coverKey = event.body.imageKey;
        }
      },
      (err) => {
        console.log(err);
        reject(err);
      }
    );

    this.loadingCoverUpload = false;
  }

  async onSubmit() {
    if (!this.eventForm.valid) return;
    this.submitted = true;
    const {
      name,
      org,
      phone,
      email,
      startDate,
      finalDate,
      description,
      infos,
      currency,
      address,
    } = this.eventForm.controls;

    const startArray = startDate.value.split("/");
    const startMount = new Date(
      `${startArray[2]}-${startArray[1]}-${startArray[0]}T00:00:00`
    );
    const endArray = finalDate.value.split("/");
    const endMount = new Date(
      `${endArray[2]}-${endArray[1]}-${endArray[0]}T23:59:59`
    );
    const newDate = startMount.getTime();
    const newEndDate = endMount.getTime();
    const dtoEvent = new Event();
    dtoEvent.name = name.value;
    dtoEvent.org = org.value;
    dtoEvent.currency = currency.value;
    dtoEvent.logoUrl = this.logoKey;
    dtoEvent.folderUrl = this.folderKey;
    dtoEvent.ruleUrl = this.ruleKey;
    dtoEvent.coverUrl = this.coverKey;
    dtoEvent.phone = phone.value;
    dtoEvent.email = email.value;
    dtoEvent.startDate = newDate;
    dtoEvent.finalDate = newEndDate;
    dtoEvent.description = description.value;
    dtoEvent.infos = infos.value;
    dtoEvent.address = address.value;
    dtoEvent.timeOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log({ endTime: dtoEvent.finalDate });

    try {
      await this.eventService.addEvent(dtoEvent);
      this.alertService.success("EVENT-CREATED");
      this.submitted = false;
      this.router.navigate(["dashboard"]);
    } catch (ex) {
      this.alertService.danger(ErrorHandler(ex.error.message));
      this.submitted = false;
    }
  }
  onDateSelection(date: NgbDate) {
    const { startDate, finalDate } = this.eventForm.controls;

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      startDate.setValue(this.formatter.format(date));
    } else if (this.fromDate && !this.toDate) {
      this.toDate = date;
      finalDate.setValue(this.formatter.format(date));
      this.NgbDatepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
      finalDate.setValue(null);
      startDate.setValue(this.formatter.format(date));
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);

    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  isDisabled(date: NgbDateStruct) {
    return (
      new Date(date.year, date.month - 1, date.day).getTime() <
      new Date().getTime()
    );
  }

  openFileLogo() {
    let el: HTMLElement = this.logoFile.nativeElement as HTMLElement;
    el.click();
  }

  openFileCover() {
    let el: HTMLElement = this.coverFile.nativeElement as HTMLElement;
    el.click();
  }

  openFileFolder() {
    let el: HTMLElement = this.folderFile.nativeElement as HTMLElement;
    el.click();
  }

  openRuleFolder() {
    let el: HTMLElement = this.ruleFile.nativeElement as HTMLElement;
    el.click();
  }
}
