<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th translate>CATEGORY</th>
        <th translate>SUBSCRIPTIONS</th>
        <th translate>COLLECTED</th>
        <th translate>AWARD</th>
        <th translate>BALANCE</th>
      </tr>
    </thead>
    <tbody *ngIf="eventCategories.length == 0 && !isCategoriesLoading">
      <tr>
        <td colspan="9" class="text-center" translate>EVENT.NO-CATEGORIES</td>
      </tr>
    </tbody>
    <tbody *ngIf="isCategoriesLoading">
      <tr>
        <td colspan="9">
          <div
            class="
              lds-dual-ring lds-dual-ring-black
              d-flex
              justify-content-center
              w-100
            "
          ></div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="eventCategories.length > 0">
      <tr *ngIf="eventCategories[0].items.length == 0 && !isCategoriesLoading">
        <td colspan="9" class="text-center" translate>NO-CATEGORIES</td>
      </tr>

      <tr *ngFor="let item of eventCategories[0].items">
        <td>
          {{ item.categoryName }} -
          {{ item.date | date: "EEEE dd/MM" | titlecase }}
        </td>
        <td>
          {{ item.subscriptions }}
        </td>
        <td>{{ event.currency | currencyPipe }}{{ item.earned }}</td>
        <td>{{ event.currency | currencyPipe }}{{ item.earnedAward }}</td>
        <td>{{ event.currency | currencyPipe }}{{ item.balance }}</td>
      </tr>
      <tr *ngIf="eventCategories[0].items.length > 0 && !isCategoriesLoading">
        <td translate>TOTAL</td>
        <td>{{ eventCategories[0].subscriptionsTotal }}</td>
        <td>
          {{ event.currency | currencyPipe
          }}{{ eventCategories[0].earnedTotal }}
        </td>
        <td>
          {{ event.currency | currencyPipe
          }}{{ eventCategories[0].earnedAward }}
        </td>
        <td>
          {{ event.currency | currencyPipe }}{{ eventCategories[0].balance }}
        </td>
      </tr>
      <tr *ngIf="testHorseTotal">
        <td>
          {{ testHorseTotal.categoryName }}
        </td>
        <td>
          {{ testHorseTotal.subscriptions }}
        </td>
        <td>{{ event.currency | currencyPipe }}{{ testHorseTotal.earned }}</td>
        <td>
          {{ event.currency | currencyPipe }}{{ testHorseTotal.earnedAward }}
        </td>
        <td>{{ event.currency | currencyPipe }}{{ testHorseTotal.balance }}</td>
      </tr>
    </tbody>
  </table>
</div>
