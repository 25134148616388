import { Pipe, PipeTransform } from "@angular/core";
import * as dateFns from "date-fns";

@Pipe({ name: "hippicalYearPipeCompetitor" })
export class HippicalYearPipeCompetitor implements PipeTransform {
  transform(value: any, exponent?: number): number {
    const year = dateFns.differenceInYears(
      new Date().getMonth() >= 6
        ? new Date(new Date().getFullYear(), 5, 30)
        : new Date(new Date().getFullYear() - 1, 5, 30),
      new Date(parseInt(value)).getMonth() >= 6
        ? new Date(new Date(parseInt(value)).getFullYear(), 6, 1)
        : new Date(new Date(parseInt(value)).getFullYear() - 1, 6, 1)
    );
    return year;
  }
}
