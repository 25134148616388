import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TypeGroupService } from "src/app/services/typeGroup.service";
import { EventService } from "src/app/services/event.service";

@Component({
  selector: "app-typeGroup-home",
  templateUrl: "./typeGroup-home.component.html",
  styleUrls: ["./typeGroup-home.component.scss"],
})
export class TypeGroupHomeComponent implements OnInit {
  params;
  typeGroup;
  events;
  typeGroupEvents: any = [];
  selectedEvent;
  isEventsLoading = false;
  ranking = [];

  constructor(
    private route: ActivatedRoute,
    private _typeGroupService: TypeGroupService,
    private _eventService: EventService
  ) {}

  async ngOnInit() {
    this.params = await this.route.params;
    this.getTypeGroup();
    this.getNoTypeGroupEvents();
  }

  async getTypeGroup() {
    this.typeGroup = await this._typeGroupService.getTypeGroupBySlug(
      this.params.value.slug
    );
    this.ranking = [];
    this.typeGroup.ranking.forEach((x, i) => {
      this.ranking.push({ id: i, value: x });
    });
  }

  async getNoTypeGroupEvents() {
    this.isEventsLoading = true;
    const events: any = await this._eventService.getEventsNoTypeGroup();
    this.events = events;
    if (this.typeGroupEvents.length > 0) {
      this.events = this.events.filter((item) => {
        return (
          this.typeGroupEvents.find((item2) => {
            return item._id == item2._id;
          }) == undefined
        );
      });
    }
    if (this.events.length > 0) {
      this.selectedEvent = this.events[0];
      this.selectedEvent.stage = this.typeGroupEvents.length + 1;
    }
    this.getTypeGroupEvents();
    this.isEventsLoading = false;
  }

  async getTypeGroupEvents() {
    this.typeGroupEvents = await this._typeGroupService.getTypeGroupEvents(
      this.typeGroup._id
    );
  }

  selectEvent(event) {
    this.selectedEvent = this.events.findIndex((ev) => ev == event);
  }

  changeStage(index, direction) {
    const forwardIndex = index + direction;

    const stored = this.typeGroupEvents[forwardIndex];

    this.typeGroupEvents[forwardIndex] = this.typeGroupEvents[index];
    this.typeGroupEvents[index] = stored;
    this.updateStage();
  }

  updateStage() {
    for (let i = 0; i < this.typeGroupEvents.length; i++) {
      this.typeGroupEvents[i].stage = i + 1;
    }
  }

  addEvent() {
    if (this.isEventsLoading) return;
    if (!this.selectedEvent) return;
    this.typeGroupEvents.push(
      this.events.splice(
        this.events.findIndex((ev) => ev == this.selectedEvent),
        1
      )[0]
    );
    this.selectedEvent = null;
    this.updateStage();
  }

  removeEvent(index: number) {
    if (this.typeGroupEvents.length > 1)
      this.events.push(this.typeGroupEvents.splice(index, 1)[0]);
    else {
      this.events.push(this.typeGroupEvents[0]);
      this.typeGroupEvents = [];
    }
    this.selectedEvent = this.events[0];
    this.updateStage();
  }

  async addTypeGroupEvents() {
    if (
      this.typeGroupEvents.length == 0 ||
      this.isEventsLoading ||
      this.isRank1000()
    )
      return;
    this.isEventsLoading = true;
    await this._typeGroupService.setTypeGroupEvents(
      this.typeGroup._id,
      this.typeGroupEvents,
      this.ranking.map((x) => x.value)
    );
    this.getNoTypeGroupEvents();
  }

  isRank1000() {
    return this.ranking.filter((x) => x.value > 1000).length > 0;
  }

  addRanking() {
    if (this.ranking.length < 20)
      this.ranking.push({ id: this.ranking.length, value: 0 });
  }

  removeRanking(i) {
    this.ranking.splice(i, 1);
  }
}
