import { Component, OnInit, Input } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-financial-competitors-details",
  templateUrl: "./financial-competitors-details.component.html",
  styleUrls: ["./financial-competitors-details.component.scss"],
})
export class FinancialCompetitorsDetailsComponent implements OnInit {
  @Input() event;
  @Input() competitor;
  competitorTakeoffs;
  isTakeoffsLoading: boolean = true;

  constructor(
    private financial: FinancialService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.listCompetitorTakeoffs();
  }

  async listCompetitorTakeoffs() {
    this.isTakeoffsLoading = true;
    this.competitorTakeoffs = await this.financial.getCompetitorTakeoffs(
      this.event._id,
      this.competitor._id
    );
    this.isTakeoffsLoading = false;
  }
}
