import { Component, Input, OnInit } from "@angular/core";
import { AlertTranslateService } from "src/app/services/alert.service";
import { EventService } from "src/app/services/event.service";

@Component({
  selector: "app-event-promotions",
  templateUrl: "./event-promotions.component.html",
  styleUrls: ["./event-promotions.component.scss"],
})
export class EventPromotionsComponent implements OnInit {
  @Input() eventParam;

  selectedCompetitions: any = [];
  competitions: any = [];
  loadedCompetitions: any = [];
  isCompetitionsLoading = false;

  searchedCoupons: any = [];
  isCouponsLoading = false;

  code: String;
  startDate: String;
  startHour: String;
  finalDate: String;
  finalHour: String;
  value: number;
  isPercentage: boolean = true;

  constructor(
    private _event: EventService,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit(): void {
    this.loadCompetitions();
    this.loadCoupons();
  }

  async loadCompetitions() {
    this.isCompetitionsLoading = true;
    this.loadedCompetitions = await this._event.getEventCompetitionsPass(
      this.eventParam._id
    );
    this.competitions = this.loadedCompetitions;
    this.isCompetitionsLoading = false;
    this.selectedCompetitions = this.competitions.map((x) => false);
  }

  toggleSelectAllCompetitions() {
    if (this.filteredSelectedCompetitions().length > 0) {
      this.selectedCompetitions = this.competitions.map((x) => false);
    } else {
      this.selectedCompetitions = this.competitions.map((x) => x._id);
    }
  }

  async couponAutomaticCheck(event, coupon) {
    await this._event.updateEventAutomaticCoupon(
      this.eventParam._id,
      coupon,
      event.target.checked
    );
    this.eventParam = await this._event.getEventById(this.eventParam._id);
    this.loadCoupons();
  }

  selectCompetition(index: number) {
    this.selectedCompetitions[index] = this.selectedCompetitions[index]
      ? false
      : this.competitions[index]._id;

    // if (this.selectedCompetitions[index]) {
    //   if (this.competitions[index].initialCompetition) {
    //     const initial = this.competitions.findIndex(
    //       (x) => x._id == this.competitions[index].initialCompetition
    //     );

    //     if (initial != -1)
    //       this.selectedCompetitions[initial] = this.competitions[initial]._id;
    //   }
    //   if (this.competitions[index].secondCompetition) {
    //     const second = this.competitions.findIndex(
    //       (x) => x._id == this.competitions[index].secondCompetition
    //     );
    //     if (second != -1)
    //       this.selectedCompetitions[second] = this.competitions[second]._id;
    //   }
    // } else {
    //   if (this.competitions[index].hasPass) {
    //     if (
    //       this.competitions[index + 1].initialCompetition ==
    //         this.competitions[index]._id ||
    //       this.competitions[index + 1].secondCompetition ==
    //         this.competitions[index]._id
    //     )
    //       this.selectedCompetitions[index + 1] = false;
    //     if (
    //       this.competitions[index + 2].initialCompetition ==
    //       this.competitions[index]._id
    //     )
    //       this.selectedCompetitions[index + 2] = false;
    //   }
    // }
  }

  async loadCoupons() {
    this.isCouponsLoading = true;
    this.searchedCoupons = await this._event.getEventCoupons(
      this.eventParam._id
    );
    this.isCouponsLoading = false;
  }

  async deleteCoupon(coupomId) {
    if (!confirm("Você realmente deseja remover este cupom?")) return;
    this.isCouponsLoading = true;
    await this._event.deleteEventCoupon(this.eventParam._id, coupomId);
    this.loadCoupons();
    this.isCouponsLoading = false;
  }

  isCouponActive(coupom) {
    return (
      this.eventParam.automaticCoupon.filter((ac) => ac === coupom).length > 0
    );
  }

  filteredSelectedCompetitions() {
    return this.selectedCompetitions.filter((x) => x);
  }

  async submitCoupon() {
    try {
      this.isCouponsLoading = true;
      const comps = this.competitions.filter((x) => x);
      const selectedComps = this.filteredSelectedCompetitions();

      let selected = [];
      for (let i = 0; i < comps.length; i++) {
        let set = comps.filter((x) => selectedComps[i] == x._id)[0];
        if (set) selected.push(set._id);

        // if (selected) await this.submitCompetition(selected);
      }
      if (selected.length === 0) return;
      let startDate = new Date(
        `${this.startDate.split("/")[1]}/${this.startDate.split("/")[0]}/${
          this.startDate.split("/")[2]
        } ${this.startHour.split(":")[0]}:${this.startHour.split(":")[1]}
          `
      ).getTime();

      let finalDate = new Date(
        `${this.finalDate.split("/")[1]}/${this.finalDate.split("/")[0]}/${
          this.finalDate.split("/")[2]
        } ${this.finalHour.split(":")[0]}:${this.finalHour.split(":")[1]}
          `
      ).getTime();
      await this._event.addEventCoupon(
        this.eventParam._id,
        this.code,
        this.value,
        this.isPercentage,
        startDate,
        finalDate,
        selected
      );
      this.alertService.success("ADDED-COUPON");

      this.loadCoupons();
    } catch (error) {
      this.alertService.danger(error);
    } finally {
      this.isCouponsLoading = false;
    }
  }
}
