<div class="container-scroller" *ngIf="!isOutRouter()">
  <app-navbar></app-navbar>
  <div class="container-fluid page-body-wrapper">
    <app-sidebar></app-sidebar>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="container-fluid container-limited">
          <router-outlet></router-outlet>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>

<router-outlet *ngIf="isOutRouter()"></router-outlet>
<ngx-alerts></ngx-alerts>
