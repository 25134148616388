<div class="modal-header">
  <h4 class="modal-title" translate>EVENT.EDIT</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="editEventForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="name" translate>NAME</label>
          <input
            type="text"
            formControlName="name"
            id="name"
            class="form-control"
          />
          <div *ngIf="submitted && f.name.invalid" class="alert alert-danger">
            <small
              *ngIf="f.name.errors.required"
              class="form-text text-danger"
              translate
              >NAME-NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="address" translate>ADDRESS</label>
          <input
            type="text"
            formControlName="address"
            id="address"
            class="form-control"
            #search
          />
          <div
            *ngIf="submitted && f.address.invalid"
            class="alert alert-danger"
          >
            <small
              *ngIf="f.address.errors.required"
              class="form-text text-danger"
              translate
              >ADDRESS-NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="phone" translate>PHONE</label>
          <input
            type="text"
            formControlName="phone"
            id="phone"
            class="form-control"
          />
          <div *ngIf="submitted && f.phone.invalid" class="alert alert-danger">
            <small
              *ngIf="f.phone.errors.required"
              class="form-text text-danger"
              translate
              >PHONE-NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="email" translate>EMAIL</label>
          <input
            type="text"
            formControlName="email"
            id="email"
            class="form-control"
          />
          <div *ngIf="submitted && f.email.invalid" class="alert alert-danger">
            <small
              *ngIf="f.email.errors.required"
              class="form-text text-danger"
              translate
              >EMAIL-NEEDED</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="activeModal.close('Close click')"
      translate
    >
      CLOSE
    </button>
    <button
      type="submit"
      class="btn btn-sm-block btn-gradient-primary mr-2 float-right"
      translate
    >
      SAVE
    </button>
  </div>
</form>
