import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  NgbActiveModal,
  NgbCalendar,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { EventService } from "src/app/services/event.service";

import { FinancialService } from "src/app/services/financial.service";
import { PdfService } from "src/app/services/pdf.service";

@Component({
  selector: "app-financial-users-detail",
  templateUrl: "./financial-users-detail.component.html",
  styleUrls: ["./financial-users-detail.component.scss"],
})
export class FinancialUsersDetailComponent implements OnInit {
  userTakeoffs: any = [];
  isTakeoffsLoading = false;
  userStalls: any = [];
  isStallsLoading = false;
  stalls: any = [];
  selectedTakeoffs = [];
  selectedStalls = [];
  allStallsSelected = false;
  allTakeoffsSelected = false;
  payedTakeoffs = [];
  payedStalls = [];
  paymentValue = 0;
  constructor(
    public modal: NgbActiveModal,
    private _financial: FinancialService,
    private _event: EventService,
    private pdfService: PdfService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.listUserStalls();
    this.listCompetitorTakeoffs();
  }
  @Input() user;
  @Input() event;
  @Output() getEventUsersFinancial: EventEmitter<any> = new EventEmitter();

  eventUsersFinancial() {
    this.getEventUsersFinancial.emit();
  }

  async loadStalls() {
    try {
      this.stalls = await this._event.getEventStalls(this.event._id);
    } catch (error) {
      console.log(error);
    }
  }

  async listCompetitorTakeoffs() {
    try {
      this.isTakeoffsLoading = true;
      this.userTakeoffs = await this._financial.getCompetitorTakeoffsByUser(
        this.event._id,
        this.user._id
      );

      this.userTakeoffs = this.userTakeoffs
        .sort((a, b) =>
          a.competition.category._id > b.competition.category._id
            ? 1
            : b.competition.category._id > a.competition.category._id
            ? -1
            : 0
        )
        .sort((a, b) =>
          a.horse._id > b.horse._id ? 1 : b.horse._id > a.horse._id ? -1 : 0
        );
      this.userTakeoffs = this.userTakeoffs
        .map((tk, i) => {
          if (!tk.hasPass && tk.pass > 1) {
            tk.price += this.userTakeoffs[i - 1].price;
          }
          if (!tk.hasPass && tk.pass > 2)
            tk.price += this.userTakeoffs[i - 2].price;
          return tk;
        })
        .sort((a, b) =>
          a.competition.category.name > b.competition.category.name
            ? 1
            : b.competition.category.name > a.competition.category.name
            ? -1
            : 0
        );
      this.isTakeoffsLoading = false;
    } catch (error) {
      console.log(error);
    }
  }

  async listUserStalls() {
    await this.loadStalls();
    if (this.stalls.length > 0) {
      try {
        this.isStallsLoading = true;
        this.userStalls = await this._financial.getUserStalls(
          this.event._id,
          this.user._id
        );
        this.isStallsLoading = false;
      } catch (error) {
        console.log(error);
      }
    }
  }

  filterSelectedTakeoffs() {
    return this.userTakeoffs.filter((e, i) => this.selectedTakeoffs[i]);
  }

  filterSelectedStalls() {
    return this.userStalls.filter((e, i) => this.selectedStalls[i]);
  }

  filterStallsTypes(data: any) {
    return new Set(data.map((item) => item.stall.stallType));
  }

  filterStallsFromType(data: any, stallType: any) {
    return data.filter((x) => x.stall.stallType == stallType).length;
  }

  selectAllStalls() {
    if (this.allStallsSelected) {
      this.selectedStalls = this.userStalls.map((e) => true);
      this.allStallsSelected = true;
    } else {
      this.selectedStalls = [];
      this.allStallsSelected = false;
    }
  }

  selectAllTakeoffs() {
    if (this.allTakeoffsSelected) {
      this.selectedTakeoffs = this.userTakeoffs.map((e) => true);
      this.allTakeoffsSelected = true;
    } else {
      this.selectedTakeoffs = [];
      this.allTakeoffsSelected = false;
    }
  }

  loadPayedTakeoffs(item: any) {
    this.payedTakeoffs = item.flat();
  }

  checkPayedTakeoffs(id: string) {
    return this.payedTakeoffs.filter((e) => e._id == id).length > 0;
  }

  loadPayedStalls(item: any) {
    this.payedStalls = item.flat();
  }

  loadPaymentValue(item: any) {
    this.paymentValue = item;
  }

  checkPayedStalls(id: string) {
    return this.payedStalls.filter((e) => e._id == id).length > 0;
  }

  countTotal() {
    const tks = this.userTakeoffs
      .map((x) => x.price)
      .reduce((a, b) => a + b, 0);
    const stalls = this.userStalls
      .map((x) => x.stall.price)
      .reduce((a, b) => a + b, 0);
    return tks + stalls - this.paymentValue;
  }

  checkSelectTakeoff(index, item) {
    this.selectedTakeoffs[index] = !this.selectedTakeoffs[index];
  }

  checkSelectStall(index) {
    this.selectedStalls[index] = !this.selectedStalls[index];
  }

  async openChooseModal(content) {
    const result = await this.modalService.open(content, { size: "sm" }).result;
    this.submitGenPdfTakeoffs(result);
  }

  submitGenPdfTakeoffs(orientation: string) {
    this.pdfService.genTakeoffs(
      this.event,
      this.user.customer,
      this.filterSelectedTakeoffs(),
      this.filterSelectedStalls(),
      orientation
    );
  }
}
