import { Component, Input, OnInit } from "@angular/core";
import { RankService } from "src/app/services/rank.service";
import { TypeGroupService } from "src/app/services/typeGroup.service";

@Component({
  selector: "app-event-categories-rank",
  templateUrl: "./event-categories-rank.component.html",
  styleUrls: ["./event-categories-rank.component.scss"],
})
export class EventCategoriesRankComponent implements OnInit {
  @Input() event: any;
  constructor(
    private rankSevice: RankService,
    private typegroupService: TypeGroupService
  ) {}

  ngOnInit(): void {}

  async setRank(rankCategoryId: string) {
    await this.rankSevice.setRank(this.event._id, rankCategoryId);
  }

  async setTypegroupRank() {
    await this.typegroupService.setTypegroupRank(this.event._id);
  }
}
