import { Component, OnInit } from "@angular/core";
import { EventService } from "src/app/services/event.service";
import { ActivatedRoute } from "@angular/router";
import { EventUser } from "src/app/dto/EventUser";
import ErrorHandler from "../../util/errorHandler";
import { AlertTranslateService } from "src/app/services/alert.service";

@Component({
  selector: "app-event-admins",
  templateUrl: "./event-admins.component.html",
  styleUrls: ["./event-admins.component.scss"],
})
export class EventAdminsComponent implements OnInit {
  event: any;
  owner: Object;
  admin;
  username;
  isUpdate: Boolean = false;
  hasInfo: Boolean = true;
  hasCategory: Boolean = true;
  hasAdmin: Boolean = true;
  hasLive: Boolean = true;

  constructor(
    private _event: EventService,
    private route: ActivatedRoute,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {
    this.loadTables();
  }

  async loadTables() {
    const params: any = await this.route.params;
    this.event = await this._event.getEvent(params.value.slug);
    this.owner = this.event.owner;
    this.admin = await this._event.getEventUsers(this.event._id);
  }

  async submitUser() {
    const eventUser = new EventUser();
    eventUser.email = this.username;
    eventUser.hasAdmin = this.hasAdmin;
    eventUser.hasCategory = this.hasCategory;
    eventUser.hasInfo = this.hasInfo;
    eventUser.hasLive = this.hasLive;
    try {
      await this._event.addEventUser(eventUser, this.event._id);
      this.loadTables();
      this.alertService.success(this.isUpdate ? "UPDATED-USER" : "ADDED-USER");

      this.setUpdateFalse();
    } catch (ex) {
      this.alertService.danger(ErrorHandler(ex.error.message));
    }
  }

  async deleteUser(userId: string, email: string) {
    if (await this.alertService.confirm("CONFIRM-REMOVE-USER", email)) {
      try {
        await this._event.deleteEventUser(this.event._id, userId);
        this.loadTables();
        this.alertService.success("DELETED-USER");
        this.setUpdateFalse();
        this.loadTables();
      } catch (ex) {
        this.alertService.danger(ErrorHandler(ex.error.message));
      }
    }
  }

  setUpdateFalse() {
    this.isUpdate = false;
    this.username = "";
    this.hasInfo = false;
    this.hasCategory = false;
    this.hasAdmin = false;
    this.hasLive = false;
  }

  editUser(event: any) {
    this.isUpdate = true;
    this.username = event.user.email;
    this.hasInfo = event.hasInfo;
    this.hasCategory = event.hasCategory;
    this.hasAdmin = event.hasAdmin;
    this.hasLive = event.hasLive;
  }
}
