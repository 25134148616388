import {
  Component,
  OnInit,
  Input,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from "@angular/core";
import { Socket } from "ngx-socket-io";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "tbody[app-pass]",
  templateUrl: "./pass.component.html",
  styleUrls: ["./pass.component.scss"],
})
export class PassComponent implements OnInit {
  @ViewChildren("scoreInput") public scoreInput: QueryList<any>;
  @Input() public competition;
  @Input() private event;
  @Input() private selectedCompetitionIndex;
  @Input() public takeOffs: any = [];
  @Input() public isInDate: false;
  @Input() public isPassedDate: false;

  private score: Array<any> = [];
  private isEditingScore: Array<boolean> = [];

  isAnyScoreLoading = false;
  isAdmin = false;

  isNewScore = [];
  isAlreadySetScore = false;
  lastScoreSet = -1;

  constructor(
    private socket: Socket,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getToken();
    this.loadTakeOffs();
  }
  getToken() {
    this.isAdmin =
      environment.adminId.filter(
        (adm) => adm == this.authService.decodeToken().id
      ).length > 0;
  }

  loadTakeOffs() {
    this.isAlreadySetScore = true;

    this.score = [];
    this.takeOffs.forEach((tk, i) => {
      this.score.push(tk.oldScore ? tk.oldScore : tk.score);

      this.isAnyScoreLoading = false;
    });
    if (this.score.length > 0) {
      this.takeOffs.forEach((tk, i) => {
        this.isNewScore[i] =
          this.score[i] != (tk.oldScore ? tk.oldScore : tk.score);
      });
    }
  }

  async setScore(takeOffId: String, index: number) {
    if (this.isAnyScoreLoading) return;
    this.isEditingScore[index] = false;

    this.isAnyScoreLoading = true;
    // this.timer(index);
    this.socket.emit("post:takeoff/score", {
      eventId: this.event._id,
      competitionForTakeOff: this.competition._id,
      takeOffId,
      score: this.score[index],
    });
    this.lastScoreSet = index;
    this.changeDetector.detectChanges();
    this.loadTakeOffs();
    // if(this.scoreInput.toArray()[index+1])this.scoreInput.toArray()[index+1].nativeElement.focus();
  }

  editScore(i: number) {
    this.isEditingScore[i] = true;
  }
}
