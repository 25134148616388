<div class="row">
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body card-body-fill">
        <h4 class="card-title" translate>ACCOUNT-DETAILS</h4>
        <p class="card-description" translate>EDIT-ACCOUNT-DETAILS</p>
        <form class="pt-3" [formGroup]="infoForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name" translate>USER</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="{{ 'USER' | translate }}"
                  formControlName="name"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="cpforcnpj" translate>CPFCNPJ</label>
                <input
                  type="text"
                  class="form-control"
                  id="cpforcnpj"
                  disabled
                  formControlName="cpforcnpj"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="email" translate>EMAIL</label>
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="{{ 'EMAIL' | translate }}"
              formControlName="email"
            />
            <div class="form-group">
              <label for="password" translate>PASS</label>
              <input
                type="password"
                class="form-control"
                id="Password"
                placeholder="{{ 'PASS' | translate }}"
                formControlName="password"
              />
            </div>
            <div class="form-group">
              <label for="confrimpassword" translate>CONFIRMPASS</label>
              <input
                type="password"
                class="form-control"
                placeholder="{{ 'CONFIRMPASS' | translate }}"
                formControlName="confirmPassword"
              />
            </div>
          </div>
          <button type="submit" class="btn btn-gradient-primary mr-2" translate>
            CONFIRM-CHANGES
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
