<div class="row">
  <div class="col-md-6 mb-2">
    <input
      type="text"
      class="form-control"
      placeholder="{{ 'DESCRIPTION' | translate }}"
      [(ngModel)]="inputDescription"
    />
    <small class="form-text text-muted" translate
      >FINANCIAL-GENERAL.ACCOUNTINGS.ENTER-DESCRIPTION</small
    >
  </div>
  <div class="col-md-2 mb-2">
    <input
      type="text"
      class="form-control"
      placeholder="{{ 'VALUE' | translate }}"
      [(ngModel)]="inputValue"
    />
    <small class="form-text text-muted" translate
      >FINANCIAL-GENERAL.ACCOUNTINGS.ENTER-VALUE</small
    >
  </div>

  <div class="col-md-2 mb-2">
    <select required class="form-control" [(ngModel)]="selectType">
      <option value="INCOME">Receita</option>
      <option value="EXPENSE">Despesa</option>
    </select>
    <small class="form-text text-muted" translate>SELECT-ONE</small>
  </div>

  <div class="col-md-2">
    <button
      type="submit"
      class="btn btn-block btn-sm btn-gradient-primary"
      (click)="submitAccount()"
      [disabled]="!inputDescription || !inputValue || !selectType"
      translate
    >
      ADD
    </button>
  </div>
</div>
<div class="row">
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th translate>DESCRIPTION</th>
          <th translate>INCOME</th>
          <th translate>EXPENSE</th>
          <th translate>PAID</th>
          <th translate>BALANCE</th>
          <th></th>
          <th></th>
        </tr>
      </thead>

      <tbody *ngIf="accountingsLoading">
        <tr>
          <td colspan="6">
            <div
              class="
                lds-dual-ring lds-dual-ring-black
                d-flex
                justify-content-center
                w-100
              "
            ></div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!accountingsLoading && accountings.length == 0">
        <tr>
          <td colspan="6" translate>
            FINANCIAL-GENERAL.ACCOUNTINGS.NO-EVENT-BUDGET
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="accountings.length > 0 && !accountingsLoading">
        <tr *ngFor="let item of accountings">
          <td>
            {{ item.description }}
          </td>
          <td>
            {{ event.currency | currencyPipe
            }}{{ item.type == "INCOME" ? item.value : "-" }}
          </td>
          <td>
            {{ event.currency | currencyPipe
            }}{{ item.type == "EXPENSE" ? item.value : "-" }}
          </td>
          <td>
            {{ event.currency | currencyPipe }}{{ item.paid ? item.paid : "0" }}
          </td>
          <td>
            {{ event.currency | currencyPipe
            }}{{ item.balance ? item.balance : "0" }}
          </td>
          <td>
            <div
              class="btn btn-outline-info"
              (click)="open(item._id)"
              translate
            >
              PAYMENTS
            </div>
          </td>
          <td>
            <div
              class="btn btn-outline-primary mdi mdi-for-btn mdi-delete-forever"
              (click)="deleteAccounting(item._id)"
            ></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
