import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { AlertTranslateService } from "src/app/services/alert.service";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
})
export class CategoriesComponent implements OnInit {
  @Input() public competition;
  @Input() public event;
  @Output() private tab = new EventEmitter<string>();
  competitionDays = [[], []];

  constructor(
    private socket: Socket,
    private alertService: AlertTranslateService
  ) {}

  async ngOnInit() {
    this.mapCompetition();
  }

  async activeCategory(competitionId, eventId) {
    if (await this.alertService.confirm("CONFIRM-ACTIVE-CATEGORY")) {
      this.socket.emit("post:event/competition/active", {
        eventId,
        competitionId,
      });
    }
  }

  async disableCategory(competitionId, eventId) {
    if (await this.alertService.confirm("CONFIRM-DEACTIVATE-CATEGORY")) {
      this.socket.emit("post:event/competition/disable", {
        eventId,
        competitionId,
      });
    }
  }

  openCategory(categoryId: string) {
    this.tab.emit(categoryId);
  }

  mapCompetition() {
    this.event.eventDays.forEach((day) => {
      this.competitionDays[0].push({
        day: day,
        competitions: this.competition.filter(
          (c) => c.date === day && !c.hasPass && !c.isFinished
        ),
      });
      this.competitionDays[1].push({
        day: day,
        competitions: this.competition.filter(
          (c) => c.date === day && !c.hasPass && c.isFinished
        ),
      });
    });
  }
}
