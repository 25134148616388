import { Component, OnInit } from "@angular/core";
import { EventService } from "src/app/services/event.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/auth.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventEditComponent } from "../event-edit/event-edit.component";

import { EventSubscriptionlistComponent } from "../event-subscriptionlist/event-subscriptionlist/event-subscriptionlist.component";
import { EventTypeGroupInfoComponent } from "../event-type-group-info/event-type-group-info.component";

@Component({
  selector: "app-event-home",
  templateUrl: "./event-home.component.html",
  styleUrls: ["./event-home.component.scss"],
})
export class EventHomeComponent implements OnInit {
  event;
  permission: any = {
    hasCategory: false,
    hasAdmin: false,
    hasLive: false,
    hasInfo: false,
  };
  apiUrl;
  params: any = null;

  JSON: any = JSON;

  constructor(
    private _event: EventService,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.apiUrl = environment.apiUrl;
    this.params = await this.route.params;
    this.initEventInfo();
  }

  async initEventInfo() {
    this.event = await this._event.getEvent(this.params.value.slug);
    this.event.coverUrl = JSON.stringify(this.event.coverUrl);
    this.permission = await this._event.getEventUserRoles(this.event._id);
  }

  hasUsagePermission() {}

  openTab(tab) {
    this.router.navigate([`event/${this.params.value.slug}/${tab}`]);
  }

  getTab() {
    return this.params.value.tab;
  }

  goToLive() {
    if (this.permission.hasLive)
      this.router.navigate([`event/${this.params.value.slug}/live`]);
  }

  open() {
    const modalEdit = this.modalService.open(EventEditComponent);
    modalEdit.componentInstance.event = this.event;
    modalEdit.result.then((result) => {
      if (result == "updated") this.initEventInfo();
    });
  }

  openActiveSubscriptionlist() {
    const modalEdit = this.modalService.open(EventSubscriptionlistComponent);
    modalEdit.componentInstance.event = this.event;
  }

  openTypeGroup() {
    const modalTypeGroup = this.modalService.open(EventTypeGroupInfoComponent);
    modalTypeGroup.componentInstance.event = this.event;
  }
}
