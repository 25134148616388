<footer class="footer">
  <div class="d-sm-flex justify-content-center">
    <span class="text-muted text-center text-sm-left d-block d-sm-inline-block"
      >Copyright © 2020
      <a href="https://www.horsecs.com.br/" target="https://horsecs.com.br"
        >Horse Club System</a
      >. All Rights Reserved. 1.0.0</span
    >
  </div>
</footer>
