import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  user;
  constructor(private auth: AuthService, public route: Router) {}

  ngOnInit() {
    document
      .querySelectorAll(".nav-link")
      .forEach(item =>
        item.addEventListener("click", () =>
          document
            .querySelector(".sidebar-offcanvas")
            .classList.remove("active")
        )
      );

    this.user = this.auth.getInfos();
  }
}
