import { Component, Input, OnInit } from "@angular/core";
import { AlertTranslateService } from "src/app/services/alert.service";
import { AbqmService } from "src/app/services/abqm.service";
import { EventService } from "src/app/services/event.service";

@Component({
  selector: "app-event-categories-abqm-result",
  templateUrl: "./event-categories-abqm-result.component.html",
  styleUrls: ["./event-categories-abqm-result.component.scss"],
})
export class EventCategoriesAbqmResultComponent implements OnInit {
  @Input() event: any;
  competitions: any;
  allCompetitions: any;
  abqmId = [];

  constructor(
    private _event: EventService,
    private _abqm: AbqmService,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {
    this.loadCompetitions();
  }

  async loadCompetitions() {
    this.allCompetitions = await this._event.getEventAllCompetitions(
      this.event._id
    );
    this.competitions = this.allCompetitions.filter(
      (x) => !x.hasPass && x.abqmId != ""
    );
    for (let i = 0; i < this.competitions.length; i++) {
      this.abqmId.push(
        this.competitions[i].abqmId ? this.competitions[i].abqmId : ""
      );
    }
  }

  async insertParticipation(i) {
    try {
      await this._abqm.setCompetitionAbqmParticipation(
        this.event._id,
        this.competitions[i]._id
      );
      this.alertService.success("UPDATED-SETTINGS");
    } catch (e) {
      console.log(e);
      this.alertService.danger("ERROR2" + e.message);
    }
  }

  async finishCompetition(i) {
    try {
      const resp = await this._abqm.setFinishCompetitionAbqm(
        this.event._id,
        this.competitions[i]._id
      );
      this.alertService.success(resp[0].gravado);
    } catch (e) {
      console.log(e);
      this.alertService.danger("ERROR2" + e.message);
    }
  }
}
