import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { AlertTranslateService } from "src/app/services/alert.service";
import { Router } from "@angular/router";
import { RegisterUser } from "src/app/dto/RegisterUser";
import { InfoUser } from "src/app/dto/InfoUser";
import ErrorHandler from "../../../../util/errorHandler";

@Component({
  selector: "app-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"],
})
export class InfoComponent implements OnInit {
  infoForm: FormGroup;
  submitted: boolean = false;

  async ngOnInit() {
    const user = this.auth.getInfos();
    const info: any = await this.auth.getUserDetails(user.id);
    const { name, email, cpforcnpj } = info;
    this.infoForm = this.formBuilder.group({
      name: [name, [Validators.required]],
      email: [email, [Validators.required]],
      cpforcnpj: [cpforcnpj, [Validators.required]],
      password: ["", [Validators.required]],
      confirmPassword: ["", [Validators.required]],
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private alertService: AlertTranslateService
  ) {}

  goBack() {
    this.router.navigate(["/dashboard"]);
  }

  get f() {
    return this.infoForm.controls;
  }

  async onSubmit() {
    if (!this.infoForm.valid) return;
    this.submitted = true;
    const { email, password } = this.infoForm.controls;
    const user = new InfoUser();
    user.login = email.value;
    user.password = password.value;

    try {
      // console.log(localStorage.getItem("currentUser"));
      // await this.auth.updateUserDetails(user)
      // const login = await this.auth.Login(user);
      // this.alertService.success("Alterado com sucesso!");
      // this.submitted = false;
      // localStorage.setItem("currentUser", JSON.stringify(login));
      // window.location.href = "/dashboard";
    } catch (ex) {
      this.alertService.danger(ErrorHandler(ex.error.message));
      this.submitted = false;
    }
  }
}
