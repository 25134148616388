<div class="card-body">
  <h4 class="card-title" translate>SUBSCRIPTIONS</h4>
  <!-- <p class="card-description" *ngIf="!eventFinished">
    Adicione novas inscrições
  </p> -->
  <div class="row">
    <div class="col-lg-6 mb-2">
      <div class="input-group">
        <div class="chip form-control-chip" *ngIf="searchedCustomer">
          <div class="badge badge-secondary">
            {{ this.searchedCustomer }}
            <span class="closebtn" (click)="setCustomerNotSearched()"
              >&times;</span
            >
          </div>
        </div>
        <ng-template #rtCustomer let-r="result" let-t="term">
          <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
          - {{ r.cpforcnpj }}
        </ng-template>
        <div class="inputcontainer" *ngIf="!searchedCustomer">
          <input
            type="text"
            class="form-control"
            [class.is-invalid]="searchCustomerFailed"
            [(ngModel)]="customer"
            [ngbTypeahead]="searchCustomer"
            placeholder="{{ 'CUSTOMER.INSERT' | translate }}"
            [inputFormatter]="formatterCustomer"
            [resultTemplate]="rtCustomer"
            (selectItem)="selectedCustomerItem($event)"
          />
          <div class="icon-container" *ngIf="searchingCustomer">
            <i class="loader"></i>
          </div>
        </div>
      </div>
      <small class="form-text text-muted" translate>CUSTOMER.INSERT</small>
    </div>
    <div class="col-lg-6 mb-2">
      <div class="input-group">
        <div class="chip form-control-chip" *ngIf="searchedCompetitor">
          <div class="badge badge-secondary">
            {{ this.searchedCompetitor }}
            <span class="closebtn" (click)="setCompetitorNotSearched()"
              >&times;</span
            >
          </div>
          <div class="badge badge-info check-bagde">
            <input
              class="form-check-input"
              type="checkbox"
              id="searchCompetitorCheck"
              [(ngModel)]="isCompetitorCore"
            />
            <label class="form-check-label" for="searchCompetitorCheck"
              >É Núcleo</label
            >
          </div>
        </div>
        <ng-template #rtCompetitor let-r="result" let-t="term">
          <div>
            <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight> -
            {{ r.cpf }}
          </div>
          <img
            *ngIf="r.returnedAbqm"
            class="search-logo"
            src="../../../assets/images/abqm.png"
            alt="abqm logo"
          />
        </ng-template>
        <div class="inputcontainer" *ngIf="!searchedCompetitor">
          <input
            type="text"
            class="form-control"
            [class.is-invalid]="searchCompetitorFailed"
            [(ngModel)]="competitor"
            [ngbTypeahead]="searchCompetitor"
            placeholder="{{ 'COMPETITOR.INSERT' | translate }}"
            [inputFormatter]="formatterCompetitor"
            [resultTemplate]="rtCompetitor"
            (selectItem)="selectedCompetitorItem($event)"
          />
          <div class="icon-container" *ngIf="searchingCompetitor">
            <i class="loader"></i>
          </div>
        </div>
      </div>
      <small class="form-text text-muted" translate>COMPETITOR.INSERT</small>
    </div>
    <div class="col-lg-5 mb-2">
      <div class="input-group">
        <div class="chip form-control-chip" *ngIf="searchedAnimal">
          <div class="badge badge-secondary">
            {{ this.searchedAnimal }}
            <span class="closebtn" (click)="setAnimalNotSearched()"
              >&times;</span
            >
          </div>
          <div class="badge badge-info check-bagde">
            <input
              class="form-check-input"
              type="checkbox"
              id="searchHorseCheck"
              [(ngModel)]="isHorseCore"
            />
            <label class="form-check-label" for="searchHorseCheck"
              >É Núcleo</label
            >
          </div>
        </div>
        <ng-template
          #rtHorse
          let-r="result"
          let-t="term"
          class="d-flex justify-content-between"
        >
          <div>
            <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
            - {{ r.registerId }}
          </div>
          <img
            *ngIf="r.returnedAbqm"
            class="search-logo"
            src="../../../assets/images/abqm.png"
            alt="abqm logo"
          />
        </ng-template>

        <div class="inputcontainer" *ngIf="!searchedAnimal">
          <input
            type="text"
            class="form-control"
            [class.is-invalid]="searchHorseFailed"
            [(ngModel)]="horse"
            [ngbTypeahead]="searchHorse"
            placeholder="{{ 'ANIMAL.INSERT' | translate }}"
            [inputFormatter]="formatterHorse"
            [resultTemplate]="rtHorse"
            (selectItem)="selectedAnimalItem($event)"
          />
          <div class="icon-container" *ngIf="searchingHorse">
            <i class="loader"></i>
          </div>
        </div>
      </div>
      <small class="form-text text-muted" translate>ANIMAL.INSERT</small>
    </div>
    <div class="col-md-3 mb-2">
      <input class="form-control" [(ngModel)]="code" />
      <small class="form-text text-muted" translate>COUPON.ENTER</small>
    </div>

    <div class="col-lg-2">
      <button
        *ngIf="!listedCompetitions"
        type="submit"
        class="btn btn-block btn-sm btn-gradient-info"
        (click)="listCompetitions()"
        [disabled]="
          !searchedAnimal ||
          !searchedCompetitor ||
          !searchedCustomer ||
          isCouponLoading ||
          isCategoriesLoading
        "
      >
        <app-loading
          class="loading-for-btn"
          *ngIf="isCategoriesLoading"
        ></app-loading>
        <span *ngIf="!isCategoriesLoading" translate
          >EVENT.CATEGORIES.LIST-ALL</span
        >
      </button>
      <button
        *ngIf="listedCompetitions"
        type="submit"
        class="btn btn-block btn-sm btn-gradient-primary"
        (click)="submitSubscription()"
        [disabled]="
          !searchedAnimal ||
          !searchedCompetitor ||
          !searchedCustomer ||
          !HasSelectedCompetitions() ||
          isCouponLoading ||
          !competitor ||
          !horse
        "
        translate
      >
        ADD
      </button>
    </div>

    <div
      class="col-md-12"
      *ngIf="returnObjectReal(competitorRestrictions).length > 0"
    >
      <div class="alert alert-danger deny-alert">
        <small class="form-text text-black">
          {{ "COMPETITOR.RESTRICTED" | translate }}
          <span *ngFor="let item of returnObjectReal(competitorRestrictions)">
            {{ "VALIDATE-TAKEOFF." + item | translate }}
          </span></small
        >
      </div>
    </div>
    <div
      class="col-md-12"
      *ngIf="returnObjectReal(horseRestrictions).length > 0"
    >
      <div class="alert alert-danger deny-alert">
        <small class="form-text text-black">
          {{ "ANIMAL.RESTRICTED" | translate }}
          <span *ngFor="let item of returnObjectReal(horseRestrictions)">{{
            "VALIDATE-TAKEOFF." + item | translate
          }}</span></small
        >
      </div>
    </div>
  </div>

  <div class="row pt-3" *ngIf="searchedCustomer">
    <div class="col-md-12">
      <h3>{{ "SUBS-OF" | translate }} {{ customer.name }}</h3>
    </div>
  </div>
  <div class="row mt-4" *ngIf="searchedCustomer">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>
                <i
                  class="mdi"
                  [ngClass]="
                    filteredSelectedCustomerTakeoffs().length ===
                      customerTakeoffs.length && !isCustomerTakeoffsLoading
                      ? 'mdi-checkbox-marked-circle-outline'
                      : 'mdi-checkbox-blank-circle-outline'
                  "
                  (click)="toggleSelectAllCustomerTakeoffs()"
                ></i>
              </th>
              <th translate>CREATEDAT</th>
              <th translate>CATEGORY</th>
              <th translate>COMPETITOR.SINGLE</th>
              <th translate>ANIMAL.SINGLE</th>
              <th>
                <div ngbDropdown class="d-inline-block">
                  <button
                    class="btn btn-sm"
                    [ngClass]="
                      HasSelectedCustomerTakeoffs() || isCustomerTakeoffsLoading
                        ? 'btn-outline-primary'
                        : 'btn-dark'
                    "
                    [disabled]="
                      !HasSelectedCustomerTakeoffs() ||
                      isCustomerTakeoffsLoading
                    "
                    ngbDropdownToggle
                  >
                    <i class="mdi mdi-for-btn mdi-settings"></i>
                  </button>
                  <div ngbDropdownMenu>
                    <button
                      (click)="openChooseModal(content, 'GTA')"
                      ngbDropdownItem
                      translate
                    >
                      GENERATEGTA
                    </button>
                    <button
                      (click)="openChooseModal(content, 'TAKEOFF')"
                      ngbDropdownItem
                      translate
                    >
                      GENERATE-SUBSCRIPTION-LIST
                    </button>
                    <button
                      *ngIf="eventParam && !eventParam.isDoneRanking"
                      (click)="submitDeleteSubscription()"
                      ngbDropdownItem
                      translate
                    >
                      DELETE
                    </button>
                  </div>
                </div>
              </th>
              <th>
                <button
                  *ngIf="toggleIsChangeCustomer != index"
                  (click)="toggleIsChangeCustomer = index"
                  class="btn btn-sm"
                  [ngClass]="
                    HasSelectedCustomerTakeoffs() || isCustomerTakeoffsLoading
                      ? 'btn-warning'
                      : 'btn-dark'
                  "
                  [disabled]="
                    !HasSelectedCustomerTakeoffs() || isCustomerTakeoffsLoading
                  "
                  translate
                >
                  TRANSFER-SUBSCRIPTION
                </button>
                <div class="d-flex" *ngIf="toggleIsChangeCustomer == index">
                  <div style="flex: 3">
                    <div class="input-group">
                      <div
                        class="chip form-control-chip"
                        *ngIf="searchedChangeCustomer"
                      >
                        <div class="badge badge-secondary">
                          {{ this.searchedChangeCustomer }}
                          <span
                            class="closebtn"
                            (click)="setChangeCustomerNotSearched()"
                            >&times;</span
                          >
                        </div>
                      </div>
                      <ng-template
                        #rtChangeCustomer
                        let-r="result"
                        let-t="term"
                      >
                        <ngb-highlight
                          [result]="r.name"
                          [term]="t"
                        ></ngb-highlight>
                        - {{ r.cpforcnpj }}
                      </ng-template>
                      <div
                        class="inputcontainer"
                        *ngIf="!searchedChangeCustomer"
                      >
                        <input
                          type="text"
                          class="form-control"
                          [class.is-invalid]="searchChangeCustomerFailed"
                          [(ngModel)]="changeCustomer"
                          [ngbTypeahead]="searchChangeCustomer"
                          placeholder="Digite o nome do usuário"
                          [inputFormatter]="formatterChangeCustomer"
                          [resultTemplate]="rtChangeCustomer"
                          (selectItem)="selectedChangeCustomerItem($event)"
                        />
                        <div
                          class="icon-container"
                          *ngIf="searchingChangeCustomer"
                        >
                          <i class="loader"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      class="btn btn-sm btn-outline-info"
                      (click)="toggleIsChangeCustomer = -1"
                      [disabled]="isChangeCustomerLoading"
                      translate
                    >
                      CANCEL
                    </button>
                  </div>
                  <div>
                    <button
                      class="btn btn-sm btn-outline-primary"
                      (click)="submitChangeCustomer()"
                      [disabled]="isChangeCustomerLoading"
                      translate
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody *ngIf="isCustomerTakeoffsLoading">
            <tr>
              <td colspan="4" style="text-align: center">
                <app-loading></app-loading>
              </td>
            </tr>
          </tbody>
          <tbody
            *ngIf="!isCustomerTakeoffsLoading && customerTakeoffs.length == 0"
          >
            <tr>
              <td
                colspan="7"
                style="text-align: center"
                [translate]="{ name: customer.name }"
              >
                CUSTOMER.EMPTY-SUBSCRIPTIONS
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isCustomerTakeoffsLoading">
            <tr
              *ngFor="let takeoff of customerTakeoffs; let index = index"
              (click)="selectCustomerTakeoffs(index)"
              [ngClass]="{ 'table-success': selectedCustomerTakeoffs[index] }"
              style="cursor: pointer"
            >
              <td>
                <i
                  class="mdi"
                  [ngClass]="
                    selectedCustomerTakeoffs[index]
                      ? 'mdi-checkbox-marked-circle-outline'
                      : 'mdi-checkbox-blank-circle-outline'
                  "
                ></i>
              </td>
              <td>{{ takeoff.createdAt | date: "d/M/yy, H:mm" }}</td>
              <td>
                {{
                  takeoff.competition.category.name | translateCategory | async
                }}
                -
                {{ takeoff.competition.pass }}
                <span translate>{{
                  takeoff.competition.pass > 1 ? "TAKEOFFS" : "TAKEOFF"
                }}</span>
                -
                {{ takeoff.competition.date | date: "EEEE dd/MM" | titlecase }}
              </td>
              <td>{{ takeoff.competitor.name }}</td>
              <td>{{ takeoff.horse.name }}</td>

              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row pt-3" *ngIf="listedCompetitions">
    <div class="col-md-12">
      <h3 translate>CATEGORIES</h3>
    </div>
  </div>
  <div class="row mt-4" *ngIf="competitions && listedCompetitions">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th translate>DATE</th>
              <th translate>CATEGORIES</th>
              <th translate>RULES</th>
              <!-- <th>Passadas</th> -->
              <th translate>PRICE</th>
            </tr>
          </thead>
          <tbody *ngIf="isCompetitionsLoading">
            <tr>
              <td colspan="4" style="text-align: center">
                <app-loading></app-loading>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isCompetitionsLoading && competitions.length == 0">
            <tr>
              <td colspan="4" style="text-align: center" translate>
                EVENT.CATEGORY.NOT-FOUND
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isCompetitionsLoading">
            <tr
              *ngFor="let comp of competitions; let index = index"
              (click)="selectCompetition(index)"
              [ngClass]="{ 'table-success': selectedCompetitions[index] }"
              style="cursor: pointer"
            >
              <td>
                <i
                  *ngIf="searchedCustomer && comp.rule.length == 0"
                  class="mdi"
                  [ngClass]="
                    selectedCompetitions[index]
                      ? 'mdi-checkbox-marked-circle-outline'
                      : 'mdi-checkbox-blank-circle-outline'
                  "
                ></i>
              </td>
              <td>
                {{ comp.date | date: "EEEE dd/MM" | titlecase }}
              </td>
              <td>
                {{ comp.category.name | translateCategory | async }} -
                {{ comp.pass }}ª
                {{ "TAKEOFF" | translate }}
              </td>
              <td>
                <span
                  [ngClass]="!isDisabled(rule) ? 'text-warning' : 'text-danger'"
                  *ngFor="
                    let rule of returnObjectReal(comp.rule);
                    let i = index
                  "
                >
                  <br *ngIf="i > 0" />
                  {{ "VALIDATE-TAKEOFF." + rule | translate }}
                </span>
              </td>
              <!-- <td>
                <span *ngIf="comp.pass == 1">1 Passada</span>
                <select
                  class="form-control"
                  *ngIf="comp.pass > 1"
                  [(ngModel)]="selectedPasses[index]"
                >
                  <option [value]="1">1 Passada</option>
                  <option [value]="2">2 Passadas</option>
                  <option [value]="3">3 Passadas</option>
                </select>
              </td> -->
              <td>{{ comp.price }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" translate>CHOOSELIST</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body"></div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('landscape')"
        translate
      >
        HORIZONTAL
      </button>
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('portrait')"
        translate
      >
        VERTICAL
      </button>
    </div>
  </ng-template>
</div>
