<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th translate>STALL-TYPE</th>
        <th translate>STALLS-QTY</th>
        <th translate>PRICE</th>
        <th translate>TOTAL</th>
      </tr>
    </thead>
    <tbody *ngIf="eventStalls.length == 0 && !isStallsLoading">
      <tr>
        <td colspan="9" class="text-center" translate>EVENT.NO-CATEGORIES</td>
      </tr>
    </tbody>
    <tbody *ngIf="isStallsLoading">
      <tr>
        <td colspan="9">
          <div
            class="
              lds-dual-ring lds-dual-ring-black
              d-flex
              justify-content-center
              w-100
            "
          ></div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="eventStalls.length > 0">
      <tr *ngIf="eventStalls[0].items.length == 0 && !isStallsLoading">
        <td colspan="9" class="text-center" translate>NO-STALLS</td>
      </tr>

      <tr *ngFor="let item of eventStalls[0].items">
        <td>
          {{ item.name }}
        </td>
        <td>
          {{ item.stalls }}
        </td>
        <td>{{ event.currency | currencyPipe }}{{ item.price }}</td>
        <td>{{ event.currency | currencyPipe }}{{ item.total }}</td>
      </tr>
      <tr *ngIf="eventStalls[0].items.length > 0 && !isStallsLoading">
        <td translate>TOTAL</td>
        <td>
          {{ eventStalls[0].totalReserved }}
        </td>
        <td>{{ event.currency | currencyPipe }}{{ eventStalls[0].price }}</td>
        <td>
          {{ event.currency | currencyPipe }}{{ eventStalls[0].stallsTotal }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
