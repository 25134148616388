import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertTranslateService } from "src/app/services/alert.service";
import { Animal } from "src/app/dto/Animal";
import { EventService } from "src/app/services/event.service";
import { AnimalAddComponent } from "../animal-add/animal-add.component";

@Component({
  selector: "app-animal-list",
  templateUrl: "./animal-list.component.html",
  styleUrls: ["./animal-list.component.scss"],
})
export class AnimalListComponent implements OnInit {
  animals = [];
  animalQuery: string = "";
  page = 0;
  count = null;

  constructor(
    private _event: EventService,
    private modalService: NgbModal,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit(): void {
    this.getAllAnimals();
  }

  async getAllAnimals() {
    try {
      const query: any = await this._event.getAllAnimals(this.page);
      this.animals = [...this.animals, ...query.resHorse];
      this.count = query.count;
      this.page += 1;
    } catch (error) {}
  }

  async editChip(_id: string, chip: string) {
    await this._event.editAnimalChip(_id, chip);
    this.alertService.success("UPDATED-ANIMAL-CHIP");
  }

  async searchAnimal() {
    if (this.animalQuery !== "") {
      this.animals = Object.assign(
        await this._event.searchHorse(this.animalQuery).toPromise()
      );
    }
  }

  clearAnimal() {
    this.animalQuery = "";
    this.getAllAnimals();
  }

  openAddAnimal(animal: Animal) {
    const modalRef = this.modalService.open(AnimalAddComponent, { size: "lg" });
    modalRef.componentInstance.animal = animal;
    modalRef.result.then(
      (close) => {
        this.getAllAnimals();
      },
      (dismiss) => {}
    );
  }
}
