import { Component, OnInit, Input } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FinancialCompetitorsDetailsComponent } from "./financial-competitors-details/financial-competitors-details.component";
import { Utils } from "src/app/util/utils";

@Component({
  selector: "app-financial-competitors",
  templateUrl: "./financial-competitors.component.html",
  styleUrls: ["./financial-competitors.component.scss"],
})
export class FinancialCompetitorsComponent implements OnInit {
  @Input() event;
  competitors: any = [];
  competitorsPerm: any = [];
  isCompetitorsLoading = true;
  competitorFilter: string = "";

  constructor(
    private financial: FinancialService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    if (Object.keys(this.event).length !== 0) {
      // console.log(this.event);
      this.getEventCompetitorsFinancial(this.event._id);
    }
  }

  async getEventCompetitorsFinancial(event: string) {
    this.isCompetitorsLoading = true;
    this.competitorsPerm = await this.financial.getEventCompetitorsFinancial(
      event
    );

    this.competitors = this.competitorsPerm;
    this.isCompetitorsLoading = false;
  }

  clearCompetitorFilter() {
    this.competitorFilter = "";
    this.competitors = this.competitorsPerm;
  }

  filterSearch() {
    this.competitorFilter = this.competitorFilter.toUpperCase();
    this.competitors = this.competitorsPerm.filter((x) => {
      if (this.competitorFilter.length == 0) return true;
      return Utils.removeAccent(x.competitor.name.toUpperCase()).includes(
        this.competitorFilter
      );
    });
  }

  open(competitor) {
    const modalRef = this.modalService.open(
      FinancialCompetitorsDetailsComponent,
      {
        size: "lg",
      }
    );
    modalRef.componentInstance.competitor = competitor;
    modalRef.componentInstance.event = this.event;
  }
}
