import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { EventService } from "src/app/services/event.service";
import { Socket } from "ngx-socket-io";
import { CategoryComponent } from "./category/category.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertTranslateService } from "src/app/services/alert.service";

@Component({
  selector: "app-event-live",
  templateUrl: "./event-live.component.html",
  styleUrls: ["./event-live.component.scss"],
})
export class EventLiveComponent implements OnInit {
  public tab = 2;
  public params;
  public event: any;
  public competition: any;
  public selectedCompetition: any;
  public selectedCompetitionIndex: number = 0;
  confirmModal = "";
  wrongName = false;
  activeId = 1;
  firstCompetition: any;

  @ViewChild(CategoryComponent) category: CategoryComponent;

  constructor(
    public route: ActivatedRoute,
    public _event: EventService,
    public socket: Socket,
    private modalService: NgbModal,
    private alertService: AlertTranslateService
  ) {}

  async ngOnInit() {
    this.params = await this.route.params;
    this.getEvent();
  }

  tabToggle(tab: number) {
    this.activeId = 1;
    this.tab = tab;
  }

  async getCompetitions(eventId: string) {
    // const dataTemp = await this._event.getEventLiveCompetitions(eventId);
    // this.competition = dataTemp;
    // console.log(dataTemp)
    this.socket.emit("get:event/competition", { eventId });
    this.socket.on("competition", (data) => {
      this.competition = data;
    });
    this.socket.on("event", (data) => {
      this.event = data;
    });
  }

  async getEvent() {
    this.event = await this._event.getEvent(this.params.value.slug);
    this.getCompetitions(this.event._id);
  }

  openCategory(event) {
    this.tabToggle(1);
    this.selectedCompetitionIndex = event;
    this.selectedCompetition = this.competition.filter(
      (cat) => cat._id == event
    )[0];
    this.firstCompetition = this.selectedCompetition;
    this.selectCategory(1);
  }

  selectCategory(tab: number) {
    this.selectedCompetitionIndex = this.selectedCompetition._id;
  }

  FinishEvent() {
    this.socket.emit("post:event/finish", {
      eventId: this.event._id,
    });
  }

  async confirmFinishEvent(content) {
    let finish = false;
    await this.modalService.open(content).result.then(
      (result) => {
        if (this.confirmModal != this.event.name) {
          this.wrongName = true;
        } else {
          finish = true;
          this.wrongName = false;
        }
      },
      (reason) => {
        finish = false;
      }
    );
    if (finish) {
      this.FinishEvent();
    } else
      this.alertService.warning(
        "Não foi possível deletar o evento, verificação inválida."
      );
  }

  async toggleAlert(onRepair) {
    if (await this.alertService.confirm("CONFIRM-ALERT")) {
      this.socket.emit("post:takeoff/alert", {
        eventId: this.event._id,
        alert: onRepair ? "NONE" : "REPAIR",
      });
    }
  }
}
