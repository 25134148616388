import { Component, OnInit } from "@angular/core";
import { Customer } from "../dto/Customer";
import { EventService } from "../services/event.service";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
})
export class CustomerComponent implements OnInit {
  customers: Array<Customer> = [];
  customerQuery: string = "";
  count = null;
  page = 0;

  constructor(private _event: EventService) {}

  ngOnInit(): void {
    this.getAllCustomers();
  }

  async getAllCustomers() {
    try {
      const query: any = await this._event.getAllCustomers(this.page);
      this.customers = [...this.customers, ...query.resCustomer];
      this.count = query.count;
      this.page += 1;
    } catch (error) {}
  }

  async searchCustomer() {
    if (this.customerQuery !== "") {
      this.customers = Object.assign(
        await this._event.advancedSearchCustomer(this.customerQuery).toPromise()
      );
    }
  }

  clearCustomer() {
    this.customerQuery = "";
    this.getAllCustomers();
  }
}
