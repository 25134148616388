import { Component, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { EventService } from "../services/event.service";

@Component({
  selector: "app-activity-registry",
  templateUrl: "./activity-registry.component.html",
  styleUrls: ["./activity-registry.component.scss"],
})
export class ActivityRegistryComponent implements OnInit {
  events = [];
  selectedEvent;
  isEventsLoading: boolean = true;
  activityRegistry = [];
  activityRegistryCount = 0;
  page = 1;
  actions = [
    "",
    "CREATE",
    "UPDATE",
    "DELETE",
    "COUPONSTATUS",
    "STALLSTATUS",
    "RANKINGSTATUS",
    "AWARDSTATUS",
    "RULESTATUS",
    "SETTINGSTATUS",
    "UPDATEACTIVESUBSCRIPTION",
    "UPDATEACTIVESUBSCRIPTIONALL",
  ];
  action = this.actions[0];
  endReached = false;

  constructor(private auth: AuthService, private event: EventService) {}

  async ngOnInit() {
    this.isEventsLoading = true;
    const infos = await this.auth.getInfos();
    const userEvents: any = await this.auth.getUserEvents(infos.id);
    this.events = [
      ...userEvents.owner,
      ...userEvents.admin.map((x) => x.event),
    ].reverse();
    this.selectedEvent = this.events[0];
    this.isEventsLoading = false;
    this.onChangeEvent();
  }

  async onChangeEvent() {
    this.activityRegistry = [];
    this.page = 1;
    this.endReached = false;
    this.loadActivityRegistry();
  }

  async onChangeAction() {
    this.onChangeEvent();
  }

  async loadActivityRegistry() {
    const resp: any = await this.event.getActivityRegistry(
      this.selectedEvent._id,
      this.page,
      this.action
    );
    this.activityRegistry.push(...resp.registers);
    this.activityRegistryCount = resp.count;
    console.log({
      a: this.activityRegistry.length,
      b: this.activityRegistryCount,
      c: this.activityRegistry.length >= this.activityRegistryCount,
    });
    if (this.activityRegistry.length >= this.activityRegistryCount)
      this.endReached = true;
  }

  loadMore() {
    this.page += 1;
    this.loadActivityRegistry();
  }
}
