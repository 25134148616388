<div *ngIf="event">
  <div class="alert alert-warning" *ngIf="event.progress == 'revision'">
    <i class="mdi mdi-alert-circle" translate>EVENT.IN-REVISION</i>
  </div>
  <button
    class="btn btn-outline-info w-100 mb-3"
    (click)="openActiveSubscriptionlist()"
    *ngIf="!event.isFinished && permission.hasCategory"
  >
    <i class="mdi mdi-ticket-confirmation" translate>
      EVENT.OPEN-SUBSCRIPTIONS
    </i>
  </button>
  <div
    class="alert alert-info"
    *ngIf="isInDate && event.progress != 'revision' && !event.isFinished"
  >
    <i class="mdi mdi-alert-circle" translate>EVENT.IS-RUNNING</i>
  </div>

  <div class="alert alert-dark" *ngIf="isPassedDate || event.isFinished">
    <i class="mdi mdi-alert-circle" translate>EVENT.IS-FINISHED</i>
  </div>
  <nav aria-label="breadcrumb" role="navigation">
    <ol class="breadcrumb bg-inverse-light">
      <li class="breadcrumb-item">
        <a [routerLink]="['/']" translate>HOME</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/events']" translate>EVENTS</a>
      </li>
      <li class="breadcrumb-item active" *ngIf="event != null">
        {{ event.name }}
      </li>
    </ol>
  </nav>

  <div class="card" *ngIf="event">
    <div
      class="card-body"
      id="header"
      [ngStyle]="{
        background:
          'linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 83%)), url(' +
          event.coverUrl +
          ')'
      }"
    >
      <div class="row mb-3">
        <div class="col-md-12 col-lg-6 d-flex">
          <img
            *ngIf="event"
            [src]="event.logoUrl"
            class="align-self-start mr-3 event-image"
            width="124"
            height="124"
            alt="..."
          />
          <div
            class="
              d-flex
              flex-column flex-wrap
              align-items-baseline
              justify-content-center
            "
          >
            <div class="d-inline-flex align-items-baseline">
              <h3
                class="home-panel-title prepend-top-8 append-bottom-5"
                *ngIf="event != null"
              >
                {{ event.name }}
              </h3>
            </div>
            <div
              class="
                home-panel-metadata
                d-flex
                align-items-center
                text-secondary
              "
            >
              <span>
                {{ "PROGRESS" | translate }}:
                <span
                  *ngIf="event.progress == 'revision'"
                  class="text-warning"
                  >{{ "IN-REVISION" | translate }}</span
                >
                <span
                  *ngIf="event.progress == 'published'"
                  class="text-success"
                  >{{ "PUBLISHED" | translate }}</span
                >
              </span>
              <span class="access-request-links prepend-left-8"> </span>
            </div>
            <div class="group-panel pt-2" *ngIf="event.typeGroup">
              <div class="btn btn-outline-primary" (click)="openTypeGroup()">
                <i class="mdi mdi-trophy-variant">
                  {{ event.typeGroup.name }}
                </i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            col-md-12 col-lg-6
            d-inline-flex
            flex-wrap
            justify-content-lg-end
          "
        >
          <div class="group-buttons d-flex flex-column justify-content-center">
            <div class="btn-group">
              <button
                *ngIf="permission.hasLive"
                class="btn btn-gradient-primary"
                (click)="goToLive()"
                [disabled]="!permission.hasLive"
              >
                {{ "LIVE.MANAGE" | translate }}
              </button>
              <button
                *ngIf="!permission.hasLive"
                class="btn btn-gradient-dark"
                [disabled]="true"
                translate
              >
                LIVE.ON
              </button>
              <button
                class="btn btn-outline-light"
                [disabled]="!permission.hasAdmin"
                (click)="open()"
                translate
              >
                EDIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body pills-primary">
      <ul class="nav nav-pills justify-content-start">
        <li class="nav-item" *ngIf="params">
          <a
            class="nav-link"
            (click)="openTab('info')"
            [ngClass]="{ active: getTab() == 'info' }"
            translate
            >INFORMATIONS</a
          >
        </li>
        <li class="nav-item" *ngIf="permission.hasCategory">
          <a
            class="nav-link"
            (click)="openTab('categories')"
            [ngClass]="{ active: getTab() == 'categories' }"
            translate
            >CATEGORIES</a
          >
        </li>
        <li class="nav-item" *ngIf="permission.hasCategory">
          <a
            class="nav-link"
            (click)="openTab('subscriptions')"
            [ngClass]="{ active: getTab() == 'subscriptions' }"
            translate
            >SUBSCRIPTIONS</a
          >
        </li>
        <li class="nav-item" *ngIf="permission.hasCategory">
          <a
            class="nav-link"
            (click)="openTab('subscriptions-beta')"
            [ngClass]="{ active: getTab() == 'subscriptions-beta' }"
            >{{ "SUBSCRIPTIONS" | translate }} (BETA)</a
          >
        </li>
        <li class="nav-item" *ngIf="permission.hasCategory">
          <a
            class="nav-link"
            (click)="openTab('stalls')"
            [ngClass]="{ active: getTab() == 'stalls' }"
            translate
            >STALLS-RESERVATION</a
          >
        </li>
        <li class="nav-item" *ngIf="permission.hasCategory">
          <a
            class="nav-link"
            (click)="openTab('promotions')"
            [ngClass]="{ active: getTab() == 'promotions' }"
            translate
            >PROMOTIONS</a
          >
        </li>
        <li
          class="nav-item"
          *ngIf="permission.hasCategory && event.progress == 'published'"
        >
          <a
            class="nav-link"
            (click)="openTab('result')"
            [ngClass]="{ active: getTab() == 'result' }"
            translate
            >RESULTS</a
          >
        </li>
        <li class="nav-item" *ngIf="permission.hasAdmin">
          <a
            class="nav-link"
            (click)="openTab('admins')"
            [ngClass]="{ active: getTab() == 'admins' }"
            translate
            >ADMINISTRATORS</a
          >
        </li>

        <li class="nav-item" *ngIf="permission.hasAdmin">
          <a
            class="nav-link"
            (click)="openTab('general')"
            [ngClass]="{ active: getTab() == 'general' }"
            translate
            >GENERAL-SETTINGS</a
          >
        </li>
      </ul>
      <app-event-info
        *ngIf="getTab() == 'info' && event"
        [eventParam]="event"
        [permission]="permission"
        (initEventInfo)="initEventInfo()"
      ></app-event-info>
      <app-event-categories
        *ngIf="getTab() == 'categories' && event && permission.hasCategory"
        [eventParam]="event"
        [permission]="permission"
      ></app-event-categories>
      <app-event-subscriptions
        *ngIf="getTab() == 'subscriptions' && event && permission.hasCategory"
        [eventParam]="event"
      ></app-event-subscriptions>
      <app-event-subscriptions-beta
        *ngIf="
          getTab() == 'subscriptions-beta' && event && permission.hasCategory
        "
        [eventParam]="event"
      ></app-event-subscriptions-beta>
      <app-event-stalls
        *ngIf="getTab() == 'stalls' && event && permission.hasCategory"
        [eventParam]="event"
      ></app-event-stalls>
      <app-event-promotions
        *ngIf="getTab() == 'promotions' && event && permission.hasCategory"
        [eventParam]="event"
      ></app-event-promotions>
      <app-event-result
        *ngIf="getTab() == 'result' && event && permission.hasCategory"
        [eventParam]="event"
      >
      </app-event-result>
      <app-event-admins
        *ngIf="getTab() == 'admins' && permission.hasAdmin"
      ></app-event-admins>

      <app-event-general
        *ngIf="getTab() == 'general' && permission.hasAdmin"
        [event]="event"
        (initEventInfo)="initEventInfo()"
      ></app-event-general>
    </div>
  </div>
</div>
