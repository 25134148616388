import { Injectable } from "@angular/core";
import { LoginUser } from "../dto/LoginUser";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CodeInfo } from "../dto/CodeInfo";
import { RegisterUser } from "../dto/RegisterUser";

import { UpdateUser } from "../dto/UpdateUser";

const helper = new JwtHelperService();

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}

  Login(user: LoginUser) {
    return this.http.post(`${environment.apiUrl}/auth`, user).toPromise();
  }

  Register(user: RegisterUser) {
    return this.http.post(`${environment.apiUrl}/users`, user).toPromise();
  }

  getInfos() {
    return helper.decodeToken(localStorage.getItem("currentUser"));
  }

  Logout() {
    localStorage.removeItem("currentUser");
    return true;
  }

  sendEmail(email: string) {
    return this.http.post(`${environment.apiUrl}/email`, { email }).toPromise();
  }

  confirmCode(codeInfo: CodeInfo) {
    return this.http
      .post(`${environment.apiUrl}/users/reset`, codeInfo)
      .toPromise();
  }

  deleteAccount(id: string) {
    return this.http.delete(`${environment.apiUrl}/users/${id}`).toPromise();
  }

  getToken() {
    return JSON.parse(localStorage.getItem("currentUser")).token;
  }

  decodeToken(): any {
    return helper.decodeToken(this.getToken());
  }

  getUserDetails(id: string) {
    return this.http.get(`${environment.apiUrl}/users/${id}`).toPromise();
  }

  updateUserDetails(id: string, updateUser: UpdateUser) {
    return this.http
      .post(`${environment.apiUrl}/users/${id}`, updateUser)
      .toPromise();
  }

  getUserEvents(id: string) {
    return this.http.get(`${environment.apiUrl}/user/events`).toPromise();
  }

  getUserEventsByYear(year: number) {
    return this.http
      .get(`${environment.apiUrl}/user/events-by-year/${year}`)
      .toPromise();
  }
}
