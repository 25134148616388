import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EventService } from "src/app/services/event.service";
import * as dateFns from "date-fns";
import errorHandler from "src/app/util/errorHandler";
import { AlertTranslateService } from "src/app/services/alert.service";

@Component({
  selector: "app-event-subscriptionlist",
  templateUrl: "./event-subscriptionlist.component.html",
  styleUrls: ["./event-subscriptionlist.component.scss"],
})
export class EventSubscriptionlistComponent implements OnInit {
  @Input() event: any;
  competitions: any;
  activeCompetitions = [];
  isLoading = false;
  date;
  hour;
  isLoadingSchedule = false;

  constructor(
    public activeModal: NgbActiveModal,
    private _event: EventService,
    private alertService: AlertTranslateService
  ) {}

  ngOnInit() {
    this.getScheduleSubscriptions();
    this.getEventCompetitions();
  }

  async getEventCompetitions() {
    this.isLoading = true;
    this.competitions = await this._event.getEventCompetitions(this.event._id);
    this.competitions = this.competitions.filter((c) => !c.isRank);
    this.isLoading = false;
  }

  async setActiveSubscription(competitionId, status) {
    await this._event.setCompetitionIsActiveSubscription(
      this.event._id,
      competitionId,
      status
    );
    this.getEventCompetitions();
  }

  async setActiveSubscriptionAll(status) {
    await this._event.setCompetitionIsActiveSubscriptionAll(
      this.event._id,
      status
    );
    this.getEventCompetitions();
  }

  submitScheduleSubscriptions() {
    let selectedDate = dateFns.addMinutes(
      dateFns.addHours(
        new Date(
          `${this.date[2]}${this.date[3]}-${this.date[0]}${this.date[1]}-${this.date[4]}${this.date[5]}${this.date[6]}${this.date[7]}`
        ),
        parseInt(`${this.hour[0]}${this.hour[1]}`)
      ),
      parseInt(`${this.hour[2]}${this.hour[3]}`)
    );
    if (dateFns.isBefore(selectedDate, new Date())) {
      this.alertService.danger("ERROR-DATE-HOUR");
    }

    try {
      this._event.setCompetitionIsActiveSubscriptionSchedule(
        this.event._id,
        new Date(selectedDate).getTime().toString()
      );
      this.alertService.success("SUCCESS-SCHEDULE");
    } catch (error) {}
  }

  async getScheduleSubscriptions() {
    this.isLoadingSchedule = true;
    let scheduled: any = await this._event.getScheduleSubscriptionActive(
      this.event._id
    );
    if (scheduled) {
      this.date = dateFns.format(
        new Date(
          scheduled.nextRunAt ? scheduled.nextRunAt : scheduled.lastRunAt
        ),
        "ddMMyyyy"
      );
      this.hour = dateFns.format(
        new Date(
          scheduled.nextRunAt ? scheduled.nextRunAt : scheduled.lastRunAt
        ),
        "HHmm"
      );
    }
    this.isLoadingSchedule = false;
  }
}
