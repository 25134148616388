<ngb-alert
  type="dark"
  *ngIf="event.isDoneRanking"
  (close)="disabledNotify = true"
  >Este evento já foi rankeado!</ngb-alert
>

<div class="row">
  <div class="col-md-6">
    <button
      class="btn btn-outline-primary"
      (click)="setRank('61638b933f39f915714b0f01')"
      [disabled]="event.isDoneRanking"
    >
      Rankear Sul
    </button>
  </div>
  <div class="col-md-6" *ngIf="event.typeGroup">
    <button
      class="btn btn-outline-primary"
      (click)="setTypegroupRank()"
      [disabled]="event.isDoneTypegroupRanking"
    >
      Rankear Grupo
    </button>
  </div>
</div>
