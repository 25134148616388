/// <reference types="@types/googlemaps" />
declare var google: any;
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  Input,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AlertTranslateService } from "src/app/services/alert.service";
import { EventService } from "src/app/services/event.service";
import { EditEvent } from "src/app/dto/EditEvent";
import { MapsAPILoader } from "@agm/core";

@Component({
  selector: "app-event-edit",
  templateUrl: "./event-edit.component.html",
  styleUrls: ["./event-edit.component.scss"],
})
export class EventEditComponent implements OnInit {
  editEventForm: FormGroup;
  submitted: boolean = false;

  @ViewChild("search", { static: true }) public searchElement: ElementRef;
  @Input() event: any;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertTranslateService,
    private _event: EventService,
    public activeModal: NgbActiveModal,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.editEventForm = this.formBuilder.group({
      name: [this.event.name, [Validators.required]],
      address: [this.event.address, [Validators.required]],
      phone: [this.event.phone, [Validators.required]],
      email: [this.event.email, [Validators.required]],
    });

    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElement.nativeElement,
        { types: ["geocode"] }
      );

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
        });
      });
    });
  }

  async onSubmit() {
    this.submitted = true;
    if (!this.editEventForm.valid) return;

    const { name, address, phone, email } = this.editEventForm.controls;

    try {
      let dtoEditEvent: EditEvent = new EditEvent();
      dtoEditEvent = {
        name: name.value,
        address: address.value,
        phone: phone.value,
        email: email.value,
      };

      await this._event.updateEventBasics(this.event._id, dtoEditEvent);
      this.alertService.success("UPDATED-EVENT");
      this.submitted = false;
      this.activeModal.close("updated");
    } catch (ex) {
      this.alertService.danger("ERROR-UPDATE-EVENT");
      this.submitted = false;
    }
  }

  get f() {
    return this.editEventForm.controls;
  }
}
