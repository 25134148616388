<nav
  class="
    navbar
    default-layout-navbar
    col-lg-12 col-12
    p-0
    fixed-top
    d-flex
    flex-row
    horse-navbar
  "
>
  <div
    class="
      text-center
      navbar-brand-wrapper
      d-flex
      align-items-center
      justify-content-center
    "
  >
    <a class="navbar-brand brand-logo" href="/">
      <img src="../../../assets/images/hcs-horizontal.png" alt="logo" />
    </a>
    <!-- 
    <a class="navbar-brand brand-logo-mini" href="index.html">
      <img src="assets/images/logo-mini.svg" alt="logo" />
    </a> -->
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-stretch">
    <!-- <div class="search-field d-none d-md-block">
      <form class="d-flex align-items-stretch h-100" action="#">
        <div class="input-group align-items-center">
          <div class="input-group-prepend bg-transparent">
            <i class="input-group-text border-0 mdi mdi-magnify"></i>
          </div>
          <input
            type="text"
            class="form-control bg-transparent border-0"
            placeholder="Procurar Evento"
          />
        </div>
      </form>
    </div> -->
    <ul class="navbar-nav navbar-nav-right">
      <!-- <li class="nav-item dropdown" ngbDropdown>
        <a
          class="nav-link count-indicator dropdown-toggle"
          id="notificationDropdown"
          ngbDropdownToggle
        >
          <i class="mdi mdi-bell-outline"></i>
          <span class="count-symbol bg-danger"></span>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
          ngbDropdownMenu
          aria-labelledby="notificationDropdown"
        >
          <h6 class="p-3 mb-0">Notificações</h6>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item preview-item">
            <div class="preview-thumbnail">
              <div class="preview-icon bg-success">
                <i class="mdi mdi-calendar"></i>
              </div>
            </div>
            <div
              class="preview-item-content d-flex align-items-start flex-column justify-content-center"
            >
              <h6 class="preview-subject font-weight-normal mb-1">
                Event today
              </h6>
              <p class="text-gray ellipsis mb-0">
                Just a reminder that you have an event today
              </p>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item preview-item">
            <div class="preview-thumbnail">
              <div class="preview-icon bg-warning">
                <i class="mdi mdi-settings"></i>
              </div>
            </div>
            <div
              class="preview-item-content d-flex align-items-start flex-column justify-content-center"
            >
              <h6 class="preview-subject font-weight-normal mb-1">Settings</h6>
              <p class="text-gray ellipsis mb-0">
                Update dashboard
              </p>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item preview-item">
            <div class="preview-thumbnail">
              <div class="preview-icon bg-info">
                <i class="mdi mdi-link-variant"></i>
              </div>
            </div>
            <div
              class="preview-item-content d-flex align-items-start flex-column justify-content-center"
            >
              <h6 class="preview-subject font-weight-normal mb-1">
                Launch Admin
              </h6>
              <p class="text-gray ellipsis mb-0">
                New admin wow!
              </p>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <h6 class="p-3 mb-0 text-center">Ver todas as notificações</h6>
        </div>
      </li> -->
      <li class="nav-item nav-profile dropdown" ngbDropdown>
        <a
          class="nav-link dropdown-toggle"
          id="profileDropdown"
          ngbDropdownToggle
        >
          <!-- <div class="nav-profile-img">
                <img src="assets/images/faces/face1.jpg" alt="image">
                <span class="availability-status online"></span>
              </div> -->
          <div class="nav-profile-text">
            <p class="mb-1 text-black" *ngIf="user">
              {{ user.userName }}
            </p>
          </div>
        </a>
        <div
          class="dropdown-menu navbar-dropdown"
          ngbDropdownMenu
          aria-labelledby="profileDropdown"
        >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/user/account']">
            <i class="mdi mdi-account text-primary"></i>
            {{ "ACCOUNT" | translate }}
          </a>
          <button class="dropdown-item" (click)="Logout()">
            <i class="mdi mdi-logout text-primary"></i>
            {{ "LOGOUT" | translate }}
          </button>
        </div>
      </li>
      <li class="nav-item nav-profile dropdown" ngbDropdown>
        <a
          class="nav-link dropdown-toggle"
          id="profileDropdown"
          ngbDropdownToggle
        >
          <div class="nav-profile-text">
            <p class="mb-1 text-black">
              <i class="mdi mdi-earth mr-2 text-primary"></i>
            </p>
          </div>
        </a>
        <div
          class="dropdown-menu navbar-dropdown"
          ngbDropdownMenu
          aria-labelledby="profileDropdown"
        >
          <button
            *ngFor="let lang of languages()"
            class="dropdown-item"
            (click)="changeLanguage(lang.type)"
          >
            {{ lang.name }}
          </button>
        </div>
      </li>
    </ul>
    <button
      class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
      type="button"
      (click)="toggleOffcanvas()"
    >
      <span class="mdi mdi-menu"></span>
    </button>
  </div>
</nav>
