import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AbqmService {
  constructor(private http: HttpClient) {}

  addAbqmCompetitor(cpf: string) {
    return this.http
      .post(`${environment.apiUrl}/abqm/competitor`, { cpf })
      .toPromise();
  }

  addAbqmAnimal(animalId: string) {
    return this.http
      .post(`${environment.apiUrl}/abqm/horse`, { animalId })
      .toPromise();
  }

  verifyCompetitorLineage(horseId: string, competitorId: string) {
    return this.http
      .get(
        `${environment.apiUrl}/abqm/competitor-lineage/${horseId}/${competitorId}`
      )
      .toPromise();
  }
  setCompetitionAbqmParticipation(eventId: string, competitionId: string) {
    return this.http
      .post(
        `${environment.apiUrl}/event/${eventId}/abqm/insert-competition-participation`,
        {
          competitionId,
        }
      )
      .toPromise();
  }
  setFinishCompetitionAbqm(eventId: string, competitionId: string) {
    return this.http
      .post(`${environment.apiUrl}/event/${eventId}/abqm/finish-competition`, {
        competitionId,
      })
      .toPromise();
  }

  getAbqmCompetitions(eventId: string) {
    return this.http
      .get(`${environment.apiUrl}/abqm/${eventId}/competitions`)
      .toPromise();
  }
}
