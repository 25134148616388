<div class="card">
  <div class="card-body">
    <div
      class="card-title"
      [translate]="{
        count: count ? ' -' + count + ' Competidores Registrados' : ''
      }"
    >
      COMPETITOR.MANAGE
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="input-group">
          <input
            type="text"
            class="form-control h-100"
            placeholder="{{ 'COMPETITOR.SEARCH' | translate }}"
            [(ngModel)]="competitorQuery"
            (keyup.enter)="searchCompetitor()"
          />
          <button
            *ngIf="competitorQuery !== ''"
            (click)="clearCompetitor()"
            class="btn bg-transparent"
            style="margin-left: -40px; z-index: 100; padding: 0"
          >
            <i class="mdi mdi-for-btn mdi-close"></i>
          </button>
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="searchCompetitor()"
              translate
            >
              SEARCH
            </button>
            <button
              class="btn btn-outline-info"
              type="button"
              (click)="openCompetitorModal({})"
              translate
            >
              COMPETITOR.ADD
            </button>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" translate>NAME</th>
          <th scope="col" translate>AGE</th>
          <th scope="col" translate>CPF</th>
          <th scope="col" translate>GENDER</th>
          <th scope="col" translate>PROFESSIONAL</th>
          <th scope="col" translate>EMAIL</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let competitor of competitors; index as i">
          <th scope="row">{{ competitor.name }}</th>
          <td>
            {{ competitor.age | date: "shortDate" }} -
            {{ competitor.age | hippicalYearPipeCompetitor }} Anos Hípicos
          </td>
          <td>{{ competitor.cpf }}</td>
          <td>{{ competitor.gender == "MALE" ? "Masculino" : "Feminino" }}</td>
          <td>{{ competitor.professional == true ? "Sim" : "Não" }}</td>
          <td>{{ competitor.email }}</td>
          <td>
            <button
              class="btn btn-outline-info mdi mdi-for-btn mdi-table-edit"
              type="button"
              (click)="openCompetitorModal(competitor)"
            ></button>
          </td>
        </tr>
        <tr>
          <td colspan="6" style="vertical-align: middle; text-align: center">
            <div
              class="btn btn-outline-primary"
              (click)="getAllCompetitors()"
              *ngIf="count != competitors.length"
              translate
            >
              LOAD-MORE
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
