<div class="modal-header">
  <h4 class="modal-title" translate>PAYMENTS</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-3 mb-2">
      <input
        type="text"
        class="form-control"
        placeholder="Valor"
        [(ngModel)]="inputValue"
      />
      <small class="form-text text-muted" translate
        >FINANCIAL-GENERAL.ACCOUNTINGS.ENTER-VALUE</small
      >
    </div>

    <div class="col-md-3 mb-2">
      <select required class="form-control" [(ngModel)]="selectType">
        <option value="CASHIER" translate>CASHIER</option>
        <option value="CHECK" translate>CHECK</option>
        <option value="DEPOSIT" translate>DEPOSIT</option>
        <option value="BANKSLIP" translate>BANKSLIP</option>
      </select>
      <small class="form-text text-muted" translate>SELECT-ONE</small>
    </div>

    <div class="col-md-4 mb-2">
      <div class="input-group">
        <input
          class="form-control"
          name="dp"
          ngbDatepicker
          [(ngModel)]="date"
          #d="ngbDatepicker"
          (click)="d.toggle()"
          readonly
        />
        <div class="input-group-append">
          <button
            class="
              btn btn-outline-primary
              mdi mdi-calendar mdi-for-btn
              horse-gradient
            "
            (click)="d.toggle()"
            type="button"
          ></button>
        </div>
      </div>
      <small class="form-text text-muted" translate>SELECT-ONE</small>
    </div>

    <div class="col-md-2">
      <button
        type="submit"
        class="btn btn-block btn-sm btn-gradient-primary"
        (click)="submitItem()"
        [disabled]="!inputValue || !selectType || !date"
        translate
      >
        ADD
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th translate>TYPE</th>
              <th translate>VALUE</th>
              <th translate>DATE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="itemsLoading">
              <td colspan="4">
                <div
                  class="
                    lds-dual-ring lds-dual-ring-black
                    d-flex
                    justify-content-center
                    w-100
                  "
                ></div>
              </td>
            </tr>
            <tr *ngIf="!itemsLoading && items.length == 0">
              <td colspan="4" translate>
                FINANCIAL-GENERAL.ACCOUNTINGS.NOTHING-RECEIVED
              </td>
            </tr>
            <tr *ngFor="let item of items">
              <td translate>
                {{ item.type }}
              </td>
              <td>{{ item.value }}</td>
              <td>{{ item.date | date: "shortDate" }}</td>
              <td>
                <div
                  class="
                    btn btn-outline-primary
                    mdi mdi-for-btn mdi-delete-forever
                  "
                  (click)="deleteItem(item._id)"
                ></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
