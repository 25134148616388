<div class="page-header">
  <h3 class="page-title">
    <span class="page-title-icon bg-gradient-primary text-white mr-2">
      <i class="mdi mdi-home"></i>
    </span>
    {{ "DASHBOARD.TITLE" | translate }}
  </h3>
  <nav aria-label="breadcrumb">
    <ul class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">
        <span translate>DASHBOARD.SCOPE</span>
      </li>
    </ul>
  </nav>
</div>
<div class="row">
  <div class="col-md-4 stretch-card grid-margin">
    <div class="card bg-gradient-danger card-img-holder text-white">
      <div class="card-body">
        <img
          src="assets/images/dashboard/circle.svg"
          class="card-img-absolute"
          alt="circle-image"
        />
        <h4 class="font-weight-normal mb-3" translate>
          {{ "DASHBOARD.MY-EVENTS" | translate }}
          <i class="mdi mdi-chart-line mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">
          {{ statistics ? statistics.eventsCount : "0" }}
        </h2>
        <h6 class="card-text">
          {{ "DASHBOARD.ONLY-ADMIN" | translate }}{{ statistics.eventsAdmin }}
        </h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin">
    <div class="card bg-gradient-info card-img-holder text-white">
      <div class="card-body">
        <img
          src="assets/images/dashboard/circle.svg"
          class="card-img-absolute"
          alt="circle-image"
        />
        <h4 class="font-weight-normal mb-3">
          {{ "DASHBOARD.EVENT-SUBS" | translate }}
          <i class="mdi mdi-ticket mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">
          {{ statistics ? statistics.takeoffs : "0" }}
        </h2>
        <!-- <h6 class="card-text">Decreased by 10%</h6> -->
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin">
    <div class="card bg-gradient-success card-img-holder text-white">
      <div class="card-body">
        <img
          src="assets/images/dashboard/circle.svg"
          class="card-img-absolute"
          alt="circle-image"
        />
        <h4 class="font-weight-normal mb-3">
          {{ "DASHBOARD.USERS-SUBS" | translate }}
          <i class="mdi mdi-account mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">
          {{ statistics ? statistics.users : "0" }}
        </h2>
        <!-- <h6 class="card-text">Increased by 5%</h6> -->
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title" translate>DASHBOARD.MY-EVENTS</h4>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th translate>NAME</th>
                <th translate>PROGRESS</th>
                <th></th>
                <th translate>EARNS</th>
                <th translate>DATE</th>
                <th translate>ACCESS</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="events.length == 0" [routerLink]="['/events/add']">
                <td class="py-1 text-center" colspan="6" rowspan="1">
                  <a [routerLink]="" translate>DASHBOARD.NO-EVENTS</a>
                </td>
              </tr>
              <tr *ngFor="let event of events" class="form-clickable">
                <td class="py-1" [routerLink]="['/event', event.slug]">
                  {{ event.name }}
                </td>
                <td [routerLink]="['/event', event.slug]">
                  <div class="progress">
                    <div
                      class="progress-bar bg-gradient-success"
                      role="progressbar"
                      [ngStyle]="{
                        width: event.progress == 'revision' ? '50%' : '100%'
                      }"
                    ></div>
                  </div>
                </td>
                <td class="py-1" [routerLink]="['/event', event.slug]">
                  <label
                    class="badge"
                    [ngClass]="
                      event.progress == 'revision'
                        ? 'badge-gradient-warning'
                        : 'badge-gradient-success'
                    "
                    translate
                    >{{
                      event.progress == "revision" ? "REVISION" : "PUBLISHED"
                    }}</label
                  >
                </td>
                <td [routerLink]="['/event', event.slug]">
                  {{ event.currency | currencyPipe }}{{ event.earnings }}
                </td>
                <td [routerLink]="['/event', event.slug]">
                  <span>{{ event.startDate | date: "shortDate" }}</span>
                  <span *ngIf="event.finalDate !== event.startDate">
                    - {{ event.finalDate | date: "shortDate" }}</span
                  >
                </td>
                <td translate>OWNER</td>
              </tr>
              <tr *ngFor="let event of adminEvents" class="form-clickable">
                <td class="py-1" [routerLink]="['/event', event.slug]">
                  {{ event.name }}
                </td>
                <td [routerLink]="['/event', event.slug]">
                  <div class="progress">
                    <div
                      class="progress-bar bg-gradient-success"
                      role="progressbar"
                      [ngStyle]="{
                        width: event.progress == 'revision' ? '50%' : '100%'
                      }"
                    ></div>
                  </div>
                </td>
                <td class="py-1" [routerLink]="['/event', event.slug]">
                  <label
                    class="badge"
                    [ngClass]="
                      event.progress == 'revision'
                        ? 'badge-gradient-warning'
                        : 'badge-gradient-success'
                    "
                    >{{
                      event.progress == "revision" ? "Em Revisão" : "Aberto"
                    }}</label
                  >
                </td>
                <td [routerLink]="['/event', event.slug]">
                  {{ event.currency | currencyPipe }}{{ event.earnings }}
                </td>
                <td [routerLink]="['/event', event.slug]">
                  <span>{{ event.startDate | date: "mediumDate" }}</span>
                  <span *ngIf="event.finalDate !== event.startDate">
                    -
                    {{ event.finalDate | date: "mediumDate" }}
                  </span>
                </td>
                <td translate>ADMIN</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="false">
  <div class="col-md-5 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Recent Updates</h4>
        <div class="d-flex">
          <div
            class="d-flex align-items-center mr-4 text-muted font-weight-light"
          >
            <i class="mdi mdi-account-outline icon-sm mr-2"></i>
            <span>jack Menqu</span>
          </div>
          <div class="d-flex align-items-center text-muted font-weight-light">
            <i class="mdi mdi-clock icon-sm mr-2"></i>
            <span>October 3rd, 2018</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6 pr-1">
            <img
              src="assets/images/dashboard/img_1.jpg"
              class="mb-2 mw-100 w-100 rounded"
              alt="image"
            />
            <img
              src="assets/images/dashboard/img_4.jpg"
              class="mw-100 w-100 rounded"
              alt="image"
            />
          </div>
          <div class="col-6 pl-1">
            <img
              src="assets/images/dashboard/img_2.jpg"
              class="mb-2 mw-100 w-100 rounded"
              alt="image"
            />
            <img
              src="assets/images/dashboard/img_3.jpg"
              class="mw-100 w-100 rounded"
              alt="image"
            />
          </div>
        </div>
        <div class="d-flex mt-5 align-items-top">
          <img
            src="assets/images/faces/face3.jpg"
            class="img-sm rounded-circle mr-3"
            alt="image"
          />
          <div class="mb-0 flex-grow">
            <h5 class="mr-2 mb-2">School Website - Authentication Module.</h5>
            <p class="mb-0 font-weight-light">
              It is a long established fact that a reader will be distracted by
              the readable content of a page.
            </p>
          </div>
          <div class="ml-auto">
            <i class="mdi mdi-heart-outline text-muted"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
