import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventService } from "src/app/services/event.service";
import { PdfService } from "src/app/services/pdf.service";

@Component({
  selector: "app-event-result",
  templateUrl: "./event-result.component.html",
  styleUrls: ["./event-result.component.scss"],
})
export class EventResultComponent implements OnInit {
  @Input() eventParam;
  resultCompetitions: any = [];
  noResult: boolean = false;
  selectedCompetition;
  takeoffs;
  equalTakeoffs;
  takeoffsLoading = false;

  constructor(
    private eventService: EventService,
    private modalService: NgbModal,
    private pdfService: PdfService
  ) {}

  ngOnInit() {
    this.getEventResults();
  }

  async getEventResults() {
    this.resultCompetitions = [];
    this.takeoffsLoading = true;
    this.takeoffs = [];
    this.resultCompetitions = await this.eventService.getEventResults(
      this.eventParam._id
    );

    if (this.resultCompetitions.length > 0) {
      this.getTakeOffs(this.resultCompetitions[0]._id);
    } else {
      this.noResult = true;
      this.takeoffsLoading = false;
    }
  }

  async getTakeOffs(compId) {
    this.takeoffsLoading = true;
    this.takeoffs = [];
    this.selectedCompetition = this.resultCompetitions.filter(
      (x) => x._id == compId
    )[0];
    this.takeoffs = await this.eventService.getEventResultsTakeoffs(
      this.eventParam._id,
      compId
    );
    this.equalTakeoffs = this.takeoffs.filter((x) => x.equal);
    this.takeoffs = this.takeoffs.filter((x) => !x.equal);
    this.takeoffsLoading = false;
  }

  getEqualsTakeoffs(horseId: string) {
    return this.equalTakeoffs.filter((x) => x.horse._id == horseId);
  }

  async openChooseModal(content) {
    const result = await this.modalService.open(content, { size: "sm" }).result;
    this.pdfService.submitGenResult(
      this.eventParam,
      this.takeoffs,
      this.selectedCompetition,
      result
    );
  }

  tkHasPass() {
    return this.takeoffs.filter((x) => x.hasPass).length > 0;
  }
}
